import React from 'react';
import helpers from "../../../chunks/helpers";
import md5 from 'md5-hash';

import { Link } from "react-router-dom";
import routes from "../../../chunks/routes";

import "./user-entry.css";

class UserEntry extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showPassword: false
        };

        this.showPassword = this.showPassword.bind(this);
    }

    showPassword() {
        this.setState({
            showPassword: true
        });
    }

    roleBg(role) {
        if (role === 'admin') {
            return 'bg-red';
        } else if (role === 'moderator') {
            return 'bg-orange'
        } else {
            return 'bg-blue';
        }
    }

    gravatar() {
        const hash = md5(this.props.data.username).toLowerCase();
        return `https://www.gravatar.com/avatar/${hash}?d=monsterid&s=40`;
    }

    render() {
        const user   = this.props.data;
        const roleBg = `${this.roleBg(user.role)} p-1`;
        const link   = routes.users.profile.replace(':username', user.username);
        const min5   = (1000 * 60 * 5);

        return (
            <>
                <tr>
                    <td>
                        <img alt={user.username} className="img-circle" src={this.gravatar()} />
                    </td>
                    <td>
                        {(Date.now() - user.lastActivity) <= min5 && (
                            <span className="user-online-dot"/>
                        )}
                        <strong className="mr-2">{user.username}</strong>
                        <small className={roleBg}>{user.role}</small>
                        {user.lastActivity && (
                            <div>
                                <small>Last Activity: <i className="text-secondary">{ helpers.dt(user.lastActivity)}</i></small>
                            </div>
                        )}
                    </td>
                    <td>
                        {this.state.showPassword ? (
                            user.password
                        ) : (
                            <span style={{ cursor: 'pointer' }} onClick={this.showPassword} className="badge badge-danger">Show password</span>
                        )}
                    </td>
                    <td>
                        {user.telegram ? (
                            <a rel="noreferrer" target="_blank" href={'tg://'+user.telegram}>
                                <strong>{user.telegram}</strong>
                            </a>
                        ) : (
                            <i className="text-muted">Unknown</i>
                        )}
                    </td>
                    <td>
                        {user.deletedAt ? (<span className="text-danger">Deleted</span>) : (
                            user.banned ? <span className="text-warning">Banned</span> : <span className="text-success">Active</span>
                        )}
                    </td>
                    <td>
                        <small>
                            <div>Created At: <i className="text-secondary">{ helpers.dt(user.createdAt)}</i></div>
                            {user.createdAt !== user.updatedAt && (
                                <div>Updated At: <i className="text-info">{ helpers.dt(user.updatedAt) }</i></div>
                            )}
                            {user.deletedAt && (
                                <div>Deleted At: <i className="text-danger">{ helpers.dt(user.deletedAt) }</i></div>
                            )}
                        </small>
                    </td>
                    <td>
                        <Link to={link} className="btn btn-sm btn-info">Show more</Link>
                    </td>
                </tr>
            </>
        );
    }
}

export default UserEntry;
