import React, {Component} from 'react';
import {Tag} from 'antd';
import helpers from "../../../chunks/helpers";

class PlacedBetOneShoulderForkHistoryEntity extends Component {
  constructor(props) {
    super(props);

    this.state = {};

  }

  render() {
    const history = this.props.data;

    // const secondScore = '15:21 (11:19 4:2) 07:34'

    // let secondTotalScore = ''
    // let secondGameTime = ''
    // let secondScoreQuarter1 = ''
    // let secondScoreQuarter2 = ''
    // let secondScoreQuarter3 = ''
    // let secondScoreQuarter4 = ''
    // let secondCountQuarter = 0
    //
    // if (history.secondScore) {
    //   secondTotalScore = `${history.secondScore}`.split(/\s+/g).shift()
    //   secondGameTime = `${history.secondScore}`.split(/\s+/g).pop()
    // }
    //
    // const sc1 = `${history.secondScore}`.split(/ \(/g)
    // if (sc1) {
    //   if (sc1[1]) {
    //     const sc2 = sc1[1].split(/\)/g)
    //     if (sc2) {
    //       const sc3 = sc2[0].split(/ /g);
    //       if (sc3) {
    //         secondCountQuarter = sc3.length
    //         for (let i = 0; i < sc3.length; i++) {
    //           const sc4 = sc3[i];
    //           if (i === 0) {
    //             secondScoreQuarter1 = sc4
    //           }
    //           if (i === 1) {
    //             secondScoreQuarter2 = sc4
    //           }
    //           if (i === 2) {
    //             secondScoreQuarter3 = sc4
    //           }
    //           if (i === 3) {
    //             secondScoreQuarter4 = sc4
    //           }
    //         }
    //       }
    //     }
    //   }
    // }


    return (
      <>
        {history.isExpress ? (
          <tr key={this.key} className={history.secondStatus === 'accepted' ? (history.marginPercent1 >= 0 ? 'accepted-tr' : 'remove-tr') : ''}>
            {/*<tr key={this.key} className={history.status === 'accepted' ? 'accepted-tr' : (history.isGameClosed ? 'remove-tr' : '')}>*/}
            <td>
              <div>{helpers.dt(history.createdAt)}</div>
              {history.updatedAt && (
                <div><strong><i className="text-info">{helpers.dt(history.updatedAt)}</i></strong></div>
                // <strong><i className="text-info">{helpers.dt(bot.updatedAt)}</i></strong>
                // <div>Last update: {helpers.dt(history.updatedAt)}</div>
              )}
            </td>
            {/*<td>*/}
            {/*  {history.updatedAt && (*/}
            {/*    <div>{helpers.dt(history.updatedAt)}</div>*/}
            {/*    // <div>Last update: {helpers.dt(history.updatedAt)}</div>*/}
            {/*  )}*/}
            {/*</td>*/}
            <td>
              <p className={history.secondStatus === 'accepted' ? 'green-text' : (history.secondStatus === 'failed' ? 'red-text' : (history.secondStatus === 'closed' ? 'text-warning' : ''))}>
                <strong>{history.secondStatus.toUpperCase()}</strong>
              </p>
            </td>
            <td>
              <div><strong>{history.sport1}</strong></div>
              <br/>
              <div><strong>{history.sport2}</strong></div>
            </td>
            <td>
              <div>{history.first1League}</div>
              <br/>
              <div>{history.first2League}</div>
            </td>
            <td>
              <div><a rel="noreferrer" target="_blank" href={`${history.first1EventUrl}`}>{`${history.first1BookmakerName}`.replace('ladbrokes', 'ladbrokes_be').replace('bwin_it', 'superbet_ro').replace('loot', 'nitrobetting').replace('bwin', 'superbet_pl').replace('tonybet', 'tipsport').replace('1win', 'cloudbet_fifa').toUpperCase()}</a></div>
              <div>{history.first1EventName}</div>
              <br/>
              <div><a rel="noreferrer" target="_blank" href={`${history.first2EventUrl}`}>{`${history.first2BookmakerName}`.replace('ladbrokes', 'ladbrokes_be').replace('bwin_it', 'superbet_ro').replace('loot', 'nitrobetting').replace('bwin', 'superbet_pl').replace('tonybet', 'tipsport').replace('1win', 'cloudbet_fifa').toUpperCase()}</a></div>
              <div>{history.first2EventName}</div>
            </td>
            <td>
              <div><a rel="noreferrer" target="_blank" href={`${history.second1EventUrl}`}>{`${history.second1BookmakerName}`.replace('ladbrokes', 'ladbrokes_be').replace('bwin_it', 'superbet_ro').replace('loot', 'nitrobetting').replace('bwin', 'superbet_pl').replace('tonybet', 'tipsport').replace('1win', 'cloudbet_fifa').toUpperCase()}</a></div>
              <div>{history.second1EventName}</div>
              <br/>
              <div><a rel="noreferrer" target="_blank" href={`${history.second2EventUrl}`}>{`${history.second2BookmakerName}`.replace('ladbrokes', 'ladbrokes_be').replace('bwin_it', 'superbet_ro').replace('loot', 'nitrobetting').replace('bwin', 'superbet_pl').replace('tonybet', 'tipsport').replace('1win', 'cloudbet_fifa').toUpperCase()}</a></div>
              <div>{history.second2EventName}</div>
            </td>
            {/*<td>*/}
            {/*  <div><strong>{history.second1EndScore}</strong></div>*/}
            {/*  <div><strong>{history.second2EndScore}</strong></div>*/}
            {/*</td>*/}
            <td>
              {/*<div><strong>{history.second1Score}</strong></div>*/}
              {/*<br/>*/}
              <div><strong>{history.second1Score}</strong></div>
              <br/>
              <div><strong>{history.second2Score}</strong></div>
            </td>
            <td>
              <div><strong>{history.first1BetName}</strong></div>
              <br/>
              <div><strong>{history.first2BetName}</strong></div>
            </td>
            <td>
              <div><strong>{history.second1BetName}</strong></div>
              <br/>
              <div><strong>{history.second2BetName}</strong></div>
            </td>
            <td>
              <div><span className="badge badge-primary badge-font"><strong>{history.first1Odds}</strong></span></div>
              <div><small><span className="badge badge-primary badge-font"><strong>{history.first1OddsUpdateDate ? helpers.dtt(history.first1OddsUpdateDate) : ''}</strong></span></small></div>
              <div><span className="badge badge-secondary badge-font"><strong>{history.first1OddsOld}</strong></span></div>
              <div><small><span className="badge badge-secondary badge-font"><strong>{history.first1OddsOldUpdateDate ? helpers.dtt(history.first1OddsOldUpdateDate) : ''}</strong></span></small></div>
              <br/>
              <div><span className="badge badge-primary badge-font"><strong>{history.first2Odds}</strong></span></div>
              <div><small><span className="badge badge-primary badge-font"><strong>{history.first2OddsUpdateDate ? helpers.dtt(history.first2OddsUpdateDate) : ''}</strong></span></small></div>
              <div><span className="badge badge-secondary badge-font"><strong>{history.first2OddsOld}</strong></span></div>
              <div><small><span className="badge badge-secondary badge-font"><strong>{history.first2OddsOldUpdateDate ? helpers.dtt(history.first2OddsOldUpdateDate) : ''}</strong></span></small></div>
            </td>
            <td>
              <div><span className="badge badge-primary badge-font"><strong>{history.second1Odds}</strong></span></div>
              <div><small><span className="badge badge-primary badge-font"><strong>{history.second1OddsUpdateDate ? helpers.dtt(history.second1OddsUpdateDate) : ''}</strong></span></small></div>
              <div><span className="badge badge-secondary badge-font"><strong>{history.second1OddsOld}</strong></span></div>
              <div><small><span className="badge badge-secondary badge-font"><strong>{history.second1OddsOldUpdateDate ? helpers.dtt(history.second1OddsOldUpdateDate) : ''}</strong></span></small></div>
              <br/>
              <div><span className="badge badge-primary badge-font"><strong>{history.second2Odds}</strong></span></div>
              <div><small><span className="badge badge-primary badge-font"><strong>{history.second2OddsUpdateDate ? helpers.dtt(history.second2OddsUpdateDate) : ''}</strong></span></small></div>
              <div><span className="badge badge-secondary badge-font"><strong>{history.second2OddsOld}</strong></span></div>
              <div><small><span className="badge badge-secondary badge-font"><strong>{history.second2OddsOldUpdateDate ? helpers.dtt(history.second2OddsOldUpdateDate) : ''}</strong></span></small></div>
            </td>
            <td>
              <div><span className={history.marginPercent1 >= 0 ? 'green-text' : 'red-text'}><strong>{history.marginPercent1}</strong></span> <i className="fa fa-percent"/></div>
              <div><span className={history.marginPercent1Init >= 0 ? 'green-text' : 'red-text'}>{history.marginPercent1Init}</span> <i className="fa fa-percent"/></div>
              <br/>
              <div><span className={history.marginPercent2 >= 0 ? 'green-text' : 'red-text'}><strong>{history.marginPercent2}</strong></span> <i className="fa fa-percent"/></div>
              <div><span className={history.marginPercent2Init >= 0 ? 'green-text' : 'red-text'}>{history.marginPercent2Init}</span> <i className="fa fa-percent"/></div>
            </td>
            <td>
              <div><i className="fa fa-usd"/> <span><strong>{history.moneyBet}</strong></span></div>
              <br/>
              <div><i className="fa fa-usd"/> <span>{history.moneyBetInit}</span></div>
            </td>
          </tr>
        ) : (
          <tr key={this.key} className={history.secondStatus === 'accepted' ? (history.marginPercent >= 0 ? 'accepted-tr' : 'remove-tr') : ''}>
            {/*<tr key={this.key} className={history.status === 'accepted' ? 'accepted-tr' : (history.isGameClosed ? 'remove-tr' : '')}>*/}
            <td>
              <div>{helpers.dt(history.createdAt)}</div>
              {history.updatedAt && (
                <div><strong><i className="text-info">{helpers.dt(history.updatedAt)}</i></strong></div>
                // <strong><i className="text-info">{helpers.dt(bot.updatedAt)}</i></strong>
                // <div>Last update: {helpers.dt(history.updatedAt)}</div>
              )}
            </td>
            {/*<td>*/}
            {/*  {history.updatedAt && (*/}
            {/*    <div>Last update: {helpers.dt(history.updatedAt)}</div>*/}
            {/*  )}*/}
            {/*</td>*/}
            <td>
              <p className={history.secondStatus === 'accepted' ? 'green-text' : (history.secondStatus === 'failed' ? 'red-text' : (history.secondStatus === 'closed' ? 'text-warning' : ''))}>
                <strong>{history.secondStatus.toUpperCase()}</strong>
              </p>
            </td>
            <td><strong>{history.sport}</strong></td>
            <td>{history.firstLeague}</td>
            {/*<td>{history.secondLeague}</td>*/}
            <td>
              <div><a rel="noreferrer" target="_blank"
                      href={`${history.firstEventUrl}`}>{`${history.firstBookmakerName}`.replace('ladbrokes', 'ladbrokes_be').replace('bwin_it', 'superbet_ro').replace('loot', 'nitrobetting').replace('bwin', 'superbet_pl').replace('tonybet', 'tipsport').replace('1win', 'cloudbet_fifa').toUpperCase()}</a>
              </div>
              <div>{history.firstEventName}</div>
            </td>
            <td>
              <div><a rel="noreferrer" target="_blank"
                      href={`${history.secondEventUrl}`}>{`${history.secondBookmakerName}`.replace('ladbrokes', 'ladbrokes_be').replace('bwin_it', 'superbet_ro').replace('loot', 'nitrobetting').replace('bwin', 'superbet_pl').replace('tonybet', 'tipsport').replace('1win', 'cloudbet_fifa').toUpperCase()}</a>
              </div>
              <div>{history.secondEventName}</div>
            </td>
            {/*<td>{history.firstOutcomeName}</td>*/}
            {/*<td>{history.secondOutcomeName}</td>*/}
            {/*<td>*/}
            {/*  <div><strong>{history.secondEndScore}</strong></div>*/}
            {/*  /!*<div>First:</div>*!/*/}
            {/*  /!*<div><strong>{history.firstScore}</strong></div>*!/*/}
            {/*  /!*<div>Second:</div>*!/*/}
            {/*  /!*<div><strong>{history.secondScore}</strong></div>*!/*/}
            {/*</td>*/}
            <td>
              <div><strong>{history.secondScore}</strong></div>
              {/*<div>First:</div>*/}
              {/*<div><strong>{history.firstScore}</strong></div>*/}
              {/*<div>Second:</div>*/}
              {/*<div><strong>{history.secondScore}</strong></div>*/}
            </td>
            {/*<td>{history.secondScore}</td>*/}
            <td>
              <div><strong>{history.firstBetName}</strong></div>
              {/*<small>*/}
              {/*  <div><strong>{history.firstBetName}</strong></div>*/}
              {/*  /!*<div>{history.firstOddsUpdateDate ? helpers.dtt(history.firstOddsUpdateDate) : ''}</div>*!/*/}
              {/*  <div>Outcome:</div>*/}
              {/*  <div><strong>{history.firstOutcomeName}</strong></div>*/}
              {/*</small>*/}
            </td>
            <td>
              <div><strong>{history.secondBetName}</strong></div>
              {history.secondBetNameReverse && (
                <>
                  <div>Реверс:</div>
                  <div><strong>{history.secondBetNameReverse}</strong></div>
                </>
              )}

              {/*<small>*/}
              {/*  <div><strong>{history.secondBetName}</strong></div>*/}
              {/*  /!*<div>{history.secondOddsUpdateDate ? helpers.dtt(history.secondOddsUpdateDate) : ''}</div>*!/*/}
              {/*  <div>Outcome:</div>*/}
              {/*  <div><strong>{history.secondOutcomeName}</strong></div>*/}
              {/*</small>*/}
            </td>

            <td>
              <div><span className="badge badge-primary badge-font"><strong>{history.firstOdds}</strong></span></div>
              <div><small><span
                className="badge badge-primary badge-font"><strong>{history.firstOddsUpdateDate ? helpers.dtt(history.firstOddsUpdateDate) : ''}</strong></span></small>
              </div>
              {/*<div><span className={history.firstIsLive ? 'blue-text' : 'gray-text'}><strong>{history.firstOdds}</strong></span></div>*/}

              <br/>

              <div><span className="badge badge-secondary badge-font"><strong>{history.firstOddsOld}</strong></span></div>
              <div><small><span
                className="badge badge-secondary badge-font"><strong>{history.firstOddsOldUpdateDate ? helpers.dtt(history.firstOddsOldUpdateDate) : ''}</strong></span></small>
              </div>
            </td>

            <td>
              <div><span className="badge badge-primary badge-font"><strong>{history.secondOdds}</strong></span></div>
              <div><small><span
                className="badge badge-primary badge-font"><strong>{history.secondOddsUpdateDate ? helpers.dtt(history.secondOddsUpdateDate) : ''}</strong></span></small>
              </div>
              {/*<div><span className={history.secondIsLive ? 'blue-text' : 'gray-text'}><strong>{history.secondOdds}</strong></span></div>*/}

              {history.secondOddsReverse && (
                <>
                  <div>Реверс:</div>
                  <div><span className={history.secondIsLive ? 'blue-text' : 'gray-text'}><strong>{history.secondOddsReverse}</strong></span></div>
                </>
              )}
              <br/>

              <div><span className="badge badge-secondary badge-font"><strong>{history.secondOddsOld}</strong></span></div>
              <div><small><span
                className="badge badge-secondary badge-font"><strong>{history.secondOddsOldUpdateDate ? helpers.dtt(history.secondOddsOldUpdateDate) : ''}</strong></span></small>
              </div>
            </td>

            <td>
              <div><span className={history.marginPercent >= 0 ? 'green-text' : 'red-text'}><strong>{history.marginPercent}</strong></span> <i
                className="fa fa-percent"/></div>
              {history.secondBetNameReverse && (
                <div><Tag color="#108ee9"><strong>Реверс</strong></Tag></div>
              )}
              <br/>
              <div><span className={history.marginPercentInit >= 0 ? 'green-text' : 'red-text'}>{history.marginPercentInit}</span> <i
                className="fa fa-percent"/></div>
            </td>

            <td>
              <div><i className="fa fa-usd"/> <span><strong>{history.secondMoneyBet}</strong></span></div>
              <br/>
              <div><i className="fa fa-usd"/> <span>{history.secondMoneyBetInit || ''}</span></div>
            </td>

            {/*<td>{history.secondCurrency === 'CZK' ? <strong>Kč</strong> : <i className="fa fa-usd"/>} <span><strong>{history.secondMoneyBet}</strong></span></td>*/}
          </tr>
        )}
      </>
    );
  }
}

export default PlacedBetOneShoulderForkHistoryEntity;
