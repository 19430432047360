import React, { Component } from 'react';
import './style.css';

import title from "../../../chunks/title";
import helpers from "../../../chunks/helpers";

import {withRouter} from "react-router-dom";
import UserData from "./UserData";
import UserBot from "./UserBot";
import UserAuthHistory from "./UserAuthHistory";
import backend from "../../../chunks/backend";
import sub from "../../../chunks/subscriber";

class UserProfile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            subIds: [],
            loaded: false,
            user: {
                sessions: []
            }
        };
    }

    componentWillUnmount() {
        for (let id of this.state.subIds) {
            sub.unsubscribe(id);
        }
    }

    componentDidMount() {
        title(`${this.props.match.params.username}'s profile`);

        const ids = [];

        for (let event of ['users.authorized', 'users.create', 'users.ban', 'users.unban', 'users.update', 'users.restore', 'users.remove', 'users.trash']) {
            const id = sub.subscribe(event, (data) => {
                if (data.username === this.props.match.params.username) {
                    this.updateData();
                }
            });

            ids.push(id);
        }

        this.setState({ subIds: ids });
        this.updateData();
    }

    updateData() {
        this.setState({
            loaded: false
        }, () => {
            backend.get(`/user/info/${this.props.match.params.username}`).then((res) => {
                if (res.data.success) {
                    this.setState({
                        user: res.data.user,
                        loaded: true
                    });
                }
            });//.catch(console.log);
        });
    }

    render() {
        const link = 'https://whatismyipaddress.com/ip/';

        return (
            <>
              <div className="page-title">
                <div className="title_left">
                  <h3>User: {this.props.match.params.username}</h3>
                </div>
                <div className="title_right">
                  <div className="col-md-5 col-sm-5 pull-right btn-remove-profile">

                    {/*{this.props.user.hasAccess('users.remove') && (*/}
                    {/*    <div className="x_content">*/}
                    {/*      <button type="button" className="btn btn-warning">Ban</button>*/}
                    {/*      <button type="button" className="btn btn-danger">Remove</button>*/}
                    {/*    </div>*/}
                    {/*)}*/}

                  </div>
                </div>
              </div>
              <div className="clearfix"/>
              <div className="row margin-top-profile">
                <div className="col-md-6">
                    <UserData user={this.props.user} />
                    <UserAuthHistory user={this.props.user} />
                </div>
                <div className="col-md-6">
                  <div className="x_panel">
                      <div className="x_title">
                          <h2>Active sessions</h2>
                          <div className="clearfix"/>
                      </div>
                      <div className="x_content">
                          {this.state.loaded && (
                              <table className="table table-sm">
                                  <thead>
                                  <tr>
                                      <th>IP</th>
                                      <th>Browser</th>
                                      <th>Logged In</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  {this.state.user.sessions.length === 0 && (
                                      <tr>
                                          <td colSpan="3">No active user sessions</td>
                                      </tr>
                                  )}
                                  {this.state.user.sessions.length > 0 && (
                                      this.state.user.sessions.map((session, i) => (
                                          <tr key={i}>
                                              <td>
                                                  <a href={link+session.ip} target="_blank" rel="noreferrer">
                                                      <mark>{session.ip}</mark>
                                                  </a>
                                                  <pre title={helpers.dt(session.lastActivity)}>
                                                      { ((Date.now() - session.lastActivity) < 60000) ? (
                                                          <small className="text-success">Online</small>
                                                      ) : (
                                                          <small className="text-muted">Offline</small>
                                                      ) }
                                                  </pre>
                                              </td>
                                              <td>{session.browser}</td>
                                              <td>
                                                  <i className="text-muted">{ helpers.dt(session.createdAt) }</i>
                                              </td>
                                          </tr>
                                      ))
                                  )}
                                  </tbody>
                              </table>
                          )}
                          {!this.state.loaded && (
                              <div>Loading...</div>
                          )}
                      </div>
                  </div>
                  <UserBot user={this.props.user} />
                </div>

              </div>

            </>
        );
  }
}

export default withRouter(UserProfile);
