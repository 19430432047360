import React from 'react';
import './style.css';

class BeforeComponent extends React.Component {
    render() {
        return (
            <div id="before_cp_loading">
                <div id="before_cp_loading_content">
                    <div className="lds-roller">
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                    </div>
                </div>
            </div>
        );
    }
}

export default BeforeComponent;
