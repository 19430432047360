import React from "react";
import { Link, withRouter } from "react-router-dom";

import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import sub from "../../chunks/subscriber";

class TopBarComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            subIds: [],
        };

        this.notify = this.notify.bind(this);
    }

    componentDidMount() {
        const ids = [];

        for (let event of ["games.goal"]) {
            const id = sub.subscribe(event, (data) => {
                // (string game, string eventID, string, sport, string bookmaker, string score)
                // const {game, eventID, sport, bookmaker, score} = data;
                const { game, score } = data;
                // const url = `/games/detailed/${sport}/${eventID}`;
                const msg = (
                    <>
                        🥅 New score <u>{score.join(":")}</u> in game {game}
                    </>
                );

                this.notify(msg);
            });

            ids.push(id);
        }

        this.setState({ subIds: ids });
    }

    componentWillUnmount() {
        for (let id of this.state.subIds) {
            sub.unsubscribe(id);
        }
    }

    notify(content) {
        /*
        toast(content, {
            position: 'bottom-left',
            autoClose: 3000
        });
         */
    }

    render() {
        return (
            <>
                {/*<div className="top_nav" style={{ marginLeft: "232px" }}>*/}
                <div className="top_nav">
                    <div className="nav_menu">
                        <ToastContainer />
                        <div className="nav toggle" >
                          <Link to="#" id="menu_toggle">
                            <i className="fa fa-bars" />
                          </Link>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(TopBarComponent);
