import React from 'react';
import helpers from "../../../chunks/helpers";
import {Link} from "react-router-dom";
import routes from "../../../chunks/routes";

class ArchiveEntity extends React.Component {
    constructor(props) {
        super(props);

        this.showGameName = this.showGameName.bind(this);
    }

    showGameName(name) {
        if (this.props.search && this.props.search !== '') {
            name = name.split(this.props.search).join(`<mark>${this.props.search}</mark>`);
        }

        return name;
    }

    render() {
        const game = this.props.data;
        const link = routes.games.history.replace(':sport', game.sport).replace(':slug', game.slug);
        const parts = game.slug.split('::');
        const html  = this.showGameName(parts[1]);

        return (
            <>
                <tr key={this.key}>
                    <td>
                        <i className="text-muted">{helpers.dt(game.createdAt)}</i>
                    </td>
                    <td>
                        <Link to={link} className={game.isGoalScored ? 'goal-scored' : ''}><strong dangerouslySetInnerHTML={{ __html: html }}/></Link>
                        <div>
                            <small className="badge badge-secondary mr-1">{game.gameType}</small>
                        </div>
                    </td>
                    <td>
                        <mark>{parts[2]}</mark>
                        <pre className="mb-0">
                          <small>{game.slug}</small>
                        </pre>
                    </td>
                </tr>
            </>
        );
    }
}

export default ArchiveEntity;
