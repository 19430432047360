import React from "react";
import helpers from "../../../chunks/helpers";
import {Link} from "react-router-dom";
import routes from "../../../chunks/routes";

class GamesLiveEntity extends React.Component {
    render() {
        const game = this.props.data;
        const messages = game.messages;
        let uniqueKey = `game_${game.eventID}_${messages[0].data.meta.score.join("_")}`;
        uniqueKey = uniqueKey.split(":").join("_").replace("(", "_").replace(")", "_");
        let tsDiff;


        let gameType = ''
        let sport = ''
        if(game.messages[0] && game.messages[0].initiator === 'calculator'){
            gameType = game.messages[0].data.meta.gameType
            sport = game.messages[0].data.meta.sport
        }
        const link = routes.games.detailed.replace(':sport', sport).replace(':name', game.eventID);


        const messageTypes = ['WaitingForCoupon', 'SumInCoupn', 'ClickedOnAcceptButton']
        let latency = 0
        let longBet = 0
        if(messages.length !== 0) {
            for (let i = 0; i < messages.length; i++) {
                const message = messages[i];
                // if(i === 0){
                //     time = message.timeStamp
                // }
                if (i > 0 && messageTypes.includes(message.data.action)) {
                    // const num = message.timeStamp - time
                    const num = message.timeStamp - messages[i - 1].timeStamp
                    latency += num;
                }
                if (message.data.action === 'KilledByTimer') {
                    const temp = message.data.reason.match(/\d+(?:.\d+)?/gi)[0]
                    const num = Number(temp)
                    longBet = num
                }
            }
        }

        let isLongDelay = latency > 1000
        let isSmallActions = messages.length < 5
        let isLongBet = longBet > 5000


        return (
            <>
                <div className="panel accordion-panel">
                    <a
                        className="panel-heading collapsed accordion-href"
                        role="tab"
                        id="headingOne1"
                        data-toggle="collapse"
                        href={`#${uniqueKey}`}
                        aria-expanded="false"
                        aria-controls="collapseOne"
                    >
                        <table className="table accordion-table">
                            <tbody>
                                <tr>
                                    <th className="accordion-th">
                                        {game.slug.split("::")[0]} ({messages[0].data.meta.score.join("-")})
                                    </th>
                                    {/*<th className="accordion-th">{game.game}</th>*/}
                                    {/*<th className="accordion-th"><a rel="noreferrer" href={eventUrl} target="_blank">{game.game}</a></th>*/}

                                    <th className="accordion-th"><Link to={link}>{game.game}</Link></th>

                                    {/*{this.props.user.hasAccess('moneys.show_ranges') && (*/}
                                    {/*    <th className="accordion-th"><Link to={link}>{game.game}</Link></th>*/}
                                    {/*)}*/}
                                    {/*{!this.props.user.hasAccess('moneys.show_ranges') && (*/}
                                    {/*    <th className="accordion-th">{game.game}</th>*/}
                                    {/*)}*/}
                                    <th className="accordion-th">{gameType}</th>

                                    {(isLongDelay || isSmallActions) && (
                                        <th className="accordion-th"><span className="check-proxy badge badge-warning">Check proxy({isLongDelay ? 'Long Latency' : ''}{isLongDelay && isSmallActions ? ', ' : ''}{isSmallActions ? 'Small number Actions' : ''})</span></th>
                                    )}
                                    {isLongBet && (
                                        <th className="accordion-th"><span className="check-proxy badge badge-warning">Waiting Bet({longBet})</span></th>
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    </a>
                    <div
                        id={`${uniqueKey}`}
                        className="panel-collapse in collapse"
                        role="tabpanel"
                        aria-labelledby="headingOne"
                    >
                        <div className="panel-body">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Initiator</th>
                                        <th>Action</th>
                                        <th>Bet ID</th>
                                        <th>Event ID</th>
                                        <th>Meta</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {messages.map((msg, i) => {
                                        if (i > 0) {
                                            tsDiff = msg.timeStamp - messages[i - 1].timeStamp;
                                            // console.log(tsDiff);
                                        }
                                        return (
                                            <tr key={i}>
                                                <td>
                                                    {msg.initiator === "calculator" && (
                                                        <strong className="text-warning">{msg.initiator}</strong>
                                                    )}
                                                    {msg.initiator !== "calculator" && (
                                                        <strong className="text-info">{msg.initiator}</strong>
                                                    )}
                                                    <br />
                                                    <i className="text-muted">{helpers.dt(msg.timeStamp)}</i>
                                                </td>
                                                <td>
                                                    <code>{msg.data.action}</code>
                                                    {msg.data.action === "goal" &&
                                                        msg.initiator === "calculator" &&
                                                        false && (
                                                            <>
                                                                <br />
                                                                <small>
                                                                    {msg.data.meta.initiator}{" "}
                                                                    <u>{msg.data.meta.score.join(":")}</u>
                                                                </small>
                                                            </>
                                                        )}
                                                </td>
                                                <td>
                                                    <mark>{msg.data.betID}</mark>
                                                    {/*<mark>{msg.data.betID ? msg.data.betID.split('_')[0] : msg.data.betID}</mark>*/}
                                                </td>
                                                <td>
                                                    <mark>{msg.data.eventID}</mark>
                                                </td>
                                                <td>
                                                    {msg.data.action === "goal" && msg.initiator === "calculator" && (
                                                        <>
                                                            <div>
                                                                <small>
                                                                    {msg.data.betData.name}{" "}
                                                                    <u>{msg.data.betData.team}</u>
                                                                </small>
                                                            </div>
                                                            <div>
                                                                <small>
                                                                    Odds: <u>{msg.data.odds}</u>, Money:{" "}
                                                                    <u>{msg.data.money}</u>
                                                                </small>
                                                            </div>
                                                            <div>
                                                                <small>
                                                                    Initiator: <code>{msg.data.meta.initiator}</code>
                                                                </small>
                                                            </div>
                                                        </>
                                                    )}
                                                    {tsDiff > 0 && (
                                                        <div>
                                                            <small>
                                                                Latency: <code>{tsDiff}</code>
                                                                <br />
                                                            </small>
                                                        </div>
                                                    )}
                                                    {"reason" in msg.data && <code>{msg.data.reason}</code>}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default GamesLiveEntity;
