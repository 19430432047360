import React, { useContext, useMemo, useState, useEffect, useRef } from 'react'
import { Collapse, Card, Button, Table } from 'antd'

import TemplateEntity from './TemplateEntity'
import BotEntity from './BotEntity'
import backend from '../../../chunks/backend'
// import PaginationComponent from '../../../components/PaginationComponent'
// import sub from '../../../chunks/subscriber'
// const {Panel} = Collapse

let wss = null
let timeInterval = null
let getBotsInterval = null

import { HolderOutlined } from '@ant-design/icons'
import { DndContext } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

const RowContext = React.createContext({})
const DragHandle = () => {
  const {setActivatorNodeRef, listeners} = useContext(RowContext)
  // console.log('DragHandle listeners:', listeners)

  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined/>}
      style={{
        cursor: 'move'
      }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  )
}

const hideFilterEvent = (ev) => {
  if (wss && wss.readyState === wss.OPEN) {
    const eventNew = JSON.parse(JSON.stringify(ev))
    eventNew.outcomes = {}

    const message = JSON.stringify({action: 'hide', event: eventNew})
    wss.send(message)
  }
}

const clearFilterEvent = () => {
  if (wss && wss.readyState === wss.OPEN) {
    const message = JSON.stringify({action: 'clear'})
    wss.send(message)
  }
}

const columns = [
  {
    key: 'sort',
    align: 'center',
    width: 60,
    render: () => <DragHandle/>
  },
  {
    key: 'remove',
    align: 'center',
    width: 60,
    render: (ev) => <>
      <button
        onClick={() => hideFilterEvent(ev)}
        // type="submit"
        className="btn btn-dark btn-sm"
      ><i className="fa fa-trash-can" aria-hidden="true"></i></button>
    </>
  },
  {
    title: 'Game',
    key: 'game',
    // dataIndex: 'name',
    render: (ev) => <strong className="mr-2"><a rel="noreferrer" target="_blank" href={`${ev.url}`}>{ev.name}</a></strong>
  },
  {
    title: 'League',
    dataIndex: 'league'
  },
  {
    title: 'Score',
    dataIndex: 'score'
  },
  {
    title: 'Outcome',
    dataIndex: '',
    key: 'x',
    render: (ev) =>  <TemplateEntity
      key={ev.id.toString()}
      data={ev}
      hideFilterEvent={hideFilterEvent}
      clearFilterEvent={clearFilterEvent}
    />,
  },
]

const Row = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: props['data-row-key']
  })
  const style = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging
      ? {
        position: 'relative',
        zIndex: 9999
      }
      : {})
  }
  const contextValue = useMemo(
    () => ({
      setActivatorNodeRef,
      listeners
    }),
    [setActivatorNodeRef, listeners]
  )
  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  )
}


const TemplateList = () => {
  const [loaded, setLoaded] = useState(false)
  const [loadedBots, setLoadedBots] = useState(false)
  const [sumMoney, setSumMoney] = useState(0)
  const [forksStatus, setForksStatus] = useState('start')
  const [isWorkSocket, setIsWorkSocket] = useState(true)
  // let wss = null
  // let timeInterval = null
  // const [wss, setWss] = useState(null);
  // const [timeInterval, setTimeInterval] = useState(null);
  const [bots, setBots] = useState([])
  const [events, setEvents] = useState([])
  const eventsRef = useRef([]);

  useEffect(() => {
    // console.log(`----- useEffect events 111 -------`, events)
    eventsRef.current = events;
  }, [events]);

  // const [dataSource, setDataSource] = useState(initialData)
  const onDragEnd = ({active, over}) => {
    // console.log('onDragEnd active:', active)
    // console.log('onDragEnd over:', over)
    // console.log('events events:', events)
    if (active.id !== over?.id) {
      setEvents((prevState) => {
        const activeIndex = prevState.findIndex((record) => record.key === active?.id)
        const overIndex = prevState.findIndex((record) => record.key === over?.id)
        // console.log('onDragEnd prevState:', prevState)
        // console.log('onDragEnd activeIndex:', activeIndex)
        // console.log('onDragEnd overIndex:', overIndex)
        // console.log('===========')
        return arrayMove(prevState, activeIndex, overIndex)
      })
    }
  }

  const loadData = () => {
    backend.get('/system/info').then(({data}) => {
      const {forks_status} = data
      setForksStatus(forks_status)
    }).catch(console.log)
  }

  const onClickStartBots = () => {
    backend.put(`/system/forks-status/start`, {}).then(() => {
      loadData()
    }).catch(console.log)
  }

  const onClickStopBots = () => {
    backend.put(`/system/forks-status/stop`, {}).then(() => {
      loadData()
    }).catch(console.log)
  }

  const loadBots = () => {
    const url = '/bot/basket-online'

    getBotsInterval = setInterval(() => {
      try {
        backend
          .get(url)
          .then(({data}) => {
            // console.log('BOTS:', data)
            if (data.success) {
              let sumMoney = 0
              const bots = data.bots
              for (const bot of bots) {
                if (bot.isBetBasketballAccepted === '3') {
                  sumMoney += Number(bot.moneySimbetRegularFork)
                }
              }

              setSumMoney(sumMoney)
              setBots(bots)
            }
          })
          .catch(console.log)
          .finally(() => {
            setLoadedBots(true)
          })
      }
      catch (error) {
        console.log(error)
      }
    }, 500)
  }

  const openSocket = () => {
    try {
      console.log(`----- openSocket -------`)
      if (isWorkSocket) {
        wss = new WebSocket('wss://bet.losha.xyz/')

        const sendMessageSocket = (mess) => {
          if (wss && wss.readyState === wss.OPEN) {
            const pureMess = JSON.stringify(mess)
            wss.send(pureMess)
          }
        }

        const parseJson = (payload) => {
          try {
            return JSON.parse(payload.data)
          }
          catch (error) {
            return {}
          }
        }

        wss.onopen = async () => {
          console.log(`### SIMBET SOCKET OPEN ###`)

          setLoaded(true)

          // console.log(`### readyState:`, this.wss.readyState)
          // console.log(`### OPEN:`, this.wss.OPEN)

          timeInterval = setInterval(() => {
            if (wss && wss.readyState === wss.OPEN) {
              sendMessageSocket({action: 'ping'})
            }
          }, 500)
        }


        wss.onmessage = (e) => {
          const msg = parseJson(e)
          // console.log(`msg:`, msg)
          // console.log(`events eventsRef:`, eventsRef.current)
          // console.log('==================================')
          // console.log('==================================')
          // console.log(`message:`, msg)
          // console.log(`eventsRef:`, eventsRef)

          // this.setState({events: msg.events})

          const eventsOld = JSON.parse(JSON.stringify(eventsRef.current))
          const eventsNew = JSON.parse(JSON.stringify(msg.events))
          const eventsNew1 = []
          const eventsNew2 = []

          // console.log(`eventsNew:`, eventsNew)

          for (const eventData of eventsOld) {
            // console.log(`eventData:`, eventData.id)
            // console.log(`events:`, events)

            const ev = eventsNew.find(e => `${e.id}` === `${eventData.id}`)
            // console.log(`ev:`, ev)
            // console.log(`ev:`, ev)
            if (ev) {
              const ev1 = JSON.parse(JSON.stringify(ev))
              const ev2 = JSON.parse(JSON.stringify(eventData))
              // console.log(`ev1:`, ev1.id)
              ev2.outcomes = ev1.outcomes
              eventsNew1.push(ev2)
            }
          }

          for (const eventNew of eventsNew) {
            // console.log(`eventData:`, eventData.id)
            // console.log(`events:`, events)

            const ev = eventsOld.find(e => `${e.id}` === `${eventNew.id}`)
            // console.log(`!ev:`, !ev)

            if (!ev) {
              const ev2 = JSON.parse(JSON.stringify(eventNew))
              // console.log(`ev2:`, ev2.id)
              eventsNew2.push(ev2)
            }
          }

          const eventsNew3 = [...eventsNew1, ...eventsNew2]

          // console.log(`eventsNew3:`, eventsNew3)
          setEvents(eventsNew3)

          // setEvents((messages) => [...messages, eventsNew3]);

          // console.log('========================')

          // const message = parseJson(e)
          //
          // console.log(`message: `, message)
          //
          // try {
          //   if (!this.isEmptyObject(message)) {
          //     // const forkId = message.forkId
          //     // const bookName = message.bookName
          //     // const gameTime = Number(message.gameTime || 0)
          //     // const score = message.score || ''
          //     // const odds = Number(message.odds || 0)
          //     // const isLive = message.isLive
          //     // const forksId = message.forksId
          //     // const action = message.action
          //     // const oddsUpdateDate = new Date().getTime()
          //     // // console.log(`forksId --- `, forksId)
          //     //
          //     // // TODO: УДАЛИЛ ВРЕМЕННО
          //
          //   }
          // }
          // catch (error) {
          //   console.log(`!!! Error Parse Socket, Error: ${error.message}`)
          // }

        }

        wss.onclose = (e) => {
          console.log(`### SIMBET SOCKET CLOSE | CODE: ${e.code}`)

          clearTimeout(timeInterval)
          try {
            wss.close()
          }
          catch (error) {
            // console.log(`ERROR SOCKET terminate: ${error.message}`);
          }

          wss = null

          setTimeout(() => openSocket(), 2000)
        }

        wss.onerror = (err) => {
          // console.log(`### SIMBET SOCKET ERROR`)
          // console.log(`### SIMBET SOCKET ERROR: ${err.message}`)
        }
      }
    }
    catch (error) {
      console.log(`### SIMBET SOCKET ERROR GLOBAL: ${error.message}`)
    }
  }

  useEffect(() => {
    // console.log(`----- useEffect openSocket 222 -------`)
    openSocket()
    loadBots()
    loadData()
  }, [])

  useEffect(() => {
    // console.log(`----- useEffect events 333 -------`, events)
  }, [events]);


  return (
    <>
      <div className="page-title">
        <div className="_title_left">
          <div className="x_content">
            <div>
              <h3>Simbet basketball</h3>
            </div>

            <div>
              <div className="btn-group" style={{marginBottom: '10px'}}>
                {forksStatus === 'start' && (
                  <button className="btn btn-sm btn-dark" style={{marginRight: '10px'}} onClick={onClickStopBots}>Вилки запущены</button>
                )}
                {forksStatus !== 'start' && (
                  <button className="btn btn-sm btn-danger" style={{marginRight: '10px'}} onClick={onClickStartBots}>Вилки остановлены</button>
                )}
                <button className="btn btn-sm btn-secondary" style={{marginRight: '20px'}} onClick={clearFilterEvent}>Clear filter</button>

                <div className="card">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item" style={{backgroundColor: '#e1f8e1'}}><strong><span
                      style={{color: '#000000'}}>{sumMoney} ₪</span></strong></li>
                  </ul>
                </div>
              </div>
            </div>

            <div>
              <Card bodyStyle={{padding: '0px'}}>
                {loadedBots && (
                  bots.length > 0 && (
                    bots.map(function (bot) {
                      return <BotEntity
                        data={bot}
                      />
                    })
                  )
                )}
              </Card>
            </div>

          </div>
        </div>
      </div>
      <div className="clearfix"/>
      <div>
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext
            items={events.map((i) => i.key)}
            strategy={verticalListSortingStrategy}
          >
            <Table
              rowKey="key"
              components={{
                body: {
                  row: Row
                }
              }}
              columns={columns}
              dataSource={events}
            />
          </SortableContext>
        </DndContext>
      </div>
      <br/>
    </>
  )
}
export default TemplateList
