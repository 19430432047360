import React from "react";

import {withRouter} from "react-router-dom";
import title from "../../chunks/title";
import backend from "../../chunks/backend";
import sub from "../../chunks/subscriber";

import Group from "./src/Group";
import CreateGroup from "./src/modals/Create";

class GroupsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            subIds: [],
            loaded: false,
            groups: [],
            bots:   [],
            showAs: this.props.user.username
        };
    }

    componentDidMount() {
        title('Groups');
        const ids = [];

        for (let event of ['groups.update']) {
            const id = sub.subscribe(event, ({ username }) => {
                this.updateData();
            });

            ids.push(id);
        }

        this.setState({ subIds: ids });
        this.updateData();
    }

    componentWillUnmount() {
        for (let id of this.state.subIds) {
            sub.unsubscribe(id);
        }
    }

    updateData() {
        this.setState({ loaded: false }, () => {
            const promises = [
                backend.get('/groups'),
                backend.get('/groups/available'),
            ];

            Promise.all(promises).then((all) => {
                const {groups} = all[0].data;
                const {bots}   = all[1].data;

                this.setState({ groups, bots })
            }).catch(console.log).finally(() => {
                this.setState({ loaded: true });
            });
        });
    }

    render() {
        return (
            <div>
                <div className="page-title">
                    <div className="title_left">
                        <h3>Groups</h3>
                    </div>
                    <div className="title_right">
                        <div className="btn-group btn-sm" style={{ float: 'right' }}>
                            {this.props.user.hasAccess('groups.create') && (
                                <>
                                    <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#modalCreateGroup">Create</button>
                                    <CreateGroup />
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className="clearfix"/>
                <div className="row margin-top-profile">
                    <div className="col-xs-12 col-md-12 mb-4">
                        <div className="jumbotron">
                            <h3 className="ml-2 mr-2"><i className="fas fa-exclamation-circle"/> Reminder!</h3>
                            <p>Bot without group will catch all goals.</p>
                        </div>
                    </div>
                    {this.state.loaded && this.state.groups.length === 0 && ( <div className="col-xs-12 col-md-12 text-center mt-4"><p>Groups list is empty</p></div> )}
                    {!this.state.loaded && ( <div className="col-xs-12 col-md-12"><p><i>Loading</i></p></div> )}
                    <div className="col-xs-12 col-md-6">
                        {this.state.loaded && this.state.groups.map((group, i) => (
                            !(i % 2) ? (
                                <Group key={i} bots={this.state.bots} group={group} />
                            ) : ''
                        ))}
                    </div>
                    <div className="col-xs-12 col-md-6">
                        {this.state.loaded && this.state.groups.map((group, i) => (
                            (i % 2) ? (
                                <Group key={i} bots={this.state.bots} group={group} />
                            ) : ''
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(GroupsPage);
