const routes = {
    dashboard: '/',
    users: {
        online:  '/users/online',
        all:     '/users/all',
        active:  '/users/active',
        banned:  '/users/banned',
        deleted: '/users/deleted',
        profile: '/users/profile/:username'
    },
    bots: {
        create:  '/bot/create',
        all:     '/bot/all',
        active:  '/bot/active',
        deleted: '/bot/deleted',
        online:  '/bot/online',
        basket_online:  '/bot/basket-online',
        basket_active:  '/bot/basket-active',
        banned:  '/bot/banned',
        detailed:  '/bot/detailed/:name',
        authorization: '/bots/authorize/:name'
    },
    extension: {
        all:  '/extensions',
        get:  '/extension/download/:name/:bookmaker',
        gettips:  '/extension/downloadtips/:name',
        getbetfair:  '/extension/download-betfair/:name',
        getsuperbetpl:  '/extension/download-superbet_pl/:name',
        upload: '/extension',
        activate: '/extension/active/'
    },
    accounting: {
        all:  '/accounting',
    },
    money_ranges: {
        all:  '/money-ranges',
    },
    simbet_basketball: {
        all:  '/simbet-basketball',
    },
    bet_strategies: {
        all:  '/bet-strategies',
    },
    flat: {
        all:  '/flat',
    },
    groups: {
        all:    '/groups',
        create: '/groups',
        update: '/groups',
        remove: '/groups',
    },
    games: {
        live: '/games/live',
        archive: '/games/archive',
        detailed: '/games/detailed/:sport/:name',
        history: '/games/history/:sport/:slug',
    },
    forks: {
        config: '/forks'
    },
    points: {
        live: '/points/live',
        history: '/points/history',
        detailed: '/points/detailed/:type/:slug'
    },
    strategy: {
        list: '/strategy'
    }
};

export default routes;
