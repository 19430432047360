import React from "react";

class PaginationComponent extends React.Component {
    constructor(props) {
        super(props);

        this.prevPage = this.prevPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
    }

    prevPage(e) {
        e.nativeEvent.preventDefault();
        if (this.props.page > 1) {
            this.props.showPage(this.props.page - 1);
        }
    }

    nextPage(e) {
        e.nativeEvent.preventDefault();
        this.props.showPage(this.props.page + 1);
    }

    render() {
        return (
            <>
                <nav style={{ float: "right" }}>
                    <ul className="pagination" style={{ marginBottom: "0px" }}>
                        <li className="page-item">
                            <button className="page-link" type="button" onClick={this.prevPage}>Previous</button>
                        </li>
                        <li className="page-item disabled">
                            <button className="page-link" type="button">{this.props.page}</button>
                        </li>
                        <li className="page-item">
                            <button className="page-link" type="button" onClick={this.nextPage}>Next</button>
                        </li>
                    </ul>
                </nav>
            </>
        );
    }
}

export default PaginationComponent;
