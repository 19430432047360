const base = (() => {
    if (window.location.host === 'eurobet.billy-lg.com') {
        return 'eurobet';
    } else if (window.location.host === 'bet365-point.billy-lg.com') {
        return 'bet365_points';
    }

    return 'bet365';
})();

const list = [];

if (base === 'bet365') {
    list.push({ sport: 'football', filter: 'football-8', title: 'Football (8 minutes)' });
    list.push({ sport: 'football', filter: 'football-10', title: 'Football (10 minutes)' });
    list.push({ sport: 'football', filter: 'football-12', title: 'Football (12 minutes)' });
    list.push({ sport: 'football', filter: 'football-120', title: 'Football' });
    list.push({ sport: 'ice-hockey', filter: 'ice-hockey', title: 'Ice Hockey' });
    list.push({ sport: 'table-tennis', title:  'Table Tennis', filter: 'table-tennis' });
} else if (base === 'bet365_points') {
    list.push({ sport: 'tennis', title:  'Tennis', filter: 'tennis' });
} else {
    list.push({ sport: 'table-tennis', title:  'Table Tennis', filter: 'table-tennis' });
    list.push({ sport:  'football', title:  'Football', filter: 'football' });
}

export default list;
