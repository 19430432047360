import React from 'react';

import TemplateList from "./src/TemplateList";
import title from "../../chunks/title";

class MoneyRanges extends React.Component {
    componentDidMount() {
        title('Money Ranges');
    }

    render() {
        return (
            <>
                <TemplateList type="all" permissions={this.props.permissions} user={this.props.user}/>
            </>
        );
    }
}

export default MoneyRanges;
