function checkRange(value) {
    return /^\d+(?:\.\d+)?(?:-\d+(?:\.\d+)?)?$/gi.test(value);
}

const validation = {
    name(name) {
        return !(`${name}`.trim() === '');
    },
    password(password) {
        return !(`${password}`.trim() === '');
    },
    description(description) {
        return !(`${description}`.trim() === '');
    },
    globalMoney(value) {
        if (`${value}`.trim() === '') {
            return false;
        }

        return checkRange(value);
    },
    oddsRanges: {
        coef(value) {
            return checkRange(value);
        },
        range(value) {
            return checkRange(value);
        }
    }
};

export default validation;
