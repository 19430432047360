import React, {Component} from 'react';
import './style.css';

import {withRouter} from "react-router-dom";
import backend from "../../../chunks/backend";
import PaginationComponent from "../../../components/PaginationComponent";
import PlacedBetWaitingForkHistoryEntity from "./PlacedBetWaitingForkHistoryEntity";
import sub from "../../../chunks/subscriber";

class BotDetailedPlacedBetWaitingForkHistory extends Component {

  constructor(props) {
    super(props);

    this.state = {
      subIds: [],
      loaded: false,
      history: [],
      page: 1,
      numberAcceptedBets: 0,
      sumAcceptedBets: 0,
      updateDataInterval: 0
    };

    this.updateData = this.updateData.bind(this);
    this.showPage = this.showPage.bind(this);
    this.manualPlaceBet = this.manualPlaceBet.bind(this);
    this.deletePlaceBet = this.deletePlaceBet.bind(this);
    this.sendWinFirstBookmaker = this.sendWinFirstBookmaker.bind(this);

  }

  componentDidMount() {
    const ids = [];

    for (let event of ['bots.update']) {
      const id = sub.subscribe(event, (data) => {
        if (data.name === this.props.match.params.name) {
          this.updateData();
        }
      });

      ids.push(id);
    }

    this.setState({subIds: ids});
    this.updateData();

    this.setState({
      updateDataInterval: setInterval(() => {
        this.updateData();
      }, 1000)
    });
  }

  componentWillUnmount() {
    for (let id of this.state.subIds) {
      sub.unsubscribe(id);
    }

    clearInterval(this.state.updateDataInterval);
  }

  updateData() {
    backend.get(`/bot/placed-bets-waiting-fork-history/${this.props.match.params.name}?page=${this.state.page}`).then(({data}) => {
      if (data.success) {
        this.setState({
          history: data.history
        });
      }
    }).catch(console.log).finally(() => {
      this.setState({
        loaded: true
      });
    })

    backend.get(`/bot/number-accepted-bets-waiting-fork/${this.props.match.params.name}`).then(({data}) => {
      if (data.success) {
        this.setState({
          numberAcceptedBets: data.count
        });
      }
    }).catch(console.log).finally(() => {
    })

    backend.get(`/bot/get-sum-accepted-bets-waiting-fork/${this.props.match.params.name}`).then(({data}) => {
      if (data.success) {
        this.setState({
          sumAcceptedBets: Number(data.data.sum).toFixed(2)
        });
      }
    }).catch(console.log).finally(() => {
    })
  }

  showPage(page) {
    this.setState({page}, () => {
      this.updateData();
    });
  }

  manualPlaceBet(fork) {
    if (fork) {
      const manualPlaceBetId = `${fork.forkId}`
      // const manualPlaceBetId = `${fork.forkId}*${fork._id}`
      // backend.put(`/bot/update/${this.props.match.params.name}`, {
      backend.put(`/bot/place-bet-second/${this.props.match.params.name}`, {
        manualPlaceBetId: manualPlaceBetId,
      })
          .then((res) => {
            if (res.data.success) {
              this.updateData();
            }
          })
          .catch(console.log);
    }
  }


  sendWinFirstBookmaker(fork, e) {
    if (fork) {
      backend.put(`/bot/update-win-waiting-fork/${fork._id}/${this.props.match.params.name}`, {
        isWinFirstBookmaker: e.target.checked,
      })
          .then((res) => {
            if (res.data.success) {
              this.updateData();
            }
          })
          .catch(console.log);
    }
  }


  deletePlaceBet(fork) {
    if (fork) {
      backend.delete(`/bot/remove-placed-bet-waiting-fork/${fork._id}/${this.props.match.params.name}`)
          // backend.delete(`/bot/remove-placed-bets-waiting-fork/${fork._id}`)
          .then(({data}) => {
            if (data.success) {
              this.updateData();
            }
          })
          .catch(console.log);
    }
  }

  render() {

    return (
        <>
          <div className="x_panel">
            <div className="x_title" style={{borderBottom: "3px solid #17a2b8"}}>
              <h2>Вилки с выжиданием(1x2). Number accepted bets: <strong><span className="text-success">{this.state.numberAcceptedBets}</span></strong></h2>
              <PaginationComponent page={this.state.page} showPage={this.showPage}/>
              <div className="clearfix"/>
            </div>
            <div className="x_content">

              <div className="table-responsive">
                <table className="table table-hover bulk_action jambo_table">
                  <thead>
                  <tr className="headings">
                    <th className="column-title">Timestamp</th>
                    <th className="column-title">Status First</th>
                    <th className="column-title">Status Second</th>
                    <th className="column-title">Sport League</th>
                    {/*<th className="column-title">League</th>*/}
                    <th className="column-title">Bookmaker First</th>
                    <th className="column-title">Bookmaker Second</th>
                    {/*<th className="column-title">Outcome</th>*/}
                    <th className="column-title">Score</th>
                    <th className="column-title" style={{width: '190px'}}>Odds First</th>
                    <th className="column-title" style={{width: '190px'}}>Odds Second</th>
                    {/*<th className="column-title">Manual Bet</th>*/}
                    {/*<th className="column-title" style={{width: '85px'}}>Percent</th>*/}
                    <th className="column-title" style={{width: '130px'}}>Stop Loss</th>
                    {/*<th className="column-title" style={{width: '95px'}}>Max Percent</th>*/}
                    <th className="column-title" style={{width: '160px'}}>Money</th>
                    {/*<th className="column-title" style={{width: '80px'}}>Money First</th>*/}
                    {/*<th className="column-title" style={{width: '80px'}}>Money Second</th>*/}
                    <th className="column-title" style={{width: '110px'}}>
                      <div>Profit</div>
                      <div><i className="fa fa-usd"/> <strong><span className={this.state.sumAcceptedBets >= 0 ? 'green-text' : 'red-text'} style={{fontSize: '14px'}}>{this.state.sumAcceptedBets}</span></strong></div>
                    </th>
                    {/*<th className="column-title">Bot</th>*/}
                    {/*<th className="column-title">User</th>*/}
                  </tr>
                  </thead>
                  <tbody>
                  {this.state.loaded && (
                      this.state.history.length > 0 ? (
                          this.state.history.map((historyData) => <PlacedBetWaitingForkHistoryEntity
                              key={historyData._id}
                              data={historyData}
                              manualPlaceBet={this.manualPlaceBet}
                              deletePlaceBet={this.deletePlaceBet}
                              sendWinFirstBookmaker={this.sendWinFirstBookmaker}
                          />)
                      ) : (
                          <tr>
                            <td colSpan="15">List is empty</td>
                          </tr>
                      )
                  )}
                  {!this.state.loaded && (
                      <tr>
                        <td colSpan="15"><i className="text-muted">Loading...</i></td>
                      </tr>
                  )}
                  </tbody>
                </table>
              </div>

            </div>
          </div>
        </>


    );
  }
}

export default withRouter(BotDetailedPlacedBetWaitingForkHistory);
