// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.margin-top-profile {
    margin-top: 10px;
}

.btn-remove-profile {
    margin: 0 0 !important;
}

.accordion-table {
    margin-bottom: 0 !important;
}

.accordion-href {
    padding: 5px !important;
}

.accordion-panel {
    border-bottom: 0 !important;
    background: white !important;
}

.accordion-th {
    border-top: 0 !important;
}

.modal-profile {
    display: none;
}

.margin-icon {
    margin-left: 5px;
}

.green-text {
    color: #009688;
}

.red-text {
    color: #E74C3C;
}

.war-text {
    color: #E74C3C;
}

.blue-text {
    color: #2196F3;
}

.gray-text {
    color: #6c757d;
}

.td-width-small {
    width: 100px;
}

.check-proxy {
    white-space: initial;
    font-size: 85%;
}

.accepted-tr {
    background-color: #ecf5ec;
}

.remove-tr {
    background-color: #f8f1ef;
}

.delete-tr {
    background-color: #fcd7d7;
}

.failed-tr {
    /*background-color: #ededef;*/
    /*color: #6c757d;*/
}`, "",{"version":3,"sources":["webpack://./src/pages/Bots/src/style.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,2BAA2B;IAC3B,4BAA4B;AAChC;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,oBAAoB;IACpB,cAAc;AAClB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,6BAA6B;IAC7B,kBAAkB;AACtB","sourcesContent":[".margin-top-profile {\n    margin-top: 10px;\n}\n\n.btn-remove-profile {\n    margin: 0 0 !important;\n}\n\n.accordion-table {\n    margin-bottom: 0 !important;\n}\n\n.accordion-href {\n    padding: 5px !important;\n}\n\n.accordion-panel {\n    border-bottom: 0 !important;\n    background: white !important;\n}\n\n.accordion-th {\n    border-top: 0 !important;\n}\n\n.modal-profile {\n    display: none;\n}\n\n.margin-icon {\n    margin-left: 5px;\n}\n\n.green-text {\n    color: #009688;\n}\n\n.red-text {\n    color: #E74C3C;\n}\n\n.war-text {\n    color: #E74C3C;\n}\n\n.blue-text {\n    color: #2196F3;\n}\n\n.gray-text {\n    color: #6c757d;\n}\n\n.td-width-small {\n    width: 100px;\n}\n\n.check-proxy {\n    white-space: initial;\n    font-size: 85%;\n}\n\n.accepted-tr {\n    background-color: #ecf5ec;\n}\n\n.remove-tr {\n    background-color: #f8f1ef;\n}\n\n.delete-tr {\n    background-color: #fcd7d7;\n}\n\n.failed-tr {\n    /*background-color: #ededef;*/\n    /*color: #6c757d;*/\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
