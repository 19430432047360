// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-online-dot {
    margin-right: 6px;
    margin-top: -3px;
    width: 6px;
    height: 6px;
    display: inline-block;
    background-color: green;
    vertical-align: middle;
    border-radius: 3px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Users/src/user-entry.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,UAAU;IACV,WAAW;IACX,qBAAqB;IACrB,uBAAuB;IACvB,sBAAsB;IAMtB,kBAAkB;AACtB","sourcesContent":[".user-online-dot {\n    margin-right: 6px;\n    margin-top: -3px;\n    width: 6px;\n    height: 6px;\n    display: inline-block;\n    background-color: green;\n    vertical-align: middle;\n    -webkit-border-radius: 3px;\n    -khtml-border-radius: 3px;\n    -moz-border-radius: 3px;\n    -ms-border-radius: 3px;\n    -o-border-radius: 3px;\n    border-radius: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
