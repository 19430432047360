import React from "react";

import routes from "../../../chunks/routes";
import backend from "../../../chunks/backend";
import ExtensionItem from "./ExtensionItem";
import helpers from "../../../chunks/helpers";
import sub from "../../../chunks/subscriber";

class ExtensionsList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            subIds: [],
            loaded: false,
            extensions: [],
            history: []
        };

        this.showHistory = this.showHistory.bind(this);
    }

    componentDidMount() {
        const ids = [];

        for (let event of ['moneys.create_range', 'moneys.update_range']) {
            const id = sub.subscribe(event, (data) => {
                console.log('MoneysList', data.event);
                this.updateData();
            });

            ids.push(id);
        }

        this.setState({ subIds: ids });
        this.updateData();
    }

    componentWillUnmount() {
        for (let id of this.state.subIds) {
            sub.unsubscribe(id);
        }
    }

    updateData() {
        this.setState({
            loaded: false
        }, () => {
            backend.get(routes.extension.all).then((msg) => {
                this.setState({
                    loaded: true,
                    extensions: msg.data.extensions.reverse()
                });
            }).catch(console.log);
        });
    }

    showHistory(history) {
        console.log(history);
        this.setState({ history });
    }

    render() {
        return (
            <>
                <div className="card mt-4">
                    <div className="card-header">
                        <h5 className="card-title">All extensions</h5>
                    </div>
                    <div className="card-body">
                        {this.state.loaded && (
                            <table className="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th>Bookmaker</th>
                                        <th>Title</th>
                                        <th>Version</th>
                                        <th>Description</th>
                                        <th>Meta</th>
                                        <th>Created At</th>
                                        <th style={{ width: '280px' }}>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.extensions.map(e =>
                                        <ExtensionItem showHistory={this.showHistory} key={e.file_name} data={e.data} id={e.file_name} />
                                    )}
                                    {this.state.extensions.length === 0 && (
                                        <tr>
                                            <td colSpan="6">No extensions</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        )}
                        {!this.state.loaded && (
                            <div>Loading...</div>
                        )}
                        <div className="modal fade bs-example-modal-lg" id="extensionHistory" tabIndex="-1" role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title" id="extensionHistoryLabel">Extension history</h4>
                                        <button type="button" className="close" data-dismiss="modal">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        {this.state.history.map(e => {
                                            return (() => {
                                                if (e.action === 'upload') {
                                                    return (
                                                        <div>
                                                            <i className="pull-right mr-1">{ helpers.dt(e.timestamp) }</i>
                                                            Uploaded by user
                                                            <strong className="ml-1 mr-1">{e.username}</strong>
                                                        </div>
                                                    )
                                                } else if (e.action === 'download') {
                                                    return (
                                                        <div>
                                                            <i className="pull-right mr-1">{ helpers.dt(e.timestamp) }</i>
                                                            Downloaded by user
                                                            <strong className="ml-1 mr-1">{e.username}</strong>
                                                            for bot
                                                            <strong className="ml-1 mr-1">{e.bot}</strong>
                                                        </div>
                                                    )
                                                } else if (e.action === 'active_status') {
                                                    return (
                                                        <div>
                                                            <i className="pull-right mr-1">{ helpers.dt(e.timestamp) }</i>
                                                            User
                                                            <strong className="ml-1 mr-1">{e.username}</strong>
                                                            changed active status <span className="mr-1">from</span>
                                                            {e.prevValue ? (<span className="text-success">active</span>) : (<span className="text-danger">not active</span>)}
                                                            <span className="ml-1 mr-1">to</span>
                                                            {e.newValue ? (<span className="text-success">active</span>) : (<span className="text-danger">not active</span>)}
                                                        </div>
                                                    )
                                                }
                                            })()
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ExtensionsList;
