import React from 'react';
import {Select, InputNumber, Input, Space, Checkbox, Card, TimePicker, TreeSelect} from 'antd';
import dayjs from 'dayjs';

const format = 'mm:ss';
// const format = ['mm:ss', 'mm:ss'];

const range = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};
const disabledDateTime = () => ({
  disabledHours: () => [],
  disabledMinutes: () => range(11, 60),
  disabledSeconds: () => [],
});

const outcomesData = [
  {
    title: '1x2',
    value: '1x2',
    key: '1x2',
    selectable: false,
    checkable: false,
    children: [
      {
        title: 'win1 | 1x2',
        value: '1x2_win1',
        key: '1x2_win1',
      },
      {
        title: 'win2 | 1x2',
        value: '1x2_win2',
        key: '1x2_win2',
      },
      {
        title: 'draw | 1x2',
        value: '1x2_draw',
        key: '1x2_draw',
      },
    ],
  },
  {
    title: '1x2-half-time',
    value: '1x2-half-time',
    key: '1x2-half-time',
    selectable: false,
    checkable: false,
    children: [
      {
        title: 'win1 (1st half) | 1x2-half-time',
        value: '1x2-half-time_win1 (1st half)',
        key: '1x2-half-time_win1 (1st half)',
      },
      {
        title: 'win2 (1st half) | 1x2-half-time',
        value: '1x2-half-time_win2 (1st half)',
        key: '1x2-half-time_win2 (1st half)',
      },
      {
        title: 'draw (1st half) | 1x2-half-time',
        value: '1x2-half-time_draw (1st half)',
        key: '1x2-half-time_draw (1st half)',
      },
    ],
  },
  {
    title: 'double-chance',
    value: 'double-chance',
    key: 'double-chance',
    selectable: false,
    checkable: false,
    children: [
      {
        title: '1x | double-chance',
        value: 'double-chance_1x',
        key: 'double-chance_1x',
      },
      {
        title: '2x | double-chance',
        value: 'double-chance_2x',
        key: 'double-chance_2x',
      },
      {
        title: '12 | double-chance',
        value: 'double-chance_12',
        key: 'double-chance_12',
      },
    ],
  },
  {
    title: 'double-chance-1-half',
    value: 'double-chance-1-half',
    key: 'double-chance-1-half',
    selectable: false,
    checkable: false,
    children: [
      {
        title: '1x (1st half) | double-chance-1-half',
        value: 'double-chance-1-half_1x (1st half)',
        key: 'double-chance-1-half_1x (1st half)',
      },
      {
        title: '2x (1st half) | double-chance-1-half',
        value: 'double-chance-1-half_2x (1st half)',
        key: 'double-chance-1-half_2x (1st half)',
      },
      {
        title: '12 (1st half) | double-chance-1-half',
        value: 'double-chance-1-half_12 (1st half)',
        key: 'double-chance-1-half_12 (1st half)',
      },
    ],
  },
  {
    title: 'both-teams-to-score',
    value: 'both-teams-to-score',
    key: 'both-teams-to-score',
    selectable: false,
    checkable: false,
    children: [
      {
        title: 'both to score | both-teams-to-score',
        value: 'both-teams-to-score_both to score',
        key: 'both-teams-to-score_both to score',
      },
      {
        title: 'one scoreless | both-teams-to-score',
        value: 'both-teams-to-score_one scoreless',
        key: 'both-teams-to-score_one scoreless',
      },
    ],
  },
  {
    title: 'over-under-goals',
    value: 'over-under-goals',
    key: 'over-under-goals',
    selectable: false,
    checkable: false,
    children: [
      {
        title: 'over | over-under-goals',
        value: 'over-under-goals_to',
        key: 'over-under-goals_to',
      },
      {
        title: 'under | over-under-goals',
        value: 'over-under-goals_tu',
        key: 'over-under-goals_tu',
      },
    ],
  },
  {
    title: 'over-under-gl',
    value: 'over-under-gl',
    key: 'over-under-gl',
    selectable: false,
    checkable: false,
    children: [
      {
        title: 'over | over-under-gl',
        value: 'over-under-gl_to',
        key: 'over-under-gl_to',
      },
      {
        title: 'under | over-under-gl',
        value: 'over-under-gl_tu',
        key: 'over-under-gl_tu',
      },
    ],
  },
  {
    title: 'over-under-goals-1-half',
    value: 'over-under-goals-1-half',
    key: 'over-under-goals-1-half',
    selectable: false,
    checkable: false,
    children: [
      {
        title: 'over | over-under-goals-1-half',
        value: 'over-under-goals-1-half_to',
        key: 'over-under-goals-1-half_to',
      },
      {
        title: 'under | over-under-goals-1-half',
        value: 'over-under-goals-1-half_tu',
        key: 'over-under-goals-1-half_tu',
      },
    ],
  },
  {
    title: 'over-under-gl-half-1',
    value: 'over-under-gl-half-1',
    key: 'over-under-gl-half-1',
    selectable: false,
    checkable: false,
    children: [
      {
        title: 'over | over-under-gl-half-1',
        value: 'over-under-gl-half-1_to',
        key: 'over-under-gl-half-1_to',
      },
      {
        title: 'under | over-under-gl-half-1',
        value: 'over-under-gl-half-1_tu',
        key: 'over-under-gl-half-1_tu',
      },
    ],
  },
  {
    title: 'over-under-goals-by-home-team',
    value: 'over-under-goals-by-home-team',
    key: 'over-under-goals-by-home-team',
    selectable: false,
    checkable: false,
    children: [
      {
        title: 'over | over-under-goals-by-home-team',
        value: 'over-under-goals-by-home-team_to',
        key: 'over-under-goals-by-home-team_to',
      },
      {
        title: 'under | over-under-goals-by-home-team',
        value: 'over-under-goals-by-home-team_tu',
        key: 'over-under-goals-by-home-team_tu',
      },
    ],
  },
  {
    title: 'over-under-goals-by-away-team',
    value: 'over-under-goals-by-away-team',
    key: 'over-under-goals-by-away-team',
    selectable: false,
    checkable: false,
    children: [
      {
        title: 'over | over-under-goals-by-away-team',
        value: 'over-under-goals-by-away-team_to',
        key: 'over-under-goals-by-away-team_to',
      },
      {
        title: 'under | over-under-goals-by-away-team',
        value: 'over-under-goals-by-away-team_tu',
        key: 'over-under-goals-by-away-team_tu',
      },
    ],
  },
  {
    title: 'handicap-a-inc',
    value: 'handicap-a-inc',
    key: 'handicap-a-inc',
    selectable: false,
    checkable: false,
    children: [
      {
        title: 'ah1 | handicap-a-inc',
        value: 'handicap-a-inc_ah1',
        key: 'handicap-a-inc_ah1',
      },
      {
        title: 'ah2 | handicap-a-inc',
        value: 'handicap-a-inc_ah2',
        key: 'handicap-a-inc_ah2',
      },
    ],
  },
  {
    title: 'handicap-1-half-a-inc',
    value: 'handicap-1-half-a-inc',
    key: 'handicap-1-half-a-inc',
    selectable: false,
    checkable: false,
    children: [
      {
        title: 'ah1 | handicap-1-half-a-inc',
        value: 'handicap-1-half-a-inc_ah1',
        key: 'handicap-1-half-a-inc_ah1',
      },
      {
        title: 'ah2 | handicap-1-half-a-inc',
        value: 'handicap-1-half-a-inc_ah2',
        key: 'handicap-1-half-a-inc_ah2',
      },
    ],
  },
  {
    title: 'handicap-e-inc',
    value: 'handicap-e-inc',
    key: 'handicap-e-inc',
    selectable: false,
    checkable: false,
    children: [
      {
        title: 'eh1 | handicap-e-inc',
        value: 'handicap-e-inc_eh1',
        key: 'handicap-e-inc_eh1',
      },
      {
        title: 'eh2 | handicap-e-inc',
        value: 'handicap-e-inc_eh2',
        key: 'handicap-e-inc_eh2',
      },
    ],
  },
  {
    title: 'handicap-1-half-e-inc',
    value: 'handicap-1-half-e-inc',
    key: 'handicap-1-half-e-inc',
    selectable: false,
    checkable: false,
    children: [
      {
        title: 'eh1 | handicap-1-half-e-inc',
        value: 'handicap-1-half-e-inc_ah1',
        key: 'handicap-1-half-e-inc_ah1',
      },
      {
        title: 'eh2 | handicap-1-half-e-inc',
        value: 'handicap-1-half-e-inc_eh2',
        key: 'handicap-1-half-e-inc_eh2',
      },
    ],
  },
];

const leagueOptions = [
  {
    label: 'asian champions league (2x4 mins)',
    value: 'asian champions league (2x4 mins)',
  },
  {
    label: 'champions league (2x4 mins)',
    value: 'champions league (2x4 mins)',
  },
  {
    label: 'champions league-2 (2x4 mins)',
    value: 'champions league-2 (2x4 mins)',
  },
  {
    label: 'europa league-2 (2x4 mins)',
    value: 'europa league-2 (2x4 mins)',
  },
  {
    label: 'fifa absolute league (2x4 mins)',
    value: 'fifa absolute league (2x4 mins)',
  },
  {
    label: 'fa cup (2x4 mins)',
    value: 'fa cup (2x4 mins)',
  },
  {
    label: 'premier league (2x4 mins)',
    value: 'premier league (2x4 mins)',
  },
  {
    label: 'asdf super league (2x4 mins)',
    value: 'asdf super league (2x4 mins)',
  },
  {
    label: 'international (2x4 mins)',
    value: 'international (2x4 mins)',
  },
  {
    label: 'international i (2x4 mins)',
    value: 'international i (2x4 mins)',
  },
  {
    label: 'international ii (2x4 mins)',
    value: 'international ii (2x4 mins)',
  },
  {
    label: 'bundesliga (2x4 mins)',
    value: 'bundesliga (2x4 mins)',
  },
  {
    label: 'la liga (2x4 mins)',
    value: 'la liga (2x4 mins)',
  },
  {
    label: 'serie a (2x4 mins)',
    value: 'serie a (2x4 mins)',
  },
  {
    label: 'esports battle',
    value: 'esports battle',
  },
  {
    label: 'gt nations league',
    value: 'gt nations league',
  },
  {
    label: 'champions league',
    value: 'champions league',
  },
  {
    label: 'europa league',
    value: 'europa league',
  },
  {
    label: 'world cup',
    value: 'world cup',
  },
  {
    label: 'spain cup',
    value: 'spain cup',
  },
  {
    label: 'italy cup',
    value: 'italy cup',
  },
  {
    label: 'england cup',
    value: 'england cup',
  },
]


class StrategyLine extends React.Component {
  render() {
    // console.log('isPlaceBet: ', this.props.data)
    // console.log('0: ', dayjs(this.props.data.time[0], format))
    const inputClass = `form-control${this.props.data.hasError ? ' is-invalid' : ''}`;

    return (
      <Card>
        <div className="form-group row">
          <div className="col-md-12 col-sm-12">
            Киберфутбол
          </div>
          <div className="col-md-12 col-sm-12">
            {this.props.hasRemoveBtn && (
              <button type="button" data-index={this.props.ind} className="btn btn-danger btn-sm mt-1"
                      onClick={this.props.removeMoneyLinePosition}>Удалить условие</button>
            )}
          </div>
        </div>

        {/*<div className="form-row">*/}
        {/*  <div className="form-group col-md-12 col-sm-12">*/}
        {/*    <label>"Ставить" - ставка СТАВИТСЯ при выполнении всей цепочки параметров в условии.</label>*/}
        {/*    <label>"Не ставить" - ставка НЕ СТАВИТСЯ при выполнении хотябы одного параметра в условии.</label>*/}
        {/*    <div className="btn-group btn-group-toggle col-md-12 col-sm-12">*/}
        {/*      <label className={`btn input-pointer btn-secondary${this.props.data.isPlaceBet === true ? ' active' : ''}`}>*/}
        {/*        <input*/}
        {/*            value="place_bet"*/}
        {/*            id={`place_bet-${this.props.ind}`}*/}
        {/*            checked={this.props.data.isPlaceBet === true}*/}
        {/*            // defaultChecked={this.props.data.isPlaceBet === true}*/}
        {/*            onChange={(e) => this.props.changeIsPlaceBet(this.props.ind, e.target.value)}*/}
        {/*            name="isPlaceBet"*/}
        {/*            type="radio"*/}
        {/*            autoComplete="off"*/}
        {/*        />*/}
        {/*        Ставить*/}
        {/*      </label>*/}
        {/*      <label className={`btn input-pointer btn-secondary${this.props.data.isPlaceBet === false ? ' active' : ''}`}>*/}
        {/*        <input*/}
        {/*            value="no_place_bet"*/}
        {/*            id={`no_place_bet-${this.props.ind}`}*/}
        {/*            checked={this.props.data.isPlaceBet === false}*/}
        {/*            // defaultChecked={this.props.data.isPlaceBet === false}*/}
        {/*            onChange={(e) => this.props.changeIsPlaceBet(this.props.ind, e.target.value)}*/}
        {/*            name="isPlaceBet"*/}
        {/*            type="radio"*/}
        {/*            autoComplete="off"*/}
        {/*        />*/}
        {/*        Не ставить*/}
        {/*      </label>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}


        <div className="form-row">
          <div className="form-group col-md-3 col-sm-3">
            <label>Реверс</label>
            <div className="custom-control custom-switch form-control control-no-border">
              <input
                checked={this.props.data.isReverse}
                // defaultChecked={this.props.data.isReverse}
                onChange={this.props.changeIsReverse}
                type="checkbox"
                className="custom-control-input input-pointer"
                id={`isReverse-${this.props.ind}`}
                disabled={!this.props.isPlaceBet}
              />
              <label className="custom-control-label input-pointer" htmlFor={`isReverse-${this.props.ind}`}><span
                className={this.props.data.isReverse ? "badge badge-success" : "badge badge-danger"}>{this.props.data.isReverse ? "YES" : "NO"}</span></label>
            </div>
          </div>
          <div className="form-group col-md-9 col-sm-9"></div>
        </div>


        <div className="form-row">
          <div className="form-group col-md-3 col-sm-3">
            <label>Лиги</label>
            <div className="custom-control custom-switch form-control control-no-border">
              <input
                checked={this.props.data.isLeague}
                // defaultChecked={this.props.data.isLeague}
                onChange={this.props.changeIsLeague}
                type="checkbox"
                className="custom-control-input input-pointer"
                id={`isLeague-${this.props.ind}`}
              />
              <label className="custom-control-label input-pointer" htmlFor={`isLeague-${this.props.ind}`}><span
                className={this.props.data.isLeague ? "badge badge-success" : "badge badge-danger"}>{this.props.data.isLeague ? "YES" : "NO"}</span></label>
            </div>
          </div>

          {this.props.data.isLeague ? (
            <div className="form-group col-md-9 col-sm-9">
              <label>Выберите Лиги</label>
              <Select
                mode="multiple"
                allowClear
                // size={"large"}
                style={{
                  width: '100%',
                }}
                placeholder="Выберите Лиги"
                value={this.props.data.leagues}
                defaultValue={this.props.data.leagues}
                // onChange={this.props.changeLeague}
                onChange={(value) => this.props.changeLeague(this.props.ind, value)}
                options={leagueOptions}
              />
            </div>
          ) : (
            <div className="form-group col-md-9 col-sm-9"></div>
          )}

        </div>

        <div className="form-row">
          <div className="form-group col-md-3 col-sm-3">
            <label>Коэффициенты</label>
            <div className="custom-control custom-switch form-control control-no-border">
              <input
                checked={this.props.data.isOdds}
                // defaultChecked={this.props.data.isScores}
                onChange={this.props.changeIsOdds}
                type="checkbox"
                className="custom-control-input input-pointer"
                id={`isOdds-${this.props.ind}`}
              />
              <label className="custom-control-label input-pointer" htmlFor={`isOdds-${this.props.ind}`}><span
                className={this.props.data.isOdds ? "badge badge-success" : "badge badge-danger"}>{this.props.data.isOdds ? "YES" : "NO"}</span></label>
            </div>
          </div>

          {this.props.data.isOdds ? (
            <div className="form-group col-md-9 col-sm-9">
              <div className="form-row">
                <div className="form-group col-md-5 col-sm-5">
                  <label>Мин. коэффициент ставки</label>
                  <Space style={{width: '100%'}}>
                    <InputNumber
                      // addonBefore="Счет"
                      min={1}
                      max={10}
                      step="0.1"
                      value={this.props.data.minOdds}
                      // onChange={this.props.changeFirstTeamScore}
                      onChange={(value) => this.props.changeMinOdds(this.props.ind, value)}
                      // disabled={!this.props.data.isFirstTeamScore}
                    />
                  </Space>
                </div>
                <div className="form-group col-md-5 col-sm-5">
                  <label>Макс. коэффициент ставки</label>
                  <Space style={{width: '100%'}}>
                    <InputNumber
                      // addonBefore="Счет"
                      min={1}
                      max={10}
                      step="0.1"
                      value={this.props.data.maxOdds}
                      onChange={(value) => this.props.changeMaxOdds(this.props.ind, value)}
                      // disabled={!this.props.data.isSecondTeamScore}
                    />
                  </Space>
                </div>
              </div>
            </div>
          ) : (
            <div className="form-group col-md-9 col-sm-9"></div>
          )}

        </div>

        <div className="form-row">
          <div className="form-group col-md-3 col-sm-3">
            <label>Маржа(процент)</label>
            <div className="custom-control custom-switch form-control control-no-border">
              <input
                checked={this.props.data.isMargin}
                // defaultChecked={this.props.data.isScores}
                onChange={this.props.changeIsMargin}
                type="checkbox"
                className="custom-control-input input-pointer"
                id={`isMargin-${this.props.ind}`}
              />
              <label className="custom-control-label input-pointer" htmlFor={`isMargin-${this.props.ind}`}><span
                className={this.props.data.isMargin ? "badge badge-success" : "badge badge-danger"}>{this.props.data.isMargin ? "YES" : "NO"}</span></label>
            </div>
          </div>

          {this.props.data.isMargin ? (
            <div className="form-group col-md-9 col-sm-9">
              <div className="form-row">
                <div className="form-group col-md-5 col-sm-5">
                  <label>Мин. маржа ставки</label>
                  <Space style={{width: '100%'}}>
                    <InputNumber
                      // addonBefore="Счет"
                      min={-10}
                      max={100}
                      step="0.1"
                      value={this.props.data.minMargin}
                      // onChange={this.props.changeFirstTeamScore}
                      onChange={(value) => this.props.changeMinMargin(this.props.ind, value)}
                      // disabled={!this.props.data.isFirstTeamScore}
                    />
                  </Space>
                </div>
                <div className="form-group col-md-5 col-sm-5">
                  <label>Макс. маржа ставки</label>
                  <Space style={{width: '100%'}}>
                    <InputNumber
                      // addonBefore="Счет"
                      min={0}
                      max={100}
                      step="0.1"
                      value={this.props.data.maxMargin}
                      onChange={(value) => this.props.changeMaxMargin(this.props.ind, value)}
                      // disabled={!this.props.data.isSecondTeamScore}
                    />
                  </Space>
                </div>
              </div>
            </div>
          ) : (
            <div className="form-group col-md-9 col-sm-9"></div>
          )}

        </div>

        <div className="form-row">
          <div className="form-group col-md-3 col-sm-3">
            <label>Сумма ставки</label>
            <div className="custom-control custom-switch form-control control-no-border">
              <input
                checked={this.props.data.isMoney}
                // defaultChecked={this.props.data.isScores}
                onChange={this.props.changeIsMoney}
                type="checkbox"
                className="custom-control-input input-pointer"
                id={`isMoney-${this.props.ind}`}
              />
              <label className="custom-control-label input-pointer" htmlFor={`isMoney-${this.props.ind}`}><span
                className={this.props.data.isMoney ? "badge badge-success" : "badge badge-danger"}>{this.props.data.isMoney ? "YES" : "NO"}</span></label>
            </div>
          </div>

          {this.props.data.isMoney ? (
            <div className="form-group col-md-9 col-sm-9">
              <div className="form-row">
                <div className="form-group col-md-5 col-sm-5">
                  <label>Сумма первой ставки</label>
                  <Space style={{width: '100%'}}>
                    <InputNumber
                      // addonBefore="Счет"
                      min={1}
                      max={100000}
                      step="100"
                      value={this.props.data.moneyFirstBet}
                      // onChange={this.props.changeFirstTeamScore}
                      onChange={(value) => this.props.changeMoneyFirstBet(this.props.ind, value)}
                      // disabled={!this.props.data.isFirstTeamScore}
                    />
                  </Space>
                </div>
                <div className="form-group col-md-5 col-sm-5">
                </div>
              </div>
            </div>
          ) : (
            <div className="form-group col-md-9 col-sm-9"></div>
          )}

        </div>

        <div className="form-row">
          <div className="form-group col-md-3 col-sm-3">
            <label>Исходы</label>
            <div className="custom-control custom-switch form-control control-no-border">
              <input
                checked={this.props.data.isOutcome}
                // defaultChecked={this.props.data.isOutcome}
                onChange={this.props.changeIsOutcome}
                type="checkbox"
                className="custom-control-input input-pointer"
                id={`isOutcome-${this.props.ind}`}
              />
              <label className="custom-control-label input-pointer" htmlFor={`isOutcome-${this.props.ind}`}><span
                className={this.props.data.isOutcome ? "badge badge-success" : "badge badge-danger"}>{this.props.data.isOutcome ? "YES" : "NO"}</span></label>
            </div>
          </div>

          {this.props.data.isOutcome ? (
            <div className="form-group col-md-9 col-sm-9">

              <div className="form-row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Выберите Исход</label>
                  <TreeSelect
                    showSearch
                    style={{
                      width: '100%',
                    }}
                    // value={value}
                    // dropdownStyle={{
                    //   maxHeight: 400,
                    //   overflow: 'auto',
                    // }}
                    value={this.props.data.outcome}
                    defaultValue={this.props.data.outcome}
                    onChange={(value) => this.props.changeOutcome(this.props.ind, value)}
                    placeholder="Выберите Исходы"
                    allowClear
                    // multiple
                    // showCheckedStrategy={TreeSelect.SHOW_CHILD}
                    treeDefaultExpandAll={true}
                    // treeCheckable={true}
                    // treeCheckStrictly={false}
                    treeData={outcomesData}
                  />
                </div>
              </div>

              {['over-under-goals_to', 'over-under-goals_tu', 'over-under-goals-1-half_to', 'over-under-goals-1-half_tu', 'over-under-goals-by-home-team_to', 'over-under-goals-by-home-team_tu', 'over-under-goals-by-away-team_to', 'over-under-goals-by-away-team_tu', 'over-under-gl_to', 'over-under-gl_tu', 'over-under-gl-half-1_to', 'over-under-gl-half-1_tu'].includes(`${this.props.data.outcome}`) ? (
                <div className="form-row">
                  <div className="form-group col-md-12 col-sm-12">
                    <label>Укажите точное число(3.5) или интервал(0-3.5 включительно) для которого выполнится условие</label>
                    <Space style={{width: '100%'}}>
                      <Input
                        // min={0}
                        // max={5}
                        // step="0.5"
                        value={`${this.props.data.intervalOverUnder}`}
                        onChange={(value) => this.props.changeIntervalOverUnder(this.props.ind, value)}
                        // onChange={this.props.changeIntervalOverUnder}
                      />
                    </Space>
                  </div>
                </div>
              ) : (
                <></>
              )}

            </div>
          ) : (
            <div className="form-group col-md-9 col-sm-9"></div>
          )}

        </div>

        <div className="form-row">
          <div className="form-group col-md-3 col-sm-3">
            <label>Время матча</label>
            <div className="custom-control custom-switch form-control control-no-border">
              <input
                checked={this.props.data.isTime}
                // defaultChecked={this.props.data.isTime}
                onChange={this.props.changeIsTime}
                type="checkbox"
                className="custom-control-input input-pointer"
                id={`isTime-${this.props.ind}`}
              />
              <label className="custom-control-label input-pointer" htmlFor={`isTime-${this.props.ind}`}><span
                className={this.props.data.isTime ? "badge badge-success" : "badge badge-danger"}>{this.props.data.isTime ? "YES" : "NO"}</span></label>
            </div>
          </div>

          {this.props.data.isTime ? (
            <div className="form-group col-md-9 col-sm-9">
              <label>Выберите интервал в течении которого выполнится условие</label>
              <label>Формат: "минут:секунд" - "минут:секунд"</label>
              <Space style={{width: '100%'}}>
                <TimePicker.RangePicker
                  showNow={false}
                  secondStep={10}
                  // placeholder={["начальное время", "конечное время"]}
                  // value={dayjs(this.props.data.time, format)}
                  // defaultValue={dayjs(this.props.data.time, format)}
                  value={[dayjs(this.props.data.times[0], format), dayjs(this.props.data.times[1], format)]}
                  defaultValue={[dayjs(this.props.data.times[0], format), dayjs(this.props.data.times[1], format)]}
                  format={format}
                  disabledTime={disabledDateTime}
                  // onChange={onChange}
                  order={false}
                  onChange={(e, value) => this.props.changeTime(this.props.ind, value)}
                  // style={{width: '100%'}}
                />
              </Space>
              {/*<Select*/}
              {/*    // size={"large"}*/}
              {/*    // mode="multiple"*/}
              {/*    // allowClear*/}
              {/*    style={{*/}
              {/*      width: '100%',*/}
              {/*    }}*/}
              {/*    placeholder="Выберите имнуту"*/}
              {/*    value={this.props.data.time}*/}
              {/*    defaultValue={this.props.data.time}*/}
              {/*    // onChange={this.props.changeTime}*/}
              {/*    onChange={(value) => this.props.changeTime(this.props.ind, value)}*/}
              {/*    options={timeOptions}*/}
              {/*/>*/}
            </div>
          ) : (
            <div className="form-group col-md-9 col-sm-9"></div>
          )}

        </div>

        <div className="form-row">
          <div className="form-group col-md-3 col-sm-3">
            <label>Счет матча</label>
            <div className="custom-control custom-switch form-control control-no-border">
              <input
                checked={this.props.data.isScores}
                // defaultChecked={this.props.data.isScores}
                onChange={this.props.changeIsScores}
                type="checkbox"
                className="custom-control-input input-pointer"
                id={`isScores-${this.props.ind}`}
              />
              <label className="custom-control-label input-pointer" htmlFor={`isScores-${this.props.ind}`}><span
                className={this.props.data.isScores ? "badge badge-success" : "badge badge-danger"}>{this.props.data.isScores ? "YES" : "NO"}</span></label>
            </div>
          </div>

          {this.props.data.isScores ? (
            <div className="form-group col-md-9 col-sm-9">
              <div className="form-row">
                <div className="form-group col-md-5 col-sm-5">
                  <label>Счет Первой команды</label>
                  <Space style={{width: '100%'}}>
                    <InputNumber
                      // addonBefore="Счет"
                      min={0}
                      max={5}
                      value={this.props.data.firstTeamScore}
                      // onChange={this.props.changeFirstTeamScore}
                      onChange={(value) => this.props.changeFirstTeamScore(this.props.ind, value)}
                      // disabled={!this.props.data.isFirstTeamScore}
                    />
                    {/*<Checkbox*/}
                    {/*    checked={this.props.data.isFirstTeamScore}*/}
                    {/*    onChange={(e) => this.props.changeIsFirstTeamScore(this.props.ind, e.target.checked)}*/}
                    {/*>*/}
                    {/*</Checkbox>*/}
                  </Space>
                </div>
                <div className="form-group col-md-5 col-sm-5">
                  <label>Счет Второй команды</label>
                  <Space style={{width: '100%'}}>
                    <InputNumber
                      // addonBefore="Счет"
                      min={0}
                      max={5}
                      value={this.props.data.secondTeamScore}
                      onChange={(value) => this.props.changeSecondTeamScore(this.props.ind, value)}
                      // disabled={!this.props.data.isSecondTeamScore}
                    />
                    {/*<Checkbox*/}
                    {/*    checked={this.props.data.isSecondTeamScore}*/}
                    {/*    onChange={(e) => this.props.changeIsSecondTeamScore(this.props.ind, e.target.checked)}*/}
                    {/*>*/}
                    {/*</Checkbox>*/}
                  </Space>
                </div>
              </div>
            </div>
          ) : (
            <div className="form-group col-md-9 col-sm-9"></div>
          )}

        </div>


      </Card>
    );
  }
}

export default StrategyLine;
