import React from "react";

import helpers from "../../../chunks/helpers";
import backend from "../../../chunks/backend";
import routes from "../../../chunks/routes";

class ExtensionItem extends React.Component {
    constructor(props) {
        super(props);

        this.onClickShowHistory = this.onClickShowHistory.bind(this);
        this.onClickSetAsActive = this.onClickSetAsActive.bind(this);
    }

    onClickShowHistory(e) {
        e.preventDefault();
        this.props.showHistory(this.props.data.history);
    }

    onClickSetAsActive(e) {
        e.preventDefault();
        backend.put(routes.extension.activate, { id: this.props.id, bookmaker: this.props.data.base }).then(({ data }) => {
            if (data.success) {
                window.location.reload();
            }
        }).catch(console.log);
    }

    render() {
        return (
            <>
                <tr>
                    <td>{this.props.data.base}</td>
                    <td>{this.props.data.title}</td>
                    <td>{this.props.data.version}</td>
                    <td>{this.props.data.description}</td>
                    <td>
                        <div><small>{this.props.data.name} ({ (this.props.data.size / 1024).toFixed(2) }Kb)</small></div>
                        <div><small>{this.props.data.mimetype}</small></div>
                    </td>
                    <td>{ helpers.dt(this.props.data.createdAt) }</td>
                    <td>
                        <div className="btn-group btn-group-justified">
                            <button type="button" className="btn btn-info" onClick={this.onClickShowHistory} data-toggle="modal" data-target="#extensionHistory">Show history</button>
                            {!this.props.data.isActive && (
                                <button type="button" className="btn btn-success" onClick={this.onClickSetAsActive}>Set as active</button>
                            )}
                            {this.props.data.isActive && (
                                <button type="button" className="btn btn-primary disabled" disabled="">Active {this.props.data.base}</button>
                            )}
                        </div>
                    </td>
                </tr>
            </>
        );
    }
}

export default ExtensionItem;
