import React, { Component } from 'react';
import './style.css';

import title from "../../../chunks/title";

import {withRouter} from "react-router-dom";
import backend from "../../../chunks/backend";
import ArchiveEntity from "./ArchiveEntity";
import PaginationComponent from "../../../components/PaginationComponent";

class GamesArchive extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sport:  'football',
            loaded: false,
            games:  [],
            page:   1,
            search: ''
        };

        this.showFootball = this.showFootball.bind(this);
        this.showTT = this.showTT.bind(this);
        this.showHockey = this.showHockey.bind(this);
        this.updateData = this.updateData.bind(this);
        this.showPage = this.showPage.bind(this);
        this.onChangeSearchInput = this.onChangeSearchInput.bind(this);
    }

    onChangeSearchInput(e) {
        const search = e.target.value;
        const page   = 1;

        this.setState({
            search, page
        }, this.updateData);
    }

    showFootball() {
        this.setState({sport: 'football', loaded: false}, () => {
            this.updateData();
        });
    }

    showHockey() {
        this.setState({sport: 'ice-hockey', loaded: false}, () => {
            this.updateData();
        });
    }

    showTT() {
        this.setState({sport: 'table-tennis', loaded: false}, () => {
            this.updateData();
        });
    }

    updateData() {
        this.setState({
            loaded: false
        }, () => {
            backend.get(`/games/history/${this.state.sport}?page=${this.state.page}&s=${this.state.search}`).then(resp => {
                if ('history' in resp.data) {
                    this.setState({
                        games: Array.isArray(resp.data.history) ? resp.data.history : []
                    })
                }
            }).catch(console.log).finally(() => {
                this.setState({
                    loaded: true
                });
            });
        });
    }

    componentDidMount() {
        title(`Archive games`);
        this.updateData();
    }

    showPage(page) {
        this.setState({ page }, this.updateData);
    }

    render() {
        return (
            <>
              <div className="page-title">
                  <div className="title_left">
                    <h3>Games Archive</h3>
                  </div>
                  <div className="title_right">
                      <div className="col-md-5 col-sm-5 pull-right btn-remove-profile">
                          <div className="btn-group btn-sm form-check pull-right" data-toggle="buttons">
                              <button className="btn btn-secondary active" onClick={this.showFootball}>
                                  <input type="radio" className="join-btn"/> Football
                              </button>
                              <button className="btn btn-secondary" onClick={this.showHockey}>
                                  <input type="radio" className="join-btn"/> Ice Hockey
                              </button>
                              <button className="btn btn-secondary" onClick={this.showTT}>
                                  <input type="radio" className="join-btn"/> Table-Tennis
                              </button>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="clearfix"/>
              <div className="row margin-top-profile">
                    <div className="col-md-12 col-xs-12">
                        <div className="x_panel">
                            <div className="x_content">
                                <input style={{ display: 'inline', float: 'left', width: '50%', marginBottom: '15px' }} type="search" onChange={this.onChangeSearchInput} className="form-control" placeholder="search" value={this.state.search} />
                                <PaginationComponent showPage={this.showPage} page={this.state.page} />
                                <div className="table-responsive">
                                    <table className="table table-hover bulk_action jambo_table table-striped">
                                        <thead>
                                        <tr className="headings">
                                            <th className="column-title" style={{ width: '160px' }}>Game Over At</th>
                                            <th className="column-title">Name</th>
                                            <th className="column-title">EventID</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.loaded && (
                                            this.state.games.length > 0 ? (
                                                this.state.games.map((game, i) => <ArchiveEntity search={this.state.search} key={i} data={game} /> )
                                            ) : (
                                                <tr>
                                                    <td colSpan="3">List is empty</td>
                                                </tr>
                                            )
                                        )}
                                        {!this.state.loaded && (
                                            <tr>
                                                <td colSpan="3"><i className="text-muted">Loading...</i></td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(GamesArchive);
