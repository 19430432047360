import React from 'react';
import {Select, InputNumber, Input, Space, Checkbox, Card, TimePicker, TreeSelect} from 'antd';
import dayjs from 'dayjs';

const format = 'mm:ss';
// const format = ['mm:ss', 'mm:ss'];

const range = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};
const disabledDateTime = () => ({
  disabledHours: () => [],
  disabledMinutes: () => range(11, 60),
  disabledSeconds: () => [],
});

const outcomesData = [
  {
    title: '1x2',
    value: '1x2',
    key: '1x2',
    selectable: false,
    checkable: false,
    children: [
      {
        title: 'win1 | 1x2',
        value: '1x2_win1',
        key: '1x2_win1',
      },
      {
        title: 'win2 | 1x2',
        value: '1x2_win2',
        key: '1x2_win2',
      },
      {
        title: 'draw | 1x2',
        value: '1x2_draw',
        key: '1x2_draw',
      },
    ],
  },
  {
    title: '1x2-half-time',
    value: '1x2-half-time',
    key: '1x2-half-time',
    selectable: false,
    checkable: false,
    children: [
      {
        title: 'win1 (1st half) | 1x2-half-time',
        value: '1x2-half-time_win1 (1st half)',
        key: '1x2-half-time_win1 (1st half)',
      },
      {
        title: 'win2 (1st half) | 1x2-half-time',
        value: '1x2-half-time_win2 (1st half)',
        key: '1x2-half-time_win2 (1st half)',
      },
      {
        title: 'draw (1st half) | 1x2-half-time',
        value: '1x2-half-time_draw (1st half)',
        key: '1x2-half-time_draw (1st half)',
      },
    ],
  },
  {
    title: 'double-chance',
    value: 'double-chance',
    key: 'double-chance',
    selectable: false,
    checkable: false,
    children: [
      {
        title: '1x | double-chance',
        value: 'double-chance_1x',
        key: 'double-chance_1x',
      },
      {
        title: '2x | double-chance',
        value: 'double-chance_2x',
        key: 'double-chance_2x',
      },
      {
        title: '12 | double-chance',
        value: 'double-chance_12',
        key: 'double-chance_12',
      },
    ],
  },
  {
    title: 'double-chance-1-half',
    value: 'double-chance-1-half',
    key: 'double-chance-1-half',
    selectable: false,
    checkable: false,
    children: [
      {
        title: '1x (1st half) | double-chance-1-half',
        value: 'double-chance-1-half_1x (1st half)',
        key: 'double-chance-1-half_1x (1st half)',
      },
      {
        title: '2x (1st half) | double-chance-1-half',
        value: 'double-chance-1-half_2x (1st half)',
        key: 'double-chance-1-half_2x (1st half)',
      },
      {
        title: '12 (1st half) | double-chance-1-half',
        value: 'double-chance-1-half_12 (1st half)',
        key: 'double-chance-1-half_12 (1st half)',
      },
    ],
  },
  {
    title: 'both-teams-to-score',
    value: 'both-teams-to-score',
    key: 'both-teams-to-score',
    selectable: false,
    checkable: false,
    children: [
      {
        title: 'both to score | both-teams-to-score',
        value: 'both-teams-to-score_both to score',
        key: 'both-teams-to-score_both to score',
      },
      {
        title: 'one scoreless | both-teams-to-score',
        value: 'both-teams-to-score_one scoreless',
        key: 'both-teams-to-score_one scoreless',
      },
    ],
  },
  {
    title: 'over-under-goals',
    value: 'over-under-goals',
    key: 'over-under-goals',
    selectable: false,
    checkable: false,
    children: [
      {
        title: 'over | over-under-goals',
        value: 'over-under-goals_to',
        key: 'over-under-goals_to',
      },
      {
        title: 'under | over-under-goals',
        value: 'over-under-goals_tu',
        key: 'over-under-goals_tu',
      },
    ],
  },
  {
    title: 'over-under-gl',
    value: 'over-under-gl',
    key: 'over-under-gl',
    selectable: false,
    checkable: false,
    children: [
      {
        title: 'over | over-under-gl',
        value: 'over-under-gl_to',
        key: 'over-under-gl_to',
      },
      {
        title: 'under | over-under-gl',
        value: 'over-under-gl_tu',
        key: 'over-under-gl_tu',
      },
    ],
  },
  {
    title: 'over-under-goals-1-half',
    value: 'over-under-goals-1-half',
    key: 'over-under-goals-1-half',
    selectable: false,
    checkable: false,
    children: [
      {
        title: 'over | over-under-goals-1-half',
        value: 'over-under-goals-1-half_to',
        key: 'over-under-goals-1-half_to',
      },
      {
        title: 'under | over-under-goals-1-half',
        value: 'over-under-goals-1-half_tu',
        key: 'over-under-goals-1-half_tu',
      },
    ],
  },
  {
    title: 'over-under-gl-half-1',
    value: 'over-under-gl-half-1',
    key: 'over-under-gl-half-1',
    selectable: false,
    checkable: false,
    children: [
      {
        title: 'over | over-under-gl-half-1',
        value: 'over-under-gl-half-1_to',
        key: 'over-under-gl-half-1_to',
      },
      {
        title: 'under | over-under-gl-half-1',
        value: 'over-under-gl-half-1_tu',
        key: 'over-under-gl-half-1_tu',
      },
    ],
  },
  {
    title: 'over-under-goals-by-home-team',
    value: 'over-under-goals-by-home-team',
    key: 'over-under-goals-by-home-team',
    selectable: false,
    checkable: false,
    children: [
      {
        title: 'over | over-under-goals-by-home-team',
        value: 'over-under-goals-by-home-team_to',
        key: 'over-under-goals-by-home-team_to',
      },
      {
        title: 'under | over-under-goals-by-home-team',
        value: 'over-under-goals-by-home-team_tu',
        key: 'over-under-goals-by-home-team_tu',
      },
    ],
  },
  {
    title: 'over-under-goals-by-away-team',
    value: 'over-under-goals-by-away-team',
    key: 'over-under-goals-by-away-team',
    selectable: false,
    checkable: false,
    children: [
      {
        title: 'over | over-under-goals-by-away-team',
        value: 'over-under-goals-by-away-team_to',
        key: 'over-under-goals-by-away-team_to',
      },
      {
        title: 'under | over-under-goals-by-away-team',
        value: 'over-under-goals-by-away-team_tu',
        key: 'over-under-goals-by-away-team_tu',
      },
    ],
  },
  {
    title: 'over-under-corners',
    value: 'over-under-corners',
    key: 'over-under-corners',
    selectable: false,
    checkable: false,
    children: [
      {
        title: 'over | over-under-corners',
        value: 'over-under-corners_to',
        key: 'over-under-corners_to',
      },
      {
        title: 'under | over-under-corners',
        value: 'over-under-corners_tu',
        key: 'over-under-corners_tu',
      },
    ],
  },
  {
    title: 'over-under-corners-by-1-half',
    value: 'over-under-corners-by-1-half',
    key: 'over-under-corners-by-1-half',
    selectable: false,
    checkable: false,
    children: [
      {
        title: 'over | over-under-corners-by-1-half',
        value: 'over-under-corners-by-1-half_to',
        key: 'over-under-corners-by-1-half_to',
      },
      {
        title: 'under | over-under-corners-by-1-half',
        value: 'over-under-corners-by-1-half_tu',
        key: 'over-under-corners-by-1-half_tu',
      },
    ],
  },
  {
    title: 'handicap-a-inc',
    value: 'handicap-a-inc',
    key: 'handicap-a-inc',
    selectable: false,
    checkable: false,
    children: [
      {
        title: 'ah1 | handicap-a-inc',
        value: 'handicap-a-inc_ah1',
        key: 'handicap-a-inc_ah1',
      },
      {
        title: 'ah2 | handicap-a-inc',
        value: 'handicap-a-inc_ah2',
        key: 'handicap-a-inc_ah2',
      },
    ],
  },
  {
    title: 'handicap-1-half-a-inc',
    value: 'handicap-1-half-a-inc',
    key: 'handicap-1-half-a-inc',
    selectable: false,
    checkable: false,
    children: [
      {
        title: 'ah1 | handicap-1-half-a-inc',
        value: 'handicap-1-half-a-inc_ah1',
        key: 'handicap-1-half-a-inc_ah1',
      },
      {
        title: 'ah2 | handicap-1-half-a-inc',
        value: 'handicap-1-half-a-inc_ah2',
        key: 'handicap-1-half-a-inc_ah2',
      },
    ],
  },
  {
    title: 'handicap-e-inc',
    value: 'handicap-e-inc',
    key: 'handicap-e-inc',
    selectable: false,
    checkable: false,
    children: [
      {
        title: 'eh1 | handicap-e-inc',
        value: 'handicap-e-inc_eh1',
        key: 'handicap-e-inc_eh1',
      },
      {
        title: 'eh2 | handicap-e-inc',
        value: 'handicap-e-inc_eh2',
        key: 'handicap-e-inc_eh2',
      },
    ],
  },
  {
    title: 'handicap-1-half-e-inc',
    value: 'handicap-1-half-e-inc',
    key: 'handicap-1-half-e-inc',
    selectable: false,
    checkable: false,
    children: [
      {
        title: 'eh1 | handicap-1-half-e-inc',
        value: 'handicap-1-half-e-inc_ah1',
        key: 'handicap-1-half-e-inc_ah1',
      },
      {
        title: 'eh2 | handicap-1-half-e-inc',
        value: 'handicap-1-half-e-inc_eh2',
        key: 'handicap-1-half-e-inc_eh2',
      },
    ],
  },
];

const bookmakerOptions = [
  {label: 'SIMBET', value: 'simbet'},
  {label: 'INBET365', value: 'inbet365'},
  {label: '212BET', value: '212bet'},
  {label: 'BET365STAR', value: 'bet365star'},
  {label: 'RABET777', value: 'rabet777'},
]


// const arr1 = ``.split(/\n/g)
// function uniq(a) {
//   return a.sort().filter(function(item, pos, ary) {
//     return !pos || item != ary[pos - 1];
//   });
// }
// const arr2 = uniq(arr1)
//
// const leagueSimbetOptions = []
//
// for (let i = 0; i < arr2.length; i -= -1) {
//   const val = arr2[i];
//   leagueSimbetOptions.push({key: val, label: val, value: val})
// }


const leagueSimbetOptions = [
  {
    "key": "afc asian cup qualifiers (play off)",
    "label": "afc asian cup qualifiers (play off)",
    "value": "afc asian cup qualifiers (play off)"
  },
  {
    "key": "afc champions league elite",
    "label": "afc champions league elite",
    "value": "afc champions league elite"
  },
  {
    "key": "afc champions league two",
    "label": "afc champions league two",
    "value": "afc champions league two"
  },
  {
    "key": "afc u20 asian cup 2025 qualifiers",
    "label": "afc u20 asian cup 2025 qualifiers",
    "value": "afc u20 asian cup 2025 qualifiers"
  },
  {
    "key": "afc women champions league",
    "label": "afc women champions league",
    "value": "afc women champions league"
  },
  {
    "key": "africa cup of nations 2025 qualifiers",
    "label": "africa cup of nations 2025 qualifiers",
    "value": "africa cup of nations 2025 qualifiers"
  },
  {
    "key": "algeria 1st division",
    "label": "algeria 1st division",
    "value": "algeria 1st division"
  },
  {
    "key": "algeria reserve league 1",
    "label": "algeria reserve league 1",
    "value": "algeria reserve league 1"
  },
  {
    "key": "argentina copa liga profesional reserve",
    "label": "argentina copa liga profesional reserve",
    "value": "argentina copa liga profesional reserve"
  },
  {
    "key": "argentina cup",
    "label": "argentina cup",
    "value": "argentina cup"
  },
  {
    "key": "argentina liga profesional",
    "label": "argentina liga profesional",
    "value": "argentina liga profesional"
  },
  {
    "key": "argentina primera b metropolitana",
    "label": "argentina primera b metropolitana",
    "value": "argentina primera b metropolitana"
  },
  {
    "key": "argentina primera b nacional",
    "label": "argentina primera b nacional",
    "value": "argentina primera b nacional"
  },
  {
    "key": "argentina primera c",
    "label": "argentina primera c",
    "value": "argentina primera c"
  },
  {
    "key": "argentina primera nacional reserve",
    "label": "argentina primera nacional reserve",
    "value": "argentina primera nacional reserve"
  },
  {
    "key": "argentina torneo federal a",
    "label": "argentina torneo federal a",
    "value": "argentina torneo federal a"
  },
  {
    "key": "argentina torneo federal a (play off)",
    "label": "argentina torneo federal a (play off)",
    "value": "argentina torneo federal a (play off)"
  },
  {
    "key": "argentina torneo promocional amateur",
    "label": "argentina torneo promocional amateur",
    "value": "argentina torneo promocional amateur"
  },
  {
    "key": "argentina women copa liga profesional",
    "label": "argentina women copa liga profesional",
    "value": "argentina women copa liga profesional"
  },
  {
    "key": "argentina women primera division a",
    "label": "argentina women primera division a",
    "value": "argentina women primera division a"
  },
  {
    "key": "armenia cup",
    "label": "armenia cup",
    "value": "armenia cup"
  },
  {
    "key": "armenia premier league",
    "label": "armenia premier league",
    "value": "armenia premier league"
  },
  {
    "key": "asean championship qualifiers",
    "label": "asean championship qualifiers",
    "value": "asean championship qualifiers"
  },
  {
    "key": "asean club championship",
    "label": "asean club championship",
    "value": "asean club championship"
  },
  {
    "key": "australia capital national premier league (play off)",
    "label": "australia capital national premier league (play off)",
    "value": "australia capital national premier league (play off)"
  },
  {
    "key": "australia capital national premier league u23 (play off)",
    "label": "australia capital national premier league u23 (play off)",
    "value": "australia capital national premier league u23 (play off)"
  },
  {
    "key": "australia capital premier league (play off)",
    "label": "australia capital premier league (play off)",
    "value": "australia capital premier league (play off)"
  },
  {
    "key": "australia capital premier league u23 (play off)",
    "label": "australia capital premier league u23 (play off)",
    "value": "australia capital premier league u23 (play off)"
  },
  {
    "key": "australia capital women national premier league (play off)",
    "label": "australia capital women national premier league (play off)",
    "value": "australia capital women national premier league (play off)"
  },
  {
    "key": "australia cup",
    "label": "australia cup",
    "value": "australia cup"
  },
  {
    "key": "australia cup - penalty shoot-out",
    "label": "australia cup - penalty shoot-out",
    "value": "australia cup - penalty shoot-out"
  },
  {
    "key": "australia northern new south wales premier league (play off)",
    "label": "australia northern new south wales premier league (play off)",
    "value": "australia northern new south wales premier league (play off)"
  },
  {
    "key": "australia northern new south wales premier league reserve (play off)",
    "label": "australia northern new south wales premier league reserve (play off)",
    "value": "australia northern new south wales premier league reserve (play off)"
  },
  {
    "key": "australia northern new south wales womens premier league (play off)",
    "label": "australia northern new south wales womens premier league (play off)",
    "value": "australia northern new south wales womens premier league (play off)"
  },
  {
    "key": "australia northern territory premier league (play off)",
    "label": "australia northern territory premier league (play off)",
    "value": "australia northern territory premier league (play off)"
  },
  {
    "key": "australia northern territory womens premier league (play off)",
    "label": "australia northern territory womens premier league (play off)",
    "value": "australia northern territory womens premier league (play off)"
  },
  {
    "key": "australia victoria womens premier league (play off)",
    "label": "australia victoria womens premier league (play off)",
    "value": "australia victoria womens premier league (play off)"
  },
  {
    "key": "austria 2nd league",
    "label": "austria 2nd league",
    "value": "austria 2nd league"
  },
  {
    "key": "austria bundesliga",
    "label": "austria bundesliga",
    "value": "austria bundesliga"
  },
  {
    "key": "austria cup",
    "label": "austria cup",
    "value": "austria cup"
  },
  {
    "key": "austria landesliga",
    "label": "austria landesliga",
    "value": "austria landesliga"
  },
  {
    "key": "austria regional league east",
    "label": "austria regional league east",
    "value": "austria regional league east"
  },
  {
    "key": "austria regional league mitte",
    "label": "austria regional league mitte",
    "value": "austria regional league mitte"
  },
  {
    "key": "austria regional league west",
    "label": "austria regional league west",
    "value": "austria regional league west"
  },
  {
    "key": "austria salzburg football cup",
    "label": "austria salzburg football cup",
    "value": "austria salzburg football cup"
  },
  {
    "key": "austria women bundesliga",
    "label": "austria women bundesliga",
    "value": "austria women bundesliga"
  },
  {
    "key": "azerbaijan division 1",
    "label": "azerbaijan division 1",
    "value": "azerbaijan division 1"
  },
  {
    "key": "azerbaijan premier league",
    "label": "azerbaijan premier league",
    "value": "azerbaijan premier league"
  },
  {
    "key": "bahrain 2nd division",
    "label": "bahrain 2nd division",
    "value": "bahrain 2nd division"
  },
  {
    "key": "bahrain premier league",
    "label": "bahrain premier league",
    "value": "bahrain premier league"
  },
  {
    "key": "bahrain super cup",
    "label": "bahrain super cup",
    "value": "bahrain super cup"
  },
  {
    "key": "baltic women cup u19 (in estonia)",
    "label": "baltic women cup u19 (in estonia)",
    "value": "baltic women cup u19 (in estonia)"
  },
  {
    "key": "belgium challenger pro league",
    "label": "belgium challenger pro league",
    "value": "belgium challenger pro league"
  },
  {
    "key": "belgium first division a",
    "label": "belgium first division a",
    "value": "belgium first division a"
  },
  {
    "key": "belgium pro league u21",
    "label": "belgium pro league u21",
    "value": "belgium pro league u21"
  },
  {
    "key": "bhutan premier league",
    "label": "bhutan premier league",
    "value": "bhutan premier league"
  },
  {
    "key": "bolivia professional football league",
    "label": "bolivia professional football league",
    "value": "bolivia professional football league"
  },
  {
    "key": "bosnia-herzegovina premier league",
    "label": "bosnia-herzegovina premier league",
    "value": "bosnia-herzegovina premier league"
  },
  {
    "key": "brazil campeonato amapaense u20",
    "label": "brazil campeonato amapaense u20",
    "value": "brazil campeonato amapaense u20"
  },
  {
    "key": "brazil campeonato aspirantes u23",
    "label": "brazil campeonato aspirantes u23",
    "value": "brazil campeonato aspirantes u23"
  },
  {
    "key": "brazil campeonato brasiliense serie b",
    "label": "brazil campeonato brasiliense serie b",
    "value": "brazil campeonato brasiliense serie b"
  },
  {
    "key": "brazil campeonato carioca serie b1",
    "label": "brazil campeonato carioca serie b1",
    "value": "brazil campeonato carioca serie b1"
  },
  {
    "key": "brazil campeonato carioca serie b2",
    "label": "brazil campeonato carioca serie b2",
    "value": "brazil campeonato carioca serie b2"
  },
  {
    "key": "brazil campeonato carioca u20",
    "label": "brazil campeonato carioca u20",
    "value": "brazil campeonato carioca u20"
  },
  {
    "key": "brazil campeonato mineiro women",
    "label": "brazil campeonato mineiro women",
    "value": "brazil campeonato mineiro women"
  },
  {
    "key": "brazil campeonato paraense u20",
    "label": "brazil campeonato paraense u20",
    "value": "brazil campeonato paraense u20"
  },
  {
    "key": "brazil campeonato paulista women",
    "label": "brazil campeonato paulista women",
    "value": "brazil campeonato paulista women"
  },
  {
    "key": "brazil campeonato piauiense serie b",
    "label": "brazil campeonato piauiense serie b",
    "value": "brazil campeonato piauiense serie b"
  },
  {
    "key": "brazil copa paulista",
    "label": "brazil copa paulista",
    "value": "brazil copa paulista"
  },
  {
    "key": "brazil cup",
    "label": "brazil cup",
    "value": "brazil cup"
  },
  {
    "key": "brazil cup u20",
    "label": "brazil cup u20",
    "value": "brazil cup u20"
  },
  {
    "key": "brazil gaucho cup",
    "label": "brazil gaucho cup",
    "value": "brazil gaucho cup"
  },
  {
    "key": "brazil rio cup",
    "label": "brazil rio cup",
    "value": "brazil rio cup"
  },
  {
    "key": "brazil santa catarina cup",
    "label": "brazil santa catarina cup",
    "value": "brazil santa catarina cup"
  },
  {
    "key": "brazil serie a",
    "label": "brazil serie a",
    "value": "brazil serie a"
  },
  {
    "key": "brazil serie b",
    "label": "brazil serie b",
    "value": "brazil serie b"
  },
  {
    "key": "brazil serie d (play off)",
    "label": "brazil serie d (play off)",
    "value": "brazil serie d (play off)"
  },
  {
    "key": "caf champions league qualifiers",
    "label": "caf champions league qualifiers",
    "value": "caf champions league qualifiers"
  },
  {
    "key": "caf super cup",
    "label": "caf super cup",
    "value": "caf super cup"
  },
  {
    "key": "cambodian premier league",
    "label": "cambodian premier league",
    "value": "cambodian premier league"
  },
  {
    "key": "canada soccer league",
    "label": "canada soccer league",
    "value": "canada soccer league"
  },
  {
    "key": "canadian championship",
    "label": "canadian championship",
    "value": "canadian championship"
  },
  {
    "key": "canadian premier league",
    "label": "canadian premier league",
    "value": "canadian premier league"
  },
  {
    "key": "cecafa u20 championship (in tanzania)",
    "label": "cecafa u20 championship (in tanzania)",
    "value": "cecafa u20 championship (in tanzania)"
  },
  {
    "key": "chile primera b",
    "label": "chile primera b",
    "value": "chile primera b"
  },
  {
    "key": "chile primera division",
    "label": "chile primera division",
    "value": "chile primera division"
  },
  {
    "key": "chile segunda division",
    "label": "chile segunda division",
    "value": "chile segunda division"
  },
  {
    "key": "chile women primera b (play off)",
    "label": "chile women primera b (play off)",
    "value": "chile women primera b (play off)"
  },
  {
    "key": "china fa cup",
    "label": "china fa cup",
    "value": "china fa cup"
  },
  {
    "key": "china football super league",
    "label": "china football super league",
    "value": "china football super league"
  },
  {
    "key": "china league 1",
    "label": "china league 1",
    "value": "china league 1"
  },
  {
    "key": "china league 2 (play off)",
    "label": "china league 2 (play off)",
    "value": "china league 2 (play off)"
  },
  {
    "key": "china league u21",
    "label": "china league u21",
    "value": "china league u21"
  },
  {
    "key": "china women super league",
    "label": "china women super league",
    "value": "china women super league"
  },
  {
    "key": "club friendly",
    "label": "club friendly",
    "value": "club friendly"
  },
  {
    "key": "club friendly women",
    "label": "club friendly women",
    "value": "club friendly women"
  },
  {
    "key": "colombia cup",
    "label": "colombia cup",
    "value": "colombia cup"
  },
  {
    "key": "colombia primera a",
    "label": "colombia primera a",
    "value": "colombia primera a"
  },
  {
    "key": "colombia primera b",
    "label": "colombia primera b",
    "value": "colombia primera b"
  },
  {
    "key": "concacaf caribbean cup",
    "label": "concacaf caribbean cup",
    "value": "concacaf caribbean cup"
  },
  {
    "key": "concacaf central american cup",
    "label": "concacaf central american cup",
    "value": "concacaf central american cup"
  },
  {
    "key": "concacaf nations league a",
    "label": "concacaf nations league a",
    "value": "concacaf nations league a"
  },
  {
    "key": "concacaf nations league b",
    "label": "concacaf nations league b",
    "value": "concacaf nations league b"
  },
  {
    "key": "concacaf nations league c",
    "label": "concacaf nations league c",
    "value": "concacaf nations league c"
  },
  {
    "key": "concacaf women champions cup",
    "label": "concacaf women champions cup",
    "value": "concacaf women champions cup"
  },
  {
    "key": "copa libertadores",
    "label": "copa libertadores",
    "value": "copa libertadores"
  },
  {
    "key": "copa libertadores women (in paraguay)",
    "label": "copa libertadores women (in paraguay)",
    "value": "copa libertadores women (in paraguay)"
  },
  {
    "key": "copa sudamericana",
    "label": "copa sudamericana",
    "value": "copa sudamericana"
  },
  {
    "key": "cosafa u20 championship (in mozambique)",
    "label": "cosafa u20 championship (in mozambique)",
    "value": "cosafa u20 championship (in mozambique)"
  },
  {
    "key": "costa rica liga de ascenso",
    "label": "costa rica liga de ascenso",
    "value": "costa rica liga de ascenso"
  },
  {
    "key": "costa rica primera division",
    "label": "costa rica primera division",
    "value": "costa rica primera division"
  },
  {
    "key": "costa rica women primera division",
    "label": "costa rica women primera division",
    "value": "costa rica women primera division"
  },
  {
    "key": "croatia cup",
    "label": "croatia cup",
    "value": "croatia cup"
  },
  {
    "key": "croatia first league",
    "label": "croatia first league",
    "value": "croatia first league"
  },
  {
    "key": "croatia hnl league",
    "label": "croatia hnl league",
    "value": "croatia hnl league"
  },
  {
    "key": "croatia second league",
    "label": "croatia second league",
    "value": "croatia second league"
  },
  {
    "key": "cyprus 1st division",
    "label": "cyprus 1st division",
    "value": "cyprus 1st division"
  },
  {
    "key": "cyprus 2nd division",
    "label": "cyprus 2nd division",
    "value": "cyprus 2nd division"
  },
  {
    "key": "cyprus cup",
    "label": "cyprus cup",
    "value": "cyprus cup"
  },
  {
    "key": "cyprus super cup",
    "label": "cyprus super cup",
    "value": "cyprus super cup"
  },
  {
    "key": "czech republic 1st division",
    "label": "czech republic 1st division",
    "value": "czech republic 1st division"
  },
  {
    "key": "czech republic cup",
    "label": "czech republic cup",
    "value": "czech republic cup"
  },
  {
    "key": "czech republic national football league",
    "label": "czech republic national football league",
    "value": "czech republic national football league"
  },
  {
    "key": "czech republic u19 league",
    "label": "czech republic u19 league",
    "value": "czech republic u19 league"
  },
  {
    "key": "denmark 1st division",
    "label": "denmark 1st division",
    "value": "denmark 1st division"
  },
  {
    "key": "denmark 2nd division",
    "label": "denmark 2nd division",
    "value": "denmark 2nd division"
  },
  {
    "key": "denmark cup",
    "label": "denmark cup",
    "value": "denmark cup"
  },
  {
    "key": "denmark future cup",
    "label": "denmark future cup",
    "value": "denmark future cup"
  },
  {
    "key": "denmark series",
    "label": "denmark series",
    "value": "denmark series"
  },
  {
    "key": "denmark super league",
    "label": "denmark super league",
    "value": "denmark super league"
  },
  {
    "key": "denmark u19 league",
    "label": "denmark u19 league",
    "value": "denmark u19 league"
  },
  {
    "key": "denmark u21 tournament",
    "label": "denmark u21 tournament",
    "value": "denmark u21 tournament"
  },
  {
    "key": "ecuador cup",
    "label": "ecuador cup",
    "value": "ecuador cup"
  },
  {
    "key": "ecuador serie a",
    "label": "ecuador serie a",
    "value": "ecuador serie a"
  },
  {
    "key": "ecuador serie b",
    "label": "ecuador serie b",
    "value": "ecuador serie b"
  },
  {
    "key": "egypt second division a",
    "label": "egypt second division a",
    "value": "egypt second division a"
  },
  {
    "key": "el salvador primera division",
    "label": "el salvador primera division",
    "value": "el salvador primera division"
  },
  {
    "key": "el salvador primera reserve league",
    "label": "el salvador primera reserve league",
    "value": "el salvador primera reserve league"
  },
  {
    "key": "el salvador segunda division",
    "label": "el salvador segunda division",
    "value": "el salvador segunda division"
  },
  {
    "key": "english central reserve league",
    "label": "english central reserve league",
    "value": "english central reserve league"
  },
  {
    "key": "english central reserve league cup",
    "label": "english central reserve league cup",
    "value": "english central reserve league cup"
  },
  {
    "key": "english fa cup qualifiers",
    "label": "english fa cup qualifiers",
    "value": "english fa cup qualifiers"
  },
  {
    "key": "english fa women league cup",
    "label": "english fa women league cup",
    "value": "english fa women league cup"
  },
  {
    "key": "english football league trophy",
    "label": "english football league trophy",
    "value": "english football league trophy"
  },
  {
    "key": "english isthmian league division one north",
    "label": "english isthmian league division one north",
    "value": "english isthmian league division one north"
  },
  {
    "key": "english isthmian league division one south central",
    "label": "english isthmian league division one south central",
    "value": "english isthmian league division one south central"
  },
  {
    "key": "english isthmian league premier division",
    "label": "english isthmian league premier division",
    "value": "english isthmian league premier division"
  },
  {
    "key": "english league championship",
    "label": "english league championship",
    "value": "english league championship"
  },
  {
    "key": "english league cup",
    "label": "english league cup",
    "value": "english league cup"
  },
  {
    "key": "english league one",
    "label": "english league one",
    "value": "english league one"
  },
  {
    "key": "english league two",
    "label": "english league two",
    "value": "english league two"
  },
  {
    "key": "english manchester premier cup",
    "label": "english manchester premier cup",
    "value": "english manchester premier cup"
  },
  {
    "key": "english national league",
    "label": "english national league",
    "value": "english national league"
  },
  {
    "key": "english national league cup",
    "label": "english national league cup",
    "value": "english national league cup"
  },
  {
    "key": "english national league south",
    "label": "english national league south",
    "value": "english national league south"
  },
  {
    "key": "english northern premier division",
    "label": "english northern premier division",
    "value": "english northern premier division"
  },
  {
    "key": "english premier league",
    "label": "english premier league",
    "value": "english premier league"
  },
  {
    "key": "english premier league 2 u21",
    "label": "english premier league 2 u21",
    "value": "english premier league 2 u21"
  },
  {
    "key": "english premier league cup u21",
    "label": "english premier league cup u21",
    "value": "english premier league cup u21"
  },
  {
    "key": "english professional development league u21",
    "label": "english professional development league u21",
    "value": "english professional development league u21"
  },
  {
    "key": "english southern premier division south",
    "label": "english southern premier division south",
    "value": "english southern premier division south"
  },
  {
    "key": "english women super league",
    "label": "english women super league",
    "value": "english women super league"
  },
  {
    "key": "estonia cup",
    "label": "estonia cup",
    "value": "estonia cup"
  },
  {
    "key": "estonia esiliiga",
    "label": "estonia esiliiga",
    "value": "estonia esiliiga"
  },
  {
    "key": "estonia meistriliiga",
    "label": "estonia meistriliiga",
    "value": "estonia meistriliiga"
  },
  {
    "key": "ethiopia premier league",
    "label": "ethiopia premier league",
    "value": "ethiopia premier league"
  },
  {
    "key": "finland kakkonen (play off)",
    "label": "finland kakkonen (play off)",
    "value": "finland kakkonen (play off)"
  },
  {
    "key": "finland kolmonen",
    "label": "finland kolmonen",
    "value": "finland kolmonen"
  },
  {
    "key": "finland veikkausliiga (play off)",
    "label": "finland veikkausliiga (play off)",
    "value": "finland veikkausliiga (play off)"
  },
  {
    "key": "finland women national league (play off)",
    "label": "finland women national league (play off)",
    "value": "finland women national league (play off)"
  },
  {
    "key": "finland ykkonen (play off)",
    "label": "finland ykkonen (play off)",
    "value": "finland ykkonen (play off)"
  },
  {
    "key": "finland ykkosliiga",
    "label": "finland ykkosliiga",
    "value": "finland ykkosliiga"
  },
  {
    "key": "four nations tournament (in china)",
    "label": "four nations tournament (in china)",
    "value": "four nations tournament (in china)"
  },
  {
    "key": "four nations tournament (in croatia)",
    "label": "four nations tournament (in croatia)",
    "value": "four nations tournament (in croatia)"
  },
  {
    "key": "four nations tournament (in romania)",
    "label": "four nations tournament (in romania)",
    "value": "four nations tournament (in romania)"
  },
  {
    "key": "four nations tournament u18 (in portugal)",
    "label": "four nations tournament u18 (in portugal)",
    "value": "four nations tournament u18 (in portugal)"
  },
  {
    "key": "france ligue 1",
    "label": "france ligue 1",
    "value": "france ligue 1"
  },
  {
    "key": "france ligue 2",
    "label": "france ligue 2",
    "value": "france ligue 2"
  },
  {
    "key": "france national",
    "label": "france national",
    "value": "france national"
  },
  {
    "key": "france u19 league",
    "label": "france u19 league",
    "value": "france u19 league"
  },
  {
    "key": "futsal world cup 2024 (in uzbekistan)",
    "label": "futsal world cup 2024 (in uzbekistan)",
    "value": "futsal world cup 2024 (in uzbekistan)"
  },
  {
    "key": "georgia cup",
    "label": "georgia cup",
    "value": "georgia cup"
  },
  {
    "key": "georgia erovnuli liga",
    "label": "georgia erovnuli liga",
    "value": "georgia erovnuli liga"
  },
  {
    "key": "germany 3rd liga",
    "label": "germany 3rd liga",
    "value": "germany 3rd liga"
  },
  {
    "key": "germany bundesliga 2",
    "label": "germany bundesliga 2",
    "value": "germany bundesliga 2"
  },
  {
    "key": "germany oberliga",
    "label": "germany oberliga",
    "value": "germany oberliga"
  },
  {
    "key": "germany regional league bavaria",
    "label": "germany regional league bavaria",
    "value": "germany regional league bavaria"
  },
  {
    "key": "germany regional league north",
    "label": "germany regional league north",
    "value": "germany regional league north"
  },
  {
    "key": "germany regional league northeast",
    "label": "germany regional league northeast",
    "value": "germany regional league northeast"
  },
  {
    "key": "germany regional league west",
    "label": "germany regional league west",
    "value": "germany regional league west"
  },
  {
    "key": "germany women bundesliga",
    "label": "germany women bundesliga",
    "value": "germany women bundesliga"
  },
  {
    "key": "germany youth league u19 qualifiers",
    "label": "germany youth league u19 qualifiers",
    "value": "germany youth league u19 qualifiers"
  },
  {
    "key": "germany-bundesliga i",
    "label": "germany-bundesliga i",
    "value": "germany-bundesliga i"
  },
  {
    "key": "greece cup",
    "label": "greece cup",
    "value": "greece cup"
  },
  {
    "key": "greece super league 1",
    "label": "greece super league 1",
    "value": "greece super league 1"
  },
  {
    "key": "greece super league 2",
    "label": "greece super league 2",
    "value": "greece super league 2"
  },
  {
    "key": "greece super league u19",
    "label": "greece super league u19",
    "value": "greece super league u19"
  },
  {
    "key": "guatemala liga national",
    "label": "guatemala liga national",
    "value": "guatemala liga national"
  },
  {
    "key": "guatemala liga primera division",
    "label": "guatemala liga primera division",
    "value": "guatemala liga primera division"
  },
  {
    "key": "honduras liga nacional",
    "label": "honduras liga nacional",
    "value": "honduras liga nacional"
  },
  {
    "key": "hong kong 1st division",
    "label": "hong kong 1st division",
    "value": "hong kong 1st division"
  },
  {
    "key": "hong kong 2nd division",
    "label": "hong kong 2nd division",
    "value": "hong kong 2nd division"
  },
  {
    "key": "hong kong premier league",
    "label": "hong kong premier league",
    "value": "hong kong premier league"
  },
  {
    "key": "hungary nb i",
    "label": "hungary nb i",
    "value": "hungary nb i"
  },
  {
    "key": "hungary nb ii",
    "label": "hungary nb ii",
    "value": "hungary nb ii"
  },
  {
    "key": "iceland 1st division (play off)",
    "label": "iceland 1st division (play off)",
    "value": "iceland 1st division (play off)"
  },
  {
    "key": "iceland premier league",
    "label": "iceland premier league",
    "value": "iceland premier league"
  },
  {
    "key": "iceland premier league (play off)",
    "label": "iceland premier league (play off)",
    "value": "iceland premier league (play off)"
  },
  {
    "key": "iceland u19 league a",
    "label": "iceland u19 league a",
    "value": "iceland u19 league a"
  },
  {
    "key": "iceland u19 league b",
    "label": "iceland u19 league b",
    "value": "iceland u19 league b"
  },
  {
    "key": "iceland women premier league (play off)",
    "label": "iceland women premier league (play off)",
    "value": "iceland women premier league (play off)"
  },
  {
    "key": "india bangalore super division",
    "label": "india bangalore super division",
    "value": "india bangalore super division"
  },
  {
    "key": "india delhi premier league",
    "label": "india delhi premier league",
    "value": "india delhi premier league"
  },
  {
    "key": "india goa professional league",
    "label": "india goa professional league",
    "value": "india goa professional league"
  },
  {
    "key": "india mizoram premier league",
    "label": "india mizoram premier league",
    "value": "india mizoram premier league"
  },
  {
    "key": "india mizoram premier league (play off)",
    "label": "india mizoram premier league (play off)",
    "value": "india mizoram premier league (play off)"
  },
  {
    "key": "india puttaiah memorial cup",
    "label": "india puttaiah memorial cup",
    "value": "india puttaiah memorial cup"
  },
  {
    "key": "india sfa a division s-league",
    "label": "india sfa a division s-league",
    "value": "india sfa a division s-league"
  },
  {
    "key": "indian super league",
    "label": "indian super league",
    "value": "indian super league"
  },
  {
    "key": "indonesia liga 1",
    "label": "indonesia liga 1",
    "value": "indonesia liga 1"
  },
  {
    "key": "indonesia liga 2",
    "label": "indonesia liga 2",
    "value": "indonesia liga 2"
  },
  {
    "key": "intercontinental cup",
    "label": "intercontinental cup",
    "value": "intercontinental cup"
  },
  {
    "key": "international friendly",
    "label": "international friendly",
    "value": "international friendly"
  },
  {
    "key": "international friendly u18",
    "label": "international friendly u18",
    "value": "international friendly u18"
  },
  {
    "key": "international friendly u19",
    "label": "international friendly u19",
    "value": "international friendly u19"
  },
  {
    "key": "international friendly u20",
    "label": "international friendly u20",
    "value": "international friendly u20"
  },
  {
    "key": "international friendly u21",
    "label": "international friendly u21",
    "value": "international friendly u21"
  },
  {
    "key": "international friendly women u19",
    "label": "international friendly women u19",
    "value": "international friendly women u19"
  },
  {
    "key": "international women football cup (in vietnam)",
    "label": "international women football cup (in vietnam)",
    "value": "international women football cup (in vietnam)"
  },
  {
    "key": "iran azadegan league",
    "label": "iran azadegan league",
    "value": "iran azadegan league"
  },
  {
    "key": "iran pro league",
    "label": "iran pro league",
    "value": "iran pro league"
  },
  {
    "key": "iraq stars league",
    "label": "iraq stars league",
    "value": "iraq stars league"
  },
  {
    "key": "ireland 1st division",
    "label": "ireland 1st division",
    "value": "ireland 1st division"
  },
  {
    "key": "ireland fai cup",
    "label": "ireland fai cup",
    "value": "ireland fai cup"
  },
  {
    "key": "ireland premier division",
    "label": "ireland premier division",
    "value": "ireland premier division"
  },
  {
    "key": "israel leumit liga",
    "label": "israel leumit liga",
    "value": "israel leumit liga"
  },
  {
    "key": "israel liga alef",
    "label": "israel liga alef",
    "value": "israel liga alef"
  },
  {
    "key": "israel premier league",
    "label": "israel premier league",
    "value": "israel premier league"
  },
  {
    "key": "israel toto cup leumit",
    "label": "israel toto cup leumit",
    "value": "israel toto cup leumit"
  },
  {
    "key": "italy campionato primavera 1 u20",
    "label": "italy campionato primavera 1 u20",
    "value": "italy campionato primavera 1 u20"
  },
  {
    "key": "italy cup",
    "label": "italy cup",
    "value": "italy cup"
  },
  {
    "key": "italy primavera cup qualifiers",
    "label": "italy primavera cup qualifiers",
    "value": "italy primavera cup qualifiers"
  },
  {
    "key": "italy serie a",
    "label": "italy serie a",
    "value": "italy serie a"
  },
  {
    "key": "italy serie b",
    "label": "italy serie b",
    "value": "italy serie b"
  },
  {
    "key": "italy serie c",
    "label": "italy serie c",
    "value": "italy serie c"
  },
  {
    "key": "italy serie d",
    "label": "italy serie d",
    "value": "italy serie d"
  },
  {
    "key": "italy women serie a",
    "label": "italy women serie a",
    "value": "italy women serie a"
  },
  {
    "key": "jamaica premier league",
    "label": "jamaica premier league",
    "value": "jamaica premier league"
  },
  {
    "key": "japan emperor cup",
    "label": "japan emperor cup",
    "value": "japan emperor cup"
  },
  {
    "key": "japan emperor cup - penalty shoot-out",
    "label": "japan emperor cup - penalty shoot-out",
    "value": "japan emperor cup - penalty shoot-out"
  },
  {
    "key": "japan football league",
    "label": "japan football league",
    "value": "japan football league"
  },
  {
    "key": "japan j-league division 1",
    "label": "japan j-league division 1",
    "value": "japan j-league division 1"
  },
  {
    "key": "japan j-league division 2",
    "label": "japan j-league division 2",
    "value": "japan j-league division 2"
  },
  {
    "key": "japan j-league division 3",
    "label": "japan j-league division 3",
    "value": "japan j-league division 3"
  },
  {
    "key": "japan league cup",
    "label": "japan league cup",
    "value": "japan league cup"
  },
  {
    "key": "japan regional football league",
    "label": "japan regional football league",
    "value": "japan regional football league"
  },
  {
    "key": "japan women empowerment league",
    "label": "japan women empowerment league",
    "value": "japan women empowerment league"
  },
  {
    "key": "japan women empowerment league cup",
    "label": "japan women empowerment league cup",
    "value": "japan women empowerment league cup"
  },
  {
    "key": "japan women football division 1",
    "label": "japan women football division 1",
    "value": "japan women football division 1"
  },
  {
    "key": "japan women football division 2",
    "label": "japan women football division 2",
    "value": "japan women football division 2"
  },
  {
    "key": "jordan 1st division",
    "label": "jordan 1st division",
    "value": "jordan 1st division"
  },
  {
    "key": "jordan pro league",
    "label": "jordan pro league",
    "value": "jordan pro league"
  },
  {
    "key": "jordan shield cup",
    "label": "jordan shield cup",
    "value": "jordan shield cup"
  },
  {
    "key": "kazakhstan 1st division",
    "label": "kazakhstan 1st division",
    "value": "kazakhstan 1st division"
  },
  {
    "key": "kazakhstan league cup",
    "label": "kazakhstan league cup",
    "value": "kazakhstan league cup"
  },
  {
    "key": "kazakhstan premier league",
    "label": "kazakhstan premier league",
    "value": "kazakhstan premier league"
  },
  {
    "key": "korea k league 1",
    "label": "korea k league 1",
    "value": "korea k league 1"
  },
  {
    "key": "korea k league 2",
    "label": "korea k league 2",
    "value": "korea k league 2"
  },
  {
    "key": "korea k3 league",
    "label": "korea k3 league",
    "value": "korea k3 league"
  },
  {
    "key": "korea women k-league",
    "label": "korea women k-league",
    "value": "korea women k-league"
  },
  {
    "key": "kosovo super liga",
    "label": "kosovo super liga",
    "value": "kosovo super liga"
  },
  {
    "key": "kuwait premier league",
    "label": "kuwait premier league",
    "value": "kuwait premier league"
  },
  {
    "key": "latvia cup",
    "label": "latvia cup",
    "value": "latvia cup"
  },
  {
    "key": "latvia virsliga",
    "label": "latvia virsliga",
    "value": "latvia virsliga"
  },
  {
    "key": "lithuania a lyga",
    "label": "lithuania a lyga",
    "value": "lithuania a lyga"
  },
  {
    "key": "malaysia mfl cup",
    "label": "malaysia mfl cup",
    "value": "malaysia mfl cup"
  },
  {
    "key": "malaysia super league",
    "label": "malaysia super league",
    "value": "malaysia super league"
  },
  {
    "key": "memorial stevan vilotic cele u19 tournament (in serbia)",
    "label": "memorial stevan vilotic cele u19 tournament (in serbia)",
    "value": "memorial stevan vilotic cele u19 tournament (in serbia)"
  },
  {
    "key": "mexico league u23",
    "label": "mexico league u23",
    "value": "mexico league u23"
  },
  {
    "key": "mexico liga de expansion",
    "label": "mexico liga de expansion",
    "value": "mexico liga de expansion"
  },
  {
    "key": "mexico liga premier serie a",
    "label": "mexico liga premier serie a",
    "value": "mexico liga premier serie a"
  },
  {
    "key": "mexico liga premier serie b",
    "label": "mexico liga premier serie b",
    "value": "mexico liga premier serie b"
  },
  {
    "key": "mexico primera division",
    "label": "mexico primera division",
    "value": "mexico primera division"
  },
  {
    "key": "mexico women primera division",
    "label": "mexico women primera division",
    "value": "mexico women primera division"
  },
  {
    "key": "moldova liga 1",
    "label": "moldova liga 1",
    "value": "moldova liga 1"
  },
  {
    "key": "moldova super liga",
    "label": "moldova super liga",
    "value": "moldova super liga"
  },
  {
    "key": "morocco botola 2",
    "label": "morocco botola 2",
    "value": "morocco botola 2"
  },
  {
    "key": "morocco botola pro",
    "label": "morocco botola pro",
    "value": "morocco botola pro"
  },
  {
    "key": "morocco excellence cup",
    "label": "morocco excellence cup",
    "value": "morocco excellence cup"
  },
  {
    "key": "myanmar national league",
    "label": "myanmar national league",
    "value": "myanmar national league"
  },
  {
    "key": "myanmar national league u20",
    "label": "myanmar national league u20",
    "value": "myanmar national league u20"
  },
  {
    "key": "nafu campeones cup",
    "label": "nafu campeones cup",
    "value": "nafu campeones cup"
  },
  {
    "key": "netherlands eerste divisie",
    "label": "netherlands eerste divisie",
    "value": "netherlands eerste divisie"
  },
  {
    "key": "netherlands eredivisie",
    "label": "netherlands eredivisie",
    "value": "netherlands eredivisie"
  },
  {
    "key": "netherlands eredivisie u19",
    "label": "netherlands eredivisie u19",
    "value": "netherlands eredivisie u19"
  },
  {
    "key": "new zealand national league championship",
    "label": "new zealand national league championship",
    "value": "new zealand national league championship"
  },
  {
    "key": "new zealand women national league championship",
    "label": "new zealand women national league championship",
    "value": "new zealand women national league championship"
  },
  {
    "key": "nigeria professional football league",
    "label": "nigeria professional football league",
    "value": "nigeria professional football league"
  },
  {
    "key": "north macedonia 1st league",
    "label": "north macedonia 1st league",
    "value": "north macedonia 1st league"
  },
  {
    "key": "north macedonia cup",
    "label": "north macedonia cup",
    "value": "north macedonia cup"
  },
  {
    "key": "northern ireland premiership development league",
    "label": "northern ireland premiership development league",
    "value": "northern ireland premiership development league"
  },
  {
    "key": "norway 1st division",
    "label": "norway 1st division",
    "value": "norway 1st division"
  },
  {
    "key": "norway 2nd division",
    "label": "norway 2nd division",
    "value": "norway 2nd division"
  },
  {
    "key": "norway 3rd division",
    "label": "norway 3rd division",
    "value": "norway 3rd division"
  },
  {
    "key": "norway cup",
    "label": "norway cup",
    "value": "norway cup"
  },
  {
    "key": "norway eliteserien",
    "label": "norway eliteserien",
    "value": "norway eliteserien"
  },
  {
    "key": "norway national champions league u19 (play off)",
    "label": "norway national champions league u19 (play off)",
    "value": "norway national champions league u19 (play off)"
  },
  {
    "key": "norway toppserien women",
    "label": "norway toppserien women",
    "value": "norway toppserien women"
  },
  {
    "key": "norway u19 cup",
    "label": "norway u19 cup",
    "value": "norway u19 cup"
  },
  {
    "key": "norway u19 interkrets a",
    "label": "norway u19 interkrets a",
    "value": "norway u19 interkrets a"
  },
  {
    "key": "norway u19 interkrets b",
    "label": "norway u19 interkrets b",
    "value": "norway u19 interkrets b"
  },
  {
    "key": "oman 1st division",
    "label": "oman 1st division",
    "value": "oman 1st division"
  },
  {
    "key": "oman professional league",
    "label": "oman professional league",
    "value": "oman professional league"
  },
  {
    "key": "paraguay cup",
    "label": "paraguay cup",
    "value": "paraguay cup"
  },
  {
    "key": "paraguay division intermedia",
    "label": "paraguay division intermedia",
    "value": "paraguay division intermedia"
  },
  {
    "key": "paraguay primera division",
    "label": "paraguay primera division",
    "value": "paraguay primera division"
  },
  {
    "key": "paraguay primera division b",
    "label": "paraguay primera division b",
    "value": "paraguay primera division b"
  },
  {
    "key": "peru liga 1",
    "label": "peru liga 1",
    "value": "peru liga 1"
  },
  {
    "key": "peru liga 2 (play off)",
    "label": "peru liga 2 (play off)",
    "value": "peru liga 2 (play off)"
  },
  {
    "key": "poland 1st division",
    "label": "poland 1st division",
    "value": "poland 1st division"
  },
  {
    "key": "poland 2nd division",
    "label": "poland 2nd division",
    "value": "poland 2nd division"
  },
  {
    "key": "poland 3rd division",
    "label": "poland 3rd division",
    "value": "poland 3rd division"
  },
  {
    "key": "poland cup",
    "label": "poland cup",
    "value": "poland cup"
  },
  {
    "key": "poland ekstraklasa",
    "label": "poland ekstraklasa",
    "value": "poland ekstraklasa"
  },
  {
    "key": "poland women ekstraliga",
    "label": "poland women ekstraliga",
    "value": "poland women ekstraliga"
  },
  {
    "key": "portugal championship u19 division 1",
    "label": "portugal championship u19 division 1",
    "value": "portugal championship u19 division 1"
  },
  {
    "key": "portugal cup",
    "label": "portugal cup",
    "value": "portugal cup"
  },
  {
    "key": "portugal liga 2",
    "label": "portugal liga 2",
    "value": "portugal liga 2"
  },
  {
    "key": "portugal liga 3",
    "label": "portugal liga 3",
    "value": "portugal liga 3"
  },
  {
    "key": "portugal liga revelacao u23",
    "label": "portugal liga revelacao u23",
    "value": "portugal liga revelacao u23"
  },
  {
    "key": "portugal primeira liga",
    "label": "portugal primeira liga",
    "value": "portugal primeira liga"
  },
  {
    "key": "premier league international cup (in england)",
    "label": "premier league international cup (in england)",
    "value": "premier league international cup (in england)"
  },
  {
    "key": "qatar olympic league u21",
    "label": "qatar olympic league u21",
    "value": "qatar olympic league u21"
  },
  {
    "key": "qatar stars league",
    "label": "qatar stars league",
    "value": "qatar stars league"
  },
  {
    "key": "qatar stars league cup",
    "label": "qatar stars league cup",
    "value": "qatar stars league cup"
  },
  {
    "key": "romania 2nd division",
    "label": "romania 2nd division",
    "value": "romania 2nd division"
  },
  {
    "key": "romania liga i",
    "label": "romania liga i",
    "value": "romania liga i"
  },
  {
    "key": "saba club friendly virtual pes 21 - 15 mins play",
    "label": "saba club friendly virtual pes 21 - 15 mins play",
    "value": "saba club friendly virtual pes 21 - 15 mins play"
  },
  {
    "key": "saba international friendly virtual pes 21 - 20 mins play",
    "label": "saba international friendly virtual pes 21 - 20 mins play",
    "value": "saba international friendly virtual pes 21 - 20 mins play"
  },
  {
    "key": "saudi 1st division",
    "label": "saudi 1st division",
    "value": "saudi 1st division"
  },
  {
    "key": "saudi 2nd division",
    "label": "saudi 2nd division",
    "value": "saudi 2nd division"
  },
  {
    "key": "saudi king cup",
    "label": "saudi king cup",
    "value": "saudi king cup"
  },
  {
    "key": "saudi pro league",
    "label": "saudi pro league",
    "value": "saudi pro league"
  },
  {
    "key": "scotland championship",
    "label": "scotland championship",
    "value": "scotland championship"
  },
  {
    "key": "scotland league 2",
    "label": "scotland league 2",
    "value": "scotland league 2"
  },
  {
    "key": "scotland league cup",
    "label": "scotland league cup",
    "value": "scotland league cup"
  },
  {
    "key": "scotland premiership",
    "label": "scotland premiership",
    "value": "scotland premiership"
  },
  {
    "key": "scotland reserve cup",
    "label": "scotland reserve cup",
    "value": "scotland reserve cup"
  },
  {
    "key": "scotland reserve league",
    "label": "scotland reserve league",
    "value": "scotland reserve league"
  },
  {
    "key": "serbia league u19",
    "label": "serbia league u19",
    "value": "serbia league u19"
  },
  {
    "key": "serbia super league",
    "label": "serbia super league",
    "value": "serbia super league"
  },
  {
    "key": "slovakia 2nd division",
    "label": "slovakia 2nd division",
    "value": "slovakia 2nd division"
  },
  {
    "key": "slovakia cup",
    "label": "slovakia cup",
    "value": "slovakia cup"
  },
  {
    "key": "slovakia super league",
    "label": "slovakia super league",
    "value": "slovakia super league"
  },
  {
    "key": "slovakia u19 league",
    "label": "slovakia u19 league",
    "value": "slovakia u19 league"
  },
  {
    "key": "slovenia 2nd division",
    "label": "slovenia 2nd division",
    "value": "slovenia 2nd division"
  },
  {
    "key": "slovenia 3rd division",
    "label": "slovenia 3rd division",
    "value": "slovenia 3rd division"
  },
  {
    "key": "slovenia cup",
    "label": "slovenia cup",
    "value": "slovenia cup"
  },
  {
    "key": "slovenia nations cup",
    "label": "slovenia nations cup",
    "value": "slovenia nations cup"
  },
  {
    "key": "slovenia prva liga",
    "label": "slovenia prva liga",
    "value": "slovenia prva liga"
  },
  {
    "key": "slovenia women cup",
    "label": "slovenia women cup",
    "value": "slovenia women cup"
  },
  {
    "key": "slovenia women league",
    "label": "slovenia women league",
    "value": "slovenia women league"
  },
  {
    "key": "south africa premiership",
    "label": "south africa premiership",
    "value": "south africa premiership"
  },
  {
    "key": "south australia women premier league (play off)",
    "label": "south australia women premier league (play off)",
    "value": "south australia women premier league (play off)"
  },
  {
    "key": "spain copa federacion",
    "label": "spain copa federacion",
    "value": "spain copa federacion"
  },
  {
    "key": "spain primera federacion",
    "label": "spain primera federacion",
    "value": "spain primera federacion"
  },
  {
    "key": "spain primera laliga",
    "label": "spain primera laliga",
    "value": "spain primera laliga"
  },
  {
    "key": "spain queen cup",
    "label": "spain queen cup",
    "value": "spain queen cup"
  },
  {
    "key": "spain segunda division",
    "label": "spain segunda division",
    "value": "spain segunda division"
  },
  {
    "key": "spain segunda federacion",
    "label": "spain segunda federacion",
    "value": "spain segunda federacion"
  },
  {
    "key": "spain tercera federacion",
    "label": "spain tercera federacion",
    "value": "spain tercera federacion"
  },
  {
    "key": "spain women primera division",
    "label": "spain women primera division",
    "value": "spain women primera division"
  },
  {
    "key": "spain women primera federacion",
    "label": "spain women primera federacion",
    "value": "spain women primera federacion"
  },
  {
    "key": "spain women segunda federacion",
    "label": "spain women segunda federacion",
    "value": "spain women segunda federacion"
  },
  {
    "key": "sweden 2nd division",
    "label": "sweden 2nd division",
    "value": "sweden 2nd division"
  },
  {
    "key": "sweden allsvenskan",
    "label": "sweden allsvenskan",
    "value": "sweden allsvenskan"
  },
  {
    "key": "sweden cup",
    "label": "sweden cup",
    "value": "sweden cup"
  },
  {
    "key": "sweden ettan north",
    "label": "sweden ettan north",
    "value": "sweden ettan north"
  },
  {
    "key": "sweden ettan south",
    "label": "sweden ettan south",
    "value": "sweden ettan south"
  },
  {
    "key": "sweden league cup elite u21",
    "label": "sweden league cup elite u21",
    "value": "sweden league cup elite u21"
  },
  {
    "key": "sweden superettan",
    "label": "sweden superettan",
    "value": "sweden superettan"
  },
  {
    "key": "sweden women cup",
    "label": "sweden women cup",
    "value": "sweden women cup"
  },
  {
    "key": "sweden women damallsvenskan",
    "label": "sweden women damallsvenskan",
    "value": "sweden women damallsvenskan"
  },
  {
    "key": "switzerland 1st liga classic",
    "label": "switzerland 1st liga classic",
    "value": "switzerland 1st liga classic"
  },
  {
    "key": "switzerland challenge league",
    "label": "switzerland challenge league",
    "value": "switzerland challenge league"
  },
  {
    "key": "switzerland cup",
    "label": "switzerland cup",
    "value": "switzerland cup"
  },
  {
    "key": "switzerland promotion league",
    "label": "switzerland promotion league",
    "value": "switzerland promotion league"
  },
  {
    "key": "switzerland super league",
    "label": "switzerland super league",
    "value": "switzerland super league"
  },
  {
    "key": "taiwan football premier league",
    "label": "taiwan football premier league",
    "value": "taiwan football premier league"
  },
  {
    "key": "tanzania premier league",
    "label": "tanzania premier league",
    "value": "tanzania premier league"
  },
  {
    "key": "thailand league 1",
    "label": "thailand league 1",
    "value": "thailand league 1"
  },
  {
    "key": "thailand league 2",
    "label": "thailand league 2",
    "value": "thailand league 2"
  },
  {
    "key": "turkey cup",
    "label": "turkey cup",
    "value": "turkey cup"
  },
  {
    "key": "turkey elite league b u19",
    "label": "turkey elite league b u19",
    "value": "turkey elite league b u19"
  },
  {
    "key": "turkey first league",
    "label": "turkey first league",
    "value": "turkey first league"
  },
  {
    "key": "turkey second league",
    "label": "turkey second league",
    "value": "turkey second league"
  },
  {
    "key": "turkey super league",
    "label": "turkey super league",
    "value": "turkey super league"
  },
  {
    "key": "u20 women world cup 2024 (in colombia)",
    "label": "u20 women world cup 2024 (in colombia)",
    "value": "u20 women world cup 2024 (in colombia)"
  },
  {
    "key": "uae 1st division",
    "label": "uae 1st division",
    "value": "uae 1st division"
  },
  {
    "key": "uae presidents cup",
    "label": "uae presidents cup",
    "value": "uae presidents cup"
  },
  {
    "key": "uae pro league",
    "label": "uae pro league",
    "value": "uae pro league"
  },
  {
    "key": "uae pro league u23",
    "label": "uae pro league u23",
    "value": "uae pro league u23"
  },
  {
    "key": "uae youth league u21",
    "label": "uae youth league u21",
    "value": "uae youth league u21"
  },
  {
    "key": "uefa champions league",
    "label": "uefa champions league",
    "value": "uefa champions league"
  },
  {
    "key": "uefa conference league",
    "label": "uefa conference league",
    "value": "uefa conference league"
  },
  {
    "key": "uefa europa league",
    "label": "uefa europa league",
    "value": "uefa europa league"
  },
  {
    "key": "uefa nations league a",
    "label": "uefa nations league a",
    "value": "uefa nations league a"
  },
  {
    "key": "uefa nations league b",
    "label": "uefa nations league b",
    "value": "uefa nations league b"
  },
  {
    "key": "uefa nations league c",
    "label": "uefa nations league c",
    "value": "uefa nations league c"
  },
  {
    "key": "uefa nations league d",
    "label": "uefa nations league d",
    "value": "uefa nations league d"
  },
  {
    "key": "uefa u17 championship qualifiers",
    "label": "uefa u17 championship qualifiers",
    "value": "uefa u17 championship qualifiers"
  },
  {
    "key": "uefa u19 championship qualifiers",
    "label": "uefa u19 championship qualifiers",
    "value": "uefa u19 championship qualifiers"
  },
  {
    "key": "uefa u21 championship 2025 qualifiers",
    "label": "uefa u21 championship 2025 qualifiers",
    "value": "uefa u21 championship 2025 qualifiers"
  },
  {
    "key": "uefa women champions league",
    "label": "uefa women champions league",
    "value": "uefa women champions league"
  },
  {
    "key": "uefa women champions league qualifiers",
    "label": "uefa women champions league qualifiers",
    "value": "uefa women champions league qualifiers"
  },
  {
    "key": "uefa youth league",
    "label": "uefa youth league",
    "value": "uefa youth league"
  },
  {
    "key": "uganda premier league",
    "label": "uganda premier league",
    "value": "uganda premier league"
  },
  {
    "key": "ukraine 1st league",
    "label": "ukraine 1st league",
    "value": "ukraine 1st league"
  },
  {
    "key": "ukraine premier league",
    "label": "ukraine premier league",
    "value": "ukraine premier league"
  },
  {
    "key": "undefined",
    "label": "undefined",
    "value": "undefined"
  },
  {
    "key": "united soccer league championship",
    "label": "united soccer league championship",
    "value": "united soccer league championship"
  },
  {
    "key": "united soccer league one",
    "label": "united soccer league one",
    "value": "united soccer league one"
  },
  {
    "key": "uruguay cup",
    "label": "uruguay cup",
    "value": "uruguay cup"
  },
  {
    "key": "uruguay primera division",
    "label": "uruguay primera division",
    "value": "uruguay primera division"
  },
  {
    "key": "uruguay reserve league",
    "label": "uruguay reserve league",
    "value": "uruguay reserve league"
  },
  {
    "key": "uruguay segunda division",
    "label": "uruguay segunda division",
    "value": "uruguay segunda division"
  },
  {
    "key": "us open cup",
    "label": "us open cup",
    "value": "us open cup"
  },
  {
    "key": "usa major league soccer",
    "label": "usa major league soccer",
    "value": "usa major league soccer"
  },
  {
    "key": "usa mls next pro",
    "label": "usa mls next pro",
    "value": "usa mls next pro"
  },
  {
    "key": "usa national independent soccer association",
    "label": "usa national independent soccer association",
    "value": "usa national independent soccer association"
  },
  {
    "key": "usa women super league",
    "label": "usa women super league",
    "value": "usa women super league"
  },
  {
    "key": "uzbekistan cup",
    "label": "uzbekistan cup",
    "value": "uzbekistan cup"
  },
  {
    "key": "uzbekistan pro league",
    "label": "uzbekistan pro league",
    "value": "uzbekistan pro league"
  },
  {
    "key": "uzbekistan super league",
    "label": "uzbekistan super league",
    "value": "uzbekistan super league"
  },
  {
    "key": "venezuela primera division",
    "label": "venezuela primera division",
    "value": "venezuela primera division"
  },
  {
    "key": "venezuela segunda division",
    "label": "venezuela segunda division",
    "value": "venezuela segunda division"
  },
  {
    "key": "vietnam v league 1",
    "label": "vietnam v league 1",
    "value": "vietnam v league 1"
  },
  {
    "key": "vietnam women championship u19",
    "label": "vietnam women championship u19",
    "value": "vietnam women championship u19"
  },
  {
    "key": "wafu a u20 tournament (in liberia)",
    "label": "wafu a u20 tournament (in liberia)",
    "value": "wafu a u20 tournament (in liberia)"
  },
  {
    "key": "western australia amateur premier division",
    "label": "western australia amateur premier division",
    "value": "western australia amateur premier division"
  },
  {
    "key": "western australia amateur premier division reserve",
    "label": "western australia amateur premier division reserve",
    "value": "western australia amateur premier division reserve"
  },
  {
    "key": "western australia national premier league (play off)",
    "label": "western australia national premier league (play off)",
    "value": "western australia national premier league (play off)"
  },
  {
    "key": "western australia national premier league u20 (play off)",
    "label": "western australia national premier league u20 (play off)",
    "value": "western australia national premier league u20 (play off)"
  },
  {
    "key": "western australia state league division 1 (play off)",
    "label": "western australia state league division 1 (play off)",
    "value": "western australia state league division 1 (play off)"
  },
  {
    "key": "western australia women league division 1",
    "label": "western australia women league division 1",
    "value": "western australia women league division 1"
  },
  {
    "key": "western australia women premier league (play off)",
    "label": "western australia women premier league (play off)",
    "value": "western australia women premier league (play off)"
  },
  {
    "key": "world cup 2026 asia qualifiers",
    "label": "world cup 2026 asia qualifiers",
    "value": "world cup 2026 asia qualifiers"
  },
  {
    "key": "world cup 2026 south america qualifiers",
    "label": "world cup 2026 south america qualifiers",
    "value": "world cup 2026 south america qualifiers"
  }
]

const league212betOptions = [
  {
    "key": "*english premier league",
    "label": "*english premier league",
    "value": "*english premier league"
  },
  {
    "key": "*english premier league - booking",
    "label": "*english premier league - booking",
    "value": "*english premier league - booking"
  },
  {
    "key": "*germany-bundesliga i",
    "label": "*germany-bundesliga i",
    "value": "*germany-bundesliga i"
  },
  {
    "key": "*italy serie a",
    "label": "*italy serie a",
    "value": "*italy serie a"
  },
  {
    "key": "*spain primera laliga",
    "label": "*spain primera laliga",
    "value": "*spain primera laliga"
  },
  {
    "key": "*uefa champions league",
    "label": "*uefa champions league",
    "value": "*uefa champions league"
  },
  {
    "key": "*uefa champions league - booking",
    "label": "*uefa champions league - booking",
    "value": "*uefa champions league - booking"
  },
  {
    "key": "*uefa conference league",
    "label": "*uefa conference league",
    "value": "*uefa conference league"
  },
  {
    "key": "*uefa europa league",
    "label": "*uefa europa league",
    "value": "*uefa europa league"
  },
  {
    "key": "*uefa europa league - booking",
    "label": "*uefa europa league - booking",
    "value": "*uefa europa league - booking"
  },
  {
    "key": "afc champions league elite",
    "label": "afc champions league elite",
    "value": "afc champions league elite"
  },
  {
    "key": "afc champions league two",
    "label": "afc champions league two",
    "value": "afc champions league two"
  },
  {
    "key": "afc u20 asian cup 2025 qualifiers",
    "label": "afc u20 asian cup 2025 qualifiers",
    "value": "afc u20 asian cup 2025 qualifiers"
  },
  {
    "key": "afc women champions league",
    "label": "afc women champions league",
    "value": "afc women champions league"
  },
  {
    "key": "africa cup of nations 2025 qualifiers",
    "label": "africa cup of nations 2025 qualifiers",
    "value": "africa cup of nations 2025 qualifiers"
  },
  {
    "key": "albania super league",
    "label": "albania super league",
    "value": "albania super league"
  },
  {
    "key": "algeria 1st division",
    "label": "algeria 1st division",
    "value": "algeria 1st division"
  },
  {
    "key": "algeria reserve league 1",
    "label": "algeria reserve league 1",
    "value": "algeria reserve league 1"
  },
  {
    "key": "argentina copa liga profesional reserve",
    "label": "argentina copa liga profesional reserve",
    "value": "argentina copa liga profesional reserve"
  },
  {
    "key": "argentina cup",
    "label": "argentina cup",
    "value": "argentina cup"
  },
  {
    "key": "argentina liga profesional",
    "label": "argentina liga profesional",
    "value": "argentina liga profesional"
  },
  {
    "key": "argentina primera b metropolitana",
    "label": "argentina primera b metropolitana",
    "value": "argentina primera b metropolitana"
  },
  {
    "key": "argentina primera b nacional",
    "label": "argentina primera b nacional",
    "value": "argentina primera b nacional"
  },
  {
    "key": "argentina primera c",
    "label": "argentina primera c",
    "value": "argentina primera c"
  },
  {
    "key": "argentina primera nacional reserve",
    "label": "argentina primera nacional reserve",
    "value": "argentina primera nacional reserve"
  },
  {
    "key": "argentina torneo federal a (play off)",
    "label": "argentina torneo federal a (play off)",
    "value": "argentina torneo federal a (play off)"
  },
  {
    "key": "argentina torneo promocional amateur",
    "label": "argentina torneo promocional amateur",
    "value": "argentina torneo promocional amateur"
  },
  {
    "key": "argentina women copa liga profesional",
    "label": "argentina women copa liga profesional",
    "value": "argentina women copa liga profesional"
  },
  {
    "key": "argentina women primera division a",
    "label": "argentina women primera division a",
    "value": "argentina women primera division a"
  },
  {
    "key": "armenia cup",
    "label": "armenia cup",
    "value": "armenia cup"
  },
  {
    "key": "armenia premier league",
    "label": "armenia premier league",
    "value": "armenia premier league"
  },
  {
    "key": "asean championship qualifiers",
    "label": "asean championship qualifiers",
    "value": "asean championship qualifiers"
  },
  {
    "key": "asean club championship",
    "label": "asean club championship",
    "value": "asean club championship"
  },
  {
    "key": "australia cup",
    "label": "australia cup",
    "value": "australia cup"
  },
  {
    "key": "australia northern territory premier league (play off)",
    "label": "australia northern territory premier league (play off)",
    "value": "australia northern territory premier league (play off)"
  },
  {
    "key": "australia northern territory womens premier league (play off)",
    "label": "australia northern territory womens premier league (play off)",
    "value": "australia northern territory womens premier league (play off)"
  },
  {
    "key": "austria 2nd league",
    "label": "austria 2nd league",
    "value": "austria 2nd league"
  },
  {
    "key": "austria bundesliga",
    "label": "austria bundesliga",
    "value": "austria bundesliga"
  },
  {
    "key": "austria cup",
    "label": "austria cup",
    "value": "austria cup"
  },
  {
    "key": "austria landesliga",
    "label": "austria landesliga",
    "value": "austria landesliga"
  },
  {
    "key": "austria regional league mitte",
    "label": "austria regional league mitte",
    "value": "austria regional league mitte"
  },
  {
    "key": "austria women bundesliga",
    "label": "austria women bundesliga",
    "value": "austria women bundesliga"
  },
  {
    "key": "azerbaijan division 1",
    "label": "azerbaijan division 1",
    "value": "azerbaijan division 1"
  },
  {
    "key": "azerbaijan premier league",
    "label": "azerbaijan premier league",
    "value": "azerbaijan premier league"
  },
  {
    "key": "bahrain 2nd division",
    "label": "bahrain 2nd division",
    "value": "bahrain 2nd division"
  },
  {
    "key": "bahrain premier league",
    "label": "bahrain premier league",
    "value": "bahrain premier league"
  },
  {
    "key": "belarus division 1",
    "label": "belarus division 1",
    "value": "belarus division 1"
  },
  {
    "key": "belarus premier league",
    "label": "belarus premier league",
    "value": "belarus premier league"
  },
  {
    "key": "belgium challenger pro league",
    "label": "belgium challenger pro league",
    "value": "belgium challenger pro league"
  },
  {
    "key": "belgium first division a",
    "label": "belgium first division a",
    "value": "belgium first division a"
  },
  {
    "key": "belgium pro league u21",
    "label": "belgium pro league u21",
    "value": "belgium pro league u21"
  },
  {
    "key": "bhutan premier league",
    "label": "bhutan premier league",
    "value": "bhutan premier league"
  },
  {
    "key": "bolivia professional football league",
    "label": "bolivia professional football league",
    "value": "bolivia professional football league"
  },
  {
    "key": "bosnia-herzegovina premier league",
    "label": "bosnia-herzegovina premier league",
    "value": "bosnia-herzegovina premier league"
  },
  {
    "key": "brazil campeonato amapaense u20",
    "label": "brazil campeonato amapaense u20",
    "value": "brazil campeonato amapaense u20"
  },
  {
    "key": "brazil campeonato aspirantes u23",
    "label": "brazil campeonato aspirantes u23",
    "value": "brazil campeonato aspirantes u23"
  },
  {
    "key": "brazil campeonato brasiliense serie b",
    "label": "brazil campeonato brasiliense serie b",
    "value": "brazil campeonato brasiliense serie b"
  },
  {
    "key": "brazil campeonato carioca serie b1",
    "label": "brazil campeonato carioca serie b1",
    "value": "brazil campeonato carioca serie b1"
  },
  {
    "key": "brazil campeonato mineiro women",
    "label": "brazil campeonato mineiro women",
    "value": "brazil campeonato mineiro women"
  },
  {
    "key": "brazil campeonato paulista women",
    "label": "brazil campeonato paulista women",
    "value": "brazil campeonato paulista women"
  },
  {
    "key": "brazil campeonato piauiense serie b",
    "label": "brazil campeonato piauiense serie b",
    "value": "brazil campeonato piauiense serie b"
  },
  {
    "key": "brazil copa paulista",
    "label": "brazil copa paulista",
    "value": "brazil copa paulista"
  },
  {
    "key": "brazil cup",
    "label": "brazil cup",
    "value": "brazil cup"
  },
  {
    "key": "brazil cup u20",
    "label": "brazil cup u20",
    "value": "brazil cup u20"
  },
  {
    "key": "brazil gaucho cup",
    "label": "brazil gaucho cup",
    "value": "brazil gaucho cup"
  },
  {
    "key": "brazil santa catarina cup",
    "label": "brazil santa catarina cup",
    "value": "brazil santa catarina cup"
  },
  {
    "key": "brazil serie a",
    "label": "brazil serie a",
    "value": "brazil serie a"
  },
  {
    "key": "brazil serie b",
    "label": "brazil serie b",
    "value": "brazil serie b"
  },
  {
    "key": "bulgaria second professional league",
    "label": "bulgaria second professional league",
    "value": "bulgaria second professional league"
  },
  {
    "key": "caf super cup",
    "label": "caf super cup",
    "value": "caf super cup"
  },
  {
    "key": "cambodian premier league",
    "label": "cambodian premier league",
    "value": "cambodian premier league"
  },
  {
    "key": "canada soccer league",
    "label": "canada soccer league",
    "value": "canada soccer league"
  },
  {
    "key": "canadian championship",
    "label": "canadian championship",
    "value": "canadian championship"
  },
  {
    "key": "canadian premier league",
    "label": "canadian premier league",
    "value": "canadian premier league"
  },
  {
    "key": "cecafa u20 championship (in tanzania)",
    "label": "cecafa u20 championship (in tanzania)",
    "value": "cecafa u20 championship (in tanzania)"
  },
  {
    "key": "chile primera b",
    "label": "chile primera b",
    "value": "chile primera b"
  },
  {
    "key": "chile primera division",
    "label": "chile primera division",
    "value": "chile primera division"
  },
  {
    "key": "chile segunda division",
    "label": "chile segunda division",
    "value": "chile segunda division"
  },
  {
    "key": "chile women primera b (play off)",
    "label": "chile women primera b (play off)",
    "value": "chile women primera b (play off)"
  },
  {
    "key": "china fa cup",
    "label": "china fa cup",
    "value": "china fa cup"
  },
  {
    "key": "china football super league",
    "label": "china football super league",
    "value": "china football super league"
  },
  {
    "key": "china league 1",
    "label": "china league 1",
    "value": "china league 1"
  },
  {
    "key": "china league 2 (play off)",
    "label": "china league 2 (play off)",
    "value": "china league 2 (play off)"
  },
  {
    "key": "china league u21",
    "label": "china league u21",
    "value": "china league u21"
  },
  {
    "key": "china women super league",
    "label": "china women super league",
    "value": "china women super league"
  },
  {
    "key": "club friendly",
    "label": "club friendly",
    "value": "club friendly"
  },
  {
    "key": "club friendly women",
    "label": "club friendly women",
    "value": "club friendly women"
  },
  {
    "key": "colombia cup",
    "label": "colombia cup",
    "value": "colombia cup"
  },
  {
    "key": "colombia primera a",
    "label": "colombia primera a",
    "value": "colombia primera a"
  },
  {
    "key": "colombia primera b",
    "label": "colombia primera b",
    "value": "colombia primera b"
  },
  {
    "key": "concacaf caribbean cup",
    "label": "concacaf caribbean cup",
    "value": "concacaf caribbean cup"
  },
  {
    "key": "concacaf central american cup",
    "label": "concacaf central american cup",
    "value": "concacaf central american cup"
  },
  {
    "key": "concacaf women champions cup",
    "label": "concacaf women champions cup",
    "value": "concacaf women champions cup"
  },
  {
    "key": "copa libertadores",
    "label": "copa libertadores",
    "value": "copa libertadores"
  },
  {
    "key": "copa libertadores women (in paraguay)",
    "label": "copa libertadores women (in paraguay)",
    "value": "copa libertadores women (in paraguay)"
  },
  {
    "key": "copa sudamericana",
    "label": "copa sudamericana",
    "value": "copa sudamericana"
  },
  {
    "key": "cosafa u20 championship (in mozambique)",
    "label": "cosafa u20 championship (in mozambique)",
    "value": "cosafa u20 championship (in mozambique)"
  },
  {
    "key": "costa rica primera division",
    "label": "costa rica primera division",
    "value": "costa rica primera division"
  },
  {
    "key": "costa rica women primera division",
    "label": "costa rica women primera division",
    "value": "costa rica women primera division"
  },
  {
    "key": "croatia first league",
    "label": "croatia first league",
    "value": "croatia first league"
  },
  {
    "key": "croatia hnl league",
    "label": "croatia hnl league",
    "value": "croatia hnl league"
  },
  {
    "key": "croatia second league",
    "label": "croatia second league",
    "value": "croatia second league"
  },
  {
    "key": "cyprus 1st division",
    "label": "cyprus 1st division",
    "value": "cyprus 1st division"
  },
  {
    "key": "cyprus 2nd division",
    "label": "cyprus 2nd division",
    "value": "cyprus 2nd division"
  },
  {
    "key": "cyprus cup",
    "label": "cyprus cup",
    "value": "cyprus cup"
  },
  {
    "key": "cyprus super cup",
    "label": "cyprus super cup",
    "value": "cyprus super cup"
  },
  {
    "key": "czech republic 1st division",
    "label": "czech republic 1st division",
    "value": "czech republic 1st division"
  },
  {
    "key": "czech republic 3rd division cfl",
    "label": "czech republic 3rd division cfl",
    "value": "czech republic 3rd division cfl"
  },
  {
    "key": "czech republic cup",
    "label": "czech republic cup",
    "value": "czech republic cup"
  },
  {
    "key": "czech republic national football league",
    "label": "czech republic national football league",
    "value": "czech republic national football league"
  },
  {
    "key": "czech republic u19 league",
    "label": "czech republic u19 league",
    "value": "czech republic u19 league"
  },
  {
    "key": "denmark 1st division",
    "label": "denmark 1st division",
    "value": "denmark 1st division"
  },
  {
    "key": "denmark 2nd division",
    "label": "denmark 2nd division",
    "value": "denmark 2nd division"
  },
  {
    "key": "denmark cup",
    "label": "denmark cup",
    "value": "denmark cup"
  },
  {
    "key": "denmark future cup",
    "label": "denmark future cup",
    "value": "denmark future cup"
  },
  {
    "key": "denmark super league",
    "label": "denmark super league",
    "value": "denmark super league"
  },
  {
    "key": "ecuador cup",
    "label": "ecuador cup",
    "value": "ecuador cup"
  },
  {
    "key": "ecuador serie a",
    "label": "ecuador serie a",
    "value": "ecuador serie a"
  },
  {
    "key": "ecuador serie b",
    "label": "ecuador serie b",
    "value": "ecuador serie b"
  },
  {
    "key": "egypt second division a",
    "label": "egypt second division a",
    "value": "egypt second division a"
  },
  {
    "key": "el salvador primera division",
    "label": "el salvador primera division",
    "value": "el salvador primera division"
  },
  {
    "key": "el salvador segunda division",
    "label": "el salvador segunda division",
    "value": "el salvador segunda division"
  },
  {
    "key": "english central reserve league cup",
    "label": "english central reserve league cup",
    "value": "english central reserve league cup"
  },
  {
    "key": "english fa cup qualifiers",
    "label": "english fa cup qualifiers",
    "value": "english fa cup qualifiers"
  },
  {
    "key": "english fa women league cup",
    "label": "english fa women league cup",
    "value": "english fa women league cup"
  },
  {
    "key": "english football league trophy",
    "label": "english football league trophy",
    "value": "english football league trophy"
  },
  {
    "key": "english isthmian league division one north",
    "label": "english isthmian league division one north",
    "value": "english isthmian league division one north"
  },
  {
    "key": "english isthmian league premier division",
    "label": "english isthmian league premier division",
    "value": "english isthmian league premier division"
  },
  {
    "key": "english league championship",
    "label": "english league championship",
    "value": "english league championship"
  },
  {
    "key": "english league cup",
    "label": "english league cup",
    "value": "english league cup"
  },
  {
    "key": "english league one",
    "label": "english league one",
    "value": "english league one"
  },
  {
    "key": "english league two",
    "label": "english league two",
    "value": "english league two"
  },
  {
    "key": "english manchester premier cup",
    "label": "english manchester premier cup",
    "value": "english manchester premier cup"
  },
  {
    "key": "english national league",
    "label": "english national league",
    "value": "english national league"
  },
  {
    "key": "english national league cup",
    "label": "english national league cup",
    "value": "english national league cup"
  },
  {
    "key": "english national league south",
    "label": "english national league south",
    "value": "english national league south"
  },
  {
    "key": "english northern premier division",
    "label": "english northern premier division",
    "value": "english northern premier division"
  },
  {
    "key": "english premier league 2 u21",
    "label": "english premier league 2 u21",
    "value": "english premier league 2 u21"
  },
  {
    "key": "english premier league cup u21",
    "label": "english premier league cup u21",
    "value": "english premier league cup u21"
  },
  {
    "key": "english professional development league u21",
    "label": "english professional development league u21",
    "value": "english professional development league u21"
  },
  {
    "key": "english southern premier division south",
    "label": "english southern premier division south",
    "value": "english southern premier division south"
  },
  {
    "key": "english women super league",
    "label": "english women super league",
    "value": "english women super league"
  },
  {
    "key": "estonia esiliiga",
    "label": "estonia esiliiga",
    "value": "estonia esiliiga"
  },
  {
    "key": "estonia esiliiga b",
    "label": "estonia esiliiga b",
    "value": "estonia esiliiga b"
  },
  {
    "key": "estonia meistriliiga",
    "label": "estonia meistriliiga",
    "value": "estonia meistriliiga"
  },
  {
    "key": "ethiopia premier league",
    "label": "ethiopia premier league",
    "value": "ethiopia premier league"
  },
  {
    "key": "finland kolmonen",
    "label": "finland kolmonen",
    "value": "finland kolmonen"
  },
  {
    "key": "finland veikkausliiga (play off)",
    "label": "finland veikkausliiga (play off)",
    "value": "finland veikkausliiga (play off)"
  },
  {
    "key": "finland women national league (play off)",
    "label": "finland women national league (play off)",
    "value": "finland women national league (play off)"
  },
  {
    "key": "finland ykkonen (play off)",
    "label": "finland ykkonen (play off)",
    "value": "finland ykkonen (play off)"
  },
  {
    "key": "finland ykkosliiga",
    "label": "finland ykkosliiga",
    "value": "finland ykkosliiga"
  },
  {
    "key": "four nations tournament (in croatia)",
    "label": "four nations tournament (in croatia)",
    "value": "four nations tournament (in croatia)"
  },
  {
    "key": "four nations tournament (in romania)",
    "label": "four nations tournament (in romania)",
    "value": "four nations tournament (in romania)"
  },
  {
    "key": "four nations tournament u18 (in portugal)",
    "label": "four nations tournament u18 (in portugal)",
    "value": "four nations tournament u18 (in portugal)"
  },
  {
    "key": "france ligue 1",
    "label": "france ligue 1",
    "value": "france ligue 1"
  },
  {
    "key": "france ligue 2",
    "label": "france ligue 2",
    "value": "france ligue 2"
  },
  {
    "key": "france national",
    "label": "france national",
    "value": "france national"
  },
  {
    "key": "futsal world cup 2024 (in uzbekistan)",
    "label": "futsal world cup 2024 (in uzbekistan)",
    "value": "futsal world cup 2024 (in uzbekistan)"
  },
  {
    "key": "georgia erovnuli liga",
    "label": "georgia erovnuli liga",
    "value": "georgia erovnuli liga"
  },
  {
    "key": "georgia erovnuli liga 2",
    "label": "georgia erovnuli liga 2",
    "value": "georgia erovnuli liga 2"
  },
  {
    "key": "germany 3rd liga",
    "label": "germany 3rd liga",
    "value": "germany 3rd liga"
  },
  {
    "key": "germany bundesliga 2",
    "label": "germany bundesliga 2",
    "value": "germany bundesliga 2"
  },
  {
    "key": "germany regional league north",
    "label": "germany regional league north",
    "value": "germany regional league north"
  },
  {
    "key": "germany regional league northeast",
    "label": "germany regional league northeast",
    "value": "germany regional league northeast"
  },
  {
    "key": "germany regional league west",
    "label": "germany regional league west",
    "value": "germany regional league west"
  },
  {
    "key": "germany women bundesliga",
    "label": "germany women bundesliga",
    "value": "germany women bundesliga"
  },
  {
    "key": "germany youth league u19 qualifiers",
    "label": "germany youth league u19 qualifiers",
    "value": "germany youth league u19 qualifiers"
  },
  {
    "key": "greece cup",
    "label": "greece cup",
    "value": "greece cup"
  },
  {
    "key": "greece super league 1",
    "label": "greece super league 1",
    "value": "greece super league 1"
  },
  {
    "key": "honduras liga nacional",
    "label": "honduras liga nacional",
    "value": "honduras liga nacional"
  },
  {
    "key": "hong kong 1st division",
    "label": "hong kong 1st division",
    "value": "hong kong 1st division"
  },
  {
    "key": "hong kong 2nd division",
    "label": "hong kong 2nd division",
    "value": "hong kong 2nd division"
  },
  {
    "key": "hong kong premier league",
    "label": "hong kong premier league",
    "value": "hong kong premier league"
  },
  {
    "key": "hungary nb i",
    "label": "hungary nb i",
    "value": "hungary nb i"
  },
  {
    "key": "iceland 1st division (play off)",
    "label": "iceland 1st division (play off)",
    "value": "iceland 1st division (play off)"
  },
  {
    "key": "iceland premier league (play off)",
    "label": "iceland premier league (play off)",
    "value": "iceland premier league (play off)"
  },
  {
    "key": "iceland u19 league a",
    "label": "iceland u19 league a",
    "value": "iceland u19 league a"
  },
  {
    "key": "iceland u19 league b",
    "label": "iceland u19 league b",
    "value": "iceland u19 league b"
  },
  {
    "key": "india bangalore super division",
    "label": "india bangalore super division",
    "value": "india bangalore super division"
  },
  {
    "key": "india delhi premier league",
    "label": "india delhi premier league",
    "value": "india delhi premier league"
  },
  {
    "key": "india goa professional league",
    "label": "india goa professional league",
    "value": "india goa professional league"
  },
  {
    "key": "india mizoram premier league",
    "label": "india mizoram premier league",
    "value": "india mizoram premier league"
  },
  {
    "key": "india mizoram premier league (play off)",
    "label": "india mizoram premier league (play off)",
    "value": "india mizoram premier league (play off)"
  },
  {
    "key": "india puttaiah memorial cup",
    "label": "india puttaiah memorial cup",
    "value": "india puttaiah memorial cup"
  },
  {
    "key": "india sfa a division s-league",
    "label": "india sfa a division s-league",
    "value": "india sfa a division s-league"
  },
  {
    "key": "indian super league",
    "label": "indian super league",
    "value": "indian super league"
  },
  {
    "key": "indonesia liga 1",
    "label": "indonesia liga 1",
    "value": "indonesia liga 1"
  },
  {
    "key": "indonesia liga 2",
    "label": "indonesia liga 2",
    "value": "indonesia liga 2"
  },
  {
    "key": "intercontinental cup",
    "label": "intercontinental cup",
    "value": "intercontinental cup"
  },
  {
    "key": "international friendly",
    "label": "international friendly",
    "value": "international friendly"
  },
  {
    "key": "international friendly u18",
    "label": "international friendly u18",
    "value": "international friendly u18"
  },
  {
    "key": "international friendly u19",
    "label": "international friendly u19",
    "value": "international friendly u19"
  },
  {
    "key": "international friendly u20",
    "label": "international friendly u20",
    "value": "international friendly u20"
  },
  {
    "key": "international friendly women u19",
    "label": "international friendly women u19",
    "value": "international friendly women u19"
  },
  {
    "key": "international women football cup (in vietnam)",
    "label": "international women football cup (in vietnam)",
    "value": "international women football cup (in vietnam)"
  },
  {
    "key": "iran azadegan league",
    "label": "iran azadegan league",
    "value": "iran azadegan league"
  },
  {
    "key": "iran pro league",
    "label": "iran pro league",
    "value": "iran pro league"
  },
  {
    "key": "iraq stars league",
    "label": "iraq stars league",
    "value": "iraq stars league"
  },
  {
    "key": "ireland 1st division",
    "label": "ireland 1st division",
    "value": "ireland 1st division"
  },
  {
    "key": "ireland fai cup",
    "label": "ireland fai cup",
    "value": "ireland fai cup"
  },
  {
    "key": "ireland premier division",
    "label": "ireland premier division",
    "value": "ireland premier division"
  },
  {
    "key": "israel leumit liga",
    "label": "israel leumit liga",
    "value": "israel leumit liga"
  },
  {
    "key": "israel premier league",
    "label": "israel premier league",
    "value": "israel premier league"
  },
  {
    "key": "israel toto cup leumit",
    "label": "israel toto cup leumit",
    "value": "israel toto cup leumit"
  },
  {
    "key": "israel women premier league",
    "label": "israel women premier league",
    "value": "israel women premier league"
  },
  {
    "key": "italy campionato primavera 1 u20",
    "label": "italy campionato primavera 1 u20",
    "value": "italy campionato primavera 1 u20"
  },
  {
    "key": "italy cup",
    "label": "italy cup",
    "value": "italy cup"
  },
  {
    "key": "italy serie b",
    "label": "italy serie b",
    "value": "italy serie b"
  },
  {
    "key": "italy serie c",
    "label": "italy serie c",
    "value": "italy serie c"
  },
  {
    "key": "italy serie d",
    "label": "italy serie d",
    "value": "italy serie d"
  },
  {
    "key": "italy women serie a",
    "label": "italy women serie a",
    "value": "italy women serie a"
  },
  {
    "key": "jamaica premier league",
    "label": "jamaica premier league",
    "value": "jamaica premier league"
  },
  {
    "key": "japan emperor cup",
    "label": "japan emperor cup",
    "value": "japan emperor cup"
  },
  {
    "key": "japan j-league division 1",
    "label": "japan j-league division 1",
    "value": "japan j-league division 1"
  },
  {
    "key": "japan j-league division 2",
    "label": "japan j-league division 2",
    "value": "japan j-league division 2"
  },
  {
    "key": "japan j-league division 3",
    "label": "japan j-league division 3",
    "value": "japan j-league division 3"
  },
  {
    "key": "japan league cup",
    "label": "japan league cup",
    "value": "japan league cup"
  },
  {
    "key": "japan regional football league",
    "label": "japan regional football league",
    "value": "japan regional football league"
  },
  {
    "key": "japan women empowerment league",
    "label": "japan women empowerment league",
    "value": "japan women empowerment league"
  },
  {
    "key": "japan women empowerment league cup",
    "label": "japan women empowerment league cup",
    "value": "japan women empowerment league cup"
  },
  {
    "key": "jordan 1st division",
    "label": "jordan 1st division",
    "value": "jordan 1st division"
  },
  {
    "key": "jordan pro league",
    "label": "jordan pro league",
    "value": "jordan pro league"
  },
  {
    "key": "jordan shield cup",
    "label": "jordan shield cup",
    "value": "jordan shield cup"
  },
  {
    "key": "kazakhstan 1st division",
    "label": "kazakhstan 1st division",
    "value": "kazakhstan 1st division"
  },
  {
    "key": "kazakhstan league cup",
    "label": "kazakhstan league cup",
    "value": "kazakhstan league cup"
  },
  {
    "key": "kazakhstan premier league",
    "label": "kazakhstan premier league",
    "value": "kazakhstan premier league"
  },
  {
    "key": "korea k league 1",
    "label": "korea k league 1",
    "value": "korea k league 1"
  },
  {
    "key": "korea k league 2",
    "label": "korea k league 2",
    "value": "korea k league 2"
  },
  {
    "key": "korea k3 league",
    "label": "korea k3 league",
    "value": "korea k3 league"
  },
  {
    "key": "korea women k-league",
    "label": "korea women k-league",
    "value": "korea women k-league"
  },
  {
    "key": "kosovo super liga",
    "label": "kosovo super liga",
    "value": "kosovo super liga"
  },
  {
    "key": "kuwait premier league",
    "label": "kuwait premier league",
    "value": "kuwait premier league"
  },
  {
    "key": "latvia cup",
    "label": "latvia cup",
    "value": "latvia cup"
  },
  {
    "key": "lithuania a lyga",
    "label": "lithuania a lyga",
    "value": "lithuania a lyga"
  },
  {
    "key": "malaysia mfl cup",
    "label": "malaysia mfl cup",
    "value": "malaysia mfl cup"
  },
  {
    "key": "malaysia super league",
    "label": "malaysia super league",
    "value": "malaysia super league"
  },
  {
    "key": "mexico league u23",
    "label": "mexico league u23",
    "value": "mexico league u23"
  },
  {
    "key": "mexico liga de expansion",
    "label": "mexico liga de expansion",
    "value": "mexico liga de expansion"
  },
  {
    "key": "mexico liga premier serie a",
    "label": "mexico liga premier serie a",
    "value": "mexico liga premier serie a"
  },
  {
    "key": "mexico liga premier serie b",
    "label": "mexico liga premier serie b",
    "value": "mexico liga premier serie b"
  },
  {
    "key": "mexico primera division",
    "label": "mexico primera division",
    "value": "mexico primera division"
  },
  {
    "key": "mexico women primera division",
    "label": "mexico women primera division",
    "value": "mexico women primera division"
  },
  {
    "key": "moldova liga 1",
    "label": "moldova liga 1",
    "value": "moldova liga 1"
  },
  {
    "key": "moldova super liga",
    "label": "moldova super liga",
    "value": "moldova super liga"
  },
  {
    "key": "morocco botola 2",
    "label": "morocco botola 2",
    "value": "morocco botola 2"
  },
  {
    "key": "morocco botola pro",
    "label": "morocco botola pro",
    "value": "morocco botola pro"
  },
  {
    "key": "morocco excellence cup",
    "label": "morocco excellence cup",
    "value": "morocco excellence cup"
  },
  {
    "key": "myanmar national league",
    "label": "myanmar national league",
    "value": "myanmar national league"
  },
  {
    "key": "myanmar national league u20",
    "label": "myanmar national league u20",
    "value": "myanmar national league u20"
  },
  {
    "key": "nafu campeones cup",
    "label": "nafu campeones cup",
    "value": "nafu campeones cup"
  },
  {
    "key": "netherlands eerste divisie",
    "label": "netherlands eerste divisie",
    "value": "netherlands eerste divisie"
  },
  {
    "key": "netherlands eredivisie",
    "label": "netherlands eredivisie",
    "value": "netherlands eredivisie"
  },
  {
    "key": "netherlands eredivisie u19",
    "label": "netherlands eredivisie u19",
    "value": "netherlands eredivisie u19"
  },
  {
    "key": "new zealand national league championship",
    "label": "new zealand national league championship",
    "value": "new zealand national league championship"
  },
  {
    "key": "new zealand women national league championship",
    "label": "new zealand women national league championship",
    "value": "new zealand women national league championship"
  },
  {
    "key": "nigeria professional football league",
    "label": "nigeria professional football league",
    "value": "nigeria professional football league"
  },
  {
    "key": "north macedonia 1st league",
    "label": "north macedonia 1st league",
    "value": "north macedonia 1st league"
  },
  {
    "key": "north macedonia cup",
    "label": "north macedonia cup",
    "value": "north macedonia cup"
  },
  {
    "key": "norway 1st division",
    "label": "norway 1st division",
    "value": "norway 1st division"
  },
  {
    "key": "norway 2nd division",
    "label": "norway 2nd division",
    "value": "norway 2nd division"
  },
  {
    "key": "norway 3rd division",
    "label": "norway 3rd division",
    "value": "norway 3rd division"
  },
  {
    "key": "norway cup",
    "label": "norway cup",
    "value": "norway cup"
  },
  {
    "key": "norway eliteserien",
    "label": "norway eliteserien",
    "value": "norway eliteserien"
  },
  {
    "key": "norway national champions league u19 (play off)",
    "label": "norway national champions league u19 (play off)",
    "value": "norway national champions league u19 (play off)"
  },
  {
    "key": "norway u19 cup",
    "label": "norway u19 cup",
    "value": "norway u19 cup"
  },
  {
    "key": "norway u19 interkrets a",
    "label": "norway u19 interkrets a",
    "value": "norway u19 interkrets a"
  },
  {
    "key": "oman federation cup",
    "label": "oman federation cup",
    "value": "oman federation cup"
  },
  {
    "key": "oman professional league",
    "label": "oman professional league",
    "value": "oman professional league"
  },
  {
    "key": "paraguay cup",
    "label": "paraguay cup",
    "value": "paraguay cup"
  },
  {
    "key": "paraguay primera division",
    "label": "paraguay primera division",
    "value": "paraguay primera division"
  },
  {
    "key": "paraguay primera division b",
    "label": "paraguay primera division b",
    "value": "paraguay primera division b"
  },
  {
    "key": "paraguay primera division reserve league",
    "label": "paraguay primera division reserve league",
    "value": "paraguay primera division reserve league"
  },
  {
    "key": "peru liga 1",
    "label": "peru liga 1",
    "value": "peru liga 1"
  },
  {
    "key": "peru liga 2 (play off)",
    "label": "peru liga 2 (play off)",
    "value": "peru liga 2 (play off)"
  },
  {
    "key": "poland 1st division",
    "label": "poland 1st division",
    "value": "poland 1st division"
  },
  {
    "key": "poland 2nd division",
    "label": "poland 2nd division",
    "value": "poland 2nd division"
  },
  {
    "key": "poland 3rd division",
    "label": "poland 3rd division",
    "value": "poland 3rd division"
  },
  {
    "key": "poland cup",
    "label": "poland cup",
    "value": "poland cup"
  },
  {
    "key": "poland ekstraklasa",
    "label": "poland ekstraklasa",
    "value": "poland ekstraklasa"
  },
  {
    "key": "portugal championship u19 division 1",
    "label": "portugal championship u19 division 1",
    "value": "portugal championship u19 division 1"
  },
  {
    "key": "portugal cup",
    "label": "portugal cup",
    "value": "portugal cup"
  },
  {
    "key": "portugal liga 2",
    "label": "portugal liga 2",
    "value": "portugal liga 2"
  },
  {
    "key": "portugal liga 3",
    "label": "portugal liga 3",
    "value": "portugal liga 3"
  },
  {
    "key": "portugal liga revelacao u23",
    "label": "portugal liga revelacao u23",
    "value": "portugal liga revelacao u23"
  },
  {
    "key": "portugal primeira liga",
    "label": "portugal primeira liga",
    "value": "portugal primeira liga"
  },
  {
    "key": "premier league international cup (in england)",
    "label": "premier league international cup (in england)",
    "value": "premier league international cup (in england)"
  },
  {
    "key": "qatar olympic league u21",
    "label": "qatar olympic league u21",
    "value": "qatar olympic league u21"
  },
  {
    "key": "qatar stars league",
    "label": "qatar stars league",
    "value": "qatar stars league"
  },
  {
    "key": "qatar stars league cup",
    "label": "qatar stars league cup",
    "value": "qatar stars league cup"
  },
  {
    "key": "romania 2nd division",
    "label": "romania 2nd division",
    "value": "romania 2nd division"
  },
  {
    "key": "romania liga i",
    "label": "romania liga i",
    "value": "romania liga i"
  },
  {
    "key": "russia cup",
    "label": "russia cup",
    "value": "russia cup"
  },
  {
    "key": "russia cup qualifiers",
    "label": "russia cup qualifiers",
    "value": "russia cup qualifiers"
  },
  {
    "key": "russia first league",
    "label": "russia first league",
    "value": "russia first league"
  },
  {
    "key": "russia premier league",
    "label": "russia premier league",
    "value": "russia premier league"
  },
  {
    "key": "russia second league division a",
    "label": "russia second league division a",
    "value": "russia second league division a"
  },
  {
    "key": "russia youth football league u19 (play off)",
    "label": "russia youth football league u19 (play off)",
    "value": "russia youth football league u19 (play off)"
  },
  {
    "key": "saudi 1st division",
    "label": "saudi 1st division",
    "value": "saudi 1st division"
  },
  {
    "key": "saudi 2nd division",
    "label": "saudi 2nd division",
    "value": "saudi 2nd division"
  },
  {
    "key": "saudi king cup",
    "label": "saudi king cup",
    "value": "saudi king cup"
  },
  {
    "key": "saudi pro league",
    "label": "saudi pro league",
    "value": "saudi pro league"
  },
  {
    "key": "scotland championship",
    "label": "scotland championship",
    "value": "scotland championship"
  },
  {
    "key": "scotland league cup",
    "label": "scotland league cup",
    "value": "scotland league cup"
  },
  {
    "key": "scotland premiership",
    "label": "scotland premiership",
    "value": "scotland premiership"
  },
  {
    "key": "serbia super league",
    "label": "serbia super league",
    "value": "serbia super league"
  },
  {
    "key": "slovakia cup",
    "label": "slovakia cup",
    "value": "slovakia cup"
  },
  {
    "key": "slovakia super league",
    "label": "slovakia super league",
    "value": "slovakia super league"
  },
  {
    "key": "slovenia cup",
    "label": "slovenia cup",
    "value": "slovenia cup"
  },
  {
    "key": "slovenia prva liga",
    "label": "slovenia prva liga",
    "value": "slovenia prva liga"
  },
  {
    "key": "slovenia women league",
    "label": "slovenia women league",
    "value": "slovenia women league"
  },
  {
    "key": "south africa premiership",
    "label": "south africa premiership",
    "value": "south africa premiership"
  },
  {
    "key": "spain copa federacion",
    "label": "spain copa federacion",
    "value": "spain copa federacion"
  },
  {
    "key": "spain division de honor u19",
    "label": "spain division de honor u19",
    "value": "spain division de honor u19"
  },
  {
    "key": "spain primera federacion",
    "label": "spain primera federacion",
    "value": "spain primera federacion"
  },
  {
    "key": "spain queen cup",
    "label": "spain queen cup",
    "value": "spain queen cup"
  },
  {
    "key": "spain segunda division",
    "label": "spain segunda division",
    "value": "spain segunda division"
  },
  {
    "key": "spain segunda federacion",
    "label": "spain segunda federacion",
    "value": "spain segunda federacion"
  },
  {
    "key": "spain tercera federacion",
    "label": "spain tercera federacion",
    "value": "spain tercera federacion"
  },
  {
    "key": "spain women primera division",
    "label": "spain women primera division",
    "value": "spain women primera division"
  },
  {
    "key": "spain women primera federacion",
    "label": "spain women primera federacion",
    "value": "spain women primera federacion"
  },
  {
    "key": "spain women segunda federacion",
    "label": "spain women segunda federacion",
    "value": "spain women segunda federacion"
  },
  {
    "key": "sweden 2nd division",
    "label": "sweden 2nd division",
    "value": "sweden 2nd division"
  },
  {
    "key": "sweden allsvenskan",
    "label": "sweden allsvenskan",
    "value": "sweden allsvenskan"
  },
  {
    "key": "sweden cup",
    "label": "sweden cup",
    "value": "sweden cup"
  },
  {
    "key": "sweden ettan north",
    "label": "sweden ettan north",
    "value": "sweden ettan north"
  },
  {
    "key": "sweden ettan south",
    "label": "sweden ettan south",
    "value": "sweden ettan south"
  },
  {
    "key": "sweden league cup elite u21",
    "label": "sweden league cup elite u21",
    "value": "sweden league cup elite u21"
  },
  {
    "key": "sweden superettan",
    "label": "sweden superettan",
    "value": "sweden superettan"
  },
  {
    "key": "sweden women cup",
    "label": "sweden women cup",
    "value": "sweden women cup"
  },
  {
    "key": "switzerland challenge league",
    "label": "switzerland challenge league",
    "value": "switzerland challenge league"
  },
  {
    "key": "switzerland super league",
    "label": "switzerland super league",
    "value": "switzerland super league"
  },
  {
    "key": "taiwan football premier league",
    "label": "taiwan football premier league",
    "value": "taiwan football premier league"
  },
  {
    "key": "tanzania premier league",
    "label": "tanzania premier league",
    "value": "tanzania premier league"
  },
  {
    "key": "thailand league 1",
    "label": "thailand league 1",
    "value": "thailand league 1"
  },
  {
    "key": "thailand league 2",
    "label": "thailand league 2",
    "value": "thailand league 2"
  },
  {
    "key": "turkey cup",
    "label": "turkey cup",
    "value": "turkey cup"
  },
  {
    "key": "turkey elite league b u19",
    "label": "turkey elite league b u19",
    "value": "turkey elite league b u19"
  },
  {
    "key": "turkey first league",
    "label": "turkey first league",
    "value": "turkey first league"
  },
  {
    "key": "turkey second league",
    "label": "turkey second league",
    "value": "turkey second league"
  },
  {
    "key": "turkey super league",
    "label": "turkey super league",
    "value": "turkey super league"
  },
  {
    "key": "u20 women world cup 2024 (in colombia)",
    "label": "u20 women world cup 2024 (in colombia)",
    "value": "u20 women world cup 2024 (in colombia)"
  },
  {
    "key": "uae 1st division",
    "label": "uae 1st division",
    "value": "uae 1st division"
  },
  {
    "key": "uae presidents cup",
    "label": "uae presidents cup",
    "value": "uae presidents cup"
  },
  {
    "key": "uae pro league",
    "label": "uae pro league",
    "value": "uae pro league"
  },
  {
    "key": "uae pro league u23",
    "label": "uae pro league u23",
    "value": "uae pro league u23"
  },
  {
    "key": "uae youth league u21",
    "label": "uae youth league u21",
    "value": "uae youth league u21"
  },
  {
    "key": "uefa nations league c",
    "label": "uefa nations league c",
    "value": "uefa nations league c"
  },
  {
    "key": "uefa nations league c - booking",
    "label": "uefa nations league c - booking",
    "value": "uefa nations league c - booking"
  },
  {
    "key": "uefa nations league d",
    "label": "uefa nations league d",
    "value": "uefa nations league d"
  },
  {
    "key": "uefa u17 championship qualifiers",
    "label": "uefa u17 championship qualifiers",
    "value": "uefa u17 championship qualifiers"
  },
  {
    "key": "uefa u19 championship qualifiers",
    "label": "uefa u19 championship qualifiers",
    "value": "uefa u19 championship qualifiers"
  },
  {
    "key": "uefa u21 championship 2025 qualifiers",
    "label": "uefa u21 championship 2025 qualifiers",
    "value": "uefa u21 championship 2025 qualifiers"
  },
  {
    "key": "uefa women champions league",
    "label": "uefa women champions league",
    "value": "uefa women champions league"
  },
  {
    "key": "uefa women champions league qualifiers",
    "label": "uefa women champions league qualifiers",
    "value": "uefa women champions league qualifiers"
  },
  {
    "key": "uefa youth league",
    "label": "uefa youth league",
    "value": "uefa youth league"
  },
  {
    "key": "ukraine 1st league",
    "label": "ukraine 1st league",
    "value": "ukraine 1st league"
  },
  {
    "key": "ukraine premier league",
    "label": "ukraine premier league",
    "value": "ukraine premier league"
  },
  {
    "key": "united soccer league championship",
    "label": "united soccer league championship",
    "value": "united soccer league championship"
  },
  {
    "key": "uruguay cup",
    "label": "uruguay cup",
    "value": "uruguay cup"
  },
  {
    "key": "uruguay primera division",
    "label": "uruguay primera division",
    "value": "uruguay primera division"
  },
  {
    "key": "uruguay segunda division",
    "label": "uruguay segunda division",
    "value": "uruguay segunda division"
  },
  {
    "key": "us open cup",
    "label": "us open cup",
    "value": "us open cup"
  },
  {
    "key": "usa major league soccer",
    "label": "usa major league soccer",
    "value": "usa major league soccer"
  },
  {
    "key": "usa mls next pro",
    "label": "usa mls next pro",
    "value": "usa mls next pro"
  },
  {
    "key": "uzbekistan cup",
    "label": "uzbekistan cup",
    "value": "uzbekistan cup"
  },
  {
    "key": "uzbekistan pro league",
    "label": "uzbekistan pro league",
    "value": "uzbekistan pro league"
  },
  {
    "key": "uzbekistan super league",
    "label": "uzbekistan super league",
    "value": "uzbekistan super league"
  },
  {
    "key": "venezuela primera division",
    "label": "venezuela primera division",
    "value": "venezuela primera division"
  },
  {
    "key": "vietnam v league 1",
    "label": "vietnam v league 1",
    "value": "vietnam v league 1"
  },
  {
    "key": "vietnam women championship u19",
    "label": "vietnam women championship u19",
    "value": "vietnam women championship u19"
  },
  {
    "key": "wafu a u20 tournament (in liberia)",
    "label": "wafu a u20 tournament (in liberia)",
    "value": "wafu a u20 tournament (in liberia)"
  },
  {
    "key": "western australia amateur premier division",
    "label": "western australia amateur premier division",
    "value": "western australia amateur premier division"
  },
  {
    "key": "western australia state league division 1 (play off)",
    "label": "western australia state league division 1 (play off)",
    "value": "western australia state league division 1 (play off)"
  },
  {
    "key": "world cup 2026 asia qualifiers",
    "label": "world cup 2026 asia qualifiers",
    "value": "world cup 2026 asia qualifiers"
  },
  {
    "key": "world cup 2026 oceania qualifiers",
    "label": "world cup 2026 oceania qualifiers",
    "value": "world cup 2026 oceania qualifiers"
  }
]

const leagueBet365starOptions = [
  {
    "key": "argentina primera b",
    "label": "argentina primera b",
    "value": "argentina primera b"
  },
  {
    "key": "argentina primera nacional",
    "label": "argentina primera nacional",
    "value": "argentina primera nacional"
  },
  {
    "key": "bolivia liga femenina",
    "label": "bolivia liga femenina",
    "value": "bolivia liga femenina"
  },
  {
    "key": "brazil brasileiro serie b",
    "label": "brazil brasileiro serie b",
    "value": "brazil brasileiro serie b"
  },
  {
    "key": "brazil brasileiro serie c",
    "label": "brazil brasileiro serie c",
    "value": "brazil brasileiro serie c"
  },
  {
    "key": "brazil cearense, serie c",
    "label": "brazil cearense, serie c",
    "value": "brazil cearense, serie c"
  },
  {
    "key": "brazil gaucho, serie a2",
    "label": "brazil gaucho, serie a2",
    "value": "brazil gaucho, serie a2"
  },
  {
    "key": "brazil pernambucano, serie a2",
    "label": "brazil pernambucano, serie a2",
    "value": "brazil pernambucano, serie a2"
  },
  {
    "key": "brazil u20 pernambucano",
    "label": "brazil u20 pernambucano",
    "value": "brazil u20 pernambucano"
  },
  {
    "key": "brazil u20 piauiense",
    "label": "brazil u20 piauiense",
    "value": "brazil u20 piauiense"
  },
  {
    "key": "bulgaria parva liga",
    "label": "bulgaria parva liga",
    "value": "bulgaria parva liga"
  },
  {
    "key": "bulgaria second prof. league",
    "label": "bulgaria second prof. league",
    "value": "bulgaria second prof. league"
  },
  {
    "key": "chile segunda division",
    "label": "chile segunda division",
    "value": "chile segunda division"
  },
  {
    "key": "chile ליגה שנייה",
    "label": "chile ליגה שנייה",
    "value": "chile ליגה שנייה"
  },
  {
    "key": "colombia primera a, clausura",
    "label": "colombia primera a, clausura",
    "value": "colombia primera a, clausura"
  },
  {
    "key": "colombia primera b",
    "label": "colombia primera b",
    "value": "colombia primera b"
  },
  {
    "key": "denmark superliga",
    "label": "denmark superliga",
    "value": "denmark superliga"
  },
  {
    "key": "egypt premier league",
    "label": "egypt premier league",
    "value": "egypt premier league"
  },
  {
    "key": "iceland 5 deild",
    "label": "iceland 5 deild",
    "value": "iceland 5 deild"
  },
  {
    "key": "iceland besta deild",
    "label": "iceland besta deild",
    "value": "iceland besta deild"
  },
  {
    "key": "international clubs club friendly games",
    "label": "international clubs club friendly games",
    "value": "international clubs club friendly games"
  },
  {
    "key": "international youth u19 european championship",
    "label": "international youth u19 european championship",
    "value": "international youth u19 european championship"
  },
  {
    "key": "international youth u20 concacaf championship",
    "label": "international youth u20 concacaf championship",
    "value": "international youth u20 concacaf championship"
  },
  {
    "key": "mexico liga mx, women, apertura",
    "label": "mexico liga mx, women, apertura",
    "value": "mexico liga mx, women, apertura"
  },
  {
    "key": "northern ireland county antrim shield challenge cup, women",
    "label": "northern ireland county antrim shield challenge cup, women",
    "value": "northern ireland county antrim shield challenge cup, women"
  },
  {
    "key": "norway 3rd division group 4",
    "label": "norway 3rd division group 4",
    "value": "norway 3rd division group 4"
  },
  {
    "key": "norway 3rd division group 5",
    "label": "norway 3rd division group 5",
    "value": "norway 3rd division group 5"
  },
  {
    "key": "panama liga panamena de futbol, clausura",
    "label": "panama liga panamena de futbol, clausura",
    "value": "panama liga panamena de futbol, clausura"
  },
  {
    "key": "paraguay primera division b (apf)",
    "label": "paraguay primera division b (apf)",
    "value": "paraguay primera division b (apf)"
  },
  {
    "key": "paraguay segunda division",
    "label": "paraguay segunda division",
    "value": "paraguay segunda division"
  },
  {
    "key": "paraguay torneo femenino",
    "label": "paraguay torneo femenino",
    "value": "paraguay torneo femenino"
  },
  {
    "key": "peru liga 2",
    "label": "peru liga 2",
    "value": "peru liga 2"
  },
  {
    "key": "poland ekstraklasa",
    "label": "poland ekstraklasa",
    "value": "poland ekstraklasa"
  },
  {
    "key": "poland i liga",
    "label": "poland i liga",
    "value": "poland i liga"
  },
  {
    "key": "romania superliga",
    "label": "romania superliga",
    "value": "romania superliga"
  },
  {
    "key": "russia premier league",
    "label": "russia premier league",
    "value": "russia premier league"
  },
  {
    "key": "sweden allsvenskan",
    "label": "sweden allsvenskan",
    "value": "sweden allsvenskan"
  },
  {
    "key": "sweden superettan",
    "label": "sweden superettan",
    "value": "sweden superettan"
  },
  {
    "key": "uruguay segunda division",
    "label": "uruguay segunda division",
    "value": "uruguay segunda division"
  },
  {
    "key": "uruguay tercera division, reserves",
    "label": "uruguay tercera division, reserves",
    "value": "uruguay tercera division, reserves"
  },
  {
    "key": "venezuela primera division",
    "label": "venezuela primera division",
    "value": "venezuela primera division"
  }
]

const leagueRabet777Options = [
  {
    "key": "1 lyga",
    "label": "1 lyga",
    "value": "1 lyga"
  },
  {
    "key": "1. deild",
    "label": "1. deild",
    "value": "1. deild"
  },
  {
    "key": "1. deild - women",
    "label": "1. deild - women",
    "value": "1. deild - women"
  },
  {
    "key": "1. hnl",
    "label": "1. hnl",
    "value": "1. hnl"
  },
  {
    "key": "1. liga classic",
    "label": "1. liga classic",
    "value": "1. liga classic"
  },
  {
    "key": "1. liga promotion",
    "label": "1. liga promotion",
    "value": "1. liga promotion"
  },
  {
    "key": "1.hnlz - women",
    "label": "1.hnlz - women",
    "value": "1.hnlz - women"
  },
  {
    "key": "1.liga",
    "label": "1.liga",
    "value": "1.liga"
  },
  {
    "key": "1.liga - women",
    "label": "1.liga - women",
    "value": "1.liga - women"
  },
  {
    "key": "1.sml",
    "label": "1.sml",
    "value": "1.sml"
  },
  {
    "key": "1.snl",
    "label": "1.snl",
    "value": "1.snl"
  },
  {
    "key": "1st division",
    "label": "1st division",
    "value": "1st division"
  },
  {
    "key": "1st division - women",
    "label": "1st division - women",
    "value": "1st division - women"
  },
  {
    "key": "1st league",
    "label": "1st league",
    "value": "1st league"
  },
  {
    "key": "2. bundesliga",
    "label": "2. bundesliga",
    "value": "2. bundesliga"
  },
  {
    "key": "2. bundesliga - women",
    "label": "2. bundesliga - women",
    "value": "2. bundesliga - women"
  },
  {
    "key": "2. deild",
    "label": "2. deild",
    "value": "2. deild"
  },
  {
    "key": "2. hnl",
    "label": "2. hnl",
    "value": "2. hnl"
  },
  {
    "key": "2. landesliga",
    "label": "2. landesliga",
    "value": "2. landesliga"
  },
  {
    "key": "2. liga",
    "label": "2. liga",
    "value": "2. liga"
  },
  {
    "key": "2. liga interregional",
    "label": "2. liga interregional",
    "value": "2. liga interregional"
  },
  {
    "key": "2.liga",
    "label": "2.liga",
    "value": "2.liga"
  },
  {
    "key": "2.liga - women",
    "label": "2.liga - women",
    "value": "2.liga - women"
  },
  {
    "key": "2.snl",
    "label": "2.snl",
    "value": "2.snl"
  },
  {
    "key": "2nd division",
    "label": "2nd division",
    "value": "2nd division"
  },
  {
    "key": "2nd division - women",
    "label": "2nd division - women",
    "value": "2nd division - women"
  },
  {
    "key": "3. deild",
    "label": "3. deild",
    "value": "3. deild"
  },
  {
    "key": "3. hnl",
    "label": "3. hnl",
    "value": "3. hnl"
  },
  {
    "key": "3. liga",
    "label": "3. liga",
    "value": "3. liga"
  },
  {
    "key": "3.liga",
    "label": "3.liga",
    "value": "3.liga"
  },
  {
    "key": "3.snl",
    "label": "3.snl",
    "value": "3.snl"
  },
  {
    "key": "3rd division",
    "label": "3rd division",
    "value": "3rd division"
  },
  {
    "key": "4. deild",
    "label": "4. deild",
    "value": "4. deild"
  },
  {
    "key": "4.liga",
    "label": "4.liga",
    "value": "4.liga"
  },
  {
    "key": "4th division",
    "label": "4th division",
    "value": "4th division"
  },
  {
    "key": "5. deild",
    "label": "5. deild",
    "value": "5. deild"
  },
  {
    "key": "5.liga",
    "label": "5.liga",
    "value": "5.liga"
  },
  {
    "key": "a lyga",
    "label": "a lyga",
    "value": "a lyga"
  },
  {
    "key": "a-league",
    "label": "a-league",
    "value": "a-league"
  },
  {
    "key": "afl cup",
    "label": "afl cup",
    "value": "afl cup"
  },
  {
    "key": "albanian cup",
    "label": "albanian cup",
    "value": "albanian cup"
  },
  {
    "key": "allsvenskan",
    "label": "allsvenskan",
    "value": "allsvenskan"
  },
  {
    "key": "amateur cup",
    "label": "amateur cup",
    "value": "amateur cup"
  },
  {
    "key": "armenian premier league",
    "label": "armenian premier league",
    "value": "armenian premier league"
  },
  {
    "key": "australia cup",
    "label": "australia cup",
    "value": "australia cup"
  },
  {
    "key": "azadegan league",
    "label": "azadegan league",
    "value": "azadegan league"
  },
  {
    "key": "baltic league - women",
    "label": "baltic league - women",
    "value": "baltic league - women"
  },
  {
    "key": "bhausaheb bandodkar memorial trophy",
    "label": "bhausaheb bandodkar memorial trophy",
    "value": "bhausaheb bandodkar memorial trophy"
  },
  {
    "key": "bundesliga",
    "label": "bundesliga",
    "value": "bundesliga"
  },
  {
    "key": "bundesliga - cards and corners",
    "label": "bundesliga - cards and corners",
    "value": "bundesliga - cards and corners"
  },
  {
    "key": "bundesliga - women",
    "label": "bundesliga - women",
    "value": "bundesliga - women"
  },
  {
    "key": "caf champions league",
    "label": "caf champions league",
    "value": "caf champions league"
  },
  {
    "key": "caf confederation cup",
    "label": "caf confederation cup",
    "value": "caf confederation cup"
  },
  {
    "key": "campeonato amapaense - women",
    "label": "campeonato amapaense - women",
    "value": "campeonato amapaense - women"
  },
  {
    "key": "campeonato brasileiro a1 - women",
    "label": "campeonato brasileiro a1 - women",
    "value": "campeonato brasileiro a1 - women"
  },
  {
    "key": "campeonato brasileiro a2 - women",
    "label": "campeonato brasileiro a2 - women",
    "value": "campeonato brasileiro a2 - women"
  },
  {
    "key": "campeonato brasileiro a3 - women",
    "label": "campeonato brasileiro a3 - women",
    "value": "campeonato brasileiro a3 - women"
  },
  {
    "key": "campeonato carioca a",
    "label": "campeonato carioca a",
    "value": "campeonato carioca a"
  },
  {
    "key": "campeonato carioca c",
    "label": "campeonato carioca c",
    "value": "campeonato carioca c"
  },
  {
    "key": "campeonato de portugal",
    "label": "campeonato de portugal",
    "value": "campeonato de portugal"
  },
  {
    "key": "campeonato mineiro 2.division",
    "label": "campeonato mineiro 2.division",
    "value": "campeonato mineiro 2.division"
  },
  {
    "key": "campeonato paranaense 2.division",
    "label": "campeonato paranaense 2.division",
    "value": "campeonato paranaense 2.division"
  },
  {
    "key": "campeonato paulista segunda division",
    "label": "campeonato paulista segunda division",
    "value": "campeonato paulista segunda division"
  },
  {
    "key": "campeonato roraimense",
    "label": "campeonato roraimense",
    "value": "campeonato roraimense"
  },
  {
    "key": "campionato primavera 1",
    "label": "campionato primavera 1",
    "value": "campionato primavera 1"
  },
  {
    "key": "campionato primavera 2",
    "label": "campionato primavera 2",
    "value": "campionato primavera 2"
  },
  {
    "key": "campionato primavera 3",
    "label": "campionato primavera 3",
    "value": "campionato primavera 3"
  },
  {
    "key": "campionato sammarinese",
    "label": "campionato sammarinese",
    "value": "campionato sammarinese"
  },
  {
    "key": "canadian premier league",
    "label": "canadian premier league",
    "value": "canadian premier league"
  },
  {
    "key": "capital premier league",
    "label": "capital premier league",
    "value": "capital premier league"
  },
  {
    "key": "central junior league",
    "label": "central junior league",
    "value": "central junior league"
  },
  {
    "key": "central league",
    "label": "central league",
    "value": "central league"
  },
  {
    "key": "challenge league",
    "label": "challenge league",
    "value": "challenge league"
  },
  {
    "key": "championship",
    "label": "championship",
    "value": "championship"
  },
  {
    "key": "championship - women",
    "label": "championship - women",
    "value": "championship - women"
  },
  {
    "key": "china super league srl",
    "label": "china super league srl",
    "value": "china super league srl"
  },
  {
    "key": "club friendlies - women",
    "label": "club friendlies - women",
    "value": "club friendlies - women"
  },
  {
    "key": "copa argentina",
    "label": "copa argentina",
    "value": "copa argentina"
  },
  {
    "key": "copa colombia",
    "label": "copa colombia",
    "value": "copa colombia"
  },
  {
    "key": "copa de campeones juvenil",
    "label": "copa de campeones juvenil",
    "value": "copa de campeones juvenil"
  },
  {
    "key": "copa de la reina - women",
    "label": "copa de la reina - women",
    "value": "copa de la reina - women"
  },
  {
    "key": "copa espirito santo",
    "label": "copa espirito santo",
    "value": "copa espirito santo"
  },
  {
    "key": "copa libertadores",
    "label": "copa libertadores",
    "value": "copa libertadores"
  },
  {
    "key": "copa libertadores srl",
    "label": "copa libertadores srl",
    "value": "copa libertadores srl"
  },
  {
    "key": "copa nacional de selecciones",
    "label": "copa nacional de selecciones",
    "value": "copa nacional de selecciones"
  },
  {
    "key": "copa santa fe",
    "label": "copa santa fe",
    "value": "copa santa fe"
  },
  {
    "key": "copa sudamericana",
    "label": "copa sudamericana",
    "value": "copa sudamericana"
  },
  {
    "key": "coppa italia",
    "label": "coppa italia",
    "value": "coppa italia"
  },
  {
    "key": "coppa italia - cards and corners",
    "label": "coppa italia - cards and corners",
    "value": "coppa italia - cards and corners"
  },
  {
    "key": "cup",
    "label": "cup",
    "value": "cup"
  },
  {
    "key": "cup - women",
    "label": "cup - women",
    "value": "cup - women"
  },
  {
    "key": "cup of amateurs",
    "label": "cup of amateurs",
    "value": "cup of amateurs"
  },
  {
    "key": "cymru premier",
    "label": "cymru premier",
    "value": "cymru premier"
  },
  {
    "key": "cypriot cup",
    "label": "cypriot cup",
    "value": "cypriot cup"
  },
  {
    "key": "damallsvenskan",
    "label": "damallsvenskan",
    "value": "damallsvenskan"
  },
  {
    "key": "danmarksserien",
    "label": "danmarksserien",
    "value": "danmarksserien"
  },
  {
    "key": "division 1",
    "label": "division 1",
    "value": "division 1"
  },
  {
    "key": "division 1 - women",
    "label": "division 1 - women",
    "value": "division 1 - women"
  },
  {
    "key": "division 2 - women",
    "label": "division 2 - women",
    "value": "division 2 - women"
  },
  {
    "key": "division intermedia",
    "label": "division intermedia",
    "value": "division intermedia"
  },
  {
    "key": "eerste divisie",
    "label": "eerste divisie",
    "value": "eerste divisie"
  },
  {
    "key": "ekstraklasa",
    "label": "ekstraklasa",
    "value": "ekstraklasa"
  },
  {
    "key": "eliteserien",
    "label": "eliteserien",
    "value": "eliteserien"
  },
  {
    "key": "elitettan - women",
    "label": "elitettan - women",
    "value": "elitettan - women"
  },
  {
    "key": "emir cup",
    "label": "emir cup",
    "value": "emir cup"
  },
  {
    "key": "england premier league srl",
    "label": "england premier league srl",
    "value": "england premier league srl"
  },
  {
    "key": "eredivisie",
    "label": "eredivisie",
    "value": "eredivisie"
  },
  {
    "key": "eredivisie cup - women",
    "label": "eredivisie cup - women",
    "value": "eredivisie cup - women"
  },
  {
    "key": "erovnuli liga",
    "label": "erovnuli liga",
    "value": "erovnuli liga"
  },
  {
    "key": "erovnuli liga 2",
    "label": "erovnuli liga 2",
    "value": "erovnuli liga 2"
  },
  {
    "key": "esiliiga",
    "label": "esiliiga",
    "value": "esiliiga"
  },
  {
    "key": "esiliiga b",
    "label": "esiliiga b",
    "value": "esiliiga b"
  },
  {
    "key": "estonian cup - women",
    "label": "estonian cup - women",
    "value": "estonian cup - women"
  },
  {
    "key": "fa cup",
    "label": "fa cup",
    "value": "fa cup"
  },
  {
    "key": "fa wsl",
    "label": "fa wsl",
    "value": "fa wsl"
  },
  {
    "key": "fai cup",
    "label": "fai cup",
    "value": "fai cup"
  },
  {
    "key": "federation cup",
    "label": "federation cup",
    "value": "federation cup"
  },
  {
    "key": "first division",
    "label": "first division",
    "value": "first division"
  },
  {
    "key": "first league",
    "label": "first league",
    "value": "first league"
  },
  {
    "key": "first league federation bih",
    "label": "first league federation bih",
    "value": "first league federation bih"
  },
  {
    "key": "first league republika srpska",
    "label": "first league republika srpska",
    "value": "first league republika srpska"
  },
  {
    "key": "fnl",
    "label": "fnl",
    "value": "fnl"
  },
  {
    "key": "fnl first league",
    "label": "fnl first league",
    "value": "fnl first league"
  },
  {
    "key": "fnl second league division a",
    "label": "fnl second league division a",
    "value": "fnl second league division a"
  },
  {
    "key": "fnl second league division b",
    "label": "fnl second league division b",
    "value": "fnl second league division b"
  },
  {
    "key": "fnsw one",
    "label": "fnsw one",
    "value": "fnsw one"
  },
  {
    "key": "fnsw two",
    "label": "fnsw two",
    "value": "fnsw two"
  },
  {
    "key": "football league - women",
    "label": "football league - women",
    "value": "football league - women"
  },
  {
    "key": "fufa big league",
    "label": "fufa big league",
    "value": "fufa big league"
  },
  {
    "key": "fufa premier league",
    "label": "fufa premier league",
    "value": "fufa premier league"
  },
  {
    "key": "georgian cup",
    "label": "georgian cup",
    "value": "georgian cup"
  },
  {
    "key": "girabola",
    "label": "girabola",
    "value": "girabola"
  },
  {
    "key": "greek cup - women",
    "label": "greek cup - women",
    "value": "greek cup - women"
  },
  {
    "key": "hkfa henderson sapling cup",
    "label": "hkfa henderson sapling cup",
    "value": "hkfa henderson sapling cup"
  },
  {
    "key": "hnl",
    "label": "hnl",
    "value": "hnl"
  },
  {
    "key": "hungarian cup",
    "label": "hungarian cup",
    "value": "hungarian cup"
  },
  {
    "key": "i liga",
    "label": "i liga",
    "value": "i liga"
  },
  {
    "key": "icelandic cup",
    "label": "icelandic cup",
    "value": "icelandic cup"
  },
  {
    "key": "icelandic cup - women",
    "label": "icelandic cup - women",
    "value": "icelandic cup - women"
  },
  {
    "key": "ii liga",
    "label": "ii liga",
    "value": "ii liga"
  },
  {
    "key": "ii liiga",
    "label": "ii liiga",
    "value": "ii liiga"
  },
  {
    "key": "ii lyga",
    "label": "ii lyga",
    "value": "ii lyga"
  },
  {
    "key": "iii liga - group i",
    "label": "iii liga - group i",
    "value": "iii liga - group i"
  },
  {
    "key": "iii liga - group ii",
    "label": "iii liga - group ii",
    "value": "iii liga - group ii"
  },
  {
    "key": "iii liga - group iii",
    "label": "iii liga - group iii",
    "value": "iii liga - group iii"
  },
  {
    "key": "iii liga - group iv",
    "label": "iii liga - group iv",
    "value": "iii liga - group iv"
  },
  {
    "key": "iv liga",
    "label": "iv liga",
    "value": "iv liga"
  },
  {
    "key": "j1 league",
    "label": "j1 league",
    "value": "j1 league"
  },
  {
    "key": "j2 league",
    "label": "j2 league",
    "value": "j2 league"
  },
  {
    "key": "j3 league",
    "label": "j3 league",
    "value": "j3 league"
  },
  {
    "key": "jfl",
    "label": "jfl",
    "value": "jfl"
  },
  {
    "key": "k league 1",
    "label": "k league 1",
    "value": "k league 1"
  },
  {
    "key": "k league 1 srl",
    "label": "k league 1 srl",
    "value": "k league 1 srl"
  },
  {
    "key": "k league 2",
    "label": "k league 2",
    "value": "k league 2"
  },
  {
    "key": "k3 league",
    "label": "k3 league",
    "value": "k3 league"
  },
  {
    "key": "k4 league",
    "label": "k4 league",
    "value": "k4 league"
  },
  {
    "key": "kakkonen",
    "label": "kakkonen",
    "value": "kakkonen"
  },
  {
    "key": "kansallinen liiga - women",
    "label": "kansallinen liiga - women",
    "value": "kansallinen liiga - women"
  },
  {
    "key": "kolmonen",
    "label": "kolmonen",
    "value": "kolmonen"
  },
  {
    "key": "la liga",
    "label": "la liga",
    "value": "la liga"
  },
  {
    "key": "la liga - cards and corners",
    "label": "la liga - cards and corners",
    "value": "la liga - cards and corners"
  },
  {
    "key": "la liga - fouls and shots on goal",
    "label": "la liga - fouls and shots on goal",
    "value": "la liga - fouls and shots on goal"
  },
  {
    "key": "laliga srl",
    "label": "laliga srl",
    "value": "laliga srl"
  },
  {
    "key": "landesliga",
    "label": "landesliga",
    "value": "landesliga"
  },
  {
    "key": "latvian cup",
    "label": "latvian cup",
    "value": "latvian cup"
  },
  {
    "key": "ldf",
    "label": "ldf",
    "value": "ldf"
  },
  {
    "key": "league 1 british columbia",
    "label": "league 1 british columbia",
    "value": "league 1 british columbia"
  },
  {
    "key": "league 2",
    "label": "league 2",
    "value": "league 2"
  },
  {
    "key": "league cup",
    "label": "league cup",
    "value": "league cup"
  },
  {
    "key": "league one",
    "label": "league one",
    "value": "league one"
  },
  {
    "key": "league two",
    "label": "league two",
    "value": "league two"
  },
  {
    "key": "league1 alberta",
    "label": "league1 alberta",
    "value": "league1 alberta"
  },
  {
    "key": "leinster senior league",
    "label": "leinster senior league",
    "value": "leinster senior league"
  },
  {
    "key": "lff - women",
    "label": "lff - women",
    "value": "lff - women"
  },
  {
    "key": "lfp - women",
    "label": "lfp - women",
    "value": "lfp - women"
  },
  {
    "key": "lfpb",
    "label": "lfpb",
    "value": "lfpb"
  },
  {
    "key": "liga 1",
    "label": "liga 1",
    "value": "liga 1"
  },
  {
    "key": "liga 1 - women",
    "label": "liga 1 - women",
    "value": "liga 1 - women"
  },
  {
    "key": "liga 2",
    "label": "liga 2",
    "value": "liga 2"
  },
  {
    "key": "liga 3",
    "label": "liga 3",
    "value": "liga 3"
  },
  {
    "key": "liga 4",
    "label": "liga 4",
    "value": "liga 4"
  },
  {
    "key": "liga alef",
    "label": "liga alef",
    "value": "liga alef"
  },
  {
    "key": "liga antioquena",
    "label": "liga antioquena",
    "value": "liga antioquena"
  },
  {
    "key": "liga ascenso",
    "label": "liga ascenso",
    "value": "liga ascenso"
  },
  {
    "key": "liga bet",
    "label": "liga bet",
    "value": "liga bet"
  },
  {
    "key": "liga de elite",
    "label": "liga de elite",
    "value": "liga de elite"
  },
  {
    "key": "liga de expansion mx",
    "label": "liga de expansion mx",
    "value": "liga de expansion mx"
  },
  {
    "key": "liga leumit",
    "label": "liga leumit",
    "value": "liga leumit"
  },
  {
    "key": "liga mx",
    "label": "liga mx",
    "value": "liga mx"
  },
  {
    "key": "liga mx - women",
    "label": "liga mx - women",
    "value": "liga mx - women"
  },
  {
    "key": "liga nacional",
    "label": "liga nacional",
    "value": "liga nacional"
  },
  {
    "key": "liga nacional b",
    "label": "liga nacional b",
    "value": "liga nacional b"
  },
  {
    "key": "liga nacional reserves",
    "label": "liga nacional reserves",
    "value": "liga nacional reserves"
  },
  {
    "key": "liga premier",
    "label": "liga premier",
    "value": "liga premier"
  },
  {
    "key": "liga primera division",
    "label": "liga primera division",
    "value": "liga primera division"
  },
  {
    "key": "liga pro",
    "label": "liga pro",
    "value": "liga pro"
  },
  {
    "key": "liga profesional",
    "label": "liga profesional",
    "value": "liga profesional"
  },
  {
    "key": "liga prom",
    "label": "liga prom",
    "value": "liga prom"
  },
  {
    "key": "liga tdp",
    "label": "liga tdp",
    "value": "liga tdp"
  },
  {
    "key": "ligue 1",
    "label": "ligue 1",
    "value": "ligue 1"
  },
  {
    "key": "ligue 1 quebec",
    "label": "ligue 1 quebec",
    "value": "ligue 1 quebec"
  },
  {
    "key": "ligue 1 srl",
    "label": "ligue 1 srl",
    "value": "ligue 1 srl"
  },
  {
    "key": "ligue 1. cards and corners",
    "label": "ligue 1. cards and corners",
    "value": "ligue 1. cards and corners"
  },
  {
    "key": "ligue 2",
    "label": "ligue 2",
    "value": "ligue 2"
  },
  {
    "key": "lpf",
    "label": "lpf",
    "value": "lpf"
  },
  {
    "key": "m-liga",
    "label": "m-liga",
    "value": "m-liga"
  },
  {
    "key": "meistriliiga - women",
    "label": "meistriliiga - women",
    "value": "meistriliiga - women"
  },
  {
    "key": "mizoram premier league",
    "label": "mizoram premier league",
    "value": "mizoram premier league"
  },
  {
    "key": "mls",
    "label": "mls",
    "value": "mls"
  },
  {
    "key": "mls next pro",
    "label": "mls next pro",
    "value": "mls next pro"
  },
  {
    "key": "moroccan cup",
    "label": "moroccan cup",
    "value": "moroccan cup"
  },
  {
    "key": "mulan league - women",
    "label": "mulan league - women",
    "value": "mulan league - women"
  },
  {
    "key": "munster senior league",
    "label": "munster senior league",
    "value": "munster senior league"
  },
  {
    "key": "myanmar league - women",
    "label": "myanmar league - women",
    "value": "myanmar league - women"
  },
  {
    "key": "nadeshiko league division 1 - women",
    "label": "nadeshiko league division 1 - women",
    "value": "nadeshiko league division 1 - women"
  },
  {
    "key": "nadeshiko league division 2 - women",
    "label": "nadeshiko league division 2 - women",
    "value": "nadeshiko league division 2 - women"
  },
  {
    "key": "national",
    "label": "national",
    "value": "national"
  },
  {
    "key": "national 2",
    "label": "national 2",
    "value": "national 2"
  },
  {
    "key": "national 3",
    "label": "national 3",
    "value": "national 3"
  },
  {
    "key": "national championship - women",
    "label": "national championship - women",
    "value": "national championship - women"
  },
  {
    "key": "national division",
    "label": "national division",
    "value": "national division"
  },
  {
    "key": "national first division",
    "label": "national first division",
    "value": "national first division"
  },
  {
    "key": "national league - women",
    "label": "national league - women",
    "value": "national league - women"
  },
  {
    "key": "national premier league",
    "label": "national premier league",
    "value": "national premier league"
  },
  {
    "key": "nb i",
    "label": "nb i",
    "value": "nb i"
  },
  {
    "key": "nb ii",
    "label": "nb ii",
    "value": "nb ii"
  },
  {
    "key": "nb iii",
    "label": "nb iii",
    "value": "nb iii"
  },
  {
    "key": "nike liga",
    "label": "nike liga",
    "value": "nike liga"
  },
  {
    "key": "nisa",
    "label": "nisa",
    "value": "nisa"
  },
  {
    "key": "niwfa championship - women",
    "label": "niwfa championship - women",
    "value": "niwfa championship - women"
  },
  {
    "key": "northern league",
    "label": "northern league",
    "value": "northern league"
  },
  {
    "key": "northern nsw state league one",
    "label": "northern nsw state league one",
    "value": "northern nsw state league one"
  },
  {
    "key": "northern nsw state league one reserves",
    "label": "northern nsw state league one reserves",
    "value": "northern nsw state league one reserves"
  },
  {
    "key": "northern territory premier league - women",
    "label": "northern territory premier league - women",
    "value": "northern territory premier league - women"
  },
  {
    "key": "npl capital territory",
    "label": "npl capital territory",
    "value": "npl capital territory"
  },
  {
    "key": "npl capital territory - women",
    "label": "npl capital territory - women",
    "value": "npl capital territory - women"
  },
  {
    "key": "npl northern nsw",
    "label": "npl northern nsw",
    "value": "npl northern nsw"
  },
  {
    "key": "npl northern nsw - women",
    "label": "npl northern nsw - women",
    "value": "npl northern nsw - women"
  },
  {
    "key": "npl northern nsw reserves",
    "label": "npl northern nsw reserves",
    "value": "npl northern nsw reserves"
  },
  {
    "key": "npl nsw",
    "label": "npl nsw",
    "value": "npl nsw"
  },
  {
    "key": "npl nsw - women",
    "label": "npl nsw - women",
    "value": "npl nsw - women"
  },
  {
    "key": "npl queensland",
    "label": "npl queensland",
    "value": "npl queensland"
  },
  {
    "key": "npl queensland - women",
    "label": "npl queensland - women",
    "value": "npl queensland - women"
  },
  {
    "key": "npl sa state league 1",
    "label": "npl sa state league 1",
    "value": "npl sa state league 1"
  },
  {
    "key": "npl sa state league 1 - women",
    "label": "npl sa state league 1 - women",
    "value": "npl sa state league 1 - women"
  },
  {
    "key": "npl sa state league 2",
    "label": "npl sa state league 2",
    "value": "npl sa state league 2"
  },
  {
    "key": "npl south australia",
    "label": "npl south australia",
    "value": "npl south australia"
  },
  {
    "key": "npl south australia - women",
    "label": "npl south australia - women",
    "value": "npl south australia - women"
  },
  {
    "key": "npl south australia reserves",
    "label": "npl south australia reserves",
    "value": "npl south australia reserves"
  },
  {
    "key": "npl south australia reserves - women",
    "label": "npl south australia reserves - women",
    "value": "npl south australia reserves - women"
  },
  {
    "key": "npl victoria",
    "label": "npl victoria",
    "value": "npl victoria"
  },
  {
    "key": "npl victoria - women",
    "label": "npl victoria - women",
    "value": "npl victoria - women"
  },
  {
    "key": "npl western australia",
    "label": "npl western australia",
    "value": "npl western australia"
  },
  {
    "key": "npl western australia - women",
    "label": "npl western australia - women",
    "value": "npl western australia - women"
  },
  {
    "key": "npsl",
    "label": "npsl",
    "value": "npsl"
  },
  {
    "key": "nwsl - women",
    "label": "nwsl - women",
    "value": "nwsl - women"
  },
  {
    "key": "oberliga",
    "label": "oberliga",
    "value": "oberliga"
  },
  {
    "key": "ofb bundesliga - women",
    "label": "ofb bundesliga - women",
    "value": "ofb bundesliga - women"
  },
  {
    "key": "olympic league",
    "label": "olympic league",
    "value": "olympic league"
  },
  {
    "key": "ontario premier division",
    "label": "ontario premier division",
    "value": "ontario premier division"
  },
  {
    "key": "pfl",
    "label": "pfl",
    "value": "pfl"
  },
  {
    "key": "polish cup - women",
    "label": "polish cup - women",
    "value": "polish cup - women"
  },
  {
    "key": "premier division",
    "label": "premier division",
    "value": "premier division"
  },
  {
    "key": "premier league",
    "label": "premier league",
    "value": "premier league"
  },
  {
    "key": "premier league - cards and corners",
    "label": "premier league - cards and corners",
    "value": "premier league - cards and corners"
  },
  {
    "key": "premier league - fouls and shots on goal",
    "label": "premier league - fouls and shots on goal",
    "value": "premier league - fouls and shots on goal"
  },
  {
    "key": "premier league - women",
    "label": "premier league - women",
    "value": "premier league - women"
  },
  {
    "key": "premier league 2",
    "label": "premier league 2",
    "value": "premier league 2"
  },
  {
    "key": "premier league cup",
    "label": "premier league cup",
    "value": "premier league cup"
  },
  {
    "key": "premier league international cup",
    "label": "premier league international cup",
    "value": "premier league international cup"
  },
  {
    "key": "premier reserve league",
    "label": "premier reserve league",
    "value": "premier reserve league"
  },
  {
    "key": "premiera division",
    "label": "premiera division",
    "value": "premiera division"
  },
  {
    "key": "premiere division",
    "label": "premiere division",
    "value": "premiere division"
  },
  {
    "key": "premium liiga",
    "label": "premium liiga",
    "value": "premium liiga"
  },
  {
    "key": "president cup",
    "label": "president cup",
    "value": "president cup"
  },
  {
    "key": "president's cup",
    "label": "president's cup",
    "value": "president's cup"
  },
  {
    "key": "primeira liga",
    "label": "primeira liga",
    "value": "primeira liga"
  },
  {
    "key": "primera a",
    "label": "primera a",
    "value": "primera a"
  },
  {
    "key": "primera b",
    "label": "primera b",
    "value": "primera b"
  },
  {
    "key": "primera c",
    "label": "primera c",
    "value": "primera c"
  },
  {
    "key": "primera division",
    "label": "primera division",
    "value": "primera division"
  },
  {
    "key": "primera division - women",
    "label": "primera division - women",
    "value": "primera division - women"
  },
  {
    "key": "primera federacion",
    "label": "primera federacion",
    "value": "primera federacion"
  },
  {
    "key": "primera federacion - women",
    "label": "primera federacion - women",
    "value": "primera federacion - women"
  },
  {
    "key": "primera nacional",
    "label": "primera nacional",
    "value": "primera nacional"
  },
  {
    "key": "pro league",
    "label": "pro league",
    "value": "pro league"
  },
  {
    "key": "professional development league",
    "label": "professional development league",
    "value": "professional development league"
  },
  {
    "key": "professional league",
    "label": "professional league",
    "value": "professional league"
  },
  {
    "key": "professional league cup",
    "label": "professional league cup",
    "value": "professional league cup"
  },
  {
    "key": "prsl",
    "label": "prsl",
    "value": "prsl"
  },
  {
    "key": "queensland premier league - women",
    "label": "queensland premier league - women",
    "value": "queensland premier league - women"
  },
  {
    "key": "queensland premier league 1",
    "label": "queensland premier league 1",
    "value": "queensland premier league 1"
  },
  {
    "key": "queensland premier league 2",
    "label": "queensland premier league 2",
    "value": "queensland premier league 2"
  },
  {
    "key": "queensland premier league 3",
    "label": "queensland premier league 3",
    "value": "queensland premier league 3"
  },
  {
    "key": "r-league",
    "label": "r-league",
    "value": "r-league"
  },
  {
    "key": "regional cup",
    "label": "regional cup",
    "value": "regional cup"
  },
  {
    "key": "regional league",
    "label": "regional league",
    "value": "regional league"
  },
  {
    "key": "regional league - women",
    "label": "regional league - women",
    "value": "regional league - women"
  },
  {
    "key": "regional league central",
    "label": "regional league central",
    "value": "regional league central"
  },
  {
    "key": "regional league east",
    "label": "regional league east",
    "value": "regional league east"
  },
  {
    "key": "regional liga",
    "label": "regional liga",
    "value": "regional liga"
  },
  {
    "key": "regionalliga bayern",
    "label": "regionalliga bayern",
    "value": "regionalliga bayern"
  },
  {
    "key": "regionalliga north",
    "label": "regionalliga north",
    "value": "regionalliga north"
  },
  {
    "key": "regionalliga north east",
    "label": "regionalliga north east",
    "value": "regionalliga north east"
  },
  {
    "key": "regionalliga south west",
    "label": "regionalliga south west",
    "value": "regionalliga south west"
  },
  {
    "key": "regionalliga west",
    "label": "regionalliga west",
    "value": "regionalliga west"
  },
  {
    "key": "reserve league",
    "label": "reserve league",
    "value": "reserve league"
  },
  {
    "key": "reserve league cup",
    "label": "reserve league cup",
    "value": "reserve league cup"
  },
  {
    "key": "romanian cup",
    "label": "romanian cup",
    "value": "romanian cup"
  },
  {
    "key": "romanian cup - women",
    "label": "romanian cup - women",
    "value": "romanian cup - women"
  },
  {
    "key": "russian cup",
    "label": "russian cup",
    "value": "russian cup"
  },
  {
    "key": "sa state league 1 reserves",
    "label": "sa state league 1 reserves",
    "value": "sa state league 1 reserves"
  },
  {
    "key": "sasol league - women",
    "label": "sasol league - women",
    "value": "sasol league - women"
  },
  {
    "key": "saudi professional league",
    "label": "saudi professional league",
    "value": "saudi professional league"
  },
  {
    "key": "scottish championship",
    "label": "scottish championship",
    "value": "scottish championship"
  },
  {
    "key": "scottish premiership",
    "label": "scottish premiership",
    "value": "scottish premiership"
  },
  {
    "key": "second division",
    "label": "second division",
    "value": "second division"
  },
  {
    "key": "second league",
    "label": "second league",
    "value": "second league"
  },
  {
    "key": "segunda division",
    "label": "segunda division",
    "value": "segunda division"
  },
  {
    "key": "segunda division rfef",
    "label": "segunda division rfef",
    "value": "segunda division rfef"
  },
  {
    "key": "segunda federacion - women",
    "label": "segunda federacion - women",
    "value": "segunda federacion - women"
  },
  {
    "key": "segunda liga",
    "label": "segunda liga",
    "value": "segunda liga"
  },
  {
    "key": "serbian league",
    "label": "serbian league",
    "value": "serbian league"
  },
  {
    "key": "serie a",
    "label": "serie a",
    "value": "serie a"
  },
  {
    "key": "serie a - cards and corners",
    "label": "serie a - cards and corners",
    "value": "serie a - cards and corners"
  },
  {
    "key": "serie a - women",
    "label": "serie a - women",
    "value": "serie a - women"
  },
  {
    "key": "serie a srl",
    "label": "serie a srl",
    "value": "serie a srl"
  },
  {
    "key": "serie b",
    "label": "serie b",
    "value": "serie b"
  },
  {
    "key": "serie c",
    "label": "serie c",
    "value": "serie c"
  },
  {
    "key": "serie d",
    "label": "serie d",
    "value": "serie d"
  },
  {
    "key": "sff cup",
    "label": "sff cup",
    "value": "sff cup"
  },
  {
    "key": "sknfa super league",
    "label": "sknfa super league",
    "value": "sknfa super league"
  },
  {
    "key": "small cup",
    "label": "small cup",
    "value": "small cup"
  },
  {
    "key": "southern league",
    "label": "southern league",
    "value": "southern league"
  },
  {
    "key": "stars league",
    "label": "stars league",
    "value": "stars league"
  },
  {
    "key": "states league",
    "label": "states league",
    "value": "states league"
  },
  {
    "key": "super cup serie c",
    "label": "super cup serie c",
    "value": "super cup serie c"
  },
  {
    "key": "super league",
    "label": "super league",
    "value": "super league"
  },
  {
    "key": "super league - women",
    "label": "super league - women",
    "value": "super league - women"
  },
  {
    "key": "super league 1",
    "label": "super league 1",
    "value": "super league 1"
  },
  {
    "key": "super league 2",
    "label": "super league 2",
    "value": "super league 2"
  },
  {
    "key": "super lig",
    "label": "super lig",
    "value": "super lig"
  },
  {
    "key": "super lig - cards and corners",
    "label": "super lig - cards and corners",
    "value": "super lig - cards and corners"
  },
  {
    "key": "super lig srl",
    "label": "super lig srl",
    "value": "super lig srl"
  },
  {
    "key": "super liga",
    "label": "super liga",
    "value": "super liga"
  },
  {
    "key": "super ligue",
    "label": "super ligue",
    "value": "super ligue"
  },
  {
    "key": "supercoppa primavera 2",
    "label": "supercoppa primavera 2",
    "value": "supercoppa primavera 2"
  },
  {
    "key": "superettan",
    "label": "superettan",
    "value": "superettan"
  },
  {
    "key": "superleague",
    "label": "superleague",
    "value": "superleague"
  },
  {
    "key": "superliga",
    "label": "superliga",
    "value": "superliga"
  },
  {
    "key": "superliga - women",
    "label": "superliga - women",
    "value": "superliga - women"
  },
  {
    "key": "svenska cupen",
    "label": "svenska cupen",
    "value": "svenska cupen"
  },
  {
    "key": "swpl 1 - women",
    "label": "swpl 1 - women",
    "value": "swpl 1 - women"
  },
  {
    "key": "sznl - women",
    "label": "sznl - women",
    "value": "sznl - women"
  },
  {
    "key": "tasmania statewide cup - women",
    "label": "tasmania statewide cup - women",
    "value": "tasmania statewide cup - women"
  },
  {
    "key": "tercera division",
    "label": "tercera division",
    "value": "tercera division"
  },
  {
    "key": "tercera division rfef",
    "label": "tercera division rfef",
    "value": "tercera division rfef"
  },
  {
    "key": "tff 1. league",
    "label": "tff 1. league",
    "value": "tff 1. league"
  },
  {
    "key": "tff 2. league",
    "label": "tff 2. league",
    "value": "tff 2. league"
  },
  {
    "key": "tff 3. league",
    "label": "tff 3. league",
    "value": "tff 3. league"
  },
  {
    "key": "thai league",
    "label": "thai league",
    "value": "thai league"
  },
  {
    "key": "thai league 4",
    "label": "thai league 4",
    "value": "thai league 4"
  },
  {
    "key": "third league north east",
    "label": "third league north east",
    "value": "third league north east"
  },
  {
    "key": "third league south east",
    "label": "third league south east",
    "value": "third league south east"
  },
  {
    "key": "third league south west",
    "label": "third league south west",
    "value": "third league south west"
  },
  {
    "key": "tnm super league",
    "label": "tnm super league",
    "value": "tnm super league"
  },
  {
    "key": "toppserien - women",
    "label": "toppserien - women",
    "value": "toppserien - women"
  },
  {
    "key": "torneo federal a",
    "label": "torneo federal a",
    "value": "torneo federal a"
  },
  {
    "key": "torneo promocional",
    "label": "torneo promocional",
    "value": "torneo promocional"
  },
  {
    "key": "torneo regional",
    "label": "torneo regional",
    "value": "torneo regional"
  },
  {
    "key": "tunisian cup",
    "label": "tunisian cup",
    "value": "tunisian cup"
  },
  {
    "key": "tweede divisie",
    "label": "tweede divisie",
    "value": "tweede divisie"
  },
  {
    "key": "u19 bundesliga",
    "label": "u19 bundesliga",
    "value": "u19 bundesliga"
  },
  {
    "key": "u19 championship",
    "label": "u19 championship",
    "value": "u19 championship"
  },
  {
    "key": "u19 cup",
    "label": "u19 cup",
    "value": "u19 cup"
  },
  {
    "key": "u19 elite",
    "label": "u19 elite",
    "value": "u19 elite"
  },
  {
    "key": "u19 elite league",
    "label": "u19 elite league",
    "value": "u19 elite league"
  },
  {
    "key": "u19 international friendlies",
    "label": "u19 international friendlies",
    "value": "u19 international friendlies"
  },
  {
    "key": "u19 league",
    "label": "u19 league",
    "value": "u19 league"
  },
  {
    "key": "u19 ligue",
    "label": "u19 ligue",
    "value": "u19 ligue"
  },
  {
    "key": "u19 ligue - women",
    "label": "u19 ligue - women",
    "value": "u19 ligue - women"
  },
  {
    "key": "u19 prva hnl",
    "label": "u19 prva hnl",
    "value": "u19 prva hnl"
  },
  {
    "key": "u19 super cup",
    "label": "u19 super cup",
    "value": "u19 super cup"
  },
  {
    "key": "u19 super league",
    "label": "u19 super league",
    "value": "u19 super league"
  },
  {
    "key": "u19 youth league",
    "label": "u19 youth league",
    "value": "u19 youth league"
  },
  {
    "key": "u20 campeonato baiano",
    "label": "u20 campeonato baiano",
    "value": "u20 campeonato baiano"
  },
  {
    "key": "u20 campeonato brasileiro",
    "label": "u20 campeonato brasileiro",
    "value": "u20 campeonato brasileiro"
  },
  {
    "key": "u20 campeonato brasileiro - women",
    "label": "u20 campeonato brasileiro - women",
    "value": "u20 campeonato brasileiro - women"
  },
  {
    "key": "u20 campeonato carioca",
    "label": "u20 campeonato carioca",
    "value": "u20 campeonato carioca"
  },
  {
    "key": "u20 campeonato catarinense",
    "label": "u20 campeonato catarinense",
    "value": "u20 campeonato catarinense"
  },
  {
    "key": "u20 campeonato goiano",
    "label": "u20 campeonato goiano",
    "value": "u20 campeonato goiano"
  },
  {
    "key": "u20 campeonato mineiro",
    "label": "u20 campeonato mineiro",
    "value": "u20 campeonato mineiro"
  },
  {
    "key": "u20 campeonato paranaense",
    "label": "u20 campeonato paranaense",
    "value": "u20 campeonato paranaense"
  },
  {
    "key": "u20 campeonato paulista",
    "label": "u20 campeonato paulista",
    "value": "u20 campeonato paulista"
  },
  {
    "key": "u20 campeonato pernambucano",
    "label": "u20 campeonato pernambucano",
    "value": "u20 campeonato pernambucano"
  },
  {
    "key": "u20 campeonato sergipano",
    "label": "u20 campeonato sergipano",
    "value": "u20 campeonato sergipano"
  },
  {
    "key": "u20 copa amazonense",
    "label": "u20 copa amazonense",
    "value": "u20 copa amazonense"
  },
  {
    "key": "u20 fnsw one",
    "label": "u20 fnsw one",
    "value": "u20 fnsw one"
  },
  {
    "key": "u20 league",
    "label": "u20 league",
    "value": "u20 league"
  },
  {
    "key": "u20 npl nsw",
    "label": "u20 npl nsw",
    "value": "u20 npl nsw"
  },
  {
    "key": "u20 npl western australia",
    "label": "u20 npl western australia",
    "value": "u20 npl western australia"
  },
  {
    "key": "u21 knvb divisie",
    "label": "u21 knvb divisie",
    "value": "u21 knvb divisie"
  },
  {
    "key": "u21 league",
    "label": "u21 league",
    "value": "u21 league"
  },
  {
    "key": "u21 ligacupen elit",
    "label": "u21 ligacupen elit",
    "value": "u21 ligacupen elit"
  },
  {
    "key": "u21 ligue 1",
    "label": "u21 ligue 1",
    "value": "u21 ligue 1"
  },
  {
    "key": "u21 ligue 2",
    "label": "u21 ligue 2",
    "value": "u21 ligue 2"
  },
  {
    "key": "u21 npl western australia - women",
    "label": "u21 npl western australia - women",
    "value": "u21 npl western australia - women"
  },
  {
    "key": "u23 league",
    "label": "u23 league",
    "value": "u23 league"
  },
  {
    "key": "u23 liga mx",
    "label": "u23 liga mx",
    "value": "u23 liga mx"
  },
  {
    "key": "u23 mfl cup",
    "label": "u23 mfl cup",
    "value": "u23 mfl cup"
  },
  {
    "key": "u23 npl capital territory",
    "label": "u23 npl capital territory",
    "value": "u23 npl capital territory"
  },
  {
    "key": "u23 npl queensland",
    "label": "u23 npl queensland",
    "value": "u23 npl queensland"
  },
  {
    "key": "u23 npl victoria",
    "label": "u23 npl victoria",
    "value": "u23 npl victoria"
  },
  {
    "key": "u23 queensland premier league 1",
    "label": "u23 queensland premier league 1",
    "value": "u23 queensland premier league 1"
  },
  {
    "key": "u23 queensland premier league 2",
    "label": "u23 queensland premier league 2",
    "value": "u23 queensland premier league 2"
  },
  {
    "key": "u23 taca revelacao",
    "label": "u23 taca revelacao",
    "value": "u23 taca revelacao"
  },
  {
    "key": "u23 victoria premier league 1",
    "label": "u23 victoria premier league 1",
    "value": "u23 victoria premier league 1"
  },
  {
    "key": "uae first division",
    "label": "uae first division",
    "value": "uae first division"
  },
  {
    "key": "uae president cup",
    "label": "uae president cup",
    "value": "uae president cup"
  },
  {
    "key": "uae pro league",
    "label": "uae pro league",
    "value": "uae pro league"
  },
  {
    "key": "university championship",
    "label": "university championship",
    "value": "university championship"
  },
  {
    "key": "urvalsdeild - women",
    "label": "urvalsdeild - women",
    "value": "urvalsdeild - women"
  },
  {
    "key": "usl championship",
    "label": "usl championship",
    "value": "usl championship"
  },
  {
    "key": "usl league one",
    "label": "usl league one",
    "value": "usl league one"
  },
  {
    "key": "usl league two",
    "label": "usl league two",
    "value": "usl league two"
  },
  {
    "key": "usl w-league - women",
    "label": "usl w-league - women",
    "value": "usl w-league - women"
  },
  {
    "key": "v-league",
    "label": "v-league",
    "value": "v-league"
  },
  {
    "key": "v-league 2",
    "label": "v-league 2",
    "value": "v-league 2"
  },
  {
    "key": "vbet premier league",
    "label": "vbet premier league",
    "value": "vbet premier league"
  },
  {
    "key": "vbet ukrainian cup",
    "label": "vbet ukrainian cup",
    "value": "vbet ukrainian cup"
  },
  {
    "key": "veikkausliiga",
    "label": "veikkausliiga",
    "value": "veikkausliiga"
  },
  {
    "key": "victoria premier league 1",
    "label": "victoria premier league 1",
    "value": "victoria premier league 1"
  },
  {
    "key": "victoria premier league 2",
    "label": "victoria premier league 2",
    "value": "victoria premier league 2"
  },
  {
    "key": "victoria state league 1",
    "label": "victoria state league 1",
    "value": "victoria state league 1"
  },
  {
    "key": "victoria state league 2",
    "label": "victoria state league 2",
    "value": "victoria state league 2"
  },
  {
    "key": "victoria state league 2 reserves",
    "label": "victoria state league 2 reserves",
    "value": "victoria state league 2 reserves"
  },
  {
    "key": "virsliga",
    "label": "virsliga",
    "value": "virsliga"
  },
  {
    "key": "vnf second league",
    "label": "vnf second league",
    "value": "vnf second league"
  },
  {
    "key": "vysshaya liga",
    "label": "vysshaya liga",
    "value": "vysshaya liga"
  },
  {
    "key": "vysshaya liga - women",
    "label": "vysshaya liga - women",
    "value": "vysshaya liga - women"
  },
  {
    "key": "vysshi division - women",
    "label": "vysshi division - women",
    "value": "vysshi division - women"
  },
  {
    "key": "wa amateur premier division",
    "label": "wa amateur premier division",
    "value": "wa amateur premier division"
  },
  {
    "key": "we league - women",
    "label": "we league - women",
    "value": "we league - women"
  },
  {
    "key": "west state league 1",
    "label": "west state league 1",
    "value": "west state league 1"
  },
  {
    "key": "west state league 1 - women",
    "label": "west state league 1 - women",
    "value": "west state league 1 - women"
  },
  {
    "key": "west state league 1 reserves",
    "label": "west state league 1 reserves",
    "value": "west state league 1 reserves"
  },
  {
    "key": "wpsl - women",
    "label": "wpsl - women",
    "value": "wpsl - women"
  },
  {
    "key": "ykkonen",
    "label": "ykkonen",
    "value": "ykkonen"
  },
  {
    "key": "ykkonen - women",
    "label": "ykkonen - women",
    "value": "ykkonen - women"
  },
  {
    "key": "ykkosliiga",
    "label": "ykkosliiga",
    "value": "ykkosliiga"
  }
]

const leagueInbet365Options = [
  {
    "key": "1. deild",
    "label": "1. deild",
    "value": "1. deild"
  },
  {
    "key": "1. deild women",
    "label": "1. deild women",
    "value": "1. deild women"
  },
  {
    "key": "1. division",
    "label": "1. division",
    "value": "1. division"
  },
  {
    "key": "1. division fbih",
    "label": "1. division fbih",
    "value": "1. division fbih"
  },
  {
    "key": "1. division rs",
    "label": "1. division rs",
    "value": "1. division rs"
  },
  {
    "key": "1. division women",
    "label": "1. division women",
    "value": "1. division women"
  },
  {
    "key": "1. hnl u19",
    "label": "1. hnl u19",
    "value": "1. hnl u19"
  },
  {
    "key": "1. lig",
    "label": "1. lig",
    "value": "1. lig"
  },
  {
    "key": "1. liga",
    "label": "1. liga",
    "value": "1. liga"
  },
  {
    "key": "1. liga classic",
    "label": "1. liga classic",
    "value": "1. liga classic"
  },
  {
    "key": "1. liga promotion",
    "label": "1. liga promotion",
    "value": "1. liga promotion"
  },
  {
    "key": "1. liga u19",
    "label": "1. liga u19",
    "value": "1. liga u19"
  },
  {
    "key": "1. liga women",
    "label": "1. liga women",
    "value": "1. liga women"
  },
  {
    "key": "1. nl",
    "label": "1. nl",
    "value": "1. nl"
  },
  {
    "key": "1st division",
    "label": "1st division",
    "value": "1st division"
  },
  {
    "key": "1st division women",
    "label": "1st division women",
    "value": "1st division women"
  },
  {
    "key": "2. bundesliga",
    "label": "2. bundesliga",
    "value": "2. bundesliga"
  },
  {
    "key": "2. bundesliga women",
    "label": "2. bundesliga women",
    "value": "2. bundesliga women"
  },
  {
    "key": "2. cfl",
    "label": "2. cfl",
    "value": "2. cfl"
  },
  {
    "key": "2. deild",
    "label": "2. deild",
    "value": "2. deild"
  },
  {
    "key": "2. division",
    "label": "2. division",
    "value": "2. division"
  },
  {
    "key": "2. division avd. 1",
    "label": "2. division avd. 1",
    "value": "2. division avd. 1"
  },
  {
    "key": "2. division avd. 2",
    "label": "2. division avd. 2",
    "value": "2. division avd. 2"
  },
  {
    "key": "2. division norra gotaland",
    "label": "2. division norra gotaland",
    "value": "2. division norra gotaland"
  },
  {
    "key": "2. division norra svealand",
    "label": "2. division norra svealand",
    "value": "2. division norra svealand"
  },
  {
    "key": "2. division sodra gotaland",
    "label": "2. division sodra gotaland",
    "value": "2. division sodra gotaland"
  },
  {
    "key": "2. division sodra svealand",
    "label": "2. division sodra svealand",
    "value": "2. division sodra svealand"
  },
  {
    "key": "2. division vastra gotaland",
    "label": "2. division vastra gotaland",
    "value": "2. division vastra gotaland"
  },
  {
    "key": "2. landesliga",
    "label": "2. landesliga",
    "value": "2. landesliga"
  },
  {
    "key": "2. liga",
    "label": "2. liga",
    "value": "2. liga"
  },
  {
    "key": "2. liga interregional",
    "label": "2. liga interregional",
    "value": "2. liga interregional"
  },
  {
    "key": "2. nl",
    "label": "2. nl",
    "value": "2. nl"
  },
  {
    "key": "2.snl",
    "label": "2.snl",
    "value": "2.snl"
  },
  {
    "key": "3. deild",
    "label": "3. deild",
    "value": "3. deild"
  },
  {
    "key": "3. division avd. 2",
    "label": "3. division avd. 2",
    "value": "3. division avd. 2"
  },
  {
    "key": "3. division avd. 3",
    "label": "3. division avd. 3",
    "value": "3. division avd. 3"
  },
  {
    "key": "3. division avd. 4",
    "label": "3. division avd. 4",
    "value": "3. division avd. 4"
  },
  {
    "key": "3. division avd. 5",
    "label": "3. division avd. 5",
    "value": "3. division avd. 5"
  },
  {
    "key": "3. liga",
    "label": "3. liga",
    "value": "3. liga"
  },
  {
    "key": "3. liga east",
    "label": "3. liga east",
    "value": "3. liga east"
  },
  {
    "key": "3. liga west",
    "label": "3. liga west",
    "value": "3. liga west"
  },
  {
    "key": "3. nl",
    "label": "3. nl",
    "value": "3. nl"
  },
  {
    "key": "3.liga",
    "label": "3.liga",
    "value": "3.liga"
  },
  {
    "key": "3.snl",
    "label": "3.snl",
    "value": "3.snl"
  },
  {
    "key": "4. deild",
    "label": "4. deild",
    "value": "4. deild"
  },
  {
    "key": "4. liga",
    "label": "4. liga",
    "value": "4. liga"
  },
  {
    "key": "5. deild",
    "label": "5. deild",
    "value": "5. deild"
  },
  {
    "key": "5. liga",
    "label": "5. liga",
    "value": "5. liga"
  },
  {
    "key": "a lyga",
    "label": "a lyga",
    "value": "a lyga"
  },
  {
    "key": "a lyga women",
    "label": "a lyga women",
    "value": "a lyga women"
  },
  {
    "key": "allsvenskan",
    "label": "allsvenskan",
    "value": "allsvenskan"
  },
  {
    "key": "amateur cup",
    "label": "amateur cup",
    "value": "amateur cup"
  },
  {
    "key": "asean club championship",
    "label": "asean club championship",
    "value": "asean club championship"
  },
  {
    "key": "bandodkar trophy",
    "label": "bandodkar trophy",
    "value": "bandodkar trophy"
  },
  {
    "key": "bayernliga south",
    "label": "bayernliga south",
    "value": "bayernliga south"
  },
  {
    "key": "besta deild karla",
    "label": "besta deild karla",
    "value": "besta deild karla"
  },
  {
    "key": "besta deild kvenna women",
    "label": "besta deild kvenna women",
    "value": "besta deild kvenna women"
  },
  {
    "key": "brasileiro u20",
    "label": "brasileiro u20",
    "value": "brasileiro u20"
  },
  {
    "key": "brazil cup u20",
    "label": "brazil cup u20",
    "value": "brazil cup u20"
  },
  {
    "key": "bundesliga",
    "label": "bundesliga",
    "value": "bundesliga"
  },
  {
    "key": "bundesliga u19",
    "label": "bundesliga u19",
    "value": "bundesliga u19"
  },
  {
    "key": "bundesliga women",
    "label": "bundesliga women",
    "value": "bundesliga women"
  },
  {
    "key": "caf champions league",
    "label": "caf champions league",
    "value": "caf champions league"
  },
  {
    "key": "caf confederations cup qualification",
    "label": "caf confederations cup qualification",
    "value": "caf confederations cup qualification"
  },
  {
    "key": "calcutta premier division",
    "label": "calcutta premier division",
    "value": "calcutta premier division"
  },
  {
    "key": "campeonato brasileiro a1 women",
    "label": "campeonato brasileiro a1 women",
    "value": "campeonato brasileiro a1 women"
  },
  {
    "key": "campeonato de portugal, group a",
    "label": "campeonato de portugal, group a",
    "value": "campeonato de portugal, group a"
  },
  {
    "key": "campeonato de portugal, group b",
    "label": "campeonato de portugal, group b",
    "value": "campeonato de portugal, group b"
  },
  {
    "key": "campeonato de portugal, group c",
    "label": "campeonato de portugal, group c",
    "value": "campeonato de portugal, group c"
  },
  {
    "key": "campeonato de portugal, group d",
    "label": "campeonato de portugal, group d",
    "value": "campeonato de portugal, group d"
  },
  {
    "key": "campeonato integracion women",
    "label": "campeonato integracion women",
    "value": "campeonato integracion women"
  },
  {
    "key": "campeonato nacional women",
    "label": "campeonato nacional women",
    "value": "campeonato nacional women"
  },
  {
    "key": "campeonato paulista u20",
    "label": "campeonato paulista u20",
    "value": "campeonato paulista u20"
  },
  {
    "key": "campionato primavera 1",
    "label": "campionato primavera 1",
    "value": "campionato primavera 1"
  },
  {
    "key": "capital territory npl 2",
    "label": "capital territory npl 2",
    "value": "capital territory npl 2"
  },
  {
    "key": "capital territory u23",
    "label": "capital territory u23",
    "value": "capital territory u23"
  },
  {
    "key": "carioca 2",
    "label": "carioca 2",
    "value": "carioca 2"
  },
  {
    "key": "catarinense 2",
    "label": "catarinense 2",
    "value": "catarinense 2"
  },
  {
    "key": "cearense u20",
    "label": "cearense u20",
    "value": "cearense u20"
  },
  {
    "key": "central premier league",
    "label": "central premier league",
    "value": "central premier league"
  },
  {
    "key": "challenge league",
    "label": "challenge league",
    "value": "challenge league"
  },
  {
    "key": "championnat national u19",
    "label": "championnat national u19",
    "value": "championnat national u19"
  },
  {
    "key": "championnat national women",
    "label": "championnat national women",
    "value": "championnat national women"
  },
  {
    "key": "championship",
    "label": "championship",
    "value": "championship"
  },
  {
    "key": "championship women",
    "label": "championship women",
    "value": "championship women"
  },
  {
    "key": "concacaf caribbean cup",
    "label": "concacaf caribbean cup",
    "value": "concacaf caribbean cup"
  },
  {
    "key": "concacaf central american cup",
    "label": "concacaf central american cup",
    "value": "concacaf central american cup"
  },
  {
    "key": "concacaf championship women",
    "label": "concacaf championship women",
    "value": "concacaf championship women"
  },
  {
    "key": "concacaf leagues cup",
    "label": "concacaf leagues cup",
    "value": "concacaf leagues cup"
  },
  {
    "key": "copa argentina",
    "label": "copa argentina",
    "value": "copa argentina"
  },
  {
    "key": "copa costa rica",
    "label": "copa costa rica",
    "value": "copa costa rica"
  },
  {
    "key": "copa de la liga reserves",
    "label": "copa de la liga reserves",
    "value": "copa de la liga reserves"
  },
  {
    "key": "copa federacion",
    "label": "copa federacion",
    "value": "copa federacion"
  },
  {
    "key": "copa gaucho",
    "label": "copa gaucho",
    "value": "copa gaucho"
  },
  {
    "key": "copa libertadores",
    "label": "copa libertadores",
    "value": "copa libertadores"
  },
  {
    "key": "copa paraguay",
    "label": "copa paraguay",
    "value": "copa paraguay"
  },
  {
    "key": "copa paulista",
    "label": "copa paulista",
    "value": "copa paulista"
  },
  {
    "key": "copa rio",
    "label": "copa rio",
    "value": "copa rio"
  },
  {
    "key": "copa simon bolivar",
    "label": "copa simon bolivar",
    "value": "copa simon bolivar"
  },
  {
    "key": "copa sudamericana",
    "label": "copa sudamericana",
    "value": "copa sudamericana"
  },
  {
    "key": "coppa italia serie d",
    "label": "coppa italia serie d",
    "value": "coppa italia serie d"
  },
  {
    "key": "cosafa champions league women",
    "label": "cosafa champions league women",
    "value": "cosafa champions league women"
  },
  {
    "key": "croatia cup",
    "label": "croatia cup",
    "value": "croatia cup"
  },
  {
    "key": "csl",
    "label": "csl",
    "value": "csl"
  },
  {
    "key": "cup",
    "label": "cup",
    "value": "cup"
  },
  {
    "key": "cup women",
    "label": "cup women",
    "value": "cup women"
  },
  {
    "key": "cymru north",
    "label": "cymru north",
    "value": "cymru north"
  },
  {
    "key": "cymru south",
    "label": "cymru south",
    "value": "cymru south"
  },
  {
    "key": "czech cup",
    "label": "czech cup",
    "value": "czech cup"
  },
  {
    "key": "damallsvenskan",
    "label": "damallsvenskan",
    "value": "damallsvenskan"
  },
  {
    "key": "danmarksserien, group 1",
    "label": "danmarksserien, group 1",
    "value": "danmarksserien, group 1"
  },
  {
    "key": "danmarksserien, group 2",
    "label": "danmarksserien, group 2",
    "value": "danmarksserien, group 2"
  },
  {
    "key": "danmarksserien, group 3",
    "label": "danmarksserien, group 3",
    "value": "danmarksserien, group 3"
  },
  {
    "key": "danmarksserien, group 4",
    "label": "danmarksserien, group 4",
    "value": "danmarksserien, group 4"
  },
  {
    "key": "derde divisie",
    "label": "derde divisie",
    "value": "derde divisie"
  },
  {
    "key": "division 1",
    "label": "division 1",
    "value": "division 1"
  },
  {
    "key": "division 1 norra",
    "label": "division 1 norra",
    "value": "division 1 norra"
  },
  {
    "key": "division 1 sodra",
    "label": "division 1 sodra",
    "value": "division 1 sodra"
  },
  {
    "key": "division intermedia",
    "label": "division intermedia",
    "value": "division intermedia"
  },
  {
    "key": "divizia nationala",
    "label": "divizia nationala",
    "value": "divizia nationala"
  },
  {
    "key": "durand cup",
    "label": "durand cup",
    "value": "durand cup"
  },
  {
    "key": "eerste divisie",
    "label": "eerste divisie",
    "value": "eerste divisie"
  },
  {
    "key": "egypt cup",
    "label": "egypt cup",
    "value": "egypt cup"
  },
  {
    "key": "ekstraklasa",
    "label": "ekstraklasa",
    "value": "ekstraklasa"
  },
  {
    "key": "ekstraliga women",
    "label": "ekstraliga women",
    "value": "ekstraliga women"
  },
  {
    "key": "elitedivisionen women",
    "label": "elitedivisionen women",
    "value": "elitedivisionen women"
  },
  {
    "key": "eliteserien",
    "label": "eliteserien",
    "value": "eliteserien"
  },
  {
    "key": "elitettan women",
    "label": "elitettan women",
    "value": "elitettan women"
  },
  {
    "key": "emperor cup",
    "label": "emperor cup",
    "value": "emperor cup"
  },
  {
    "key": "eredivisie",
    "label": "eredivisie",
    "value": "eredivisie"
  },
  {
    "key": "erovnuli liga",
    "label": "erovnuli liga",
    "value": "erovnuli liga"
  },
  {
    "key": "erovnuli liga 2",
    "label": "erovnuli liga 2",
    "value": "erovnuli liga 2"
  },
  {
    "key": "esiliiga",
    "label": "esiliiga",
    "value": "esiliiga"
  },
  {
    "key": "esiliiga b",
    "label": "esiliiga b",
    "value": "esiliiga b"
  },
  {
    "key": "fa cup",
    "label": "fa cup",
    "value": "fa cup"
  },
  {
    "key": "fai cup women",
    "label": "fai cup women",
    "value": "fai cup women"
  },
  {
    "key": "ffa cup",
    "label": "ffa cup",
    "value": "ffa cup"
  },
  {
    "key": "first division",
    "label": "first division",
    "value": "first division"
  },
  {
    "key": "first division a",
    "label": "first division a",
    "value": "first division a"
  },
  {
    "key": "first division b",
    "label": "first division b",
    "value": "first division b"
  },
  {
    "key": "first division women",
    "label": "first division women",
    "value": "first division women"
  },
  {
    "key": "first league",
    "label": "first league",
    "value": "first league"
  },
  {
    "key": "fnl",
    "label": "fnl",
    "value": "fnl"
  },
  {
    "key": "friendly club matches  (possible format change)",
    "label": "friendly club matches  (possible format change)",
    "value": "friendly club matches  (possible format change)"
  },
  {
    "key": "friendly club matches women",
    "label": "friendly club matches women",
    "value": "friendly club matches women"
  },
  {
    "key": "gaucho women",
    "label": "gaucho women",
    "value": "gaucho women"
  },
  {
    "key": "hnl",
    "label": "hnl",
    "value": "hnl"
  },
  {
    "key": "i lyga",
    "label": "i lyga",
    "value": "i lyga"
  },
  {
    "key": "ii lyga",
    "label": "ii lyga",
    "value": "ii lyga"
  },
  {
    "key": "iii liga, group 1",
    "label": "iii liga, group 1",
    "value": "iii liga, group 1"
  },
  {
    "key": "iii liga, group 2",
    "label": "iii liga, group 2",
    "value": "iii liga, group 2"
  },
  {
    "key": "iii liga, group 3",
    "label": "iii liga, group 3",
    "value": "iii liga, group 3"
  },
  {
    "key": "iii liga, group 4",
    "label": "iii liga, group 4",
    "value": "iii liga, group 4"
  },
  {
    "key": "isthmian division one south east",
    "label": "isthmian division one south east",
    "value": "isthmian division one south east"
  },
  {
    "key": "isthmian league division one south center",
    "label": "isthmian league division one south center",
    "value": "isthmian league division one south center"
  },
  {
    "key": "isthmian league premier",
    "label": "isthmian league premier",
    "value": "isthmian league premier"
  },
  {
    "key": "iv liga",
    "label": "iv liga",
    "value": "iv liga"
  },
  {
    "key": "j league",
    "label": "j league",
    "value": "j league"
  },
  {
    "key": "j2 league",
    "label": "j2 league",
    "value": "j2 league"
  },
  {
    "key": "j3 league",
    "label": "j3 league",
    "value": "j3 league"
  },
  {
    "key": "jia league",
    "label": "jia league",
    "value": "jia league"
  },
  {
    "key": "jordan league",
    "label": "jordan league",
    "value": "jordan league"
  },
  {
    "key": "jordan league women",
    "label": "jordan league women",
    "value": "jordan league women"
  },
  {
    "key": "k-4 league",
    "label": "k-4 league",
    "value": "k-4 league"
  },
  {
    "key": "k-league 1",
    "label": "k-league 1",
    "value": "k-league 1"
  },
  {
    "key": "k-league 2",
    "label": "k-league 2",
    "value": "k-league 2"
  },
  {
    "key": "k3 league",
    "label": "k3 league",
    "value": "k3 league"
  },
  {
    "key": "kakkonen lohko a",
    "label": "kakkonen lohko a",
    "value": "kakkonen lohko a"
  },
  {
    "key": "kakkonen lohko b",
    "label": "kakkonen lohko b",
    "value": "kakkonen lohko b"
  },
  {
    "key": "kakkonen lohko c",
    "label": "kakkonen lohko c",
    "value": "kakkonen lohko c"
  },
  {
    "key": "kategoria superiore",
    "label": "kategoria superiore",
    "value": "kategoria superiore"
  },
  {
    "key": "kolmonen",
    "label": "kolmonen",
    "value": "kolmonen"
  },
  {
    "key": "la liga",
    "label": "la liga",
    "value": "la liga"
  },
  {
    "key": "laliga 2",
    "label": "laliga 2",
    "value": "laliga 2"
  },
  {
    "key": "landesliga",
    "label": "landesliga",
    "value": "landesliga"
  },
  {
    "key": "landespokal",
    "label": "landespokal",
    "value": "landespokal"
  },
  {
    "key": "league 2",
    "label": "league 2",
    "value": "league 2"
  },
  {
    "key": "league cup",
    "label": "league cup",
    "value": "league cup"
  },
  {
    "key": "league cup women",
    "label": "league cup women",
    "value": "league cup women"
  },
  {
    "key": "league two",
    "label": "league two",
    "value": "league two"
  },
  {
    "key": "league women",
    "label": "league women",
    "value": "league women"
  },
  {
    "key": "leinster senior league",
    "label": "leinster senior league",
    "value": "leinster senior league"
  },
  {
    "key": "leumit league",
    "label": "leumit league",
    "value": "leumit league"
  },
  {
    "key": "liga 1",
    "label": "liga 1",
    "value": "liga 1"
  },
  {
    "key": "liga 1 - clasura",
    "label": "liga 1 - clasura",
    "value": "liga 1 - clasura"
  },
  {
    "key": "liga 1 women",
    "label": "liga 1 women",
    "value": "liga 1 women"
  },
  {
    "key": "liga 2",
    "label": "liga 2",
    "value": "liga 2"
  },
  {
    "key": "liga 3, group a",
    "label": "liga 3, group a",
    "value": "liga 3, group a"
  },
  {
    "key": "liga 3, group b",
    "label": "liga 3, group b",
    "value": "liga 3, group b"
  },
  {
    "key": "liga a1",
    "label": "liga a1",
    "value": "liga a1"
  },
  {
    "key": "liga de ascenso apertura gr. a",
    "label": "liga de ascenso apertura gr. a",
    "value": "liga de ascenso apertura gr. a"
  },
  {
    "key": "liga de ascenso apertura gr. b",
    "label": "liga de ascenso apertura gr. b",
    "value": "liga de ascenso apertura gr. b"
  },
  {
    "key": "liga de expansion mx",
    "label": "liga de expansion mx",
    "value": "liga de expansion mx"
  },
  {
    "key": "liga de futbol profesional boliviano",
    "label": "liga de futbol profesional boliviano",
    "value": "liga de futbol profesional boliviano"
  },
  {
    "key": "liga futve",
    "label": "liga futve",
    "value": "liga futve"
  },
  {
    "key": "liga futve 2",
    "label": "liga futve 2",
    "value": "liga futve 2"
  },
  {
    "key": "liga mx",
    "label": "liga mx",
    "value": "liga mx"
  },
  {
    "key": "liga mx femenil",
    "label": "liga mx femenil",
    "value": "liga mx femenil"
  },
  {
    "key": "liga mx u19",
    "label": "liga mx u19",
    "value": "liga mx u19"
  },
  {
    "key": "liga mx u19 femenil",
    "label": "liga mx u19 femenil",
    "value": "liga mx u19 femenil"
  },
  {
    "key": "liga nacional",
    "label": "liga nacional",
    "value": "liga nacional"
  },
  {
    "key": "liga profesional",
    "label": "liga profesional",
    "value": "liga profesional"
  },
  {
    "key": "liga prom",
    "label": "liga prom",
    "value": "liga prom"
  },
  {
    "key": "liga revelacao u23",
    "label": "liga revelacao u23",
    "value": "liga revelacao u23"
  },
  {
    "key": "liga segunda",
    "label": "liga segunda",
    "value": "liga segunda"
  },
  {
    "key": "ligat ha'al",
    "label": "ligat ha'al",
    "value": "ligat ha'al"
  },
  {
    "key": "ligue 1",
    "label": "ligue 1",
    "value": "ligue 1"
  },
  {
    "key": "ligue 2",
    "label": "ligue 2",
    "value": "ligue 2"
  },
  {
    "key": "lpf",
    "label": "lpf",
    "value": "lpf"
  },
  {
    "key": "maranhense u19",
    "label": "maranhense u19",
    "value": "maranhense u19"
  },
  {
    "key": "meistriliiga",
    "label": "meistriliiga",
    "value": "meistriliiga"
  },
  {
    "key": "mfl cup u23",
    "label": "mfl cup u23",
    "value": "mfl cup u23"
  },
  {
    "key": "mizoram premier league",
    "label": "mizoram premier league",
    "value": "mizoram premier league"
  },
  {
    "key": "mls next pro league",
    "label": "mls next pro league",
    "value": "mls next pro league"
  },
  {
    "key": "mocambola",
    "label": "mocambola",
    "value": "mocambola"
  },
  {
    "key": "mumbai super division",
    "label": "mumbai super division",
    "value": "mumbai super division"
  },
  {
    "key": "munster senior league",
    "label": "munster senior league",
    "value": "munster senior league"
  },
  {
    "key": "national",
    "label": "national",
    "value": "national"
  },
  {
    "key": "national 2, group b",
    "label": "national 2, group b",
    "value": "national 2, group b"
  },
  {
    "key": "national 2, group c",
    "label": "national 2, group c",
    "value": "national 2, group c"
  },
  {
    "key": "national 3",
    "label": "national 3",
    "value": "national 3"
  },
  {
    "key": "national division",
    "label": "national division",
    "value": "national division"
  },
  {
    "key": "national league",
    "label": "national league",
    "value": "national league"
  },
  {
    "key": "national league 2",
    "label": "national league 2",
    "value": "national league 2"
  },
  {
    "key": "national league north",
    "label": "national league north",
    "value": "national league north"
  },
  {
    "key": "national league premier division women",
    "label": "national league premier division women",
    "value": "national league premier division women"
  },
  {
    "key": "national league south",
    "label": "national league south",
    "value": "national league south"
  },
  {
    "key": "nb i",
    "label": "nb i",
    "value": "nb i"
  },
  {
    "key": "nb ii",
    "label": "nb ii",
    "value": "nb ii"
  },
  {
    "key": "nb iii",
    "label": "nb iii",
    "value": "nb iii"
  },
  {
    "key": "nisa",
    "label": "nisa",
    "value": "nisa"
  },
  {
    "key": "nm cup women",
    "label": "nm cup women",
    "value": "nm cup women"
  },
  {
    "key": "northern ireland premier league women",
    "label": "northern ireland premier league women",
    "value": "northern ireland premier league women"
  },
  {
    "key": "northern league division one east",
    "label": "northern league division one east",
    "value": "northern league division one east"
  },
  {
    "key": "northern league division one midlands",
    "label": "northern league division one midlands",
    "value": "northern league division one midlands"
  },
  {
    "key": "northern nsw premier league",
    "label": "northern nsw premier league",
    "value": "northern nsw premier league"
  },
  {
    "key": "northern nsw premier league women",
    "label": "northern nsw premier league women",
    "value": "northern nsw premier league women"
  },
  {
    "key": "northern nsw reserves league",
    "label": "northern nsw reserves league",
    "value": "northern nsw reserves league"
  },
  {
    "key": "northern premier league",
    "label": "northern premier league",
    "value": "northern premier league"
  },
  {
    "key": "northern territory darwin premier league",
    "label": "northern territory darwin premier league",
    "value": "northern territory darwin premier league"
  },
  {
    "key": "npl act",
    "label": "npl act",
    "value": "npl act"
  },
  {
    "key": "npl new south wales",
    "label": "npl new south wales",
    "value": "npl new south wales"
  },
  {
    "key": "npl new south wales u20",
    "label": "npl new south wales u20",
    "value": "npl new south wales u20"
  },
  {
    "key": "npl new south wales women",
    "label": "npl new south wales women",
    "value": "npl new south wales women"
  },
  {
    "key": "npl queensland women",
    "label": "npl queensland women",
    "value": "npl queensland women"
  },
  {
    "key": "npl south australia",
    "label": "npl south australia",
    "value": "npl south australia"
  },
  {
    "key": "npl tasmania",
    "label": "npl tasmania",
    "value": "npl tasmania"
  },
  {
    "key": "npl victoria",
    "label": "npl victoria",
    "value": "npl victoria"
  },
  {
    "key": "npl victoria women",
    "label": "npl victoria women",
    "value": "npl victoria women"
  },
  {
    "key": "npl western australia",
    "label": "npl western australia",
    "value": "npl western australia"
  },
  {
    "key": "npl western australia u20",
    "label": "npl western australia u20",
    "value": "npl western australia u20"
  },
  {
    "key": "npl western australia women u21",
    "label": "npl western australia women u21",
    "value": "npl western australia women u21"
  },
  {
    "key": "nsw premier league 2",
    "label": "nsw premier league 2",
    "value": "nsw premier league 2"
  },
  {
    "key": "nwsl",
    "label": "nwsl",
    "value": "nwsl"
  },
  {
    "key": "oberliga baden-wurttemberg",
    "label": "oberliga baden-wurttemberg",
    "value": "oberliga baden-wurttemberg"
  },
  {
    "key": "oberliga bayern nord",
    "label": "oberliga bayern nord",
    "value": "oberliga bayern nord"
  },
  {
    "key": "oberliga hamburg",
    "label": "oberliga hamburg",
    "value": "oberliga hamburg"
  },
  {
    "key": "oberliga hessen",
    "label": "oberliga hessen",
    "value": "oberliga hessen"
  },
  {
    "key": "oberliga mittelrhein",
    "label": "oberliga mittelrhein",
    "value": "oberliga mittelrhein"
  },
  {
    "key": "oberliga niederrhein",
    "label": "oberliga niederrhein",
    "value": "oberliga niederrhein"
  },
  {
    "key": "oberliga niedersachsen",
    "label": "oberliga niedersachsen",
    "value": "oberliga niedersachsen"
  },
  {
    "key": "oberliga nofv nord",
    "label": "oberliga nofv nord",
    "value": "oberliga nofv nord"
  },
  {
    "key": "oberliga rheinland-pfalzsaar /",
    "label": "oberliga rheinland-pfalzsaar /",
    "value": "oberliga rheinland-pfalzsaar /"
  },
  {
    "key": "oberliga schleswig-holstein",
    "label": "oberliga schleswig-holstein",
    "value": "oberliga schleswig-holstein"
  },
  {
    "key": "oberliga westfalen",
    "label": "oberliga westfalen",
    "value": "oberliga westfalen"
  },
  {
    "key": "paraibano 2nd division",
    "label": "paraibano 2nd division",
    "value": "paraibano 2nd division"
  },
  {
    "key": "paranaense 3",
    "label": "paranaense 3",
    "value": "paranaense 3"
  },
  {
    "key": "parva liga",
    "label": "parva liga",
    "value": "parva liga"
  },
  {
    "key": "persha liga",
    "label": "persha liga",
    "value": "persha liga"
  },
  {
    "key": "persian gulf pro league",
    "label": "persian gulf pro league",
    "value": "persian gulf pro league"
  },
  {
    "key": "premier division",
    "label": "premier division",
    "value": "premier division"
  },
  {
    "key": "premier league",
    "label": "premier league",
    "value": "premier league"
  },
  {
    "key": "premier league 2",
    "label": "premier league 2",
    "value": "premier league 2"
  },
  {
    "key": "premier league international cup",
    "label": "premier league international cup",
    "value": "premier league international cup"
  },
  {
    "key": "premier league women",
    "label": "premier league women",
    "value": "premier league women"
  },
  {
    "key": "premier liga",
    "label": "premier liga",
    "value": "premier liga"
  },
  {
    "key": "premier soccer league",
    "label": "premier soccer league",
    "value": "premier soccer league"
  },
  {
    "key": "premiership",
    "label": "premiership",
    "value": "premiership"
  },
  {
    "key": "primavera cup",
    "label": "primavera cup",
    "value": "primavera cup"
  },
  {
    "key": "primeira liga",
    "label": "primeira liga",
    "value": "primeira liga"
  },
  {
    "key": "primera a",
    "label": "primera a",
    "value": "primera a"
  },
  {
    "key": "primera a women",
    "label": "primera a women",
    "value": "primera a women"
  },
  {
    "key": "primera b",
    "label": "primera b",
    "value": "primera b"
  },
  {
    "key": "primera b metropolitana",
    "label": "primera b metropolitana",
    "value": "primera b metropolitana"
  },
  {
    "key": "primera c metropolitana",
    "label": "primera c metropolitana",
    "value": "primera c metropolitana"
  },
  {
    "key": "primera division",
    "label": "primera division",
    "value": "primera division"
  },
  {
    "key": "primera division reserve",
    "label": "primera division reserve",
    "value": "primera division reserve"
  },
  {
    "key": "primera division women",
    "label": "primera division women",
    "value": "primera division women"
  },
  {
    "key": "primera federacion, group 1",
    "label": "primera federacion, group 1",
    "value": "primera federacion, group 1"
  },
  {
    "key": "primera federacion, group 2",
    "label": "primera federacion, group 2",
    "value": "primera federacion, group 2"
  },
  {
    "key": "primera nacional",
    "label": "primera nacional",
    "value": "primera nacional"
  },
  {
    "key": "primera nacional reserves",
    "label": "primera nacional reserves",
    "value": "primera nacional reserves"
  },
  {
    "key": "pro liga",
    "label": "pro liga",
    "value": "pro liga"
  },
  {
    "key": "profesional league",
    "label": "profesional league",
    "value": "profesional league"
  },
  {
    "key": "professional development league",
    "label": "professional development league",
    "value": "professional development league"
  },
  {
    "key": "professional league",
    "label": "professional league",
    "value": "professional league"
  },
  {
    "key": "prva liga",
    "label": "prva liga",
    "value": "prva liga"
  },
  {
    "key": "regional cup",
    "label": "regional cup",
    "value": "regional cup"
  },
  {
    "key": "regional league",
    "label": "regional league",
    "value": "regional league"
  },
  {
    "key": "regionalliga bayern",
    "label": "regionalliga bayern",
    "value": "regionalliga bayern"
  },
  {
    "key": "regionalliga central",
    "label": "regionalliga central",
    "value": "regionalliga central"
  },
  {
    "key": "regionalliga east",
    "label": "regionalliga east",
    "value": "regionalliga east"
  },
  {
    "key": "regionalliga north",
    "label": "regionalliga north",
    "value": "regionalliga north"
  },
  {
    "key": "regionalliga northeast",
    "label": "regionalliga northeast",
    "value": "regionalliga northeast"
  },
  {
    "key": "regionalliga southwest",
    "label": "regionalliga southwest",
    "value": "regionalliga southwest"
  },
  {
    "key": "regionalliga west",
    "label": "regionalliga west",
    "value": "regionalliga west"
  },
  {
    "key": "reserve league",
    "label": "reserve league",
    "value": "reserve league"
  },
  {
    "key": "reserves league",
    "label": "reserves league",
    "value": "reserves league"
  },
  {
    "key": "rio cup u20",
    "label": "rio cup u20",
    "value": "rio cup u20"
  },
  {
    "key": "segunda b nacional",
    "label": "segunda b nacional",
    "value": "segunda b nacional"
  },
  {
    "key": "segunda division",
    "label": "segunda division",
    "value": "segunda division"
  },
  {
    "key": "serie a",
    "label": "serie a",
    "value": "serie a"
  },
  {
    "key": "serie b",
    "label": "serie b",
    "value": "serie b"
  },
  {
    "key": "serie c, group a",
    "label": "serie c, group a",
    "value": "serie c, group a"
  },
  {
    "key": "serie c, group b",
    "label": "serie c, group b",
    "value": "serie c, group b"
  },
  {
    "key": "serie c, group c",
    "label": "serie c, group c",
    "value": "serie c, group c"
  },
  {
    "key": "serie d",
    "label": "serie d",
    "value": "serie d"
  },
  {
    "key": "south australia premier league reserves",
    "label": "south australia premier league reserves",
    "value": "south australia premier league reserves"
  },
  {
    "key": "south australia premier league women",
    "label": "south australia premier league women",
    "value": "south australia premier league women"
  },
  {
    "key": "south australia premier league women reserves",
    "label": "south australia premier league women reserves",
    "value": "south australia premier league women reserves"
  },
  {
    "key": "south australia state league 1",
    "label": "south australia state league 1",
    "value": "south australia state league 1"
  },
  {
    "key": "south australia state league 1 reserves",
    "label": "south australia state league 1 reserves",
    "value": "south australia state league 1 reserves"
  },
  {
    "key": "southern league premier division central",
    "label": "southern league premier division central",
    "value": "southern league premier division central"
  },
  {
    "key": "southern league premier division south",
    "label": "southern league premier division south",
    "value": "southern league premier division south"
  },
  {
    "key": "southern premier league",
    "label": "southern premier league",
    "value": "southern premier league"
  },
  {
    "key": "stars league",
    "label": "stars league",
    "value": "stars league"
  },
  {
    "key": "suomen cup",
    "label": "suomen cup",
    "value": "suomen cup"
  },
  {
    "key": "super cup women",
    "label": "super cup women",
    "value": "super cup women"
  },
  {
    "key": "super league",
    "label": "super league",
    "value": "super league"
  },
  {
    "key": "super league women",
    "label": "super league women",
    "value": "super league women"
  },
  {
    "key": "super lig",
    "label": "super lig",
    "value": "super lig"
  },
  {
    "key": "super liga",
    "label": "super liga",
    "value": "super liga"
  },
  {
    "key": "superettan",
    "label": "superettan",
    "value": "superettan"
  },
  {
    "key": "superliga",
    "label": "superliga",
    "value": "superliga"
  },
  {
    "key": "superliga women",
    "label": "superliga women",
    "value": "superliga women"
  },
  {
    "key": "svenska cupen",
    "label": "svenska cupen",
    "value": "svenska cupen"
  },
  {
    "key": "tasmania championship",
    "label": "tasmania championship",
    "value": "tasmania championship"
  },
  {
    "key": "tasmania national premier league u21",
    "label": "tasmania national premier league u21",
    "value": "tasmania national premier league u21"
  },
  {
    "key": "tercera division",
    "label": "tercera division",
    "value": "tercera division"
  },
  {
    "key": "thai league 1",
    "label": "thai league 1",
    "value": "thai league 1"
  },
  {
    "key": "thai league 2",
    "label": "thai league 2",
    "value": "thai league 2"
  },
  {
    "key": "torneo federal a - losers stage",
    "label": "torneo federal a - losers stage",
    "value": "torneo federal a - losers stage"
  },
  {
    "key": "torneo federal a - winners stage",
    "label": "torneo federal a - winners stage",
    "value": "torneo federal a - winners stage"
  },
  {
    "key": "u19 championship",
    "label": "u19 championship",
    "value": "u19 championship"
  },
  {
    "key": "u19 elite league",
    "label": "u19 elite league",
    "value": "u19 elite league"
  },
  {
    "key": "u19 league",
    "label": "u19 league",
    "value": "u19 league"
  },
  {
    "key": "u19 liga",
    "label": "u19 liga",
    "value": "u19 liga"
  },
  {
    "key": "u19 ligaen",
    "label": "u19 ligaen",
    "value": "u19 ligaen"
  },
  {
    "key": "u20 championship",
    "label": "u20 championship",
    "value": "u20 championship"
  },
  {
    "key": "u20 league",
    "label": "u20 league",
    "value": "u20 league"
  },
  {
    "key": "u23 league",
    "label": "u23 league",
    "value": "u23 league"
  },
  {
    "key": "uae pro league",
    "label": "uae pro league",
    "value": "uae pro league"
  },
  {
    "key": "uae u21 pro league",
    "label": "uae u21 pro league",
    "value": "uae u21 pro league"
  },
  {
    "key": "uefa champions league qualification",
    "label": "uefa champions league qualification",
    "value": "uefa champions league qualification"
  },
  {
    "key": "uefa europa conference league qualification",
    "label": "uefa europa conference league qualification",
    "value": "uefa europa conference league qualification"
  },
  {
    "key": "uefa europa league qualification",
    "label": "uefa europa league qualification",
    "value": "uefa europa league qualification"
  },
  {
    "key": "ukraine cup",
    "label": "ukraine cup",
    "value": "ukraine cup"
  },
  {
    "key": "usl championship",
    "label": "usl championship",
    "value": "usl championship"
  },
  {
    "key": "usl league one",
    "label": "usl league one",
    "value": "usl league one"
  },
  {
    "key": "varsity challenge",
    "label": "varsity challenge",
    "value": "varsity challenge"
  },
  {
    "key": "veikkausliiga",
    "label": "veikkausliiga",
    "value": "veikkausliiga"
  },
  {
    "key": "victoria state league 1",
    "label": "victoria state league 1",
    "value": "victoria state league 1"
  },
  {
    "key": "virsliga",
    "label": "virsliga",
    "value": "virsliga"
  },
  {
    "key": "vtora liga",
    "label": "vtora liga",
    "value": "vtora liga"
  },
  {
    "key": "vysshaya liga",
    "label": "vysshaya liga",
    "value": "vysshaya liga"
  },
  {
    "key": "western australia premier league women",
    "label": "western australia premier league women",
    "value": "western australia premier league women"
  },
  {
    "key": "western australia state league 1",
    "label": "western australia state league 1",
    "value": "western australia state league 1"
  },
  {
    "key": "western australia state league 1 reserves",
    "label": "western australia state league 1 reserves",
    "value": "western australia state league 1 reserves"
  },
  {
    "key": "wk-league",
    "label": "wk-league",
    "value": "wk-league"
  },
  {
    "key": "ykkonen",
    "label": "ykkonen",
    "value": "ykkonen"
  },
  {
    "key": "ykkosliiga",
    "label": "ykkosliiga",
    "value": "ykkosliiga"
  },
  {
    "key": "youth league",
    "label": "youth league",
    "value": "youth league"
  }
]


class StrategyLineRegularFootball extends React.Component {
  render() {
    // console.log('isPlaceBet: ', this.props.data)
    // console.log('0: ', dayjs(this.props.data.time[0], format))
    const inputClass = `form-control${this.props.data.hasError ? ' is-invalid' : ''}`;

    return (
      <Card>
        <div className="form-group row">
          <div className="col-md-12 col-sm-12">
            Футбол
          </div>
          <div className="col-md-6 col-sm-6">
            <button type="button" data-index={this.props.ind} className="btn btn-warning btn-sm mt-1"
                    onClick={this.props.cloneMoneyLinePosition}>Копировать условие
            </button>
          </div>
          <div className="col-md-6 col-sm-6">
            {this.props.hasRemoveBtn && (
              <button type="button" data-index={this.props.ind} className="btn btn-danger btn-sm mt-1"
                      onClick={this.props.removeMoneyLinePosition}>Удалить условие</button>
            )}
          </div>
        </div>

        {/*<div className="form-row">*/}
        {/*  <div className="form-group col-md-12 col-sm-12">*/}
        {/*    <label>"Ставить" - ставка СТАВИТСЯ при выполнении всей цепочки параметров в условии.</label>*/}
        {/*    <label>"Не ставить" - ставка НЕ СТАВИТСЯ при выполнении хотябы одного параметра в условии.</label>*/}
        {/*    <div className="btn-group btn-group-toggle col-md-12 col-sm-12">*/}
        {/*      <label className={`btn input-pointer btn-secondary${this.props.data.isPlaceBet === true ? ' active' : ''}`}>*/}
        {/*        <input*/}
        {/*            value="place_bet"*/}
        {/*            id={`place_bet-${this.props.ind}`}*/}
        {/*            checked={this.props.data.isPlaceBet === true}*/}
        {/*            // defaultChecked={this.props.data.isPlaceBet === true}*/}
        {/*            onChange={(e) => this.props.changeIsPlaceBet(this.props.ind, e.target.value)}*/}
        {/*            name="isPlaceBet"*/}
        {/*            type="radio"*/}
        {/*            autoComplete="off"*/}
        {/*        />*/}
        {/*        Ставить*/}
        {/*      </label>*/}
        {/*      <label className={`btn input-pointer btn-secondary${this.props.data.isPlaceBet === false ? ' active' : ''}`}>*/}
        {/*        <input*/}
        {/*            value="no_place_bet"*/}
        {/*            id={`no_place_bet-${this.props.ind}`}*/}
        {/*            checked={this.props.data.isPlaceBet === false}*/}
        {/*            // defaultChecked={this.props.data.isPlaceBet === false}*/}
        {/*            onChange={(e) => this.props.changeIsPlaceBet(this.props.ind, e.target.value)}*/}
        {/*            name="isPlaceBet"*/}
        {/*            type="radio"*/}
        {/*            autoComplete="off"*/}
        {/*        />*/}
        {/*        Не ставить*/}
        {/*      </label>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}


        <div className="form-row">
          <div className="form-group col-md-3 col-sm-3">
            <label>Реверс</label>
            <div className="custom-control custom-switch form-control control-no-border">
              <input
                checked={this.props.data.isReverse}
                // defaultChecked={this.props.data.isReverse}
                onChange={this.props.changeIsReverse}
                type="checkbox"
                className="custom-control-input input-pointer"
                id={`isReverse-${this.props.ind}`}
                disabled={!this.props.isPlaceBet}
              />
              <label className="custom-control-label input-pointer" htmlFor={`isReverse-${this.props.ind}`}><span
                className={this.props.data.isReverse ? "badge badge-success" : "badge badge-danger"}>{this.props.data.isReverse ? "YES" : "NO"}</span></label>
            </div>
          </div>
          <div className="form-group col-md-9 col-sm-9"></div>
        </div>


        <div className="form-row">
          <div className="form-group col-md-3 col-sm-3">
            <label>Лиги</label>
            <div className="custom-control custom-switch form-control control-no-border">
              <input
                checked={this.props.data.isLeague}
                // defaultChecked={this.props.data.isLeague}
                onChange={this.props.changeIsLeague}
                type="checkbox"
                className="custom-control-input input-pointer"
                id={`isLeague-${this.props.ind}`}
              />
              <label className="custom-control-label input-pointer" htmlFor={`isLeague-${this.props.ind}`}><span
                className={this.props.data.isLeague ? "badge badge-success" : "badge badge-danger"}>{this.props.data.isLeague ? "YES" : "NO"}</span></label>
            </div>
          </div>

          {this.props.data.isLeague ? (
            <>
              <div className="form-group col-md-3 col-sm-3">
                <label>Букмекер</label>
                <Select
                  // mode="multiple"
                  allowClear
                  // size={"large"}
                  style={{
                    width: '100%'
                  }}
                  placeholder="Букмекер"
                  value={this.props.data.bookmaker}
                  defaultValue={this.props.data.bookmaker}
                  // onChange={this.props.changeLeague}
                  onChange={(value) => this.props.changeBookmaker(this.props.ind, value)}
                  options={bookmakerOptions}
                />
              </div>
              <div className="form-group col-md-6 col-sm-6">
                <label>Выберите Лиги</label>
                <Select
                  mode="multiple"
                  optionRender={(option) => (
                    <Space>
                      <span style={{color: (option.data.value.includes("women") || option.data.value.includes("junior")) ? 'red' : 'black'}}>{option.data.value}</span>
                    </Space>
                  )}
                  allowClear
                  // size={"large"}
                  style={{
                    width: '100%'
                  }}
                  placeholder="Выберите Лиги"
                  value={this.props.data.leagues}
                  defaultValue={this.props.data.leagues}
                  // onChange={this.props.changeLeague}
                  onChange={(value) => this.props.changeLeague(this.props.ind, value)}
                  options={this.props.data.bookmaker === 'simbet' ? leagueSimbetOptions : (this.props.data.bookmaker === 'bet365star' ? leagueBet365starOptions : (this.props.data.bookmaker === '212bet' ? league212betOptions : (this.props.data.bookmaker === 'rabet777' ? leagueRabet777Options : (this.props.data.bookmaker === 'inbet365' ? leagueInbet365Options : []))))}
                />
              </div>
            </>
          ) : (
            <div className="form-group col-md-9 col-sm-9"></div>
          )}

        </div>

        <div className="form-row">
          <div className="form-group col-md-3 col-sm-3">
            <label>Коэффициенты</label>
            <div className="custom-control custom-switch form-control control-no-border">
              <input
                checked={this.props.data.isOdds}
                // defaultChecked={this.props.data.isScores}
                onChange={this.props.changeIsOdds}
                type="checkbox"
                className="custom-control-input input-pointer"
                id={`isOdds-${this.props.ind}`}
              />
              <label className="custom-control-label input-pointer" htmlFor={`isOdds-${this.props.ind}`}><span
                className={this.props.data.isOdds ? "badge badge-success" : "badge badge-danger"}>{this.props.data.isOdds ? "YES" : "NO"}</span></label>
            </div>
          </div>

          {this.props.data.isOdds ? (
            <div className="form-group col-md-9 col-sm-9">
              <div className="form-row">
                <div className="form-group col-md-5 col-sm-5">
                  <label>Мин. коэффициент ставки</label>
                  <Space style={{width: '100%'}}>
                    <InputNumber
                      // addonBefore="Счет"
                      min={1}
                      max={10}
                      step="0.1"
                      value={this.props.data.minOdds}
                      // onChange={this.props.changeFirstTeamScore}
                      onChange={(value) => this.props.changeMinOdds(this.props.ind, value)}
                      // disabled={!this.props.data.isFirstTeamScore}
                    />
                  </Space>
                </div>
                <div className="form-group col-md-5 col-sm-5">
                  <label>Макс. коэффициент ставки</label>
                  <Space style={{width: '100%'}}>
                    <InputNumber
                      // addonBefore="Счет"
                      min={1}
                      max={10}
                      step="0.1"
                      value={this.props.data.maxOdds}
                      onChange={(value) => this.props.changeMaxOdds(this.props.ind, value)}
                      // disabled={!this.props.data.isSecondTeamScore}
                    />
                  </Space>
                </div>
              </div>
            </div>
          ) : (
            <div className="form-group col-md-9 col-sm-9"></div>
          )}

        </div>

        <div className="form-row">
          <div className="form-group col-md-3 col-sm-3">
            <label>Маржа(процент)</label>
            <div className="custom-control custom-switch form-control control-no-border">
              <input
                checked={this.props.data.isMargin}
                // defaultChecked={this.props.data.isScores}
                onChange={this.props.changeIsMargin}
                type="checkbox"
                className="custom-control-input input-pointer"
                id={`isMargin-${this.props.ind}`}
              />
              <label className="custom-control-label input-pointer" htmlFor={`isMargin-${this.props.ind}`}><span
                className={this.props.data.isMargin ? "badge badge-success" : "badge badge-danger"}>{this.props.data.isMargin ? "YES" : "NO"}</span></label>
            </div>
          </div>

          {this.props.data.isMargin ? (
            <div className="form-group col-md-9 col-sm-9">
              <div className="form-row">
                <div className="form-group col-md-5 col-sm-5">
                  <label>Мин. маржа ставки</label>
                  <Space style={{width: '100%'}}>
                    <InputNumber
                      // addonBefore="Счет"
                      min={-10}
                      max={100}
                      step="0.1"
                      value={this.props.data.minMargin}
                      // onChange={this.props.changeFirstTeamScore}
                      onChange={(value) => this.props.changeMinMargin(this.props.ind, value)}
                      // disabled={!this.props.data.isFirstTeamScore}
                    />
                  </Space>
                </div>
                <div className="form-group col-md-5 col-sm-5">
                  <label>Макс. маржа ставки</label>
                  <Space style={{width: '100%'}}>
                    <InputNumber
                      // addonBefore="Счет"
                      min={0}
                      max={100}
                      step="0.1"
                      value={this.props.data.maxMargin}
                      onChange={(value) => this.props.changeMaxMargin(this.props.ind, value)}
                      // disabled={!this.props.data.isSecondTeamScore}
                    />
                  </Space>
                </div>
              </div>
            </div>
          ) : (
            <div className="form-group col-md-9 col-sm-9"></div>
          )}

        </div>

        <div className="form-row">
          <div className="form-group col-md-3 col-sm-3">
            <label>Сумма ставки</label>
            <div className="custom-control custom-switch form-control control-no-border">
              <input
                checked={this.props.data.isMoney}
                // defaultChecked={this.props.data.isScores}
                onChange={this.props.changeIsMoney}
                type="checkbox"
                className="custom-control-input input-pointer"
                id={`isMoney-${this.props.ind}`}
              />
              <label className="custom-control-label input-pointer" htmlFor={`isMoney-${this.props.ind}`}><span
                className={this.props.data.isMoney ? "badge badge-success" : "badge badge-danger"}>{this.props.data.isMoney ? "YES" : "NO"}</span></label>
            </div>
          </div>

          {this.props.data.isMoney ? (
            <div className="form-group col-md-9 col-sm-9">
              <div className="form-row">
                <div className="form-group col-md-5 col-sm-5">
                  <label>Сумма первой ставки</label>
                  <Space style={{width: '100%'}}>
                    <InputNumber
                      // addonBefore="Счет"
                      min={1}
                      max={100000}
                      step="100"
                      value={this.props.data.moneyFirstBet}
                      // onChange={this.props.changeFirstTeamScore}
                      onChange={(value) => this.props.changeMoneyFirstBet(this.props.ind, value)}
                      // disabled={!this.props.data.isFirstTeamScore}
                    />
                  </Space>
                </div>
                <div className="form-group col-md-5 col-sm-5">
                </div>
              </div>
            </div>
          ) : (
            <div className="form-group col-md-9 col-sm-9"></div>
          )}

        </div>

        <div className="form-row">
          <div className="form-group col-md-3 col-sm-3">
            <label>Исходы</label>
            <div className="custom-control custom-switch form-control control-no-border">
              <input
                checked={this.props.data.isOutcome}
                // defaultChecked={this.props.data.isOutcome}
                onChange={this.props.changeIsOutcome}
                type="checkbox"
                className="custom-control-input input-pointer"
                id={`isOutcome-${this.props.ind}`}
              />
              <label className="custom-control-label input-pointer" htmlFor={`isOutcome-${this.props.ind}`}><span
                className={this.props.data.isOutcome ? "badge badge-success" : "badge badge-danger"}>{this.props.data.isOutcome ? "YES" : "NO"}</span></label>
            </div>
          </div>

          {this.props.data.isOutcome ? (
            <div className="form-group col-md-9 col-sm-9">

              <div className="form-row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Выберите Исход</label>
                  <TreeSelect
                    showSearch
                    style={{
                      width: '100%',
                    }}
                    // value={value}
                    // dropdownStyle={{
                    //   maxHeight: 400,
                    //   overflow: 'auto',
                    // }}
                    value={this.props.data.outcome}
                    defaultValue={this.props.data.outcome}
                    onChange={(value) => this.props.changeOutcome(this.props.ind, value)}
                    placeholder="Выберите Исходы"
                    allowClear
                    // multiple
                    // showCheckedStrategy={TreeSelect.SHOW_CHILD}
                    treeDefaultExpandAll={true}
                    // treeCheckable={true}
                    // treeCheckStrictly={false}
                    treeData={outcomesData}
                  />
                </div>
              </div>

              {['over-under-corners-by-1-half_to', 'over-under-corners-by-1-half_tu', 'over-under-corners_to', 'over-under-corners_tu', 'over-under-goals_to', 'over-under-goals_tu', 'over-under-goals-1-half_to', 'over-under-goals-1-half_tu', 'over-under-goals-by-home-team_to', 'over-under-goals-by-home-team_tu', 'over-under-goals-by-away-team_to', 'over-under-goals-by-away-team_tu', 'over-under-gl_to', 'over-under-gl_tu', 'over-under-gl-half-1_to', 'over-under-gl-half-1_tu'].includes(`${this.props.data.outcome}`) ? (
                <div className="form-row">
                  <div className="form-group col-md-12 col-sm-12">
                    <label>Укажите точное число(3.5) или интервал(0-3.5 включительно) для которого выполнится условие</label>
                    <Space style={{width: '100%'}}>
                      <Input
                        // min={0}
                        // max={5}
                        // step="0.5"
                        value={`${this.props.data.intervalOverUnder}`}
                        onChange={(value) => this.props.changeIntervalOverUnder(this.props.ind, value)}
                        // onChange={this.props.changeIntervalOverUnder}
                      />
                    </Space>
                  </div>
                </div>
              ) : (
                <></>
              )}

            </div>
          ) : (
            <div className="form-group col-md-9 col-sm-9"></div>
          )}

        </div>

        <div className="form-row">
          <div className="form-group col-md-3 col-sm-3">
            <label>Время матча</label>
            <div className="custom-control custom-switch form-control control-no-border">
              <input
                checked={this.props.data.isTime}
                // defaultChecked={this.props.data.isTime}
                onChange={this.props.changeIsTime}
                type="checkbox"
                className="custom-control-input input-pointer"
                id={`isTime-${this.props.ind}`}
              />
              <label className="custom-control-label input-pointer" htmlFor={`isTime-${this.props.ind}`}><span
                className={this.props.data.isTime ? "badge badge-success" : "badge badge-danger"}>{this.props.data.isTime ? "YES" : "NO"}</span></label>
            </div>
          </div>

          {this.props.data.isTime ? (
            <div className="form-group col-md-9 col-sm-9">
              <div className="form-row">
                <div className="form-group col-md-5 col-sm-5">
                  <label>От</label>
                  <Space style={{width: '100%'}}>
                    <InputNumber
                      // addonBefore="Счет"
                      min={0}
                      max={100}
                      value={this.props.data.time1}
                      // onChange={this.props.changeFirstTeamScore}
                      onChange={(value) => this.props.changeTime1(this.props.ind, value)}
                      // disabled={!this.props.data.isFirstTeamScore}
                    />
                    {/*<Checkbox*/}
                    {/*    checked={this.props.data.isFirstTeamScore}*/}
                    {/*    onChange={(e) => this.props.changeIsFirstTeamScore(this.props.ind, e.target.checked)}*/}
                    {/*>*/}
                    {/*</Checkbox>*/}
                  </Space>
                </div>
                <div className="form-group col-md-5 col-sm-5">
                  <label>До</label>
                  <Space style={{width: '100%'}}>
                    <InputNumber
                      // addonBefore="Счет"
                      min={0}
                      max={100}
                      value={this.props.data.time2}
                      onChange={(value) => this.props.changeTime2(this.props.ind, value)}
                      // disabled={!this.props.data.isSecondTeamScore}
                    />
                    {/*<Checkbox*/}
                    {/*    checked={this.props.data.isSecondTeamScore}*/}
                    {/*    onChange={(e) => this.props.changeIsSecondTeamScore(this.props.ind, e.target.checked)}*/}
                    {/*>*/}
                    {/*</Checkbox>*/}
                  </Space>
                </div>
              </div>
            </div>
          ) : (
            <div className="form-group col-md-9 col-sm-9"></div>
          )}

        </div>

        <div className="form-row">
          <div className="form-group col-md-3 col-sm-3">
            <label>Счет матча</label>
            <div className="custom-control custom-switch form-control control-no-border">
              <input
                checked={this.props.data.isScores}
                // defaultChecked={this.props.data.isScores}
                onChange={this.props.changeIsScores}
                type="checkbox"
                className="custom-control-input input-pointer"
                id={`isScores-${this.props.ind}`}
              />
              <label className="custom-control-label input-pointer" htmlFor={`isScores-${this.props.ind}`}><span
                className={this.props.data.isScores ? "badge badge-success" : "badge badge-danger"}>{this.props.data.isScores ? "YES" : "NO"}</span></label>
            </div>
          </div>

          {this.props.data.isScores ? (
            <div className="form-group col-md-9 col-sm-9">
              <div className="form-row">
                <div className="form-group col-md-5 col-sm-5">
                  <label>Счет Первой команды</label>
                  <Space style={{width: '100%'}}>
                    <InputNumber
                      // addonBefore="Счет"
                      min={0}
                      max={5}
                      value={this.props.data.firstTeamScore}
                      // onChange={this.props.changeFirstTeamScore}
                      onChange={(value) => this.props.changeFirstTeamScore(this.props.ind, value)}
                      // disabled={!this.props.data.isFirstTeamScore}
                    />
                    {/*<Checkbox*/}
                    {/*    checked={this.props.data.isFirstTeamScore}*/}
                    {/*    onChange={(e) => this.props.changeIsFirstTeamScore(this.props.ind, e.target.checked)}*/}
                    {/*>*/}
                    {/*</Checkbox>*/}
                  </Space>
                </div>
                <div className="form-group col-md-5 col-sm-5">
                  <label>Счет Второй команды</label>
                  <Space style={{width: '100%'}}>
                    <InputNumber
                      // addonBefore="Счет"
                      min={0}
                      max={5}
                      value={this.props.data.secondTeamScore}
                      onChange={(value) => this.props.changeSecondTeamScore(this.props.ind, value)}
                      // disabled={!this.props.data.isSecondTeamScore}
                    />
                    {/*<Checkbox*/}
                    {/*    checked={this.props.data.isSecondTeamScore}*/}
                    {/*    onChange={(e) => this.props.changeIsSecondTeamScore(this.props.ind, e.target.checked)}*/}
                    {/*>*/}
                    {/*</Checkbox>*/}
                  </Space>
                </div>
              </div>
            </div>
          ) : (
            <div className="form-group col-md-9 col-sm-9"></div>
          )}

        </div>

      </Card>
    );
  }
}

export default StrategyLineRegularFootball;
