import React from 'react';
import helpers from "../../../../chunks/helpers";

class AuthHistoryLine extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };

  }

  sayWho(string) {
    let ua = string, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if(/trident/i.test(M[1])){
      tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
      return 'IE '+(tem[1] || '');
    }
    if(M[1]=== 'Chrome'){
      tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
      if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
    if((tem = ua.match(/version\/(\d+)/i))!= null) {
      M.splice(1, 1, tem[1]);
    }
    return M.join(' ');
  }

  render() {
    const auth   = this.props.data;
    const ip   = `${auth.ip}`.replace('::ffff:', '');
    const link   = `https://whatismyipaddress.com/ip/${ip}`

    return (
        <>
          <tr>
            <td>{helpers.dt(auth.createdAt)}</td>
            <td><a rel="noopener noreferrer" href={link} target="_blank">{ip}</a></td>
            <td>
              {Object.keys(auth.user_agent).map((k, i) => {
                return (
                    <div key={i}>
                      {k} <small className="text-muted">{auth.user_agent[k]}</small>
                    </div>
                )
              })}
            </td>
            <td>{auth.browser  ? this.sayWho( String(auth.browser) ) : (
                <i className="text-muted">unknown</i>
            )}</td>
          </tr>
        </>
    );
  }
}

export default AuthHistoryLine;
