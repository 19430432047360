import React from 'react';
import helpers from "../../../chunks/helpers";
import routes from "../../../chunks/routes";
import {Link, withRouter} from "react-router-dom";

class TemplateEntity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: false
    };
  }

  render() {
    const bot = this.props.data;
    const url = routes.users.profile.replace(':username', bot.username);

    return (
        <>
          <tr key={this.key}>
            <td>
              <strong className="mr-2">{bot.name}</strong>
            </td>
            {this.props.accessToUser && (
                <td>
                  {bot.username && (
                      <Link to={url}>{bot.username}</Link>
                  )}
                  {!bot.username && (
                      <i className="text-muted">unknown</i>
                  )}
                </td>
            )}
            <td>
              <div>{bot.oddsRanges.map((userData) => <span className="badge badge-secondary mr-1">{userData.coef}: <u>${userData.money}</u></span> )}</div>
            </td>
            <td>
              <small>
                <div>Created At: <i className="text-secondary">{ helpers.dt(bot.createdAt)}</i></div>
                {bot.updatedAt && (
                    <div>Updated At: <i className="text-info">{ helpers.dt(bot.updatedAt) }</i></div>
                )}
              </small>
            </td>
            <td>
              <div className="btn-group">
                <button type="button" onClick={() => this.props.updateRange(bot)} data-toggle="modal" data-target=".modal-money-update" className="btn btn-info">Update</button>
                <button type="button" onClick={() => this.props.deleteRange(bot)} className="btn btn-danger">Delete</button>
              </div>
            </td>
          </tr>
        </>
    );
  }
}

export default withRouter(TemplateEntity);
