import React, {Component} from 'react';
import helpers from "../../../chunks/helpers";

class PlacedBetRegularForkHistoryEntity extends Component {
  constructor(props) {
    super(props);

    this.state = {};

  }

  render() {
    const history = this.props.data;

    // const secondScore = '15:21 (11:19 4:2) 07:34'

    // let secondTotalScore = ''
    // let secondGameTime = ''
    // let secondScoreQuarter1 = ''
    // let secondScoreQuarter2 = ''
    // let secondScoreQuarter3 = ''
    // let secondScoreQuarter4 = ''
    // let secondCountQuarter = 0
    //
    // if (history.secondScore) {
    //   secondTotalScore = `${history.secondScore}`.split(/\s+/g).shift()
    //   secondGameTime = `${history.secondScore}`.split(/\s+/g).pop()
    // }
    //
    // const sc1 = `${history.secondScore}`.split(/ \(/g)
    // if (sc1) {
    //   if (sc1[1]) {
    //     const sc2 = sc1[1].split(/\)/g)
    //     if (sc2) {
    //       const sc3 = sc2[0].split(/ /g);
    //       if (sc3) {
    //         secondCountQuarter = sc3.length
    //         for (let i = 0; i < sc3.length; i++) {
    //           const sc4 = sc3[i];
    //           if (i === 0) {
    //             secondScoreQuarter1 = sc4
    //           }
    //           if (i === 1) {
    //             secondScoreQuarter2 = sc4
    //           }
    //           if (i === 2) {
    //             secondScoreQuarter3 = sc4
    //           }
    //           if (i === 3) {
    //             secondScoreQuarter4 = sc4
    //           }
    //         }
    //       }
    //     }
    //   }
    // }

    const currency1 = <span style={{fontSize: "8px"}}><strong>{history.firstCurrency}</strong></span>
    const currency2 = <span style={{fontSize: "8px"}}><strong>{history.secondCurrency}</strong></span>

    return (
        <>
          <tr key={this.key}
              className={(history.firstStatus === 'accepted' && history.secondStatus === 'accepted') ? (history.marginMoney >= 0 ? 'accepted-tr' : 'remove-tr') : ''}>
            {/*<tr key={this.key} className={history.status === 'accepted' ? 'accepted-tr' : (history.isGameClosed ? 'remove-tr' : '')}>*/}
            <td>
              <div>{helpers.dt(history.createdAt)}</div>
              {history.updatedAt && (
                <small>
                  <div>Last update: {helpers.dt(history.updatedAt)}</div>
                </small>
              )}
            </td>
            <td><p
              className={history.firstStatus === 'accepted' ? 'green-text' : (history.firstStatus === 'failed' ? 'red-text' : (history.firstStatus === 'closed' ? 'text-warning' : ''))}>
              <strong>{history.firstStatus.toUpperCase()}</strong></p></td>
            <td><p
              className={history.secondStatus === 'accepted' ? 'green-text' : (history.secondStatus === 'failed' ? 'red-text' : (history.secondStatus === 'closed' ? 'text-warning' : ''))}>
              <strong>{history.secondStatus.toUpperCase()}</strong></p></td>
            <td><strong>{history.sport}</strong></td>
            <td>
              <div>{history.firstLeague}</div>
              <br/>
              <div>{history.secondLeague}</div>
            </td>
            <td>
              <div><a rel="noreferrer" target="_blank"
                      href={`${history.firstEventUrl}`}>{`${history.firstBookmakerName}`.replace('loot', 'nitrobetting').replace('efbet', 'ifortuna').replace('betflag', 'inbet365').replace('zenit', 'chance').replace('omg', '212bet').replace('ligastavok', 'lottoland').replace('vbet', 'winnbet').replace('gorilla', 'newgioco').replace('unikrn', 'rabet777').replace('dafabet_df', 'winbet_bg').replace('ladbrokes', 'ladbrokes_be').replace('bwin_it', 'superbet_ro').replace('bwin', 'superbet_pl').replace('sbobet', 'simbet').replace('tonybet', 'tipsport').replace('1win', 'cloudbet_fifa').toUpperCase()}</a>
              </div>
              <div>{history.firstEventName}</div>
            </td>
            <td>
              <div><a rel="noreferrer" target="_blank"
                      href={`${history.secondEventUrl}`}>{`${history.secondBookmakerName}`.replace('loot', 'nitrobetting').replace('efbet', 'ifortuna').replace('zenit', 'chance').replace('ligastavok', 'lottoland').replace('gorilla', 'newgioco').replace('vbet', 'winnbet').replace('dafabet_df', 'winbet_bg').replace('ladbrokes', 'ladbrokes_be').replace('bwin_it', 'superbet_ro').replace('bwin', 'superbet_pl').replace('betflag', 'inbet365').replace('omg', '212bet').replace('unikrn', 'rabet777').replace('sbobet', 'simbet').replace('tonybet', 'tipsport').replace('1win', 'cloudbet_fifa').toUpperCase()}</a>
              </div>
              <div>{history.secondEventName}</div>
            </td>
            {/*<td>{history.firstOutcomeName}</td>*/}
            {/*<td>{history.secondOutcomeName}</td>*/}
            <td>
              <div>First:</div>
              <div><strong>{history.firstScore}</strong></div>
              <div>Second:</div>
              <div><strong>{history.secondScore}</strong></div>
              <div>Время матча:</div>
              <div><strong>{history.gameTime}</strong></div>
            </td>
            {/*<td>{history.secondScore}</td>*/}
            <td>
              <div><span className="badge badge-primary badge-font"><strong>{history.betFirstOdds}</strong></span></div>
              <div><span className="badge badge-primary badge-font">{history.betFirstTime ? helpers.dtt(history.betFirstTime) : ''}</span></div>
              <br/>
              <div><span className="badge badge-secondary badge-font"><strong>{history.firstOdds}</strong></span></div>
              <div><small><span
                className="badge badge-secondary badge-font"><strong>{history.firstOddsUpdateDate ? helpers.dtt(history.firstOddsUpdateDate) : ''}</strong></span></small>
              </div>
              {/*<div><span className={history.firstIsLive ? 'blue-text' : 'gray-text'}><strong>{history.firstOdds}</strong></span></div>*/}

              <small>
                <div style={{visibility: "hidden"}}>.</div>
                <div><strong>{history.firstBetName}</strong></div>
                {/*<div>{history.firstOddsUpdateDate ? helpers.dtt(history.firstOddsUpdateDate) : ''}</div>*/}
                <div>Outcome:</div>
                <div><strong>{history.firstOutcomeName}</strong></div>
              </small>
            </td>
            <td>
              <div><span className="badge badge-primary badge-font"><strong>{history.betSecondOdds}</strong></span> | <span
                className={history.betMarginPercent === 0 ? "badge badge-secondary badge-font" : (history.betMarginPercent > 0 ? "badge badge-success badge-font" : "badge badge-danger badge-font")}><strong>{history.betMarginPercent}</strong> <i
                className="fa fa-percent"/></span></div>
              <div><span className="badge badge-primary badge-font">{history.betSecondTime ? helpers.dtt(history.betSecondTime) : '-'}</span></div>
              {/*<div><span className={history.secondIsLive ? 'blue-text' : 'gray-text'}><strong>{history.secondOdds}</strong></span></div>*/}
              <br/>
              <div><span className="badge badge-secondary badge-font"><strong>{history.secondOdds}</strong></span> | <span
                className={history.marginPercent === 0 ? "badge badge-secondary badge-font" : (history.marginPercent > 0 ? "badge badge-success badge-font" : "badge badge-danger badge-font")}><strong>{history.marginPercent}</strong> <i
                className="fa fa-percent"/></span></div>
              <div><small><span
                className="badge badge-secondary badge-font"><strong>{history.secondOddsUpdateDate ? helpers.dtt(history.secondOddsUpdateDate) : ''}</strong></span></small>
              </div>

              <small>
                <div style={{visibility: "hidden"}}>.</div>
                <div><strong>{history.secondBetName}</strong></div>
                {/*<div>{history.secondOddsUpdateDate ? helpers.dtt(history.secondOddsUpdateDate) : ''}</div>*/}
                <div>Outcome:</div>
                <div><strong>{history.secondOutcomeName}</strong></div>
              </small>
            </td>
            <td>
              <div>1 Принятая: <span
                className={history.betFirstMoneyBet === 0 ? "badge badge-secondary badge-font" : "badge badge-success badge-font"}><strong>{history.betFirstMoneyBet}</strong></span> {currency1}
              </div>
              <div>2 Принятая: <span
                className={history.betSecondMoneyBet === 0 ? "badge badge-secondary badge-font" : "badge badge-success badge-font"}><strong>{history.betSecondMoneyBet}</strong></span> {currency2}
              </div>
              <div>1 Начальная: <span><strong>{history.firstMoneyBet}</strong></span> {currency1}</div>
              <div>2 Начальная: <span><strong>{history.secondMoneyBet}</strong></span> {currency2}</div>
              <div>2 без конверсии: <span><strong>{Number(history.secondMoneyBetOriginal).toFixed(2)}</strong></span> {currency1}</div>
              <div>Конверсия: <span><strong>{history.exchangeRate || 1}</strong></span></div>
            </td>
            <td>
              <div><span
                className={history.betMarginMoney === 0 ? "badge badge-secondary badge-font" : (history.betMarginMoney > 0 ? "badge badge-success badge-font" : "badge badge-danger badge-font")}><strong>{Number(history.betMarginMoney).toFixed(3)}</strong></span> {currency1}
              </div>
              <br/>
              <div><span className="badge badge-secondary badge-font"><strong>{Number(history.marginMoney).toFixed(3)}</strong></span> {currency1}
              </div>
            </td>

          </tr>
        </>
    );
  }
}

export default PlacedBetRegularForkHistoryEntity;
