const required = (() => {
    switch (window.location.host) {
        case "bet365-point.billy-lg.com":
        case "billy-lg.com":
            return "bet365";
        case "eurobet.billy-lg.com":
            return "eurobet";
        case "parimatch.billy-lg.com":
            return "parimatch";
        default:
            return "bet365";
    }
})();
const filters = {
    default: {
        s: {
            "american-football": false,
            "ice-hockey": false,
            "table-tennis": false,
            badminton: false,
            baseball: false,
            futsal: false,
            rugby: false,
            handball: false,
            football: false,
            tennis: false,
            basketball: false,
            volleyball: false,
            cybersport: false,
        }, // +
        p: 0, // +
        pmax: 100, // +
        bti: [], // +
        r: [], // +
        from: 0, // +
        to: 30, // +
        reversed: "both", // +
        gomin: 0, // +
        gomax: 100, // +
        currGame: false, // +
        o: {}, // min odds by bookmakers
        omax: {}, // max odds by bookmakers
        init: true, // +
        b: (() => {
            const str =
                "10bet,188bet,1wxx,1xbit,5dimes_eu,bet365,betcoin,betfair,betfair_it,betonline,betvictor,betway,bookmaker,bwin,bwin_it,cbet,cloudbet,efbet,energybet,eurobet,europebet,fonbet,fortunejack,ggbet,goldbet,gorilla,hotderby,klondaika,ladbrokes,leon_ru,ligastavok,loot,marathon_mobile,marathon_mobile_it,melbet,mostbet,novibet,olimp,omg,paddypower,parimatch,parimatch_ru,pinnacle,planetwin,sbobet,snai,sportsbet,stake,stanleybet,startbet,texsportbet,tonybet,unibet,unikrn,virginbet,willhill,willhill_it,winline,zenit";
            const prt = str.split(",");
            const res = {};

            for (let entry of prt) {
                res[entry] = entry === required ? [] : false;
            }

            return res;
        })(),
    },
    mix(data) {
        const filters = JSON.parse(JSON.stringify(this.default));

        for (let key in data) {
            const entry = data[key];

            if (typeof entry === "object") {
                for (let v in entry) {
                    if (entry[v] !== false) {
                        filters[key][v] = entry[v];
                    }
                }
            } else {
                filters[key] = entry;
            }
        }

        if (filters.r.indexOf(this.required) === -1) {
            filters.r.push(this.required);
        }

        return filters;
    },
    required,
};

export default filters;
