import React, {Component} from 'react';
import './style.css';

import title from "../../../chunks/title";

import {withRouter, Link} from "react-router-dom";
import backend from "../../../chunks/backend";
import helpers from "../../../chunks/helpers";
import sub from "../../../chunks/subscriber";
import routes from "../../../chunks/routes";

class GameDetailed extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subIds: [],
      loaded: false,
      game: {},
      page: 1,
    };

    this.updateData = this.updateData.bind(this);
    this.showPage = this.showPage.bind(this);
    this.unsubscribeFromAll = this.unsubscribeFromAll.bind(this);
  }

  get gameExists() {
    return (this.state.game && this.state.game.books);
  }

  componentDidMount() {
    if (this.props.detailed) {
      const ids = [];

      for (let event of ['games.created', 'games.stored', 'games.deleted', 'games.bookmaker_removed']) {
        const id = sub.subscribe(event, (data) => {
          if (!this.gameExists) {
            this.unsubscribeFromAll();
          } else {
            if ('slug' in this.state.game && this.state.game.slug === data.slug) {
              this.updateData();
            }
          }
        });

        ids.push(id);
      }

      this.setState({ subIds: ids });
    }

    title(this.gameTitle());
    this.updateData()
  }

  unsubscribeFromAll() {
    if (this.props.detailed) {
      for (let id of this.state.subIds) {
        sub.unsubscribe(id);
      }
    }
  }

  componentWillUnmount() {
    this.unsubscribeFromAll();
  }

  updateData() {
    if (this.props.detailed) {
      backend.get(`/games/live/${this.props.match.params.sport}/${this.props.match.params.name}`).then(({data}) => {
        this.setState({game: data.game});
        title(this.gameTitle())
      }).catch(console.log).finally(() => {
        this.setState({
          loaded: true
        });
      });
    } else {
      backend.get(`/games/history/${this.props.match.params.sport}/${this.props.match.params.slug}`).then(({ data }) => {
        this.setState({
          game: Array.isArray(data.details) ? data.details[0] : {}
        })
        title(`Archive game`);
      }).catch(console.log).finally(() => {
        this.setState({
          loaded: true
        });
      })
    }
  }

  gameTitle() {
    try {
      return (this.state.game.books ? `${this.state.game.books[0].home} - ${this.state.game.books[0].away}` : this.props.match.params.name);
    } catch (e) {
      return 'Game over';
    }
  }

  showPage(page) {
    this.setState({ page }, () => {
      this.updateData();
    });
  }

  render() {
    console.log('Game --- ', this.state.game)
    return (
        <>
          {this.state.loaded && (
              (this.gameExists) ? (
                  <>
                    <div className="page-title mb-2">
                      <h4>{this.gameTitle()}</h4>
                      {(!this.props.detailed && this.gameExists) && (
                          <div className="text-danger">Game over: <u>{helpers.dt(this.state.game.createdAt)}</u></div>
                      )}
                    </div>
                    <div className="clearfix"/>
                    <div className="row margin-top-profile">
                      <div className="col-md-6 col-xs-12">
                        <div className="x_panel">
                          <div className="x_title">
                            <h2>Actual bets ({ Object.values(this.state.game.bets).length })</h2>
                            <div className="clearfix"/>
                          </div>
                          <div className="x_content">
                            <div className="table-responsive">
                              {this.state.game && this.state.game.bets ? (
                                  Object.values(this.state.game.bets).map((e, i) => (
                                      <p key={i}>
                                        <mark>{e.name}</mark>
                                        <span className="badge badge-secondary ml-2">Odds: <u>{e.odds}</u></span>
                                        <span className="badge badge-secondary ml-2">Value: <u>{e.value}</u></span>
                                        <span className="badge badge-secondary ml-2">Team related: <u>{e.pingIf === 'any' ? 'no team' : e.pingIf}</u></span>
                                        <br />
                                        <small className="text-muted">ID: <strong>{e.id}</strong></small>
                                      </p>
                                  ))
                              ) : (
                                  <div>No data</div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-xs-12">
                        <div className="x_panel">
                          <div className="x_title">
                            <h2>Books ({this.state.game.books.length})</h2>
                            <div className="clearfix"/>
                          </div>
                          <div className="x_content">
                            <div className="table-responsive">
                              <table className="table table-hover bulk_action jambo_table table-striped">
                                <thead>
                                <tr className="headings">
                                  <th className="column-title" style={{ width: '160px' }}>Timestamp</th>
                                  <th className="column-title">Source</th>
                                  <th className="column-title">Teams</th>
                                  <th className="column-title">Score</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.loaded && (
                                    this.state.game && this.state.game.books && this.state.game.books.length > 0 ? (
                                        this.state.game.books.map((book, i) => {
                                          const scoresNew = book.scores.filter(s => {
                                            return s.scoreTime !== '';
                                          });

                                          return (
                                              <tr key={i}>
                                                <td>
                                                  <i className="text-muted">{helpers.dt(book.timeStamp)}</i>
                                                </td>
                                                <td>
                                                  {/*{this.props.user.hasAccess('games.view_game_url') ? (*/}
                                                  {/*    <a rel="noopener noreferrer" href={book.url} target="_blank"><strong className="blue-text">{book.bookmaker}</strong></a>*/}
                                                  {/*) : (*/}
                                                  {/*    <strong className="text-secondary">{book.bookmaker}</strong>*/}
                                                  {/*)}*/}

                                                  <strong className="text-secondary">{book.bookmaker}</strong>
                                                </td>
                                                <td>
                                                  <pre>
                                                    <div>Home: <strong>{book.home}</strong></div>
                                                    <div>Away: <strong>{book.away}</strong></div>
                                                  </pre>
                                                </td>
                                                <td>
                                                  <pre>
                                                    {scoresNew && scoresNew.length > 0 ? (
                                                        scoresNew.map((sc, i2) => {
                                                          const tempScore = `${sc.score[0]}:${sc.score[1]}`;
                                                          const score = book.pings ? book.pings[tempScore] : null;

                                                          if (!sc.scoreTime) {
                                                            return '';
                                                          }

                                                          return (
                                                              <>
                                                                <div key={i2}>
                                                                  <mark>{tempScore}</mark>
                                                                  <i className="text-muted ml-2">{helpers.dt(sc.scoreTime)}</i>
                                                                  {score && (
                                                                      <>
                                                                        <i className="fa fa-chevron-circle-down fa-lg green-text ml-2" />
                                                                        {'teamScoreParser' in score && (
                                                                            <span className="ml-2">Parser result: <u>{score.teamScoreParser}</u></span>
                                                                        )}
                                                                        {'teamScoreCalculator' in score && (
                                                                            <span className="ml-2">Calculator result: <u>{score.teamScoreCalculator.pingIf}</u></span>
                                                                        )}
                                                                        {'deliveredToBots' in score && (
                                                                            <div>
                                                                              <span className="ml-2">Bots:
                                                                                {score.deliveredToBots.map((bot, i) => (
                                                                                    <>
                                                                                      {typeof bot === 'string' && (
                                                                                          <>
                                                                                            {i !== 0 && <span>, </span>}
                                                                                            <Link to={String(routes.bots.detailed).replace(':name', bot)} className="ml-1">{bot}</Link>
                                                                                          </>
                                                                                      )}
                                                                                      {typeof bot === 'object' && (
                                                                                          <>
                                                                                            {i !== 0 && <span>, </span>}
                                                                                            {bot.sent ? (
                                                                                                <strong>
                                                                                                  <Link to={String(routes.bots.detailed).replace(':name', bot.name)} className="ml-1">{bot.name}</Link>
                                                                                                </strong>
                                                                                            ) : (
                                                                                                <strike>
                                                                                                  <Link to={String(routes.bots.detailed).replace(':name', bot.name)} className="ml-1">{bot.name}</Link>
                                                                                                </strike>
                                                                                            )}
                                                                                          </>
                                                                                      )}
                                                                                    </>
                                                                                ))}
                                                                              </span>
                                                                            </div>
                                                                        )}
                                                                      </>
                                                                  )}
                                                                </div>
                                                              </>
                                                          );
                                                        })
                                                    ) : (
                                                        <div/>
                                                    )}
                                                  </pre>
                                                </td>
                                              </tr>
                                          );
                                        })
                                    ) : (
                                        <tr>
                                          <td colSpan="5">List is empty</td>
                                        </tr>
                                    )
                                )}
                                {!this.state.loaded && (
                                    <tr>
                                      <td colSpan="5"><i className="text-muted">Loading...</i></td>
                                    </tr>
                                )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
              ) : (
                  <div className="page-title mt-4">
                    <div className="alert alert-danger mt-4 pt-2" role="alert">Game over</div>
                  </div>
              )
          )}
          {!this.state.loaded && (
              <div>Loading...</div>
          )}
        </>
    );
  }
}

export default withRouter(GameDetailed);
