import React from "react";
import list from "./src/outcomes-list";

class HandicapsOutcomesComponent extends React.Component {
    constructor(props) {
        super(props);

        const sports = list;
        const active = 'active' in this.props && Array.isArray(this.props.active) ? this.props.active : [];
        const inType = ('type' in this.props) ? this.props.type : 'radio';

        for (let entry of sports) {
            entry.isActive = (active.indexOf(entry.filter) !== -1);
        }

        this.state = { sports, type: inType };
        this.toggleSport = this.toggleSport.bind(this);
        this.sportBtnClass = this.sportBtnClass.bind(this);
    }

    toggleSport(e) {
        const sport    = e.target.getAttribute('data-sport');
        const filter   = e.target.getAttribute('data-filter');
        const {sports} = this.state;
        let result = [];

        if (this.props.selectSport) {
            this.props.selectSport(sport, filter);
            return;
        }

        for (let entry of sports) {
            if (entry.filter === filter) {
                entry.isActive = !entry.isActive;
            }

            if (entry.isActive) {
                result.push(entry.filter);
            }
        }

        this.setState({ sports });
        this.props.setSports(result);
    }

    sportBtnClass(isActive) {
        let res = `btn btn-secondary btn-sports`;

        if (isActive) {
            res += ' active';
        }

        return res;
    }

    render() {
        return (
            <>
                {this.state.sports.map((entry) => {
                    return (
                        <button type="button" data-filter={entry.filter} data-sport={entry.sport} key={entry.filter} className={this.sportBtnClass(entry.isActive)} onClick={this.toggleSport}>
                            {this.state.type === 'radio' && (
                                <input type={this.state.type} className="join-btn"/>
                            )}
                            {entry.title}
                        </button>
                    );
                })}
            </>
        );
    }
}

export default HandicapsOutcomesComponent;
