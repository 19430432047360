import React from 'react';
import helpers from "../../../chunks/helpers";
import routes from "../../../chunks/routes";
import {Link, withRouter} from "react-router-dom";

class TemplateEntity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: false
    };
  }

  render() {
    const flat = this.props.data;
    const url = routes.users.profile.replace(':username', flat.username);

    return (
        <>
          <tr key={this.key}>
            <td>
              <strong className="mr-2">{flat.name}</strong>
            </td>
            <td>
              <strong className="mr-2">{flat.percentBalance}</strong>
            </td>
            <td>
              <strong className="mr-2">{flat.moneyRange}</strong>
            </td>
            <td>
              <strong className="mr-2">{flat.description}</strong>
            </td>
            {this.props.accessToUser ? (
                <td>
                  {flat.username && (
                      <Link to={url}>{flat.username}</Link>
                  )}
                  {!flat.username && (
                      <i className="text-muted">unknown</i>
                  )}
                </td>
            ) : (
                <td>
                  <div className="text-muted">{flat.username}</div>
                </td>
              )}
            <td>
              <small>
                <div>Created At: <i className="text-secondary">{ helpers.dt(flat.createdAt)}</i></div>
                {flat.updatedAt && (
                    <div>Updated At: <i className="text-info">{ helpers.dt(flat.updatedAt) }</i></div>
                )}
              </small>
            </td>
            <td>
              <div className="btn-group">
                <button type="button" onClick={() => this.props.updateRange(flat)} data-toggle="modal" data-target=".modal-money-update" className="btn btn-info">Update</button>
                <button type="button" onClick={() => this.props.deleteRange(flat)} className="btn btn-danger">Delete</button>
                <button type="button" onClick={() => this.props.copyRange(flat)} className="btn btn-warning">Copy</button>
              </div>
            </td>
          </tr>
        </>
    );
  }
}

export default withRouter(TemplateEntity);
