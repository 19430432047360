import React from 'react';

import {withRouter} from "react-router-dom";
import { Link } from "react-router-dom";
import routes from "../../../../chunks/routes";

class BotsLine extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: false
    };

    this.showPassword = this.showPassword.bind(this);
  }

  showPassword() {
    this.setState({
      showPassword: true
    });
  }

  render() {
    const bot  = this.props.data;
    const link = routes.bots.detailed.replace(':name', bot.name);

    return (
        <>
          <tr key={this.key}>
            <td>
              <Link to={link}>{bot.name}</Link>
              <pre className="mb-0">
                {bot.online ? (<small className="text-success">Online</small>) : (<small className="text-muted">Offline</small>)}
              </pre>
            </td>
            <td>
              {this.state.showPassword ? (
                  bot.password
              ) : (
                  <span style={{ cursor: 'pointer' }} onClick={this.showPassword} className="badge badge-danger">Show password</span>
              )}
            </td>
            <td>
              <a href={bot.mirror} target="_blank" rel="noreferrer">{bot.mirror}</a>
              <div>{bot.workStatus}</div>
            </td>
            <td>{bot.balance ? (
                <>
                  $<strong className="text-success">{bot.balance}</strong>
                </>
            ) : (
                <span className="text-muted"><strong>{bot.balance}</strong></span>
                // <span className="text-muted">unknown</span>
            )}</td>
            <td>
              <div className="btn-group-sm btn-group">
                <Link to={routes.bots.detailed.replace(':name', bot.name)} className="btn btn-info">Show</Link>
                {!bot.banned && (
                    <button type="button" onClick={() => this.props.banBot(bot)} className="btn btn-warning">Ban</button>
                )}
                {bot.banned && (
                    <button type="button" onClick={() => this.props.unBanBot(bot)} className="btn btn-dark">UnBan</button>
                )}
                {!bot.deletedAt && (
                    <button type="button" onClick={() => this.props.deleteBot(bot)} className="btn btn-danger">Delete</button>
                )}
                {bot.deletedAt && this.props.user.hasAccess('users.trash_bot') && (
                    <button type="button" onClick={() => this.props.trashBot(bot)} className="btn btn-danger">Trash</button>
                )}
                {bot.deletedAt && (
                    <button type="button" onClick={() => this.props.restoreBot(bot)} className="btn btn-dark">Restore</button>
                )}
              </div>
            </td>
          </tr>
        </>
    );
  }
}

export default withRouter(BotsLine);
