import React from "react";
import Chart from "chart.js";

class BarChart extends React.Component {
    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
    }

    componentDidMount() {
        console.log( this.props.data );
        this.myChart = new Chart(this.chartRef.current, {
            type: 'bar',
            data: this.props.data,
            options: {
                maintainAspectRatio: false,
            }
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.myChart.data.labels   = this.props.data.labels;
        this.myChart.data.datasets = this.props.data.datasets;
        this.myChart.update();
    }

    render() {
        return (
            <canvas ref={this.chartRef} style={{ height: '300px' }} />
        );
    }
}

export default BarChart
