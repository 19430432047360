import React from "react";
import helpers from "../../chunks/helpers";
import title from "../../chunks/title";

import ExtensionsList from "./src/ExtensionsList";
import backend from "../../chunks/backend";
import routes from "../../chunks/routes";

class ExtensionPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            uploading: false,
            file:   null,
            bookmaker:  'bet365',
            title:  '',
            version: '',
            description: '',
            errors: {
                file: '',
                bookmaker: '',
                title: '',
                version: '',
                description: ''
            }
        };

        this.onFileSelect  = this.onFileSelect.bind(this);
        this.onUploadForm = this.onUploadForm.bind(this);
        this.clearSelected = this.clearSelected.bind(this);
        this.onChangeTitle = this.onChangeTitle.bind(this);
        this.onChangeBookmaker = this.onChangeBookmaker.bind(this);
        this.onChangeVersion = this.onChangeVersion.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);
    }

    componentDidMount() {
        title('Extension List');
        this.setState({
            loaded: true
        });
    }

    onChangeTitle(e) {
        const title = e.target.value.toString();
        const {errors} = this.state;
        errors.title   = '';
        this.setState({ title, errors });
    }

    onChangeBookmaker(e) {
        const bookmaker = e.target.value.toString();
        const {errors} = this.state;
        errors.bookmaker   = '';
        this.setState({ bookmaker, errors });
    }

    onChangeVersion(e) {
        const version  = e.target.value.toString();
        const {errors} = this.state;
        errors.version = '';
        this.setState({ version, errors });
    }

    onChangeDescription(e) {
        const description  = e.target.value.toString();
        const {errors}     = this.state;
        errors.description = '';
        this.setState({ description, errors });
    }

    onFileSelect(e) {
        const {errors} = this.state;
        errors.file = '';

        this.setState({
            file: e.target.files[0] || null,
            errors
        });
    }

    clearSelected() {
        this.setState({
            file: null
        });
    }

    onUploadForm() {
        let hasErrors  = false;
        const {errors} = this.state;
        const checkF   = ['title', 'bookmaker', 'file', 'version', 'description'];

        for (let field of checkF) {
            if (!this.state[field] || this.state[field] === '') {
                errors[field] = `Required field`;
                hasErrors = true;
            } else {
                errors[field] = '';
            }
        }

        if (!hasErrors) {
            this.setState({ uploading: true });

            const formData = new FormData();
            formData.append('title', this.state.title);
            formData.append('bookmaker', this.state.bookmaker);
            formData.append('version', this.state.version);
            formData.append('description', this.state.description);
            formData.append('file', this.state.file);
            backend.post(routes.extension.upload, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((msg) => {
                if (msg.data.success) {
                    this.setState({
                        bookmaker:  'bet365',
                        title:  '',
                        version: '',
                        description: '',
                    })
                }
            }).catch(console.log).finally(() => {
                this.setState({ uploading: false });
            })
        }

        this.setState({ errors });
    }

    render() {
        return (
            <>
                {!this.state.loaded && (
                    <div>Loading...</div>
                )}
                {this.state.loaded && (
                    <>
                        <div className="card mb-4">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">Create extension</h5>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-xs-12 col-md-4" style={{ height: '200px', position: 'relative' }}>
                                            {!this.state.file && (
                                                <label htmlFor="select_extension" style={{ cursor: 'pointer', margin: 'auto', width: 'auto', top: 0, left: 0, right: 0, bottom: 0, position: 'absolute', height: '25px', textAlign: 'center' }}>
                                                    Click to select file
                                                    {this.state.errors.file && (
                                                        <span className="text-danger ml-1">{this.state.errors.file}</span>
                                                    )}
                                                    <input type="file" accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed" id="select_extension" style={{visibility: 'hidden', width: 0, height: 0, overflow: 'hidden'}} onChange={this.onFileSelect} />
                                                </label>
                                            )}
                                            {this.state.file && (
                                                <div style={{ marginTop: '45px' }}>
                                                    <div>Name: <strong>{this.state.file.name}</strong></div>
                                                    <div>Size: <strong>{(this.state.file.size / 1024).toFixed(2)}Kb</strong></div>
                                                    <div>Type: <strong>{this.state.file.type}</strong></div>
                                                    <div>Last Modified: <i className="text-muted">{helpers.dt(this.state.file.lastModified)}</i></div>
                                                    <button onClick={this.clearSelected} type="button" className="btn btn-sm btn-warning">Clear</button>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-xs-12 col-md-8">
                                            <div className="row">
                                                <div className="col-xs-4 col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="ext_bookmaker">Bookmaker</label>
                                                        <select
                                                            className="form-control input-pointer"
                                                            defaultValue={this.state.bookmaker}
                                                            onChange={this.onChangeBookmaker}
                                                            id="ext_bookmaker"
                                                        >
                                                            <option key="bet365" value="bet365">Bet365</option>
                                                            <option key="pinnacle" value="pinnacle">Pinnacle</option>
                                                            <option key="superbet_pl" value="superbet_pl">Superbet_pl</option>
                                                            <option key="superbet_ro" value="superbet_ro">Superbet_ro</option>
                                                            <option key="betfair" value="betfair">Betfair</option>
                                                            <option key="ladbrokes_be" value="ladbrokes_be">Ladbrokes_be</option>
                                                            <option key="tipsport" value="tipsport">Tipsport</option>
                                                        </select>

                                                        {/*{this.state.errors.bookmaker && ( <span className="text-danger ml-1">{this.state.errors.bookmaker}</span> )}*/}
                                                        {/*<input type="text" className="form-control" id="ext_bookmaker" value={this.state.bookmaker} onChange={this.onChangeBookmaker} />*/}
                                                    </div>
                                                </div>
                                                <div className="col-xs-4 col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="ext_title">Title</label>
                                                        {this.state.errors.title && ( <span className="text-danger ml-1">{this.state.errors.title}</span> )}
                                                        <input type="text" className="form-control" id="ext_title" value={this.state.title} onChange={this.onChangeTitle} />
                                                    </div>
                                                </div>
                                                <div className="col-xs-4 col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="ext_ver">Version</label>
                                                        {this.state.errors.version && ( <span className="text-danger ml-1">{this.state.errors.version}</span> )}
                                                        <input type="text" className="form-control" id="ext_ver" value={this.state.version} onChange={this.onChangeVersion} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-xs-12 col-md-12">
                                                    <div className="form-group">
                                                        <label htmlFor="ext_description">Description</label>
                                                        {this.state.errors.description && ( <span className="text-danger ml-1">{this.state.errors.description}</span> )}
                                                        <textarea style={{resize: 'none'}} rows="3" id="ext_description" className="form-control" value={this.state.description} onChange={this.onChangeDescription} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button type="button" className="btn btn-dark" onClick={this.onUploadForm}>Upload</button>
                                </div>
                            </div>
                        </div>
                        {!this.state.uploading && (
                            <ExtensionsList />
                        )}
                        {this.state.uploading && (
                            <div className="mt-2">
                                <div className="progress">
                                    <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: '100%' }}/>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </>
        );
    }
}

export default ExtensionPage;
