import React from 'react';

import UsersList from "./src/UsersList";
import title from "../../chunks/title";
import permissions from "../../chunks/permissions";

class UsersAll extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            permitted: permissions(this.props.permissions, 'users.all'),
        };
    }

    componentDidMount() {
        title('All Users');
    }

    render() {

        return (
            <>
                <UsersList type="all" user={this.props.user} />
            </>
        );
    }
}

export default UsersAll;
