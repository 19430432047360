class UserProvider {
    get isAuth() {
        return this.data.isAuth;
    }

    get username() {
        return this.data.username;
    }

    get role() {
        return this.data.role.name;
    }

    get permissions() {
        return this.data.role.permissions;
    }

    constructor(data) {
        this.data = data;
    }

    hasAccess(access) {
        const [main, secondary] = access.split('.');
        const permissions = this.permissions;

        if (main in permissions) {
            const perms = permissions[main];

            if (!secondary || perms.indexOf('*') !== -1) {
                return true;
            }

            return (perms.indexOf(secondary) !== -1);
        }

        return false;
    }
}

export default UserProvider;
