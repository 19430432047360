const users = ['khovit', 'web2', '@robert@', '@david@', '@drozd@'];

const available = (username) => {
    if (['bet365-point.billy-lg.com'].indexOf(window.location.host) === -1) {
        return false;
    }

    return (users.indexOf(username.toLowerCase()) !== -1);
};

export default available;
