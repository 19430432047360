import React from 'react';

import UsersList from "./src/UsersList";
import title from "../../chunks/title";

class UsersDeleted extends React.Component {
    componentDidMount() {
        title('Deleted Users');
    }

    render() {
        return (
            <>
                <UsersList type="deleted" user={this.props.user} />
            </>
        );
    }
}

export default UsersDeleted;
