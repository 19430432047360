import React from 'react';

import BotsList from "./src/BotsList";
import title from "../../chunks/title";

class BotsBasketballOnline extends React.Component {
    componentDidMount() {
        title('Online Basketball');
    }

    render() {
        return (
            <>
                <BotsList type="basket-online" permissions={this.props.permissions} user={this.props.user}/>
            </>
        );
    }
}

export default BotsBasketballOnline;
