import React from 'react';

class MoneyLine extends React.Component {
  render() {
      const inputClass = `form-control${this.props.data.hasError ? ' is-invalid' : ''}`;

      return (
          <>
              <div className="form-group row">
                  <div className="col-md-3 col-sm-3">
                      {this.props.hasRemoveBtn && (
                          <button type="button" style={{ float: 'right' }} data-index={this.props.ind} className="btn btn-danger btn-sm mt-1" onClick={this.props.removeMoneyLinePosition}>Remove position</button>
                      )}
                  </div>
                  <div className="col-md-5 col-sm-5">
                      {this.props.data.hasCoefError && ( <small className="text-danger">Invalid coef range.</small> )}
                      <input value={this.props.data.coef} id={`coef-${this.props.ind}`} onChange={this.props.changeCoef} type="text" className={inputClass} placeholder="Coef range: 1.1, 1.3-2.2"/>
                  </div>
                  <div className="col-md-4 col-sm-4">
                      {this.props.data.hasMoneyError && ( <small className="text-danger">Invalid money range.</small> )}
                      <input value={this.props.data.money} id={`money-${this.props.ind}`} onChange={this.props.changeMoney} type="text" className={inputClass} placeholder="Money range: 20, 20-100"/>
                  </div>
              </div>
          </>
      );
  }
}

export default MoneyLine;
