import React, {Component} from 'react';
import './style.css';

import {withRouter} from "react-router-dom";
import backend from "../../../chunks/backend";
import PaginationComponent from "../../../components/PaginationComponent";
import PlacedBetHistoryEntity from "./PlacedBetHistoryEntity";
import sub from "../../../chunks/subscriber";

class BotDetailedPlacedBetHistory extends Component {

  constructor(props) {
    super(props);

    this.state = {
      subIds: [],
      loaded: false,
      history: [],
      page: 1,
      numberAcceptedBets: 0,
      updateDataInterval: 0
    };

    this.updateData = this.updateData.bind(this);
    this.showPage = this.showPage.bind(this);
  }

  componentDidMount() {
    const ids = [];

    for (let event of ['bots.update']) {
      const id = sub.subscribe(event, (data) => {
        if (data.name === this.props.match.params.name) {
          this.updateData();
        }
      });

      ids.push(id);
    }

    this.setState({subIds: ids});
    this.updateData();

    this.setState({
      updateDataInterval: setInterval(() => {
        this.updateData();
      }, 3000)
    });
  }

  componentWillUnmount() {
    for (let id of this.state.subIds) {
      sub.unsubscribe(id);
    }

    clearInterval(this.state.updateDataInterval);
  }

  updateData() {
    backend.get(`/bot/placed-bets-history/${this.props.match.params.name}?page=${this.state.page}`).then(({data}) => {
      if (data.success) {
        this.setState({
          history: data.history
        });
      }
    }).catch(console.log).finally(() => {
      this.setState({
        loaded: true
      });
    })

    backend.get(`/bot/number-accepted-bets/${this.props.match.params.name}`).then(({data}) => {
      if (data.success) {
        this.setState({
          numberAcceptedBets: data.count
        });
      }
    }).catch(console.log).finally(() => {
    })
  }

  showPage(page) {
    this.setState({page}, () => {
      this.updateData();
    });
  }

  render() {

    return (
        <>
          <div className="x_panel">
            <div className="x_title" style={{borderBottom: "3px solid #1d2124"}}>
              <h2>Отложенные ставки в одно плечо. Number accepted bets: <strong><span className="text-success">{this.state.numberAcceptedBets}</span></strong></h2>
              <PaginationComponent page={this.state.page} showPage={this.showPage}/>
              <div className="clearfix"/>
            </div>
            <div className="x_content">

              <div className="table-responsive">
                <table className="table table-hover bulk_action jambo_table table-striped">
                  <thead>
                  <tr className="headings">
                    <th className="column-title">Timestamp</th>
                    <th className="column-title">Status Second</th>
                    <th className="column-title">Sport</th>
                    <th className="column-title">League</th>
                    <th className="column-title">Bookmaker First</th>
                    <th className="column-title">Bookmaker Second</th>
                    <th className="column-title">Outcome</th>
                    <th className="column-title">Score</th>
                    <th className="column-title">Odds First</th>
                    <th className="column-title" style={{width: '160px'}}>Odds Second</th>
                    <th className="column-title" style={{width: '85px'}}>Percent</th>
                    <th className="column-title" style={{width: '80px'}}>Money Second</th>
                  </tr>
                  </thead>
                  <tbody>
                  {this.state.loaded && (
                      this.state.history.length > 0 ? (
                          this.state.history.map((historyData) => <PlacedBetHistoryEntity key={historyData._id} data={historyData}/>)
                      ) : (
                          <tr>
                            <td colSpan="12">List is empty</td>
                          </tr>
                      )
                  )}
                  {!this.state.loaded && (
                      <tr>
                        <td colSpan="12"><i className="text-muted">Loading...</i></td>
                      </tr>
                  )}
                  </tbody>
                </table>
              </div>

            </div>
          </div>
        </>


    );
  }
}

export default withRouter(BotDetailedPlacedBetHistory);
