// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.is-serve {
    background-color: rgba(0, 255, 0, 0.25);
}

.point-table-header {
    background-color: rgba(127,127,127,0.25);
}

th.rotate {
    width: 50px;
    white-space: nowrap;
}

th.rotate > div {
    transform: translate(25px, 51px) rotate(270deg);
    width: 0;
    margin-left: -10px;
    margin-top: 30px;
    text-transform: uppercase;
    color: rgb(90, 90, 90);
}
th.rotate > div > span {
    padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Points/src/style.css"],"names":[],"mappings":"AAAA;IACI,uCAAuC;AAC3C;;AAEA;IACI,wCAAwC;AAC5C;;AAEA;IACI,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,+CAA+C;IAC/C,QAAQ;IACR,kBAAkB;IAClB,gBAAgB;IAChB,yBAAyB;IACzB,sBAAsB;AAC1B;AACA;IACI,UAAU;AACd","sourcesContent":[".is-serve {\n    background-color: rgba(0, 255, 0, 0.25);\n}\n\n.point-table-header {\n    background-color: rgba(127,127,127,0.25);\n}\n\nth.rotate {\n    width: 50px;\n    white-space: nowrap;\n}\n\nth.rotate > div {\n    transform: translate(25px, 51px) rotate(270deg);\n    width: 0;\n    margin-left: -10px;\n    margin-top: 30px;\n    text-transform: uppercase;\n    color: rgb(90, 90, 90);\n}\nth.rotate > div > span {\n    padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
