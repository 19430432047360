import React, {Component} from 'react';
import './style.css';

import {withRouter} from "react-router-dom";
import backend from "../../../../chunks/backend";
import telegram from "./telegram";

class UserData extends Component {

  constructor(props) {
    super(props);

    this.state = {
      name: '',
      username: {value: '', hasError: false},
      password: {value: '', hasError: false, showPassword: false},
      role: {value: '', hasError: false},
      deleted: false,
      banned: false,
      telegram: '',
      telegramToken: '',
      telegramConfig: [],
      showTgHelper: false,
    };

    this.sendForm = this.sendForm.bind(this);
    this.changeUsername = this.changeUsername.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.changeRole = this.changeRole.bind(this);
    this.banUser = this.banUser.bind(this);
    this.unBanUser = this.unBanUser.bind(this);
    this.removeUser = this.removeUser.bind(this);
    this.restoreUser = this.restoreUser.bind(this);
    this.updateData = this.updateData.bind(this);

    this.changeTelegram = this.changeTelegram.bind(this);
    this.changeTelegramToken = this.changeTelegramToken.bind(this);
    this.updateTelegram = this.updateTelegram.bind(this);
    this.changeTelegramConfig = this.changeTelegramConfig.bind(this);

    this.toggleShowPassword = this.toggleShowPassword.bind(this);
    this.toggleTgHelper = this.toggleTgHelper.bind(this);
  }

  toggleTgHelper() {
    this.setState({
      showTgHelper: !this.state.showTgHelper
    });
  }

  changeTelegram(e) {
    this.setState({
      telegram: e.target.value
    });
  }

  changeTelegramToken(e) {
    this.setState({
      telegramToken: e.target.value
    });
  }

  changeTelegramConfig(e) {
    const btn = e.target;
    const val = btn.value;
    const telegramConfig = this.state.telegramConfig || [];

    if (telegramConfig.indexOf(val) === -1) {
      telegramConfig.push(val);
    }

    if (!btn.checked) {
      telegramConfig.splice(telegramConfig.indexOf(val), 1);
    }

    this.setState({ telegramConfig });
  }

  updateTelegram() {
    const {telegram, telegramToken, telegramConfig} = this.state;

    backend.put(`/user/telegram/${this.props.match.params.username}`, {
      telegramToken: telegramToken.trim(),
      telegram: telegram.trim(),
      telegramConfig: telegramConfig.filter(e => e)
    }).then(this.updateData).catch(console.log);
  }


  toggleShowPassword(e) {
    e.nativeEvent.preventDefault();
    const {password} = this.state;
    password.showPassword = !password.showPassword;

    this.setState({ password });
  }

  componentDidMount() {
    this.updateData();
  }

  updateData() {
    backend.get(`/user/info/${this.props.match.params.username}`).then((res) => {
      // console.log('UserData updateData -- ', res)
      if (res.data.success) {
        const {username, password, role, banned, deletedAt} = res.data.user;
        const telegram = ('telegram' in res.data.user) ? res.data.user.telegram : '';
        const telegramToken = ('telegramToken' in res.data.user) ? res.data.user.telegramToken : '';
        const telegramConfig = ('telegramConfig' in res.data.user) ? res.data.user.telegramConfig : [];
        this.setState({telegramConfig, telegram, telegramToken, username: {value: username}, password: {value: password}, role: {value: role}, deleted: deletedAt, banned});
      }
    }).catch(console.log).finally(() => {
      this.setState({
        loaded: true
      })
    });
  }

  banUser() {
    backend.put(`/user/ban/${this.props.match.params.username}`).then((res) => {
      if (res.data.success) {
        console.log('Ban success, User: ', this.props.match.params.username)
        this.updateData()
      }
    }).catch(console.log)
  }

  unBanUser() {
    backend.put(`/user/unban/${this.props.match.params.username}`).then((res) => {
      if (res.data.success) {
        console.log('UnBan success, User: ', this.props.match.params.username)
        this.updateData()
      }
    }).catch(console.log)
  }

  removeUser() {
    // backend.delete(`/user/trash/${this.props.match.params.username}`).then((res) => {
    backend.delete(`/user/remove/${this.props.match.params.username}`).then((res) => {
      if (res.data.success) {
        console.log('Remove success, User: ', this.props.match.params.username)
        this.updateData()
      }
    }).catch(console.log)
  }

  restoreUser() {
    backend.put(`/user/restore/${this.props.match.params.username}`).then((res) => {
      if (res.data.success) {
        console.log('Restore success, User: ', this.props.match.params.username)
        this.updateData()
      }
    }).catch(console.log)
  }

  changeUsername(e) {
    this.setState({
      username: {
        value: e.target.value,
        hasError: false
      }
    });
  }

  changePassword(e) {
    this.setState({
      password: {
        value: e.target.value,
        hasError: false,
        showPassword: this.state.password.showPassword
      }
    });
  }

  changeRole(e) {
    this.setState({
      role: {
        value: e.target.value,
        hasError: false
      }
    });
  }

  sendForm(e) {
    e.preventDefault();

    const {username, password} = this.state;
    let formWithErrors = false;

    if (username.value === '') {
      username.hasError = true;
      formWithErrors = true;
    }

    if (password.value === '') {
      password.hasError = true;
      formWithErrors = true;
    }

    if (formWithErrors) {
      this.setState({
        username, password
      });

      return;
    }

    backend.put(`/user/update/${this.props.match.params.username}`, {
      password: this.state.password.value.trim(),
      role: this.state.role.value.trim()
    }).then(this.updateData).catch(console.log);
  }

  render() {
    const usernameClass = `form-control${this.state.username.hasError ? ' is-invalid' : ''}`;
    const passwordClass = `form-control${this.state.password.hasError ? ' is-invalid' : ''}`;
    const permissionUpdate = `${this.props.user.hasAccess('users.update') ? '' : 'disabled'}`;
    const passwordType  = this.state.password.showPassword ? 'text' : 'password';
    const message = `
    {
      "ok": false,
      "error_code": 409,
      "description": "Conflict: can’t use getUpdates method while webhook is active; use deleteWebhook to delete the webhook first"
    }`;
    const jsonMessage = `
    "chat": {
      "id": -1001364194678,
      "title": "TetrisBot",
      "type": "channel"
      },`;

    return (
        <div className="x_panel">
          <div className="x_title">
            <h2>General info</h2>
            <div className="clearfix"/>
          </div>
          <div className="x_content">
            <br/>
            <form className="form-label-left input_mask" onSubmit={this.sendForm}>

              <div className="form-group row has-feedback">
                <label className="col-form-label col-md-3 col-sm-3 ">User Name</label>
                <div className="col-md-9 col-sm-9 ">
                  {this.state.username.hasError && (
                      <small className="text-danger">Invalid username</small>
                  )}
                  <input value={this.state.username.value} onChange={this.changeUsername} type="text" className={usernameClass} required="" disabled="disabled"/>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-3 col-sm-3">Password</label>
                <div className="col-md-9 col-sm-9 ">
                  {this.state.password.hasError && (
                      <small className="text-danger">Invalid password</small>
                  )}
                  <div className="input-group mb-2">
                    <div className="input-group-prepend">
                      <div style={{ cursor: 'pointer' }} className="input-group-text" onClick={this.toggleShowPassword}>
                        {this.state.password.showPassword ? (
                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-eye" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.134 13.134 0 0 0 1.66 2.043C4.12 11.332 5.88 12.5 8 12.5c2.12 0 3.879-1.168 5.168-2.457A13.134 13.134 0 0 0 14.828 8a13.133 13.133 0 0 0-1.66-2.043C11.879 4.668 10.119 3.5 8 3.5c-2.12 0-3.879 1.168-5.168 2.457A13.133 13.133 0 0 0 1.172 8z"/>
                              <path fillRule="evenodd" d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                            </svg>
                        ) : (
                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-eye-slash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
                              <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299l.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
                              <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709z"/>
                              <path fillRule="evenodd" d="M13.646 14.354l-12-12 .708-.708 12 12-.708.708z"/>
                            </svg>
                        )}
                      </div>
                    </div>
                    <input type={passwordType} value={this.state.password.value} onChange={this.changePassword} className={passwordClass} required="" disabled={permissionUpdate}/>
                  </div>
                </div>
              </div>
              {this.props.user.hasAccess('users.update') && (
                  <>
                    <div className="form-group row">
                      <label className="col-form-label col-md-3 col-sm-3 ">Role</label>
                      <div className="col-md-9 col-sm-9 ">
                        <select defaultValue={this.state.role.value} className="form-control" onChange={this.changeRole} value={this.state.role.value} disabled={permissionUpdate}>
                          <option value="user">User</option>
                          <option value="admin">Admin</option>
                        </select>
                      </div>
                    </div>
                    <div className="ln_solid"/>
                    <div className="form-group row">
                      <div className="col-md-9 col-sm-9">
                        <div className="x_content">
                          <div className="btn-group">
                            <button type="submit" className="btn btn-success">Update User</button>
                            {!this.state.banned && !this.state.deleted && (
                                <button type="button" onClick={this.banUser} className="btn btn-warning">Ban User</button>
                            )}
                            {this.state.banned && (
                                <button type="button" onClick={this.unBanUser} className="btn btn-dark">UnBan User</button>
                            )}
                            {this.state.deleted ? (
                                <button type="button" onClick={this.restoreUser} className="btn btn-dark">Restore User</button>
                            ) : (
                                <button type="button" onClick={this.removeUser} className="btn btn-danger">Delete User</button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
              )}
              <div className="ln_solid" />
              <div className="form-group row has-feedback">
                <label className="col-form-label col-md-3 col-sm-3 ">Telegram ID</label>
                <div className="col-md-9 col-sm-9 ">
                  <input value={this.state.telegram || ''} onChange={this.changeTelegram} type="text" className="form-control" />
                </div>
              </div>
              <div className="form-group row has-feedback">
                <label className="col-form-label col-md-3 col-sm-3 ">Telegram Token</label>
                <div className="col-md-9 col-sm-9 ">
                  <input value={this.state.telegramToken || ''} onChange={this.changeTelegramToken} type="text" className="form-control" />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-3 col-sm-3 ">Telegram Messages settings</label>
                <div className="col-md-9 col-sm-9 ">
                  {telegram.map( (action) => (
                      <div key={action.title}>
                        <strong className="mr-2">{action.title}</strong>
                        <input type="checkbox" name={action.title} value={action.value} onChange={this.changeTelegramConfig} checked={this.state.telegramConfig.indexOf(action.value) !== -1} />
                      </div>
                  ) )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-3 col-sm-3 "/>
                <div className="col-md-9 col-sm-9">
                  <div className="btn-group">
                    <button type="button" className="btn btn-success" onClick={this.updateTelegram}>Save Telegram changes</button>
                    <button type="button" className="btn btn-info" onClick={this.toggleTgHelper}>Need help?</button>
                  </div>
                </div>
              </div>
              {this.state.showTgHelper && (
                  <div className="form-group row mt-4">
                    <div className="col-md-12 col-sm-12">
                      <ol>
                        {/* First step start here */}
                        <li>
                          <div>Create bot and get his <mark>token</mark></div>
                          <ul>
                            <li>Find in Telegram search <u>BotFather</u></li>
                            <li>Click <u>Start</u> button</li>
                            <li>Send to him <u>/newbot</u> action. You will got a message <code>Alright, a new bot. How are we going to call it? Please choose a name for your bot.</code></li>
                            <li>Create a bot name. For example, <u>Tetris</u>. You will got a message <code>Good. Now let's choose a username for your bot. It must end in `bot`. Like this, for example: TetrisBot or tetris_bot.</code></li>
                            <li>Create a login with end <u>Bot</u> or <u>_bot</u></li>
                            <li>If bot name is available, you will receive bot token (like <u>1469170206:AAHwLdDBefrhg6iVXT9p4s0mcE_UzWUS5yo</u>).</li>
                          </ul>
                        </li>
                        {/* First step end here */}
                        {/* Second step start here */}
                        <li>
                          <div>Create channel and get <mark>chat ID</mark></div>
                          <ul>
                            <li>Create a telegram channel</li>
                            <li>Add bot to channel and set him as <u>administrator</u></li>
                            <li>Open in browser next url: <i>https://api.telegram.org/bot<mark>TOKEN_FROM_FIRST_STEP</mark>/getUpdates</i></li>
                            <li>
                              If you see next response,
                              <pre>
                                <code>{message}</code>
                              </pre>
                              then open in browser next url: <i>https://api.telegram.org/bot<mark>TOKEN_FROM_FIRST_STEP</mark>/deleteWebhook</i> and repeat previous step
                            </li>
                            <li>
                              You will receive a response like this: <pre><code>{jsonMessage}</code></pre>A field named ID is your chat ID.
                            </li>
                          </ul>
                        </li>
                        {/* Second step end here */}
                      </ol>
                    </div>
                  </div>
              )}
            </form>
          </div>
        </div>
    );
  }
}

export default withRouter(UserData);
