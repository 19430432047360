import React from "react";
import routes from "../../../chunks/routes";
import backend from "../../../chunks/backend";
import helpers from "../../../chunks/helpers";

import { withRouter } from "react-router-dom";
import title from "../../../chunks/title";
import "./style.css";

import Calculator from "./Calculator";

class PointGame extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            game: null,
            finished: false
        };

        this.updateData = this.updateData.bind(this);
    }

    componentDidMount() {
        this.updateData();
        title('Detailed point game');
    }

    updateData() {
        const {type, slug} = this.props.match.params;
        const url = ((type === 'live') ? routes.points.live : routes.points.history)+'/'+slug;

        backend.get(url).then(({ data }) => {
            if (data.success) {
                if (data.data.slug) {
                    this.setState({
                        game: data.data
                    });
                } else {
                    this.setState({
                        finished: true
                    })
                }
            }
        }).catch(console.log);
    }

    render() {
        return (
            <div>
                <div className="page-title">
                    <div className="title_left">
                        <h3>{ this.props.match.params.slug.split(' :: ')[2] }</h3>
                    </div>
                    <div className="title_right pull-right text-right">
                        {this.state.game && (
                            <>
                                <div>{this.state.game.book}</div>
                                <div>{this.state.game.sport}</div>
                            </>
                        )}
                    </div>
                </div>
                <div className="clearfix"/>
                <div className="row margin-top-profile">
                    {this.state.finished && (
                        <div className="col-xs-12 col-sm-12 col-md-12">
                            <div className="alert alert-danger">
                                <strong>Warning!</strong> Game is over!
                            </div>
                        </div>
                    )}
                    <div className="col-xs-4 cos-sm-4 col-md-4">
                        <h5>Home</h5>
                        {this.state.game ? (
                            <ul className="list-group">
                                {this.state.game.outcome && this.state.game.outcome.home ? (
                                    <>
                                        <li className="list-group-item">Name: <strong>{this.state.game.home}</strong></li>
                                        <li className="list-group-item">
                                            <strong className={this.state.game.outcome.home.isServe ? 'text-success' : ''}>{this.state.game.outcome.home.name}</strong>
                                            <mark className="ml-1">{this.state.game.outcome.home.odds}</mark>
                                        </li>
                                        <li className="list-group-item">Percent: <code>{this.state.game.homePr}%</code></li>
                                    </>
                                ) : (
                                    <div>Empty data</div>
                                )}
                            </ul>
                        ) : (
                            <div className="text-muted">
                                <i>Loading...</i>
                            </div>
                        )}
                    </div>
                    <div className="col-xs-4 cos-sm-4 col-md-4">
                        <h5>Away</h5>
                        {this.state.game ? (
                            <ul className="list-group">
                                {this.state.game.outcome && this.state.game.outcome.away ? (
                                    <>
                                        <li className="list-group-item">Name: <strong>{this.state.game.away}</strong></li>
                                        <li className="list-group-item">
                                            <strong className={this.state.game.outcome.away.isServe ? 'text-success' : ''}>{this.state.game.outcome.away.name}</strong>
                                            <mark className="ml-1">{this.state.game.outcome.away.odds}</mark>
                                        </li>
                                        <li className="list-group-item">Percent: <code>{this.state.game.awayPr}%</code></li>
                                    </>
                                ) : (
                                    <div>Empty data</div>
                                )}
                            </ul>
                        ) : (
                            <div className="text-muted">
                                <i>Loading...</i>
                            </div>
                        )}
                    </div>
                    <div className="col-xs-4 cos-sm-4 col-md-4">
                        <h5>Meta</h5>
                        {this.state.game ? (
                            <ul className="list-group">
                                <li className="list-group-item">Updated At: <strong>{helpers.dt(this.state.game.updatedAt)}</strong></li>
                                {this.state.game.deletedAt && (
                                    <li className="list-group-item">Game over At: <strong className="text-danger">{helpers.dt(this.state.game.deletedAt)}</strong></li>
                                )}
                                <li className="list-group-item">
                                    <span>League</span>
                                    <mark className="ml-1">{this.state.game.slug.split(' :: ')[1]}</mark>
                                </li>
                            </ul>
                        ) : (
                            <div className="text-muted">
                                <i>Loading...</i>
                            </div>
                        )}
                    </div>
                    {this.state.game && (
                        <>
                            <div className="col-xs-12 col-sm-12 col-md-12 mt-4">
                                <h5>Calculator</h5>
                                <Calculator dataset={[ this.state.game ]} />
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 mt-4">
                                <h5>Detailed</h5>
                                <div className="x_panel">
                                    <div className="x_content">
                                        <table className="table table-hover">
                                            {Object.keys(this.state.game.goals).reverse().map(gameKey => (
                                                <>
                                                    <tr className="point-table-header">
                                                        <th rowSpan={this.state.game.goals[gameKey].length + 1} className="rotate">
                                                            <div>
                                                                <span>{gameKey.replace('_', ' ').replace('goal', 'game')}</span>
                                                            </div>
                                                        </th>
                                                        <th colSpan="3" style={{ textAlign: 'center' }}>Home ({this.state.game.home})</th>
                                                        <th style={{ width: '160px', textAlign: 'center' }}>Score</th>
                                                        <th style={{ width: '160px', textAlign: 'center' }}>Timestamp</th>
                                                        <th colSpan="3" style={{ textAlign: 'center' }}>Away ({this.state.game.away})</th>
                                                    </tr>
                                                    {this.state.game.goals[gameKey].reverse().map((gameGoal, i) => {
                                                        if (!gameGoal.outcome) {
                                                            return (<tr>
                                                                <td colSpan="8">No old buttons to display data</td>
                                                            </tr>);
                                                        }

                                                        const hs = gameGoal.outcome.home && gameGoal.outcome.home.isServe ? 'is-serve' : '';
                                                        const as = gameGoal.outcome.away && gameGoal.outcome.away.isServe ? 'is-serve' : '';

                                                        return (
                                                            <tr>
                                                                <td className={hs}>Points: <u>{(gameGoal.points.home === null) ? 'A' : gameGoal.points.home}</u></td>
                                                                <td className={hs}>{gameGoal.outcome.home ? gameGoal.outcome.home.name : ''}</td>
                                                                <td className={hs}>
                                                                    {gameGoal.outcome && gameGoal.outcome.home && (
                                                                        <>
                                                                            <div>
                                                                                Odds: <i className="text-muted">{gameGoal.outcome.home.odds ? gameGoal.outcome.home.odds : 0}</i>
                                                                            </div>
                                                                            <div>
                                                                                Percent: <i className="text-muted">{gameGoal.homePrc || 0}%</i>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </td>
                                                                <td className="bg-secondary text-white" style={{ textAlign: 'center' }}>{gameGoal.score}</td>
                                                                <td className="bg-secondary text-white" style={{ textAlign: 'center' }}>{helpers.dt(gameGoal.time)}</td>
                                                                <td className={as}>Points: <u>{(gameGoal.points.away === null) ? 'A' : gameGoal.points.away}</u></td>
                                                                <td className={as}>{gameGoal.outcome.away ? gameGoal.outcome.away.name : ''}</td>
                                                                <td className={as}>
                                                                    {gameGoal.outcome && gameGoal.outcome.away && (
                                                                        <>
                                                                            <div>
                                                                                Odds: <i className="text-muted">{gameGoal.outcome.away.odds ? gameGoal.outcome.away.odds : 0}</i>
                                                                            </div>
                                                                            <div>
                                                                                Percent: <i className="text-muted">{gameGoal.awayPrc || 0}%</i>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </>
                                            ))}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }
}

export default withRouter(PointGame);
