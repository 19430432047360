import React from 'react';
import {withRouter} from "react-router-dom";
import title from "../../chunks/title";
import BotDetailedInfo from "./src/BotDetailedInfo";
import BotDetailedBalanceHistorySecond from "./src/BotDetailedBalanceHistorySecond";
import BotDetailedBalanceHistoryFirst from "./src/BotDetailedBalanceHistoryFirst";
import BotDetailedGamesHistory from "./src/BotDetailedGamesHistory";
import BotAcceptedGamesHistory from "./src/BotAcceptedGamesHistory";
import BotDetailedGamesLive from "./src/BotDetailedGamesLive";
import BotDetailedBetHistory from "./src/BotDetailedBetHistory";
import BotDetailedAuthHistory from "./src/BotDetailedAuthHistory";
import BotDetailedPlacedBetHistory from "./src/BotDetailedPlacedBetHistory";
import BotDetailedPlacedBetWaitingForkHistory from "./src/BotDetailedPlacedBetWaitingForkHistory";
import BotDetailedPlacedBetRegularForkHistory from "./src/BotDetailedPlacedBetRegularForkHistory";
import BotDetailedPlacedBetOneShoulderForkHistory from "./src/BotDetailedPlacedBetOneShoulderForkHistory";
import routes from "../../chunks/routes";
import backend from "../../chunks/backend";
import ForksPage from "../Forks";
import UpdateBotModal from "./modals/UpdateBotModal";
import sub from "../../chunks/subscriber";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


class BotDetailed extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      forksAvailable: false,
      timing: '',
      eventId: '',
      game: {},
      bot: {},
      subIds: [],
    };

    this.updateData = this.updateData.bind(this);
    this.requestAuth = this.requestAuth.bind(this);
    this.stopSendBot = this.stopSendBot.bind(this);
    this.requestTiming = this.requestTiming.bind(this);
    this.getBetsHistory = this.getBetsHistory.bind(this);
    this.requestReload = this.requestReload.bind(this);
    this.downloadExtension1 = this.downloadExtension1.bind(this);
    this.downloadExtension2 = this.downloadExtension2.bind(this);
    this.downloadTipsportExtension = this.downloadTipsportExtension.bind(this);
    this.downloadBetfairExtension = this.downloadBetfairExtension.bind(this);
    this.downloadSuperbetPlExtension = this.downloadSuperbetPlExtension.bind(this);
    this.getBalance = this.getBalance.bind(this);
    this.manualPlaceBet = this.manualPlaceBet.bind(this);
  }

  componentDidMount() {
    title(`Bot information (${this.props.match.params.name})`);

    const ids = [];
    for (let event of ['bots.update']) {
      const id = sub.subscribe(event, (data) => {
        this.updateData();
      });
      ids.push(id);
    }

    this.setState({subIds: ids});
    this.updateData()
  }

  componentWillUnmount() {
    for (let id of this.state.subIds) {
      sub.unsubscribe(id);
    }
  }

  updateData() {
    backend.get('/forks/available').then(({data}) => {
      const {available} = data;
      this.setState({forksAvailable: available});
    }).catch(console.log);

    backend.get(`/games/live/football`).then(({data}) => {
      if (data.success) {
        if (data.games && data.games.length !== 0) {
          let game = {}
          for (let i = 0; i < data.games.length; i++) {
            const temp = data.games[i];
            if (temp.gameType === 'football-8') {
              game = temp
            }
          }
          if (Object.keys(game).length === 0) {
            game = data.games[0]
          }
          // console.log('BOT GAME === ', game)

          this.setState({eventId: game.eventID});

          // backend.get(`/games/live/football/${game.eventID}`).then(({data}) => {
          //   console.log('111111111111111111111111 ', data)
          //
          //   this.setState({game: data.game});
          // }).catch(console.log).finally(() => {
          //
          // });

        }
      }
    }).catch(console.log).finally(() => {
    })

    backend.get(`/bot/info/${this.props.match.params.name}`).then(({data}) => {
      if (data.success) {
        const {bot} = data;
        if (bot.isSendToBot === undefined) {
          console.log('isSendToBot undefined: ', bot)
          bot.isSendToBot = false
        }

        this.setState({bot});
      }
    }).catch(console.log).finally(() => {
      this.setState({
        loaded: true
      });
    });

  }

  downloadExtension1(e) {
    e.preventDefault();

    const book = this.state.bot.bookmakerFirst.replace('stake', 'pinnacle_2').replace('loot', 'nitrobetting').replace('ladbrokes', 'ladbrokes_be').replace('bwin_it', 'superbet_ro').replace('bwin', 'superbet_pl').replace('1win', 'cloudbet_fifa').replace('efbet', 'ifortuna').replace('ligastavok', 'lottoland').replace('tonybet', 'tipsport').replace('zenit', 'chance').replace('mostbet', 'ps3838').replace('betflag', 'inbet365').replace('omg', '212bet').replace('unikrn', 'rabet777').replace('sbobet', 'simbet')

    const fileName = `${this.props.match.params.name}_${book}.zip`;

    backend.download(routes.extension.get.replace(':name', this.props.match.params.name).replace(':bookmaker', this.state.bot.bookmakerFirst), fileName)
  }

  downloadExtension2(e) {
    e.preventDefault();

    const book = this.state.bot.bookmakerSecond.replace('stake', 'pinnacle_2').replace('loot', 'nitrobetting').replace('ladbrokes', 'ladbrokes_be').replace('bwin_it', 'superbet_ro').replace('bwin', 'superbet_pl').replace('1win', 'cloudbet_fifa').replace('efbet', 'ifortuna').replace('ligastavok', 'lottoland').replace('tonybet', 'tipsport').replace('zenit', 'chance').replace('mostbet', 'ps3838').replace('betflag', 'inbet365').replace('omg', '212bet').replace('unikrn', 'rabet777').replace('sbobet', 'simbet')

    const fileName = `${this.props.match.params.name}_${book}.zip`;

    backend.download(routes.extension.get.replace(':name', this.props.match.params.name).replace(':bookmaker', this.state.bot.bookmakerSecond), fileName)
  }

  downloadTipsportExtension(e) {
    e.preventDefault();

    const fileName = `${this.props.match.params.name}.zip`;

    backend.download(routes.extension.gettips.replace(':name', this.props.match.params.name), fileName)
  }

  downloadBetfairExtension(e) {
    e.preventDefault();

    const fileName = `${this.props.match.params.name}.zip`;

    backend.download(routes.extension.getbetfair.replace(':name', this.props.match.params.name), fileName)
  }

  downloadSuperbetPlExtension(e) {
    e.preventDefault();

    const fileName = `${this.props.match.params.name}.zip`;

    backend.download(routes.extension.getsuperbetpl.replace(':name', this.props.match.params.name), fileName)
  }

  stopSendBot(e) {
    e.preventDefault();
    backend.put(`/bot/update/${this.props.match.params.name}`, {
      isSendToBot: !this.state.bot.isSendToBot,
    })
      .then((res) => {
        if (res.data.success) {
          this.updateData();
        }
      })
      .catch(console.log);
  }

  requestAuth(e) {
    e.preventDefault();
    const url = `/bot/authorize/${this.props.match.params.name}`;
    backend.get(url).catch(console.log);
  }

  async requestTiming(e) {
    e.preventDefault();
    const url = `/bot/timing/${this.state.eventId}/${this.props.match.params.name}`;

    backend.get(url).then(({data}) => {
      console.log('request - ', data)
      const {timing} = data;
      this.setState({timing});
    }).catch(console.log);
  }

  async getBetsHistory(e) {
    e.preventDefault();
    const url = `/bot/bet-history/${this.props.match.params.name}`;

    backend.get(url).then(({data}) => {
      // console.log('bet-history - ', data)
      // const {timing} = data;
      // this.setState({timing});
    }).catch(console.log);
  }

  async getBalance(e) {
    e.preventDefault();
    const url = `/bot/balance/${this.props.match.params.name}`;

    backend.get(url).then(({data}) => {
      // console.log('bet-history - ', data)
      // const {timing} = data;
      // this.setState({timing});
    }).catch(console.log);
  }

  async manualPlaceBet(e) {
    e.preventDefault();

    // const manualPlaceBetId = `${fork.forkId}`
    // const manualPlaceBetId = `${fork.forkId}*${fork._id}`
    // backend.put(`/bot/update/${this.props.match.params.name}`, {
    backend.put(`/bot/place-bet-second/${this.props.match.params.name}`, {
      manualPlaceBetId: '',
    })
      .then((res) => {
        if (res.data.success) {
          this.updateData();
        }
      })
      .catch(console.log);
  }

  requestReload(e) {
    e.preventDefault();
    const url = `/bot/reload-on-site/${this.props.match.params.name}`;
    backend.get(url).catch(console.log);
  }

  render() {
    const name = this.props.match.params.name;

    const book1 = this.state.bot.bookmakerFirst && this.state.bot.bookmakerFirst.replace('loot', 'nitrobetting').replace('stake', 'pinnacle_2').replace('ladbrokes', 'ladbrokes_be').replace('bwin_it', 'superbet_ro').replace('bwin', 'superbet_pl').replace('efbet', 'ifortuna').replace('1win', 'cloudbet_fifa').replace('ligastavok', 'lottoland').replace('tonybet', 'tipsport').replace('zenit', 'chance').replace('mostbet', 'ps3838').replace('betflag', 'inbet365').replace('omg', '212bet').replace('unikrn', 'rabet777').replace('sbobet', 'simbet')
    const book2 = this.state.bot.bookmakerSecond && this.state.bot.bookmakerSecond.replace('loot', 'nitrobetting').replace('stake', 'pinnacle_2').replace('ladbrokes', 'ladbrokes_be').replace('bwin_it', 'superbet_ro').replace('bwin', 'superbet_pl').replace('efbet', 'ifortuna').replace('1win', 'cloudbet_fifa').replace('ligastavok', 'lottoland').replace('tonybet', 'tipsport').replace('zenit', 'chance').replace('mostbet', 'ps3838').replace('betflag', 'inbet365').replace('omg', '212bet').replace('unikrn', 'rabet777').replace('sbobet', 'simbet')


    return (
      <>
        <div className="page-title">
          <div className="title_left">
            <h3>Bot: {name}</h3>
          </div>
          <div className="title_right">
            <div className="pull-right">
              <div className="btn-group ml-1" style={{float: 'right'}}>
                <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#modalUpdateBot">Update</button>
                <a className={this.state.bot.isSendToBot ? 'btn btn-warning' : 'btn btn-success'} href="/"
                   onClick={this.stopSendBot}>{this.state.bot.isSendToBot ? 'Stop bot' : 'Start bot'}</a>

                {/*<OverlayTrigger*/}
                {/*  placement="bottom"*/}
                {/*  overlay={<Tooltip>{book1} extension</Tooltip>}*/}
                {/*  // overlay={<Tooltip>Betfair Extension</Tooltip>}*/}
                {/*>*/}
                {/*  <a className="btn btn-secondary" href="/" onClick={this.downloadExtension1}>*/}
                {/*    <i className="fa fa-download" aria-hidden="true"/> {book1}*/}
                {/*  </a>*/}
                {/*</OverlayTrigger>*/}

                {/*<OverlayTrigger*/}
                {/*  placement="bottom"*/}
                {/*  overlay={<Tooltip>{book2} extension</Tooltip>}*/}
                {/*  // overlay={<Tooltip>Betfair Extension</Tooltip>}*/}
                {/*>*/}
                {/*  <a className="btn btn-secondary" href="/" onClick={this.downloadExtension2}>*/}
                {/*    <i className="fa fa-download" aria-hidden="true"/> {book2}*/}
                {/*  </a>*/}
                {/*</OverlayTrigger>*/}

                {/*<a className="btn btn-secondary" href="/" title="download extension" onClick={this.downloadExtension}>*/}
                {/*  <i className="fa fa-download" aria-hidden="true"/> Bet365*/}
                {/*</a>*/}
                {/*<a className="btn btn-secondary" href="/" title="download extension" onClick={this.downloadBetfairExtension}>*/}
                {/*  <i className="fa fa-download" aria-hidden="true"/> Betfair*/}
                {/*</a>*/}
                {/*<a className="btn btn-secondary" href="/" title="download extension" onClick={this.downloadSuperbetPlExtension}>*/}
                {/*  <i className="fa fa-download" aria-hidden="true"/> Superbet_pl*/}
                {/*</a>*/}
                {/*<a className="btn btn-secondary" href="/" title="download extension" onClick={this.downloadTipsportExtension}>*/}
                {/*  <i className="fa fa-puzzle-piece" aria-hidden="true"/> Tipsport*/}
                {/*</a>*/}
                <a className="btn btn-info" href="/" onClick={this.requestAuth}>
                  <i className="fa fa-sign-in" aria-hidden="true"/> Auth bot
                </a>
                {/*<a className="btn btn-dark" href="/" onClick={this.requestTiming}>*/}
                {/*  <i className="fa fa-clock-o" aria-hidden="true"/> Timing*/}
                {/*</a>*/}
                {/*<a className="btn btn-primary" href="/" onClick={this.getBetsHistory}>*/}
                {/*  <i className="fa fa-history" aria-hidden="true"/> Bets History*/}
                {/*</a>*/}

                <a className="btn btn-dark" href="/" onClick={this.manualPlaceBet}>
                  Стоп перекрытие
                </a>

                {/*TODO: Раскоментить*/}
                {/*<a className="btn btn-danger" href="/" onClick={this.getBalance}>*/}
                {/*  <i className="fa fa-usd" aria-hidden="true"/> Balance*/}
                {/*</a>*/}
                {/*<a className="btn btn-warning" href="/" onClick={this.requestReload}>*/}
                {/*  <i className="fas fa-sync" aria-hidden="true"/> Reload*/}
                {/*</a>*/}
              </div>
              {/*<div className="btn-group" style={{float: 'right'}}>*/}
              {/*  <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#modalUpdateBot">Update</button>*/}
              {/*  /!*<button type="button" className="btn btn-success" data-toggle="modal" data-target="#modalUpdateBot">Update</button>*!/*/}
              {/*</div>*/}

              {this.state.timing && (
                <div className="btn-group"
                     style={{float: 'right', marginRight: '20px', fontSize: '15px', fontWeight: '450', marginTop: '10px'}}>
                  <div style={{marginRight: '10px'}}>Timing:</div>
                  <div style={{color: 'red', marginRight: '5px',}}>{this.state.timing}</div>
                  <div>second</div>
                </div>
              )}

            </div>
          </div>
        </div>
        <div className="clearfix"/>
        <div className="row margin-top-profile" id="accordion">
          {this.state.forksAvailable && (
            <div className="col-md-12 col-xs-12">
              <ForksPage name={name}/>
            </div>
          )}


          <div className="col-md-12 col-sm-12" style={{marginBottom: "10px"}}>
            <p>
              <button className="btn btn-primary" type="button" data-toggle="collapse" data-target="#collapseInfo" aria-expanded="false"
                      aria-controls="collapseInfo">
                Info Bot
              </button>
              {/*<button className="btn btn-primary" type="button" data-toggle="collapse" data-target="#collapseBalance" aria-expanded="false"*/}
              {/*        aria-controls="collapseBalance">*/}
              {/*  Balance*/}
              {/*</button>*/}
              {this.props.user.hasAccess('strategies.show_admin') && (
                  <button className="btn btn-info" type="button" data-toggle="collapse" data-target="#collapseWaitingForks" aria-expanded="false" aria-controls="collapseWaitingForks">
                    Вилки с выжиданием
                  </button>
              )}
              <button className="btn btn-warning" type="button" data-toggle="collapse" data-target="#collapseForks" aria-expanded="true"
                      aria-controls="collapseForks">
                Вилки
              </button>
              {/*<button className="btn btn-success" type="button" data-toggle="collapse" data-target="#collapseOneShoulderForks" aria-expanded="false"*/}
              {/*        aria-controls="collapseOneShoulderForks">*/}
              {/*  Вилки в одно плечо*/}
              {/*</button>*/}
              {/*{this.props.user.hasAccess('strategies.show_admin') && (*/}
              {/*    <button className="btn btn-dark" type="button" data-toggle="collapse" data-target="#collapsePendingBets" aria-expanded="false" aria-controls="collapsePendingBets">*/}
              {/*      Отложенные ставки*/}
              {/*    </button>*/}
              {/*)}*/}

              {/*<button className="btn btn-info" type="button" data-toggle="collapse" data-target="#collapseWaitingForks" aria-expanded="false" aria-controls="collapseWaitingForks">*/}
              {/*  Forks with waiting*/}
              {/*</button>*/}
              {/*<button className="btn btn-warning" type="button" data-toggle="collapse" data-target="#collapseForks" aria-expanded="false" aria-controls="collapseForks">*/}
              {/*  Forks*/}
              {/*</button>*/}
              {/*<button className="btn btn-dark" type="button" data-toggle="collapse" data-target="#collapsePendingBets" aria-expanded="false" aria-controls="collapsePendingBets">*/}
              {/*  Pending Bets*/}
              {/*</button>*/}

              {/*<button className="btn btn-primary" type="button" data-toggle="collapse" data-target="#collapseAfterGoal" aria-expanded="false"*/}
              {/*        aria-controls="collapseAfterGoal">*/}
              {/*    After Goal*/}
              {/*</button>*/}
            </p>
          </div>

          <div className="col-md-12 col-sm-12">

            <div className="collapse" id="collapseInfo" data-parent="#accordion">
              <div className="row">
                <div className="col-md-4 col-sm-4">
                  <BotDetailedInfo/>
                </div>
                {/*<div className="col-md-8 col-sm-8">*/}
                {/*  <BotDetailedAuthHistory/>*/}
                {/*</div>*/}
                <div className="col-md-4 col-sm-4">
                  <BotDetailedBalanceHistoryFirst bot={this.state.bot}/>
                </div>
                <div className="col-md-4 col-sm-4">
                  <BotDetailedBalanceHistorySecond bot={this.state.bot}/>
                </div>
              </div>
            </div>

            {/*<div className="collapse" id="collapseBalance" data-parent="#accordion">*/}
            {/*  <div className="col-md-6 col-xs-12">*/}
            {/*    <BotDetailedBalanceHistoryFirst bot={this.state.bot}/>*/}
            {/*  </div>*/}
            {/*  <div className="col-md-6 col-xs-12">*/}
            {/*    <BotDetailedBalanceHistorySecond bot={this.state.bot}/>*/}
            {/*  </div>*/}
            {/*</div>*/}

            <div className={this.state.bot.botType === 'waiting_forks' ? "collapse show" : "collapse"} id="collapseWaitingForks" data-parent="#accordion">
              <div className="col-md-12 col-xs-12">
                <div>Описание статусов(Status)</div>
                {/*<h6>Описание статусов(Status)</h6>*/}
                <ul>
                  {/*<li>SENT_FIRST - Ставка отправлена на Bookmaker First</li>*/}
                  {/*<li>SENT_SECOND - Ставка отправлена на Bookmaker Second</li>*/}
                  {/*<li>ACCEPTED_FIRST - Ставка успешно проставилась на Bookmaker First</li>*/}
                  {/*<li><span className="green-text">ACCEPTED</span> - Ставка успешно проставилась на обеих(First и Second) букмекерах</li>*/}
                  {/*<li><span className="red-text">CLOSED</span> - Игра закончилась. Bookmaker Second не проставлен. Нажмите на чекбокс "Win" если ставка на Bookmaker First*/}
                  {/*  выиграла*/}
                  {/*</li>*/}

                  <li>SENT - Ставка отправлена на Bookmaker</li>
                  <li>PENDING - Ожидание отправки ставки на Bookmaker</li>
                  <li><span className="red-text">FAILED</span> - Ставка на Bookmaker не прошла</li>
                  <li><span className="green-text">ACCEPTED</span> - Ставка успешно проставилась на Bookmaker</li>
                  <li><span className="text-warning">CLOSED</span> - Игра закончилась. Bookmaker Second не проставлен. Нажмите на чекбокс "Win" если
                    ставка на Bookmaker First выиграла
                  </li>
                </ul>
              </div>
              <div className="col-md-12 col-xs-12">
                <BotDetailedPlacedBetWaitingForkHistory user={this.props.user}/>
              </div>
            </div>

            <div className={this.state.bot.botType === 'regular_forks' ? "collapse show" : "collapse"} id="collapseForks" data-parent="#accordion">
              <div className="col-md-12 col-xs-12">
                <div>Описание статусов(Status)</div>
                {/*<h6>Описание статусов(Status)</h6>*/}
                <ul>
                  <li>SENT - Ставка отправлена на Bookmaker</li>
                  <li>PENDING - Ожидание отправки ставки на Bookmaker</li>
                  <li><span className="red-text">FAILED</span> - Ставка на Bookmaker не прошла</li>
                  <li><span className="green-text">ACCEPTED</span> - Ставка успешно проставилась на Bookmaker</li>
                  <li><span className="text-warning">CLOSED</span> - Игра закончилась. Bookmaker Second не проставлен. Нажмите на чекбокс "Win" если
                    ставка на Bookmaker First выиграла
                  </li>
                  {/*<li><span className="red-text">CLOSED</span> - Игра закончилась. Bookmaker Second не проставлен.</li>*/}
                </ul>
              </div>
              <div className="col-md-12 col-xs-12">
                <BotDetailedPlacedBetRegularForkHistory user={this.props.user}/>
              </div>
            </div>

            <div className="collapse" id="collapseOneShoulderForks" data-parent="#accordion">
              <div className="col-md-12 col-xs-12">
                <div>Описание статусов(Status)</div>
                {/*<h6>Описание статусов(Status)</h6>*/}
                <ul>
                  <li>SENT - Ставка отправлена на Bookmaker</li>
                  <li>PENDING - Ожидание отправки ставки на Bookmaker</li>
                  <li><span className="red-text">FAILED</span> - Ставка на Bookmaker не прошла</li>
                  <li><span className="green-text">ACCEPTED</span> - Ставка успешно проставилась на Bookmaker</li>
                  <li><span className="text-warning">CLOSED</span> - Игра закончилась. Bookmaker Second не проставлен. Нажмите на чекбокс "Win" если
                    ставка на Bookmaker First выиграла
                  </li>
                  {/*<li><span className="red-text">CLOSED</span> - Игра закончилась. Bookmaker Second не проставлен.</li>*/}
                </ul>
              </div>
              <div className="col-md-12 col-xs-12">
                <BotDetailedPlacedBetOneShoulderForkHistory user={this.props.user} bot={this.state.bot}/>
              </div>
            </div>

            <div className="collapse" id="collapsePendingBets" data-parent="#accordion">
              <div className="col-md-12 col-xs-12">
                <div>Описание статусов(Status)</div>
                <ul>
                  <li>SENT - Ставка отправлена на Bookmaker Second</li>
                  <li><span className="red-text">FAILED</span> - Ставка на Bookmaker не прошла</li>
                  <li><span className="green-text">ACCEPTED</span> - Ставка успешно проставилась на Bookmaker Second</li>
                  {/*<li><span className="text-warning">CLOSED</span> - Игра закончилась. Bookmaker Second не проставлен.</li>*/}
                </ul>
              </div>
              <div className="col-md-12 col-xs-12">
                <BotDetailedPlacedBetHistory user={this.props.user}/>
              </div>
            </div>

            {/*<div className="collapse" id="collapseAfterGoal" data-parent="#accordion">*/}
            {/*    <div className="col-md-6 col-xs-12">*/}
            {/*        <BotDetailedGamesLive user={this.props.user}/>*/}
            {/*        <BotAcceptedGamesHistory user={this.props.user}/>*/}
            {/*        <BotDetailedGamesHistory user={this.props.user}/>*/}
            {/*    </div>*/}
            {/*    <div className="col-md-6 col-xs-12">*/}
            {/*        <BotDetailedBetHistory/>*/}
            {/*    </div>*/}
            {/*</div>*/}

          </div>


          {this.state.bot.name ? (
            <UpdateBotModal user={this.props.user} data={this.state.bot} name={this.state.bot.name} updateData={this.updateData}/>) : <></>}

        </div>
      </>
    );
  }
}

export default withRouter(BotDetailed);
