import React, {Component} from 'react';
import './style.css';
import sub from "../../../chunks/subscriber";

import {withRouter} from "react-router-dom";
import backend from "../../../chunks/backend";
import GamesLiveEntity from "./GamesLiveEntity";

class BotDetailedGamesLive extends Component {
    constructor(props) {
        super(props);

        this.state = {
            subIds: [],
            loaded: false,
            games: [],
            details: {},
        };

        this.updateData = this.updateData.bind(this);

    }

    componentDidMount() {
        const ids = [];

        for (let event of ['bots.live_update']) {
            const id = sub.subscribe(event, (data) => {
                if (data.name === this.props.match.params.name) {
                    this.updateData();
                }
            });

            ids.push(id);
        }

        this.setState({ subIds: ids });
        this.updateData();
    }

    componentWillUnmount() {
        for (let id of this.state.subIds) {
            sub.unsubscribe(id);
        }
    }

    updateData() {
        backend.get(`/bot/games-live/${this.props.match.params.name}`).then(({data}) => {
            if (data.success) {
                const games = data.games.reverse();
                this.setState({ games });
            }
        }).catch(console.log).finally(() => {
            this.setState({
                loaded: true
            })
        })
    }

    render() {
        const games = this.state.games.filter(e => e.messages.length);

        return (
            <div className="x_panel">
                <div className="x_title">
                    <h2>Live goals ({this.state.games.length})</h2>
                    <div className="clearfix"/>
                </div>
                <div className="x_content">
                    <div className="table-responsive">
                        <table className="table table-hover bulk_action jambo_table table-striped accordion-table">
                            <thead>
                            <tr className="headings">
                                <th className="column-title">Sport</th>
                                <th className="column-title">Name</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.games.length === 0 && (
                                <tr>
                                    <td colSpan="2">List is empty</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        <div className="accordion" id="accordion1" role="tablist" aria-multiselectable="true">
                            {this.state.loaded && (
                                games.length > 0 && (
                                    games.map((game, i) => <GamesLiveEntity key={game.eventID + i} data={game} user={this.props.user}/>)
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(BotDetailedGamesLive);
