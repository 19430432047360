import React, {Component} from 'react';
import './style.css';

import {withRouter} from "react-router-dom";
import backend from "../../../chunks/backend";
import PaginationComponent from "../../../components/PaginationComponent";
import GamesHistoryEntity from "./GamesHistoryEntity";

class BotAcceptedGamesHistory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            games: [],
            details: {},
            page: 1,
            search: ''
        };

        this.updateData = this.updateData.bind(this);
        this.showPage = this.showPage.bind(this);
        this.onChangeSearch = this.onChangeSearch.bind(this);
    }

    componentDidMount() {
        this.updateData()
    }

    updateData() {
        const {page, search} = this.state;
        const name = this.props.match.params.name;

        backend.get(`/bot/accepted-history/${name}?page=${page}&search=${search}`).then(({data}) => {
            if (data.success) {
                this.setState({games: data.games});
            }
        }).catch(console.log).finally(() => {
            this.setState({
                loaded: true
            });
        })
    }

    showPage(page) {
        this.setState({ page }, () => {
            this.updateData();
        });
    }

    onChangeSearch(e) {
        this.setState({
            search: e.target.value
        }, () => {
            this.updateData();
        });
    }

    render() {
        return (
            <div className="x_panel">
                <div className="x_title">
                    <h2>Accepted Games history</h2>
                    <input onChange={this.onChangeSearch} value={this.state.input} placeholder="Search..." type="text" className="form-control-sm form-control ml-2 mr-2" style={{ display: 'inline', width: '200px' }} />
                    <PaginationComponent page={this.state.page} showPage={this.showPage} />
                    <div className="clearfix"/>
                </div>
                <div className="x_content">
                    <div className="table-responsive">
                        <table className="table table-hover bulk_action jambo_table table-striped accordion-table">
                            <thead>
                            <tr className="headings">
                                <th className="column-title">Timestamp</th>
                                <th className="column-title">Name</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.games.length === 0 && (
                                <tr>
                                    <td colSpan="2">List is empty</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        <div className="accordion" id="accordion1" role="tablist" aria-multiselectable="true">
                            {this.state.loaded && (
                                this.state.games.length > 0 && (
                                    this.state.games.map((game, i) => <GamesHistoryEntity key={i} data={game} search={this.state.search} user={this.props.user}/>)
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(BotAcceptedGamesHistory);
