import React from "react";

import title from "../../chunks/title";
import helpers from "../../chunks/helpers";
import backend from "../../chunks/backend";

import BarChart from "./charts/BarChart";
import LineChart from "./charts/LineChart";

class DashboardPage extends React.Component{
    constructor(props) {
        super(props);
        this.stInterval = 0;

        this.state = {
            server_time:   0,
            goals_status:  'available',
            blocked_hours: [],
            barChartData: {
                labels:   [],
                datasets: []
            },
            lineCharts: [],
            storage: null
        };

        this.onClickStopBots  = this.onClickStopBots.bind(this);
        this.onClickStartBots = this.onClickStartBots.bind(this);

        this.onClickRemovePosition = this.onClickRemovePosition.bind(this);
        this.onClickAddPosition = this.onClickAddPosition.bind(this);
        this.onClickSaveHours = this.onClickSaveHours.bind(this);
        this.onChangeTimeFrom = this.onChangeTimeFrom.bind(this);
        this.onChangeTimeTo = this.onChangeTimeTo.bind(this);
        this.loadStorage = this.loadStorage.bind(this);
        this.changeStorage = this.changeStorage.bind(this);
    }

    loadStorage() {
        backend.get('/system/storage').then(({ data }) => {
            if (data.success) {
                let storage = data.storage;

                while (typeof storage === 'string') {
                    storage = JSON.parse(storage);
                }

                this.setState({ storage });
            }
        }).catch(console.log);
    }

    componentDidMount() {
        title('Dashboard');
        this.loadData();
        this.loadCharts();
        this.loadStorage();
    }

    loadCharts() {
        backend.get(`/charts/private/${this.props.user.username}`).then(({ data }) => {
            if (data.success) {
                const {lineChart, barChart} = data;

                if (barChart) {
                    const labels   = [];
                    const balances = [];
                    const costs    = [];
                    const profits  = [];

                    for (let bot of barChart) {
                        const balance = Number(bot.balance) || 0;
                        const cost = Number(bot.cost) || 0;
                        const profit = balance - cost;

                        if (balance === 0 && cost === 0) {
                            continue;
                        }

                        labels.push(bot.name);
                        balances.push(balance);
                        costs.push(cost);
                        profits.push(profit);
                    }

                    this.setState({
                        barChartData: {
                            labels,
                            datasets: [{
                                label: 'Costs',
                                data: costs,
                                stack: 'Costs',
                                backgroundColor: 'rgb(255, 99, 132)'
                            }, {
                                label: 'Balances',
                                data: balances,
                                stack: 'Balances',
                                backgroundColor: 'rgb(54, 162, 235)'
                            }, {
                                label: 'Profit',
                                data: profits,
                                stack: 'Profit',
                                backgroundColor: 'rgb(75, 192, 192)'
                            }]
                        }
                    })
                }

                if (lineChart) {
                    const result = [];

                    for (let bot of lineChart) {
                        const datasets = [];

                        const entry = {
                            labels:   [],
                            datasets: [],
                        };

                        for (let row of bot.balance) {
                            if (row.balance === null) {
                                continue;
                            }

                            entry.labels.push(helpers.dt(row.createdAt));
                            datasets.push(row.balance);

                            if (entry.updatedAt) {
                                entry.labels.push(helpers.dt(row.createdAt));
                                datasets.push(row.updatedAt);
                            }
                        }

                        if (entry.labels.length > 1) {
                            entry.datasets = [{
                                label: bot.bot,
                                data:  datasets
                            }]

                            result.push(entry);
                        }
                    }

                    this.setState({ lineCharts: result });
                }
            }
        }).catch(console.log);
    }

    loadData() {
        backend.get('/system/info').then(({ data }) => {
            const {server_time, goals_status, blocked_hours} = data
            blocked_hours.map((e, i) => {
                if (!('index' in e)) {
                    e.index = i;
                }

                return e;
            });

            clearInterval(this.stInterval);
            this.stInterval = setInterval(() => {
                const {server_time} = this.state;
                this.setState({ server_time: server_time + 1000 })
            }, 1000);
            this.setState({ server_time, goals_status, blocked_hours });
        }).catch(console.log);
    }

    onClickSaveHours(e) {
        backend.put('/system/blocked-hours/', this.state).then(({ data }) => {
            if (data.success) {
                this.loadData();
            } else {
                console.log(data);
            }
        }).catch(console.log);
    }

    onClickRemovePosition(index) {
        const {blocked_hours} = this.state;
        const newHours = [];

        for (let entry of blocked_hours) {
            if (entry.index === index) {
                continue;
            }

            newHours.push(entry);
        }

        this.setState({
            blocked_hours: newHours
        });
    }

    onClickAddPosition() {
        const {blocked_hours} = this.state;
        blocked_hours.push({
            index: `_${Date.now()}`,
            from: '',
            to: ''
        });
        this.setState({ blocked_hours });
    }

    onChangeTimeFrom(e) {
        const element = e.nativeEvent.target;
        console.log(e, element);
        const index = element.getAttribute('index');
        const {blocked_hours} = this.state;
        const newHours = [];

        for (let entry of blocked_hours) {
            if (entry.index === index) {
                entry.from = element.value;
            }

            newHours.push(entry);
        }
        this.setState({
            blocked_hours: newHours
        });
    }

    onChangeTimeTo(e) {
        const element = e.nativeEvent.target;
        const index = element.getAttribute('index');
        const {blocked_hours} = this.state;
        const newHours = [];

        for (let entry of blocked_hours) {
            if (entry.index === index) {
                entry.to = element.value;
            }

            newHours.push(entry);
        }
        this.setState({
            blocked_hours: newHours
        });
    }

    onClickStopBots() {
        backend.put(`/system/goals-status/restricted`, {}).then(() => {
            this.loadData();
        }).catch(console.log);
    }

    onClickStartBots() {
        backend.put(`/system/goals-status/available`, {}).then(() => {
            this.loadData();
        }).catch(console.log);
    }

    barChartData() {
        return {};
    }

    changeStorage(e) {
        const btn = e.target;
        const key = btn.getAttribute('data-key');
        const act = btn.getAttribute('data-action');
        const val = (act === 'remove') ? null : true;

        backend.put('/system/storage', { key, value: val }).then(this.loadStorage).catch(console.log);
    }

    render() {
        const bookmakerGames = (() => {
            const result = [];
            const host = window.location.host;

            if (host === 'eurobet.billy-lg.com') {
                result.push({ key: 'table-tennis', title: 'Table Tennis' });
                result.push({ key: 'football', title: 'Football' });
            } else if (host === 'bet365-point.billy-lg.com') {
                result.push({ key: 'tennis', title: 'Tennis' });
            } else {
                result.push({ key: 'table-tennis', title: 'Table Tennis' });
                result.push({ key: 'football-8', title: 'Football (8 minutes)' });
                result.push({ key: 'football-10', title: 'Football (10 minutes)' });
                result.push({ key: 'football-12', title: 'Football (12 minutes)' });
            }

            return result;
        })();

        return (
            <>
                <div className="page-title">
                    <div className="title_left">
                        <h3>Dashboard</h3>
                    </div>
                    {/*{this.props.user.hasAccess('misc.change_system_info') && (*/}
                    {/*    <div className="title_right">*/}
                    {/*        <div className="col-md-5 col-sm-5 pull-right btn-remove-profile">*/}
                    {/*            <div className="btn-group btn-sm form-check pull-right" data-toggle="buttons">*/}
                    {/*                {this.state.goals_status === 'available' && (*/}
                    {/*                    <button className="btn btn-warning" onClick={this.onClickStopBots}>Stop bots</button>*/}
                    {/*                )}*/}
                    {/*                {this.state.goals_status !== 'available' && (*/}
                    {/*                    <button className="btn btn-success" onClick={this.onClickStartBots}>Start bots</button>*/}
                    {/*                )}*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*)}*/}
                </div>
                <div className="clearfix"/>
                <div className="row margin-top-profile">
                    {this.props.user.hasAccess('misc.change_system_info') && (
                        <>
                            <div className="col-xs-12 col-md-12 mb-4">
                                Server time: <code>{helpers.dt(this.state.server_time)}</code>
                            </div>
                            <div className="col-xs-12 col-md-6 mb-4">
                                <div className="x_panel">
                                    <div className="x_title">
                                        <h4>Restrict hours</h4>
                                    </div>
                                    <div className="x_content">
                                        {this.state.blocked_hours.length === 0 ? (
                                            <i className="text-muted">No entries found</i>
                                        ) : (
                                            <pre>
                                                Valid entries:
                                                <ul>
                                                    <li>
                                                        <mark>10</mark> to set <u>10</u> hours
                                                    </li>
                                                    <li>
                                                        <mark>10:15</mark> to set <u>10</u> hours and <u>15</u> minutes
                                                    </li>
                                                    <li>
                                                        <mark>10:15:45</mark> to set <u>10</u> hours, <u>15</u> minutes and <u>45</u> seconds
                                                    </li>
                                                </ul>
                                            </pre>
                                        )}
                                        {this.state.blocked_hours.map((e, i) => {
                                            return (
                                                <div className="row mb-3" key={i}>
                                                    <div className="col-xs-12 col-md-12">
                                                        <label className="mr-2">
                                                            From
                                                            <input className="form-control" value={e.from} index={e.index} onChange={this.onChangeTimeFrom} placeholder="09:00" />
                                                        </label>
                                                        <label>
                                                            To
                                                            <input className="form-control" value={e.to} index={e.index} onChange={this.onChangeTimeTo} placeholder="10:30:20" />
                                                        </label>
                                                    </div>
                                                    <div className="col-xs-12 col-md-12">
                                                        <button type="button" className="btn btn-sm btn-danger" onClick={() => this.onClickRemovePosition(e.index)}>Remove entry</button>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        <div className="clearfix"/>
                                        <div className="btn-group btn-sm">
                                            <button type="button" className="btn btn-sm btn-info" onClick={this.onClickAddPosition}>Add new position</button>
                                            <button type="button" className="btn btn-sm btn-success" onClick={this.onClickSaveHours}>Save result</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {!this.props.withForks && (
                                <div className="col-xs-12 col-md-6 mb-4">
                                    <div className="x_panel">
                                        <div className="x_title">
                                            <h4>Available sports</h4>
                                        </div>
                                        <div className="x_content">
                                            <div className="clearfix"/>
                                            {this.state.storage ? (
                                                <ul className="list-group">
                                                    {bookmakerGames.map((entry) => (
                                                        <li key={entry.key} className="list-group-item">
                                                            <span className="mr-2">{entry.title}</span>
                                                            {entry.key in this.state.storage && this.state.storage[entry.key] ? (
                                                                <button onClick={this.changeStorage} data-key={entry.key} data-action="remove" className="btn btn-success btn-sm">Start</button>
                                                            ) : (
                                                                <button onClick={this.changeStorage} data-key={entry.key} data-action="add" className="btn btn-danger btn-sm">Stop</button>
                                                            )}
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <i className="text-muted">Loading...</i>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                    <div className="col-xs-12 col-md-12 mb-4">
                        <BarChart data={this.state.barChartData} />
                    </div>
                    {this.state.lineCharts.map((e, i) => (
                        <div className="col-xs-12 col-md-12 mb-4">
                            <LineChart key={i} data={e} />
                        </div>
                    ))}
                </div>
            </>
        );
    }
}

export default DashboardPage;
