import React from 'react';
import helpers from "../../../chunks/helpers";
import {Link} from "react-router-dom";
import routes from "../../../chunks/routes";

class LiveEntity extends React.Component {
  render() {
    const game = this.props.data;
    const link = routes.games.detailed.replace(':sport', game.sport).replace(':name', game.eventID);

    return (
        <>
            <tr key={this.key}>
              <td>
                <i className="text-muted">{helpers.dt(game.createdAt)}</i>
              </td>
              <td>
                <Link to={link}>
                  <strong className={game.isGoalScored ? 'goal-scored' : ''}>{game.home}</strong></Link> - <Link to={link}><strong className={game.isGoalScored ? 'goal-scored' : ''}>{game.away}</strong>
                  {game.sportBlocked && (
                      <code className="ml-2 text-danger">Suspended game type</code>
                  )}
              </Link>
                {game.books && (
                    <div>
                      {game.books.map((book, i) => <small key={i} className="badge badge-secondary mr-1">{book}</small>)}
                    </div>
                )}
              </td>
              <td>
                <pre className="mb-0"><strong className="text-danger">{game.score.join(':')}</strong></pre>
                {game.currentGame && (
                    <div>
                      <small>Current game: <strong>{game.currentGame}</strong></small>
                    </div>
                )}
                {game.gameType && (
                    <div>
                      <small>Game type: <strong>{game.gameType}</strong></small>
                    </div>
                )}
              </td>
              <td>
                <mark>{game.eventID}</mark>
                <pre className="mb-0">
                  <small>{game.slug}</small>
                </pre>
              </td>
            </tr>
        </>
    );
  }
}

export default LiveEntity;
