// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.margin-top-profile {
    margin-top: 10px;
}

.btn-remove-profile {
    margin: 0 0 !important;
}

.modal-profile{
    display: none;
}

.green-text{
    color: #009688;
}

.blue-text{
    color: #039be5;
}

.red-text{
    color: #D32F2F
}

@media (max-width: 991.98px) {
    .page-title {
        height: 95px;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Games/Detailed/style.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI;AACJ;;AAEA;IACI;QACI,YAAY;IAChB;AACJ","sourcesContent":[".margin-top-profile {\n    margin-top: 10px;\n}\n\n.btn-remove-profile {\n    margin: 0 0 !important;\n}\n\n.modal-profile{\n    display: none;\n}\n\n.green-text{\n    color: #009688;\n}\n\n.blue-text{\n    color: #039be5;\n}\n\n.red-text{\n    color: #D32F2F\n}\n\n@media (max-width: 991.98px) {\n    .page-title {\n        height: 95px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
