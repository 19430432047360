// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.margin-top-profile {
    margin-top: 10px;
}

.btn-remove-profile {
    margin: 0 0 !important;
}

.form-control-feedback-profile{
    margin-top: 11px !important;
    height: 18px !important;
}

.modal-profile{
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/Users/Profile/UserData/style.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,2BAA2B;IAC3B,uBAAuB;AAC3B;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".margin-top-profile {\n    margin-top: 10px;\n}\n\n.btn-remove-profile {\n    margin: 0 0 !important;\n}\n\n.form-control-feedback-profile{\n    margin-top: 11px !important;\n    height: 18px !important;\n}\n\n.modal-profile{\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
