import React from 'react';
import UserEntry from "./UserEntry";

import backend from "../../../chunks/backend";
import title from "../../../chunks/title";

import PaginationComponent from "../../../components/PaginationComponent";

import sub from "../../../chunks/subscriber";

class UsersList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            subIds: [],
            page:   1,
            users:  [],
            loaded: false,
            telegram: '',
            username: {value: '', hasError: false},
            password: {value: '', hasError: false},
            role: {value: 'user', hasError: false},
        };

        this.changeUsername = this.changeUsername.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.changeRole = this.changeRole.bind(this);
        this.sendFormAdd = this.sendFormAdd.bind(this);
        this.updateData = this.updateData.bind(this);
        this.showPage = this.showPage.bind(this);
        this.changeTelegram = this.changeTelegram.bind(this);
        this.changeTelegramToken = this.changeTelegramToken.bind(this);
    }

    componentWillUnmount() {
        for (let id of this.state.subIds) {
            sub.unsubscribe(id);
        }
    }

    componentDidMount() {
        title('All Users');

        const ids = [];

        for (let event of ['users.authorized', 'users.create', 'users.ban', 'users.unban', 'users.update', 'users.restore', 'users.remove', 'users.trash']) {
            const id = sub.subscribe(event, (data) => {
                console.log('UsersList', data.event, data.username);
                this.updateData();
            });

            ids.push(id);
        }

        this.setState({ subIds: ids });
        this.updateData()
        // setInterval(() => {
        //     this.updateData()
        // }, 5000);
    }

    updateData() {
        if (this.props.user.hasAccess('users.list')) {
            backend.get(`/user/${this.props.type}?page=${this.state.page}`).then(({ data }) => {
                if (data.success) {
                    this.setState({ users:  data.users });
                }
            }).catch(console.log).finally(() => {
                this.setState({ loaded: true });
            });
        } else {
            this.setState({ loaded: true });
        }
    }

    changeUsername(e) {
        this.setState({
            username: {
                value: e.target.value,
                hasError: false
            }
        });
    }

    changePassword(e) {
        this.setState({
            password: {
                value: e.target.value,
                hasError: false
            }
        });
    }

    changeRole(e) {
        this.setState({
            role: {
                value: e.target.value,
                hasError: false
            }
        });
    }

    changeTelegram(e) {
        this.setState({
            telegram: e.target.value
        });
    }

    changeTelegramToken(e) {
        this.setState({
            telegramToken: e.target.value
        });
    }

    sendFormAdd(e) {
        e.preventDefault();

        const {username, password} = this.state;
        let formWithErrors = false;

        if (`${username.value}`.trim() === '') {
            username.hasError = true;
            formWithErrors = true;
        }

        if (`${password.value}`.trim() === '') {
            password.hasError = true;
            formWithErrors = true;
        }

        if (formWithErrors) {
            this.setState({
                username, password
            });

            return;
        }

        backend.post(`/user/create`, {
            username: String(this.state.username.value).trim(),
            password: String(this.state.password.value).trim(),
            role:     String(this.state.role.value).trim(),
            telegram: String(this.state.telegram).trim(),
            telegramToken: String(this.state.telegramToken).trim(),
        }).then((res) => {
            if (res.data.success) {
                console.log('Create user success, User: ', this.state.username.value)
                this.updateData()
            }
        }).catch(console.log).finally(() => {
            document.querySelector('#myModalAdd').querySelector('.close').click()
        })
    }

    showPage(page) {
        this.setState({ page }, () => {
            this.updateData();
        });
    }

    render() {
        const usernameClass = `form-control${this.state.username.hasError ? ' is-invalid' : ''}`;
        const passwordClass = `form-control${this.state.password.hasError ? ' is-invalid' : ''}`;

        return (
            <>
                <div className="page-title">
                    <div className="title_left">
                        {this.props.user.hasAccess('users.create') && (
                            <div className="x_content">
                                <button type="button" className="btn btn-primary" data-toggle="modal" data-target=".bs-example-modal-lg">Add User</button>
                                <div className="modal fade bs-example-modal-lg modal-profile" id="myModalAdd" tabIndex="-1" role="dialog" aria-hidden="true">
                                    <div className="modal-dialog modal-lg">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h4 className="modal-title" id="myModalLabel">Add new user</h4>
                                                <button type="button" className="close" data-dismiss="modal"><span aria-hidden="true">×</span>
                                                </button>
                                            </div>
                                            <form className="form-label-left input_mask" onSubmit={this.sendFormAdd}>
                                                <div className="modal-body">
                                                    <div className="form-group row has-feedback">
                                                        <label className="col-form-label col-md-3 col-sm-3 ">Username</label>
                                                        <div className="col-md-9 col-sm-9 ">
                                                            {this.state.username.hasError && (
                                                                <small className="text-danger">Invalid username</small>
                                                            )}
                                                            <input value={this.state.username.value} onChange={this.changeUsername} type="text" className={usernameClass} required="" />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-md-3 col-sm-3 ">Password</label>
                                                        <div className="col-md-9 col-sm-9 ">
                                                            {this.state.password.hasError && (
                                                                <small className="text-danger">Invalid password</small>
                                                            )}
                                                            <input value={this.state.password.value} onChange={this.changePassword} type="text" className={passwordClass} required="" />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row has-feedback">
                                                        <label className="col-form-label col-md-3 col-sm-3 ">Telegram ID</label>
                                                        <div className="col-md-9 col-sm-9 ">
                                                            <input value={this.state.telegram} onChange={this.changeTelegram} type="text" className="form-control" required="" />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row has-feedback">
                                                        <label className="col-form-label col-md-3 col-sm-3 ">Telegram Token</label>
                                                        <div className="col-md-9 col-sm-9 ">
                                                            <input value={this.state.telegramToken} onChange={this.changeTelegramToken} type="text" className="form-control" required="" />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-md-3 col-sm-3">Role</label>
                                                        <div className="col-md-9 col-sm-9">
                                                            <select defaultValue="user" className="form-control" onChange={this.changeRole} value={this.state.role.value}>
                                                                <option value="user">User</option>
                                                                <option value="admin">Admin</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                                    <button type="submit" className="btn btn-success">Save</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="title_right">
                        <PaginationComponent showPage={this.showPage} page={this.state.page} />
                    </div>
                </div>
                <div className="clearfix"/>
                <div className="table-responsive">
                    <table className="table table-hover bulk_action jambo_table table-striped">
                        <thead>
                            <tr className="headings">
                                <th className="column-title" style={{width: '40px'}} />
                                <th className="column-title">Username</th>
                                <th className="column-title">Password</th>
                                <th className="column-title">Telegram</th>
                                <th className="column-title">Status</th>
                                <th className="column-title">Timestamps</th>
                                <th className="column-title">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                        {this.state.loaded && (
                            this.state.users.length > 0 ? (
                                this.state.users.map((userData) => <UserEntry key={userData._id} data={userData} /> )
                            ) : (
                                <tr>
                                    <td colSpan="7">List is empty</td>
                                </tr>
                            )
                        )}
                        {!this.state.loaded && (
                            <tr>
                                <td colSpan="7"><i className="text-muted">Loading...</i></td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
                <br />
            </>
        );
    }
}

export default UsersList;
