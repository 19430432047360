const list = [];


list.push({ sport: 'Handicaps', filter: '4', title: 'Handicaps' });
list.push({ sport: 'Handicaps', filter: '10', title: 'Handicaps' });
list.push({ sport: 'Handicaps', filter: '70', title: 'Handicaps' });
list.push({ sport: 'Handicaps', filter: '16', title: 'Handicaps' });
list.push({ sport: 'Handicaps', filter: '22', title: 'Handicaps' });
list.push({ sport: 'Handicaps', filter: '64', title: 'Handicaps' });
list.push({ sport: 'Handicaps', filter: '58', title: 'Handicaps' });
list.push({ sport: 'Handicaps', filter: '76', title: 'Handicaps' });
list.push({ sport: 'Handicaps', filter: '34', title: 'Handicaps' });
list.push({ sport: 'Handicaps', filter: '28', title: 'Handicaps' });



// list.push({ sport: 'football', filter: 'football', title: 'Football' });
// list.push({ sport: 'tennis', filter: 'tennis', title: 'Tennis' });
// list.push({ sport: 'table-tennis', filter: 'table-tennis', title:  'Table Tennis' });
// list.push({ sport: 'ice-hockey', filter: 'ice-hockey', title: 'Ice Hockey' });
// list.push({ sport: 'basketball', filter: 'basketball', title: 'Basketball' });
// list.push({ sport: 'volleyball', filter: 'volleyball', title:  'Volleyball' });
// list.push({ sport: 'baseball', filter: 'baseball', title:  'Baseball' });
// list.push({ sport: 'handball', filter: 'handball', title:  'Handball' });
// list.push({ sport: 'american-football', filter: 'american-football', title:  'American Football' });
// list.push({ sport: 'cybersport', filter: 'cybersport', title:  'eSports' });

export default list;
