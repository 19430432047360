import React, {Component} from 'react';
import './style.css';

import {withRouter} from "react-router-dom";
import backend from "../../../../chunks/backend";
import AuthHistoryLine from "./AuthHistoryLine";
import PaginationComponent from "../../../../components/PaginationComponent";

class UserAuthHistory extends Component {

  constructor(props) {
    super(props);

    this.state = {
      auths:   [],
      loaded:  false,
      page:    1,
    };

    this.updateData = this.updateData.bind(this);
    this.showPage   = this.showPage.bind(this);
  }

  componentDidMount() {
    this.updateData()
  }

  showPage(page) {
    this.setState({ page }, () => {
      this.updateData();
    });
  }

  updateData() {
    this.setState({ loaded: false }, () => {
      backend.get(`/user/auth-history/${this.props.match.params.username}?page=${this.state.page}`).then(({ data }) => {
        if (data.success) {
          const { auths } = data;
          this.setState({ auths });
        }
      }).catch(console.log).finally(() => {
        this.setState({ loaded: true });
      });
    });
  }

  render() {
    return (
        <div id="user_auth_history" className="x_panel">
          <div className="x_title">
            <div className="row">
              <div className="col-xs-6 col-md-6">
                <h2>Auth history</h2>
              </div>
              <div className="col-xs-6 col-md-6">
                <PaginationComponent page={this.state.page} showPage={this.showPage} />
              </div>
            </div>
            <div className="clearfix"/>
          </div>
          <div className="x_content">
            <div className="table-responsive">
              <table className="table table-hover bulk_action jambo_table table-striped">
                <thead>
                <tr className="headings">
                  <th className="column-title">Date</th>
                  <th className="column-title">IP</th>
                  <th className="column-title">Device</th>
                  <th className="column-title">Browser</th>
                </tr>
                </thead>
                <tbody>
                {this.state.loaded && (
                    this.state.auths.length > 0 ? (
                        this.state.auths.map((authData) => <AuthHistoryLine key={authData._id} data={authData} /> )
                    ) : (
                        <tr>
                          <td colSpan="7">List is empty</td>
                        </tr>
                    )
                )}
                {!this.state.loaded && (
                    <tr>
                      <td colSpan="7"><i className="text-muted">Loading...</i></td>
                    </tr>
                )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

    );
  }
}

export default withRouter(UserAuthHistory);
