import React from "react";

import backend from "../../../chunks/backend";
import helpers from "../../../chunks/helpers";
import routes from "../../../chunks/routes";
import {Link} from "react-router-dom";

class Group extends React.Component {
    constructor(props) {
        super(props);

        this.onAssign = this.onAssign.bind(this);
        this.onWithdraw = this.onWithdraw.bind(this);
        this.onRemoveGroup = this.onRemoveGroup.bind(this);
    }

    onRemoveGroup(e) {
        e.nativeEvent.preventDefault();
        backend.delete(`/groups/${this.props.group.title}`).catch(console.log);
    }

    onAssign(bot) {
        const data = {
            name: bot.name,
            title: this.props.group.title
        }

        backend.put('/groups/assign', data).catch(console.log);
    }

    onWithdraw(bot) {
        const data = {
            name: bot.name,
            title: this.props.group.title
        }

        backend.put('/groups/withdraw', data).catch(console.log);
    }

    render() {
        const bl = (name) => routes.bots.detailed.replace(':name', name);

        return (
            <>
                <div className="x_panel mb-3">
                    <div className="x_title">
                        <h4>{this.props.group.title}</h4>
                        <small>Created At: <i>{ helpers.dt(this.props.group.createdAt) }</i></small>
                    </div>
                    <div className="x_content">
                        <div className="row">
                            <div className="col-md-6 col-xs-12">
                                <h6>Assigned</h6>
                                <ul className="list-group">
                                    {this.props.group.bots.map((bot, i) => (
                                        <li key={i} className="list-group-item pr-2">
                                            <div className="row">
                                                <div className="col-xs-8 col-md-8">
                                                    <Link to={bl(bot.name)}>
                                                        <strong>{bot.name}</strong>
                                                    </Link>
                                                    <br />
                                                    <small>{bot.mirror}</small>
                                                </div>
                                                <div className="col-xs-4 col-md-4">
                                                    <button style={{ float: 'right' }} className="btn btn-sm btn-danger mb-0 mt-1" onClick={() => this.onWithdraw(bot)}>Withdraw</button>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                    {this.props.group.bots.length === 0 && (
                                        <li className="list-group-item text-muted" style={{ backgroundColor: '#e9ecef' }}>No assigned bots</li>
                                    )}
                                </ul>
                            </div>
                            <div className="col-md-6 col-xs-12">
                                <h6>Available</h6>
                                <ul className="list-group">
                                    {this.props.bots.map((bot, i) => (
                                        <li key={i} className="list-group-item pr-2">
                                            <div className="row">
                                                <div className="col-xs-8 col-md-8">
                                                    <Link to={bl(bot.name)}>
                                                        <strong>{bot.name}</strong>
                                                    </Link>
                                                    <br />
                                                    <small>{bot.mirror}</small>
                                                </div>
                                                <div className="col-xs-4 col-md-4">
                                                    <button style={{ float: 'right' }} className="btn btn-sm btn-success mb-0 mt-1" onClick={() => this.onAssign(bot)}>Assign</button>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                    {this.props.bots.length === 0 && (
                                        <li className="list-group-item text-muted">No available bots</li>
                                    )}
                                </ul>
                            </div>
                        </div>
                        <hr />
                        <div>
                            <button onClick={this.onRemoveGroup} className="btn btn-warning">Remove Group</button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Group;
