import React from "react";
import { Link } from "react-router-dom";
import routes from "../../../chunks/routes";
import helpers from "../../../chunks/helpers";
import "../../Users/src/user-entry.css";
import backend from "../../../chunks/backend";

class BotAccountingEntity extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showPassword: false,
            timeOut: 0,
            loaded:  false,
            history: [],
            acceptedBets: 0,
            madeBets: 0,
            forks: 0,
            balance: 0,
            balanceList: [],
            cost: 0,
            profit: 0,
            botName: '',
            username: '',
        };

    }

    componentDidMount() {
        const bot = this.props.data;
        if(bot.balance){
            this.setState({balance: bot.balance});
        }
        this.setState({botName: bot.name});
        this.setState({username: bot.username});
        this.updateData(bot.name);
    }

    updateData(botName) {
        backend.get(`/bot/balance-history/${botName}?limit=99999999`).then(({data}) => {
            if (data.success) {
                this.setState({history: data.history});

                let balanceOld = this.state.balance
                let balanceNew = 0
                if(data.history.length !== 0){
                    let balanceList = []
                    for (let m = 0; m < data.history.length; m++) {
                      const tempBalance = data.history[m];
                      if(!balanceOld && !balanceNew && tempBalance.balance){
                        balanceNew = tempBalance.balance
                      }
                      if(tempBalance.balance !== undefined && tempBalance.balance !== null){
                        balanceList.push(tempBalance.balance)
                      }
                      else {
                        balanceList.push('not authorized')
                      }

                    }
                    balanceList.reverse()
                    this.setState({balanceList: balanceList});

                  const lastBalance = data.history[data.history.length - 1]
                  const profit = Number((balanceOld - lastBalance.balance).toFixed(2))
                  this.setState({cost: lastBalance.balance});
                  this.setState({profit: profit});

                  if(balanceNew){
                    this.setState({balance: balanceNew});
                    const profit = Number((balanceNew - lastBalance.balance).toFixed(2))
                    this.setState({profit: profit});
                  }
                }
            }
        }).catch(console.log).finally(() => {
            this.setState({
                loaded: true
            });
        })

        backend.get(`/bot/accepted-history/${botName}?limit=99999999`).then(({data}) => {
            if (data.success) {
                this.setState({acceptedBets: data.games.length});
            }
        }).catch(console.log).finally(() => {
            this.setState({
                loaded: true
            });
        })

        backend.get(`/bot/games-live/${botName}?limit=99999999`).then(({data}) => {
            if (data.success) {
                let madeBets = 0
                let forks = 0
                let liveNumAll = 0
                let archiveNumAll = 0
                let liveNum = 0
                let archiveNum = 0

                for (let i = 0; i < data.games.length; i++) {
                    const game = data.games[i];
                    const messages = game.messages;
                    if(messages.length !== 0) {
                        for (let m = 0; m < messages.length; m++) {
                            const message = messages[m];
                            if (message.data.action === 'KilledByTimer') {
                                const temp = message.data.reason.match(/\d+(?:.\d+)?/gi)[0]
                                const num = Number(temp)
                                if(num > 0){
                                    liveNum++
                                }
                            }
                        }
                    }
                }

                liveNumAll = data.games.length

                backend.get(`/bot/games-history/${botName}?limit=99999999`).then(({data}) => {
                    if (data.success) {
                        for (let i = 0; i < data.games.length; i++) {
                            const game = data.games[i];
                            const messages = game.messages;
                            if(messages.length !== 0) {
                                for (let m = 0; m < messages.length; m++) {
                                    const message = messages[m];
                                    if (message.data.action === 'KilledByTimer') {
                                        const temp = message.data.reason.match(/\d+(?:.\d+)?/gi)[0]
                                        const num = Number(temp)
                                        if(num > 0){
                                            archiveNum++
                                        }
                                    }
                                }
                            }
                        }

                        archiveNumAll = data.games.length
                        madeBets = liveNum + archiveNum
                        forks = liveNumAll + archiveNumAll
                        this.setState({madeBets: madeBets});
                        this.setState({forks: forks});
                    }
                }).catch(console.log).finally(() => {
                    this.setState({
                        loaded: true
                    })
                })
            }
        }).catch(console.log).finally(() => {
            this.setState({
                loaded: true
            })
        })

    }

    componentWillUnmount() {
        // clearTimeout(this.state.timeOut);
    }


    render() {
        const bot = this.props.data;
        const link = routes.users.profile.replace(":username", bot.username);
        const linkBotDetailed = routes.bots.detailed.replace(":name", bot.name);
        const userAccess = "userAccess" in this.props ? this.props.userAccess : true;
        let botClasses = "text-muted";

        if (bot.online) {
            if (bot.authOnSite) {
                botClasses = bot.balance > 0 ? "text-success" : "text-warning";
            } else {
                botClasses = "text-danger";
            }
        }

        botClasses += " mr-2";

        return (
            <>
                <tr key={this.key}>
                    <td>
                        <div>
                            {bot.online && <span className="user-online-dot" />}
                            <Link to={linkBotDetailed}>
                                <strong className={botClasses}>{bot.name}</strong>
                            </Link>
                            {bot.deletedAt ? (
                                <small className="text-danger">Deleted</small>
                            ) : bot.banned ? (
                                <small className="text-warning">Banned</small>
                            ) : (
                                ""
                            )}
                        </div>
                        <div>
                            {"restricted" in bot ? (
                                <>
                                    <code className="mb-0">Restricted</code>
                                    {!bot.restricted && <mark className="ml-1">Allowed by user</mark>}
                                </>
                            ) : (
                                <code className="mb-0 text-success">working</code>
                            )}
                        </div>
                    </td>
                    {userAccess && (
                        <td>
                            <strong>
                                <Link to={link}>{bot.username}</Link>
                            </strong>
                        </td>
                    )}
                    <td>
                        <small>
                            <div>
                                Created At: <i className="text-secondary">{helpers.dt(bot.createdAt)}</i>
                            </div>
                            {bot.updatedAt && bot.createdAt !== bot.updatedAt && (
                                <div>
                                    Updated At: <i className="text-info">{helpers.dt(bot.updatedAt)}</i>
                                </div>
                            )}
                            {bot.banned && (
                                <div>
                                    Banned At: <i className="text-warning">{helpers.dt(bot.banned)}</i>
                                </div>
                            )}
                            {bot.deletedAt && (
                                <div>
                                    Deleted At: <i className="text-danger">{helpers.dt(bot.deletedAt)}</i>
                                </div>
                            )}
                        </small>
                    </td>

                    <td>
                        <strong>{this.state.acceptedBets}</strong>
                    </td>
                    <td>
                        <strong>{this.state.madeBets}</strong>
                    </td>
                    <td>
                    <strong>{this.state.forks}</strong>
                    </td>
                    <td>
                        {this.state.balanceList.map((balance, i) => (
                            [8, 16, 24, 32, 40, 48, 56].includes(i) ? <div></div> : <small>{balance}, </small>
                        ))}
                    </td>

                  <td>
                    <>
                      <i className="fa fa-usd green-text" /> <strong><u>{this.state.cost}</u></strong>
                    </>
                  </td>

                    <td>
                        <>
                            <i className="fa fa-usd green-text" /> <strong><u>{this.state.balance}</u></strong>
                        </>
                    </td>

                    <td>
                        <>
                            <i className="fa fa-usd green-text" /> <strong className={this.state.profit >= 0 ? (this.state.profit === 0 ? '' : 'green-text') : 'red-text'}><u>{this.state.profit}</u></strong>
                        </>
                    </td>

                </tr>
            </>
        );
    }
}

export default BotAccountingEntity;
