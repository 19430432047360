import React from 'react';
import TemplateEntity from "./TemplateEntity";
import backend from "../../../chunks/backend";
import PaginationComponent from "../../../components/PaginationComponent";
import sub from "../../../chunks/subscriber";
import validation from "../../Bots/modals/modalValidation";

class TemplateList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      subIds: [],
      strategies: [],
      users: [],
      loaded: false,
      name: {value: '', hasError: false},
      percentBalance: {value: '', hasError: false},
      moneyRange: {value: '', hasError: false},
      description: '',
      page: 1,
      username: this.props.user.username
    };

    this.sendFormAdd = this.sendFormAdd.bind(this);
    this.sendFormUpdate = this.sendFormUpdate.bind(this);
    this.changeName = this.changeName.bind(this);
    this.changePercentBalance = this.changePercentBalance.bind(this);
    this.changeMoneyRange = this.changeMoneyRange.bind(this);
    this.changeDescription = this.changeDescription.bind(this);
    this.updateData = this.updateData.bind(this);
    this.updateRange = this.updateRange.bind(this);
    this.deleteRange = this.deleteRange.bind(this);
    this.copyRange = this.copyRange.bind(this);
    this.clearRangeState = this.clearRangeState.bind(this);
    this.showPage = this.showPage.bind(this);
    this.loadUsers = this.loadUsers.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
  }

  loadUsers() {
    backend.get('/user/active').then(({data}) => {
      const {users} = data;
      this.setState({users});
    }).catch(console.log);
  }

  componentDidMount() {
    const ids = [];
    for (let event of ['strategies.create_strategy', 'strategies.update_strategy']) {
      const id = sub.subscribe(event, (data) => {
        // console.log('StrategiesList', data.event);
        this.updateData();
      });

      ids.push(id);
    }

    this.setState({subIds: ids});
    this.updateData();

    if (this.props.user.hasAccess('moneys.assign_user')) {
      this.loadUsers();
    }
  }

  componentWillUnmount() {
    for (let id of this.state.subIds) {
      sub.unsubscribe(id);
    }
  }

  updateData() {
    backend.get(`/flat?page=${this.state.page}`).then(({data}) => {
      if (data.success) {
        const strategies = data.strategies
        this.setState({strategies: strategies});
      }
    }).catch(console.log).finally(() => {
      this.setState({
        loaded: true
      })
    })
  }

  showPage(page) {
    this.setState({page, loaded: false}, () => {
      this.updateData();
    });
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  changeName(e) {
    this.setState({
      name: {
        value: e.target.value,
        hasError: false
      }
    });
  }

  changePercentBalance(e) {
    this.setState({
      percentBalance: {
        value: e.target.value,
        hasError: false
      }
    });
  }

  changeMoneyRange(e) {
    this.setState({
      moneyRange: {
        value: e.target.value,
        hasError: false
      }
    });
  }

  changeDescription(e) {
    this.setState({
      description: e.target.value
    });
  }




  updateRange(e) {
    this.setState({
      name: {value: e.name, hasError: false},
      percentBalance: {value: e.percentBalance, hasError: false},
      moneyRange: {value: e.moneyRange, hasError: false},
      description: e.description || '',
      username: e.username || '',
    });
  }

  deleteRange(e) {
    backend.delete(`/flat/${e.name}`).then(({data}) => {
      if (data.success) {
        console.log('Remove success, Bot: ', e.name)
        this.updateData()
      }
    }).catch(console.log)
  }

  copyRange(e) {
    // console.log('COPY: ', e)
    document.querySelector('[data-target=".modal-money-add"]').click()
    this.setState({
      name: {value: `${e.name}-COPY`, hasError: false},
      percentBalance: {value: e.percentBalance, hasError: false},
      moneyRange: {value: e.moneyRange, hasError: false},
      description: e.description || '',
      username: e.username || ''
    });
  }

  clearRangeState() {
    this.setState({
      username: this.props.user.username || '',
      name: {value: '', hasError: false},
      percentBalance: {value: '', hasError: false},
      moneyRange: {value: '', hasError: false},
      description: ''
    });
  }

  sendFormUpdate(e) {
    e.preventDefault();

    const {percentBalance, moneyRange} = this.state;
    let formWithErrors = false;

    if (!validation.globalMoney(percentBalance.value)) {
      percentBalance.hasError = true;
      formWithErrors = true;
    }

    if (!validation.globalMoney(moneyRange.value)) {
      moneyRange.hasError = true;
      formWithErrors = true;
    }

    if (formWithErrors) {
      this.setState({
        percentBalance,
        moneyRange
      });

      return;
    }

    backend.put(`/flat/${this.state.name.value.trim()}`, {
      percentBalance: `${this.state.percentBalance.value}`.trim(),
      moneyRange: `${this.state.moneyRange.value}`.trim(),
      description: `${this.state.description}`.trim(),
      username: this.state.username,
    }).catch(console.log).finally(() => {
      document.querySelector('#modalMoneyUpdate').querySelector('.close').click()
      this.updateData()
    })
  }

  sendFormAdd(e) {
    e.preventDefault();

    const {name, percentBalance, moneyRange} = this.state;
    let formWithErrors = false;

    if (!validation.name(name.value)) {
      name.hasError = true;
      formWithErrors = true;
    }

    if (!validation.globalMoney(percentBalance.value)) {
      percentBalance.hasError = true;
      formWithErrors = true;
    }

    if (!validation.globalMoney(moneyRange.value)) {
      moneyRange.hasError = true;
      formWithErrors = true;
    }

    if (formWithErrors) {
      this.setState({
        name,
        percentBalance,
        moneyRange
      });

      return;
    }

    backend.post(`/flat`, {
      name: `${this.state.name.value}`.trim(),
      percentBalance: `${this.state.percentBalance.value}`.trim(),
      moneyRange: `${this.state.moneyRange.value}`.trim(),
      description: `${this.state.description}`.trim(),
      username: this.state.username,
    }).catch(console.log).finally(() => {
      document.querySelector('#modalMoneyAdd').querySelector('.close').click()
      this.updateData()
    })
  }


  render() {
    const nameClass = `form-control${this.state.name.hasError ? ' is-invalid' : ''}`;
    const percentBalanceClass = `form-control${this.state.percentBalance.hasError ? ' is-invalid' : ''}`;
    const moneyRangeClass = `form-control${this.state.moneyRange.hasError ? ' is-invalid' : ''}`;

    return (
      <>
        <div className="page-title">
          <div className="_title_left">
            <div className="x_content">
              <button type="button" onClick={this.clearRangeState} className="btn btn-primary" data-toggle="modal" data-target=".modal-money-add">Add
                Flat
              </button>
              <PaginationComponent showPage={this.showPage} page={this.state.page}/>
              <div className="modal fade bs-example-modal-lg modal-money-add" id="modalMoneyAdd" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title" id="myModalLabel">Add Flat</h4>
                      <button type="button" className="close" data-dismiss="modal"><span aria-hidden="true">×</span>
                      </button>
                    </div>

                    {/* Form add start here */}
                    <form className="form-label-left input_mask" onSubmit={this.sendFormAdd}>
                      <div className="modal-body">

                        <div className="form-row">
                          <div className="form-group col-md-6 col-sm-6">
                            <label>Name</label>
                            <input
                              value={this.state.name.value}
                              onChange={this.changeName}
                              className={nameClass}
                              type="text"
                            />
                            {this.state.name.hasError && (
                              <small className="text-danger">Invalid Name</small>
                            )}
                          </div>

                          <div className="form-group col-md-6 col-sm-6">
                            <label>Description</label>
                            <input
                              value={this.state.description}
                              onChange={this.changeDescription}
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="form-group col-md-6 col-sm-6">
                            <label>Percent of balance</label>
                            <input
                              value={this.state.percentBalance.value}
                              onChange={this.changePercentBalance}
                              className={percentBalanceClass}
                              type="text"
                              placeholder="Percent: 5, 7.5"
                            />
                            {this.state.percentBalance.hasError && (
                              <small className="text-danger">Invalid Percent</small>
                            )}
                          </div>

                          <div className="form-group col-md-6 col-sm-6">
                            <label>Money Range</label>
                            <input
                              value={this.state.moneyRange.value}
                              onChange={this.changeMoneyRange}
                              className={moneyRangeClass}
                              type="text"
                              placeholder="Money range: 10, 10-200"
                            />
                            {this.state.moneyRange.hasError && (
                              <small className="text-danger">Invalid money range</small>
                            )}
                          </div>
                        </div>

                      </div>

                      <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="submit" className="btn btn-success">Save</button>
                      </div>
                    </form>
                    {/* Form add end here */}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix"/>
        <div className="table-responsive">
          <table className="table table-hover bulk_action jambo_table table-striped">
            <thead>
            <tr className="headings">
              <th className="column-title">Name</th>
              <th className="column-title">Percent of balance</th>
              <th className="column-title">Money Range</th>
              <th className="column-title">Description</th>
              <th className="column-title">User created</th>
              <th className="column-title">Timestamps</th>
              <th className="column-title">Actions</th>
            </tr>
            </thead>
            <tbody>
            {this.state.loaded && (
              this.state.strategies.length > 0 ? (
                this.state.strategies.map((botData) =>
                  <TemplateEntity
                    key={botData._id.toString()}
                    data={botData}
                    updateRange={this.updateRange}
                    deleteRange={this.deleteRange}
                    copyRange={this.copyRange}
                    accessToUser={this.props.user.hasAccess('moneys.assign_user')}
                  />)
              ) : (
                <tr>
                  <td colSpan="6">List is empty</td>
                </tr>
              )
            )}
            {!this.state.loaded && (
              <tr>
                <td colSpan="6"><i className="text-muted">Loading...</i></td>
              </tr>
            )}
            </tbody>
          </table>
        </div>
        <br/>
        <div className="modal fade bs-example-modal-lg modal-profile modal-money-update" id="modalMoneyUpdate" tabIndex="-1" role="dialog"
             aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel">Update Bet Strategy</h4>
                <button type="button" className="close" data-dismiss="modal"><span aria-hidden="true">×</span>
                </button>
              </div>

              <form className="form-label-left input_mask" onSubmit={this.sendFormUpdate}>

                <div className="modal-body">

                  <div className="form-row">
                    <div className="form-group col-md-6 col-sm-6">
                      <label>Name</label>
                      <input
                        value={this.state.name.value}
                        disabled="disabled"
                        className="form-control"
                        type="text"
                      />
                      {this.state.name.hasError && (
                        <small className="text-danger">Invalid Name</small>
                      )}
                    </div>

                    <div className="form-group col-md-6 col-sm-6">
                      <label>Description</label>
                      <input
                        value={this.state.description}
                        onChange={this.changeDescription}
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6 col-sm-6">
                      <label>Percent of balance</label>
                      <input
                        value={this.state.percentBalance.value}
                        onChange={this.changePercentBalance}
                        className={percentBalanceClass}
                        type="text"
                        placeholder="Percent: 5, 7.5"
                      />
                      {this.state.percentBalance.hasError && (
                        <small className="text-danger">Invalid Percent</small>
                      )}
                    </div>

                    <div className="form-group col-md-6 col-sm-6">
                      <label>Money Range</label>
                      <input
                        value={this.state.moneyRange.value}
                        onChange={this.changeMoneyRange}
                        className={moneyRangeClass}
                        type="text"
                        placeholder="Money range: 10, 10-200"
                      />
                      {this.state.moneyRange.hasError && (
                        <small className="text-danger">Invalid money range</small>
                      )}
                    </div>
                  </div>

                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                  <button type="submit" className="btn btn-success">Save</button>
                </div>

              </form>

            </div>
          </div>
        </div>
      </>
    );
  }
}

export default TemplateList;
