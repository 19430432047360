import React, { Component } from 'react';
import './style.css';
import title from "../../../chunks/title";
import {withRouter} from "react-router-dom";
import LiveEntity from "./LiveEntity";
import backend from "../../../chunks/backend";
import sub from "../../../chunks/subscriber";
import SportsComponent from "../../../components/SportsComponent";

class GamesLive extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subIds: [],
      loaded: false,
      games: [],
      details: {},
      sport: 'football',
      // sport: 'table-tennis',
      filter: 'football-8'
    };

    this.updateData = this.updateData.bind(this);
    this.selectSport = this.selectSport.bind(this);

  }

  componentWillUnmount() {
    for (let id of this.state.subIds) {
      sub.unsubscribe(id);
    }
  }

  componentDidMount() {
    title(`Games Live`);
    const ids = [];

    for (let event of ['games.created', 'games.stored', 'games.deleted', 'games.bookmaker_removed']) {
      const id = sub.subscribe(event, () => {
        this.updateData();
      });

      ids.push(id);
    }

    this.setState({ subIds: ids });
    this.updateData();
  }

  updateData() {
    backend.get(`/games/live/${this.state.sport}`).then(({data}) => {
      if (data.success) {
        if(data.games){
          this.setState({games: data.games});
        }
      }
    }).catch(console.log).finally(() => {
      title(`Live ${this.state.sport} (${this.state.games.length})`);
      this.setState({
        loaded: true
      })
    })
  }

  selectSport(sport, filter) {
    this.setState({
      sport, filter, loaded: false
    }, () => {
      this.updateData();
    });
  }

  render() {
    return (
        <>
          <div className="page-title">
            <div className="title_left">
              <h3>Games Live</h3>
            </div>
            <div className="title_right">
              <div className="col-md-5 col-sm-5 pull-right btn-remove-profile">
                <div className="btn-group btn-sm form-check pull-right" data-toggle="buttons">
                  <SportsComponent active={[this.state.sport]} selectSport={this.selectSport} />
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix"/>
          <div className="row margin-top-profile">
            <div className="col-md-12 ">
              <div className="x_panel">
                <div className="x_content">
                  <div className="table-responsive">
                    <table className="table table-hover bulk_action jambo_table table-striped">
                      <thead>
                        <tr className="headings">
                          <th className="column-title" style={{ width: '160px' }}>Created At</th>
                          <th className="column-title">Name</th>
                          <th className="column-title">Score</th>
                          <th className="column-title">Event ID</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.loaded && (
                            this.state.games.length > 0 ? (
                                this.state.games.filter(e => (e.gameType === this.state.filter)).map((game) => <LiveEntity key={game.eventID} data={game} /> )
                            ) : (
                                <tr>
                                  <td colSpan="4">List is empty</td>
                                </tr>
                            )
                        )}
                        {!this.state.loaded && (
                            <tr>
                              <td colSpan="4"><i className="text-muted">Loading...</i></td>
                            </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
    );
  }
}

export default withRouter(GamesLive);
