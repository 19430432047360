import React from 'react';
import helpers from "../../../chunks/helpers";

class BetHistoryEntity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };

  }

  render() {
    const bet = this.props.data;

    return (
        <>
          <tr key={this.key}>
            <td><i className="text-muted">{helpers.dt(bet.createdAt)}</i></td>
            {/*<td>{bet.name}</td>*/}
            <td>{bet.gameName}</td>
            <td>{bet.golFrom}</td>
            <td>{bet.odd}</td>
            <td>{bet.stake}</td>
            <td>{bet.returnNum}</td>
            <td>{bet.status}</td>
          </tr>
        </>
    );
  }
}

export default BetHistoryEntity;
