const actions = [
    {
        title: 'Профиль запущен',
        value: 'botLoggedIn'
    },
    {
        title: 'Профиль Авторизован на сайте',
        value: 'botAuthOnSite'
    },
    {
        title: 'Профиль отключен',
        value: 'botLoggedOut'
    },
    // {
    //     title: 'Бот потерял соединение с сервером',
    //     value: 'botConnectionClosed'
    // },
    // {
    //     title: 'Bot restricted',
    //     value: 'botRestricted'
    // },
    {
        title: 'Баланс букмекера изменился',
        value: 'botBalanceChanged'
    },
    // {
    //     title: 'Бот не авторизован на сайте букмекера',
    //     value: 'botNotAuthOnSite'
    // },
    {
        title: 'Успешная проставка',
        value: 'betAccepted'
    },
    {
        title: 'Не удалось перекрыть плечо',
        value: 'botFailedCloseShoulder'
    },
    {
        title: 'Не принимаются ставки',
        value: 'botNotAcceptedBets'
    },

    {
        title: 'Ошибки проставки вилки',
        value: 'forkErrors'
    },
];

export default actions;
