import React from 'react';

import UsersList from "./src/UsersList";
import title from "../../chunks/title";

class UsersActive extends React.Component {
    componentDidMount() {
        title('Active Users');
    }

    render() {
        return (
            <>
                <UsersList type="active" user={this.props.user} />
            </>
        );
    }
}

export default UsersActive;
