import React from 'react';

import title from "../../chunks/title";
import BotAccountingList from "./src/BotAccountingList";


class Accounting extends React.Component {
  componentDidMount() {
    title('Accounting');
  }

  render() {
    return (
        <>
          {(this.props.user.hasAccess('games.live') && ['@@Mike@@', '@MIKE@', '@DAVID@', 'webserver', 'khovit', 'xchaos'].includes(this.props.user.data.username)) && (
              <BotAccountingList type="all" permissions={this.props.permissions} user={this.props.user}/>
          )}
        </>
    );
  }
}

export default Accounting;

