import React from "react";
import './style.css';
import BotAccountingEntity from "./BotAccountingEntity";
import backend from "../../../chunks/backend";
import PaginationComponent from "../../../components/PaginationComponent";
import sub from "../../../chunks/subscriber";


class BotAccountingList extends React.Component {
  constructor(props) {
    super(props);

    const mirror = (() => {
      const host = window.location.host;

      if (host === "eurobet.billy-lg.com") {
        return "https://eurobet.it";
      } else if (host === "parimatch.billy-lg.com") {
        return false;
      } else if (host === "billy-lg.com" || host === "bet365-point.billy-lg.com") {
        return "https://bet365.com";
      }

      return "https://bet365.com";
    })();

    this.state = {
      subIds: [],
      bots: [],
      users: [],
      isActive: true,
      loaded: false,
      username: { value: "", hasError: false },
      password: { value: "", hasError: false },
      description: "",
      mirror,
      global_money: "",
      oddsRanges: [
        { coef: "", money: "" },
        { coef: "", money: "" },
        { coef: "", money: "" },
      ],
      page: 1,

      filters: {
        moneyStatus: "",
        workStatus: "",
        username: "",
      },
    };

    this.changeFilterUsername = this.changeFilterUsername.bind(this);
    this.changeFilterWorkStatus = this.changeFilterWorkStatus.bind(this);
    this.changeFilterMoneyStatus = this.changeFilterMoneyStatus.bind(this);

    this.sendFormUpdate = this.sendFormUpdate.bind(this);
    this.changeUsername = this.changeUsername.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.changeGlobalMoney = this.changeGlobalMoney.bind(this);
    this.changeDescription = this.changeDescription.bind(this);
    this.changeMirror = this.changeMirror.bind(this);
    this.updateData = this.updateData.bind(this);
    this.updateBot = this.updateBot.bind(this);
    this.banBot = this.banBot.bind(this);
    this.unBanBot = this.unBanBot.bind(this);
    this.deleteBot = this.deleteBot.bind(this);
    this.trashBot = this.trashBot.bind(this);
    this.restoreBot = this.restoreBot.bind(this);
    this.openBot = this.openBot.bind(this);
    this.showActive = this.showActive.bind(this);
    this.showDeleted = this.showDeleted.bind(this);
    this.changeCoef = this.changeCoef.bind(this);
    this.changeMoney = this.changeMoney.bind(this);
    this.addMoneyRange = this.addMoneyRange.bind(this);
    this.disallowBot = this.disallowBot.bind(this);
    this.allowBot = this.allowBot.bind(this);
    this.showPage = this.showPage.bind(this);
    this.updateUsers = this.updateUsers.bind(this);
  }

  componentDidMount() {
    const ids = [];

    for (let event of ["bots.ban", "bots.unban", "bots.create", "bots.update", "bots.restore", "bots.trash"]) {
      const id = sub.subscribe(event, (data) => {
        this.updateData(true);
      });

      ids.push(id);
    }

    this.setState({ subIds: ids });
    this.updateData();
    this.updateUsers();
  }

  componentWillUnmount() {
    for (let id of this.state.subIds) {
      sub.unsubscribe(id);
    }
  }

  updateUsers() {
    backend
        .get("/user/active?limit=99999999")
        .then(({ data }) => {
          const { users } = data;
          this.setState({ users });
        })
        .catch(console.log);
  }

  updateData(loaded) {
    if (!loaded) {
      loaded = false;
    }

    this.setState(
        {
          loaded,
        },
        () => {
          const f = this.state.filters;
          let url = `/accounting/${this.props.type}?page=${this.state.page}&f_ms=${f.moneyStatus}&f_ws=${f.workStatus}`;

          if (this.props.user.hasAccess("bots.global_list")) {
            url += `&f_un=${f.username}`;
          }

          if (!this.props.user.hasAccess("bots.global_list")) {
            url = `/accounting/${this.props.type}/${this.props.user.username}?page=${this.state.page}`;
          }

          backend
              .get(url)
              .then(({ data }) => {
                if (data.success) {
                  const bots = data.bots;
                  // console.log('bots --- ', bots)
                  this.setState({ bots });
                }
              })
              .catch(console.log)
              .finally(() => {
                this.setState({
                  loaded: true,
                });
              });
        }
    );
  }

  showPage(page) {
    this.setState({ page }, () => {
      this.updateData();
    });
  }

  changeUsername(e) {
    this.setState({
      username: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changePassword(e) {
    this.setState({
      password: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeGlobalMoney(e) {
    this.setState({ global_money: e.target.value });
  }

  changeDescription(e) {
    this.setState({ description: e.target.value });
  }

  changeMirror(e) {
    this.setState({ mirror: e.target.value });
  }

  changeCoef(e) {
    const ind = `${e.target.id}`.replace("coef-", "");
    const coef = e.target.value;
    const oddsRanges = this.state.oddsRanges;
    oddsRanges[ind].coef = coef;
    this.setState({ oddsRanges: oddsRanges });
  }

  changeMoney(e) {
    const ind = `${e.target.id}`.replace("money-", "");
    const money = e.target.value;
    const oddsRanges = this.state.oddsRanges;
    oddsRanges[ind].money = money;
    this.setState({ oddsRanges: oddsRanges });
  }

  allowBot(e) {
    if ("restricted" in e) {
      backend
          .put(`/bot/allow/${e.name}`)
          .then(({ data }) => {
            if (data.success) {
              this.updateData();
            }
          })
          .catch(console.log);
    }
  }

  disallowBot(e) {
    if ("restricted" in e) {
      backend
          .put(`/bot/disallow/${e.name}`)
          .then(({ data }) => {
            if (data.success) {
              this.updateData();
            }
          })
          .catch(console.log);
    }
  }

  updateBot(e) {
    this.setState({
      username: {
        value: e.name,
        hasError: false,
      },
      password: {
        value: e.password,
        hasError: false,
      },
      description: e.description,
      mirror: e.mirror,
      global_money: e.global_money,
      oddsRanges: e.oddsRanges,
    });
  }

  deleteBot(e) {
    backend
        .delete(`/bot/remove/${e.name}`)
        .then(({ data }) => {
          if (data.success) {
            this.updateData();
          }
        })
        .catch(console.log);
  }

  trashBot(e) {
    backend
        .delete(`/bot/trash/${e.name}`)
        .then(({ data }) => {
          if (data.success) {
            this.updateData();
          }
        })
        .catch(console.log);
  }

  restoreBot(e) {
    backend
        .put(`/bot/restore/${e.name}`)
        .then(({ data }) => {
          if (data.success) {
            this.updateData();
          }
        })
        .catch(console.log);
  }

  banBot(e) {
    backend
        .put(`/bot/ban/${e.name}`)
        .then(({ data }) => {
          if (data.success) {
            this.updateData();
          }
        })
        .catch(console.log);
  }

  unBanBot(e) {
    backend
        .put(`/bot/unban/${e.name}`)
        .then(({ data }) => {
          if (data.success) {
            this.updateData();
          }
        })
        .catch(console.log);
  }

  openBot(e) {
    // window.location.assign('/search/'+this.state.query+'/some-action');
  }

  showActive() {
    this.setState({
      isActive: true,
      loaded: false,
    });

    this.updateData();
  }

  showDeleted() {
    this.setState({
      isActive: false,
      loaded: false,
    });

    this.updateData();
  }

  addMoneyRange() {
    const oddsRanges = this.state.oddsRanges;
    oddsRanges.push({ coef: "", money: "" });
    this.setState({ oddsRanges });
  }

  sendFormUpdate(e) {
    e.preventDefault();

    const { password } = this.state;
    let formWithErrors = false;

    if (password.value === "") {
      password.hasError = true;
      formWithErrors = true;
    }

    if (formWithErrors) {
      this.setState({
        password,
      });

      return;
    }

    const oddsRanges = this.state.oddsRanges.filter((el) => {
      return `${el.coef}`.trim() !== "" && `${el.money}`.trim() !== "";
    });

    backend
        .put(`/bot/update/${this.state.username.value}`, {
          password: `${this.state.password.value}`.trim(),
          global_money: `${this.state.global_money}`.trim(),
          description: `${this.state.description}`.trim(),
          mirror: `${this.state.mirror}`.trim(),
          oddsRanges: oddsRanges,
        })
        .then((res) => {
          if (res.data.success) {
            console.log("Update bot success, Bot: ", this.state.username.value);
            this.updateData();
          }
        })
        .catch(console.log)
        .finally(() => {
          document.querySelector("#modalBotUpdate").querySelector(".close").click();
        });
  }

  changeFilterMoneyStatus(e) {
    const { filters } = this.state;
    filters.moneyStatus = e.target.value;

    this.setState({ filters });
    this.updateData(false);
  }

  changeFilterWorkStatus(e) {
    const { filters } = this.state;
    filters.workStatus = e.target.value;

    this.setState({ filters });
    this.updateData(false);
  }

  changeFilterUsername(e) {
    const { filters } = this.state;
    filters.username = e.target.value;

    this.setState({ filters });
    this.updateData(false);
  }

  render() {
    const userAccess = this.props.user.hasAccess("bots.global_list");

    return (
        <>
          <div className="row mb-2">
            <div className="col-xs-9 col-md-9">
              <div className="row">
                {userAccess && (
                    <div className="col-xs-4 col-md-4">
                      <select
                          onChange={this.changeFilterUsername}
                          className="form-control form-control-sm"
                      >
                        <option value="">- all users -</option>
                        {this.state.users.map((user, i) => (
                            <option key={i} value={user.username}>
                              {user.username}
                            </option>
                        ))}
                      </select>
                    </div>
                )}
              </div>
            </div>
            <div className="col-xs-3 col-md-3">
              <PaginationComponent showPage={this.showPage} page={this.state.page} />
            </div>
          </div>
          <div className="table-responsive">
            <table className="table jambo_table">
              <thead>
              <tr className="headings">
                <th className="column-title">Bot Name</th>
                {userAccess && <th className="column-title">User</th>}
                <th className="column-title">Timestamps</th>
                <th className="column-title">Accepted Bets</th>
                <th className="column-title">Try Bets</th>
                <th className="column-title">Forks</th>
                <th className="column-title">Balance List</th>
                <th className="column-title">Cost</th>
                <th className="column-title">Balance</th>
                <th className="column-title">Profit</th>
              </tr>
              </thead>
              <tbody>
              {this.state.loaded &&
              (this.state.bots.length > 0 ? (
                  this.state.bots.map((botData) => (
                      <BotAccountingEntity
                          key={botData._id.toString()}
                          data={botData}
                          updateBot={this.updateBot}
                          deleteBot={this.deleteBot}
                          trashBot={this.trashBot}
                          banBot={this.banBot}
                          unBanBot={this.unBanBot}
                          restoreBot={this.restoreBot}
                          allowBot={this.allowBot}
                          disallowBot={this.disallowBot}
                          userAccess={userAccess}
                      />
                  ))
              ) : (
                  <tr>
                    <td colSpan={userAccess ? 8 : 7}>List is empty</td>
                  </tr>
              ))}
              {!this.state.loaded && (
                  <tr>
                    <td colSpan="8">
                      <i className="text-muted">Loading...</i>
                    </td>
                  </tr>
              )}
              </tbody>
            </table>
          </div>
        </>
    );
  }
}

export default BotAccountingList;
