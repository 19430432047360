const groupID = (group, sport) => {
    if (sport === 'football') {
        switch (group) {
            case '1x2': return 1;
            case 'totals': return 2;
            case 'individual_totals': return 3;
            case 'handicaps': return 4; //
            case 'winner': return 5;
            case 'additionals': return 6;
            default: return 0;
        }
    }

    if (sport === 'tennis') {
        switch (group) {
            case '1x2': return 7; //
            case 'totals': return 8; //
            case 'individual_totals': return 9; //
            case 'handicaps': return 10; //
            case 'winner': return 11; //
            case 'additionals': return 12; //
            default: return 0;
        }
    }

    if (sport === 'ice-hockey') {
        switch (group) {
            case '1x2': return 13;
            case 'totals': return 14;
            case 'individual_totals': return 15;
            case 'handicaps': return 16; //
            case 'winner': return 17;
            case 'additionals': return 18;
            default: return 0;
        }
    }

    if (sport === 'basketball') {
        switch (group) {
            case '1x2': return 19;
            case 'totals': return 20;
            case 'individual_totals': return 21;
            case 'handicaps': return 22; //
            case 'winner': return 23;
            case 'additionals': return 24;
            default: return 0;
        }
    }

    if (['esport-cs:go', 'esport-dota', 'esport-lol', 'esport-fifa', 'cybersport'].indexOf(sport) !== -1) {
        switch (group) {
            case '1x2': return 25; //
            case 'totals': return 26; //
            case 'individual_totals': return 27; //
            case 'handicaps': return 28; //
            case 'winner': return 29; //
            case 'additionals': return 30; //
            default: return 0;
        }
    }

    if (sport === 'american-football') {
        switch (group) {
            case '1x2': return 31; //
            case 'totals': return 32; //
            case 'individual_totals': return 33; //
            case 'handicaps': return 34; //
            case 'winner': return 35; //
            case 'additionals': return 36; //
            default: return 0;
        }
    }

    if (sport === 'rugby') {
        switch (group) {
            case '1x2': return 37;
            case 'totals': return 38;
            case 'individual_totals': return 39;
            case 'handicaps': return 40;
            case 'winner': return 41;
            case 'additionals': return 42;
            default: return 0;
        }
    }

    if (sport === 'futsal') {
        switch (group) {
            case '1x2': return 43;
            case 'totals': return 44;
            case 'individual_totals': return 45;
            case 'handicaps': return 46;
            case 'winner': return 47;
            case 'additionals': return 48;
            default: return 0;
        }
    }

    if (sport === 'badminton') {
        switch (group) {
            case '1x2': return 49;
            case 'totals': return 50;
            case 'individual_totals': return 51;
            case 'handicaps': return 52;
            case 'winner': return 53;
            case 'additionals': return 54;
            default: return 0;
        }
    }

    if (sport === 'baseball') {
        switch (group) {
            case '1x2': return 55;
            case 'totals': return 56;
            case 'individual_totals': return 57;
            case 'handicaps': return 58;
            case 'winner': return 59;
            case 'additionals': return 60;
            default: return 0;
        }
    }

    if (sport === 'volleyball') {
        switch (group) {
            case '1x2': return 61;
            case 'totals': return 62;
            case 'individual_totals': return 63;
            case 'handicaps': return 64;
            case 'winner': return 65;
            case 'additionals': return 66;
            default: return 0;
        }
    }

    if (sport === 'table-tennis') {
        switch (group) {
            case '1x2': return 67;
            case 'totals': return 68;
            case 'individual_totals': return 69;
            case 'handicaps': return 70;
            case 'winner': return 71;
            case 'additionals': return 72;
            default: return 0;
        }
    }

    if (sport === 'handball') {
        switch (group) {
            case '1x2': return 73;
            case 'totals': return 74;
            case 'individual_totals': return 75;
            case 'handicaps': return 76;
            case 'winner': return 77;
            case 'additionals': return 78;
            default: return 0;
        }
    }

    return 0;
};

export default groupID;
