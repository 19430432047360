const list = [];


list.push({ sport: 'Totals', filter: '2', title: 'Totals' });
list.push({ sport: 'Totals', filter: '8', title: 'Totals' });
list.push({ sport: 'Totals', filter: '68', title: 'Totals' });
list.push({ sport: 'Totals', filter: '14', title: 'Totals' });
list.push({ sport: 'Totals', filter: '20', title: 'Totals' });
list.push({ sport: 'Totals', filter: '62', title: 'Totals' });
list.push({ sport: 'Totals', filter: '56', title: 'Totals' });
list.push({ sport: 'Totals', filter: '74', title: 'Totals' });
list.push({ sport: 'Totals', filter: '32', title: 'Totals' });
list.push({ sport: 'Totals', filter: '26', title: 'Totals' });



// list.push({ sport: 'football', filter: 'football', title: 'Football' });
// list.push({ sport: 'tennis', filter: 'tennis', title: 'Tennis' });
// list.push({ sport: 'table-tennis', filter: 'table-tennis', title:  'Table Tennis' });
// list.push({ sport: 'ice-hockey', filter: 'ice-hockey', title: 'Ice Hockey' });
// list.push({ sport: 'basketball', filter: 'basketball', title: 'Basketball' });
// list.push({ sport: 'volleyball', filter: 'volleyball', title:  'Volleyball' });
// list.push({ sport: 'baseball', filter: 'baseball', title:  'Baseball' });
// list.push({ sport: 'handball', filter: 'handball', title:  'Handball' });
// list.push({ sport: 'american-football', filter: 'american-football', title:  'American Football' });
// list.push({ sport: 'cybersport', filter: 'cybersport', title:  'eSports' });

export default list;
