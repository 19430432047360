import { Component } from "react";
import backend from "../../../chunks/backend";

class Form extends Component {
    constructor(props) {
        super(props);

        this.state = this.props.data ? this.props.data : {
            server: {
                available: false,
                odds: '',
                percent: '',
                excluded: ''
            },
            acceptor: {
                available: false,
                odds: '',
                percent: '',
                excluded: ''
            },
            itfLeague: '',
            priority: 'acceptor',
            type: 'win_15',
            name: ''
        };

        // Server
        this.onChangeServerAvailable = this.onChangeServerAvailable.bind(this);
        this.onChangeServerOdds = this.onChangeServerOdds.bind(this);
        this.onChangeServerPercent = this.onChangeServerPercent.bind(this);
        this.onChangeServerExcluded = this.onChangeServerExcluded.bind(this);

        // Acceptor
        this.onChangeAcceptorAvailable = this.onChangeAcceptorAvailable.bind(this);
        this.onChangeAcceptorOdds = this.onChangeAcceptorOdds.bind(this);
        this.onChangeAcceptorPercent = this.onChangeAcceptorPercent.bind(this);
        this.onChangeAcceptorExcluded = this.onChangeAcceptorExcluded.bind(this);

        // Global
        this.onChangeItfLeague = this.onChangeItfLeague.bind(this);
        this.onChangePriority = this.onChangePriority.bind(this);
        this.onChangeGameType = this.onChangeGameType.bind(this);
        this.onChangeName = this.onChangeName.bind(this);
        this.onSubmitForm = this.onSubmitForm.bind(this);
    }

    onChangeServerAvailable(e) {
        const { server } = this.state;
        server.available = e.target.checked;
        this.setState({ server });
    }

    onChangeServerOdds(e) {
        const { server } = this.state;
        server.odds = e.target.value;
        this.setState({ server });
    }

    onChangeServerPercent(e) {
        const { server } = this.state;
        server.percent = e.target.value;
        this.setState({ server });
    }

    onChangeServerExcluded(e) {
        const { server } = this.state;
        server.excluded = e.target.value;
        this.setState({ server });
    }

    onChangeAcceptorAvailable(e) {
        const { acceptor } = this.state;
        acceptor.available = e.target.checked;
        this.setState({ acceptor });
    }

    onChangeAcceptorOdds(e) {
        const { acceptor } = this.state;
        acceptor.odds = e.target.value;
        this.setState({ acceptor });
    }

    onChangeAcceptorPercent(e) {
        const { acceptor } = this.state;
        acceptor.percent = e.target.value;
        this.setState({ acceptor });
    }

    onChangeAcceptorExcluded(e) {
        const { acceptor } = this.state;
        acceptor.excluded = e.target.value;
        this.setState({ acceptor });
    }

    onChangeItfLeague(e) {
        this.setState({ itfLeague: e.target.value });
    }

    onChangePriority(e) {
        this.setState({ priority: e.target.value });
    }

    onChangeGameType(e) {
        this.setState({ type: e.target.value });
    }

    onChangeName(e) {
        this.setState({ name: e.target.value });
    }

    onSubmitForm(e) {
        e.nativeEvent.preventDefault();
        const method = ('data' in this.props) ? 'put' : 'post';
        const data   = this.state;

        if (method === 'put') {
            delete data._id;
            data.name = this.props.data.name;
        }

        data.name = data.name.trim();

        backend[method]('/strategies', data).then(({ data }) => {
            if (data.success) {
                const els = document.querySelectorAll('[data-dismiss="modal"]');

                for (let i=0;i<els.length;i-=-1){
                    els[i].click();
                }
            }
        }).catch(console.log);
    }

    render() {
        return (
            <form id={this.props.id} className="modal fade bs-example-modal-lg modal-profile" role="dialog" onSubmit={this.onSubmitForm}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">{'data' in this.props ? `Update strategy ${this.props.data.name}` : 'Create strategy'}</h4>
                            <button type="button" className="close" data-dismiss="modal"><span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            {/* Form start here */}
                            <div className="row">
                                <div className="col-xs-12 col-md-12">
                                    <div className="form-group">
                                        <div className="input-group input-group-sm">
                                            <div className="input-group-prepend">
                                                <label className="input-group-text" htmlFor="name">Name</label>
                                            </div>
                                            <input disabled={'data' in this.props} onChange={this.onChangeName} value={this.state.name} className="form-control" id="name" />
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                                {/* Server start here */}
                                <div className="col-xs-6 col-md-6 col-sm-6">
                                    <label for="select_server" className="mb-2">
                                        <span className="mr-2">Server</span>
                                        <input id="select_server" type="checkbox" checked={this.state.server.available} onChange={this.onChangeServerAvailable} />
                                    </label>
                                    <div className="form-group">
                                        <div className="input-group input-group-sm">
                                            <div className="input-group-prepend">
                                                <label className="input-group-text" for="server_odds">Odds range</label>
                                            </div>
                                            <input onChange={this.onChangeServerOdds} value={this.state.server.odds} className="form-control" id="server_odds" disabled={!this.state.server.available} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="input-group input-group-sm">
                                            <div className="input-group-prepend">
                                                <label className="input-group-text" htmlFor="server_percent">Percent over</label>
                                            </div>
                                            <input onChange={this.onChangeServerPercent} value={this.state.server.percent} className="form-control" id="server_percent" disabled={!this.state.server.available} />
                                            <div className="input-group-append">
                                                <span className="input-group-text">%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="input-group input-group-sm">
                                            <div className="input-group-prepend">
                                                <label className="input-group-text" htmlFor="server_excluded_games">Excluded games</label>
                                            </div>
                                            <input onChange={this.onChangeServerExcluded} value={this.state.server.excluded} className="form-control" id="server_excluded_games" disabled={!this.state.server.available} />
                                        </div>
                                    </div>
                                </div>
                                {/* Server end here */}
                                {/* Acceptor start here */}
                                <div className="col-xs-6 col-md-6 col-sm-6">
                                    <label htmlFor="select_acceptor" className="mb-2">
                                        <span className="mr-2">Acceptor</span>
                                        <input onChange={this.onChangeAcceptorAvailable} id="select_acceptor" type="checkbox" checked={this.state.acceptor.available} />
                                    </label>
                                    <div className="form-group">
                                        <div className="input-group input-group-sm">
                                            <div className="input-group-prepend">
                                                <label className="input-group-text" htmlFor="acceptor_odds">Odds range</label>
                                            </div>
                                            <input defaultValue={this.state.acceptor.odds} onChange={this.onChangeAcceptorOdds} className="form-control" id="acceptor_odds" disabled={!this.state.acceptor.available} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="input-group input-group-sm">
                                            <div className="input-group-prepend">
                                                <label className="input-group-text" htmlFor="acceptor_percent">Server percent under</label>
                                            </div>
                                            <input defaultValue={this.state.acceptor.percent} className="form-control" id="acceptor_percent"  disabled={!this.state.acceptor.available} />
                                            <div className="input-group-append">
                                                <span className="input-group-text">%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="input-group input-group-sm">
                                            <div className="input-group-prepend">
                                                <label className="input-group-text" htmlFor="acceptor_excluded_games">Excluded games</label>
                                            </div>
                                            <input defaultValue={this.state.acceptor.excluded} className="form-control" id="acceptor_excluded_games"  disabled={!this.state.acceptor.available} />
                                        </div>
                                    </div>
                                </div>
                                {/* Acceptor end here */}
                                {/* Global start here */}
                                <div className="col-xs-12 col-md-12 col-sm-12">
                                    <hr />
                                    <div className="row">
                                        <div className="col-xs-4 col-sm-4 col-md-4">
                                            <div className="form-group">
                                                <div className="input-group input-group-sm">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text" htmlFor="itf_league">ITF League</label>
                                                    </div>
                                                    <select id="itf_league" defaultValue={this.state.itfLeague} className="form-control" onChange={this.onChangeItfLeague}>
                                                        <option value=""> - </option>
                                                        <option value="only">Only</option>
                                                        <option value="skip">Without</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-4 col-sm-4 col-md-4">
                                            <div className="form-group">
                                                <div className="input-group input-group-sm">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text" htmlFor="strategy_priority">Priority</label>
                                                    </div>
                                                    <select id="strategy_priority" defaultValue={this.state.priority} className="form-control" onChange={this.onChangePriority}>
                                                        <option value="acceptor">Acceptor</option>
                                                        <option value="server">Server</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-4 col-sm-4 col-md-4">
                                            <div className="form-group">
                                                <div className="input-group input-group-sm">
                                                    <div className="input-group-prepend">
                                                        <label className="input-group-text" htmlFor="strategy_game_type">Game type</label>
                                                    </div>
                                                    <select id="strategy_game_type" className="form-control" defaultValue={this.state.type} onChange={this.onChangeGameType}>
                                                        <option value="win_15">Who first 0:15</option>
                                                        <option value="loose_15">Server to win 0/15</option>
                                                        <option value="loose_15_30">Server to win 0/15/30</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Global end here */}
                            </div>
                            {/* Form start here */}
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-success" type="submit">Save</button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

export default Form;
