import React from 'react';
import helpers from "../../../chunks/helpers";

class AuthHistoryEntity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };

  }

  render() {
    const auth = this.props.data;
    const link   = `https://whatismyipaddress.com/ip/${auth.ip}`

    return (
        <>
          <tr key={this.key}>
            <td><i className="text-muted">{helpers.dt(auth.createdAt)}</i></td>
            <td><a rel="noopener noreferrer" href={link} target="_blank">{auth.ip}</a></td>
            <td>{auth.name}</td>
            <td>{auth.sessionEnd ? helpers.dt(auth.sessionEnd) : (<span className="text-success">Session active</span>)}</td>
          </tr>
        </>
    );
  }
}

export default AuthHistoryEntity;
