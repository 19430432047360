import React from "react";
import backend from "../../chunks/backend";
import filters from "./filters";
import groupID from "./groups";

class ForksPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filtersOpened:   false,
            filters:         filters.default,
            sportGroups:     {},
            sportGroupNames: ['1x2', 'totals', 'individual_totals', 'handicaps', 'winner', 'additionals']
        };

        this.onClickSaveButton = this.onClickSaveButton.bind(this);
        this.groupSelected = this.groupSelected.bind(this);
        this.sportGroupsTotal = this.sportGroupsTotal.bind(this);
        this.loadForksConfig = this.loadForksConfig.bind(this);

        this.toggleSportGroups = this.toggleSportGroups.bind(this);
        this.onChangeSport = this.onChangeSport.bind(this);
        this.onChangeMinPercent = this.onChangeMinPercent.bind(this);
        this.onChangeMaxPercent = this.onChangeMaxPercent.bind(this);
        this.onChangeSecondsFrom = this.onChangeSecondsFrom.bind(this);
        this.onChangeSecondsTo = this.onChangeSecondsTo.bind(this);
        this.onChangeOddsMin = this.onChangeOddsMin.bind(this);
        this.onChangeOddsMax = this.onChangeOddsMax.bind(this);
        this.onChangeInitiator = this.onChangeInitiator.bind(this);
        this.onChangeCurrGame = this.onChangeCurrGame.bind(this);
        this.onChangeRequiredBookmaker = this.onChangeRequiredBookmaker.bind(this);
        this.onClickRemoveRequired = this.onClickRemoveRequired.bind(this);
        this.onChangeSportGroup = this.onChangeSportGroup.bind(this);
        this.onChangeReversedForks = this.onChangeReversedForks.bind(this);
        this.toggleBookmaker = this.toggleBookmaker.bind(this);
        this.onChangeMinOdds = this.onChangeMinOdds.bind(this);
        this.onChangeMaxOdds = this.onChangeMaxOdds.bind(this);
        this.onClickToggleFilters = this.onClickToggleFilters.bind(this);
        this.buildQuery = this.buildQuery.bind(this);
    }

    onClickToggleFilters() {
        const { filtersOpened } = this.state;

        this.setState({
            filtersOpened: !filtersOpened
        });
    }

    loadForksConfig() {
        backend.get(`/bot/info/${this.props.name}`).then(({ data }) => {
            const forks = (data.success) ? data.bot.forks : null;

            this.setState({
                filters: filters.mix(forks || {})
            });
        }).catch(console.log);
    }

    buildQuery() {
        const _data = this.state.filters;
        const parts = [];

        if ('s' in _data) {
            const sports = [];

            for (let s in _data.s) {
                const sport = _data.s[s];

                if (sport) {
                    sports.push(s);
                }
            }

            parts.push('s='+sports.join(','));
        }

        if ('r' in _data) {
            parts.push('r='+_data.r.join(','));
        }

        if ('reversed' in _data) {
            parts.push(`reversed=`+_data.reversed);
        }

        if ('p' in _data) {
            parts.push(`p=${_data.p}`);
        }

        if ('pmax' in _data) {
            parts.push(`pmax=${_data.pmax}`);
        }

        if ('init' in _data) {
            parts.push(`init=true`);
        }

        if ('gomin' in _data) {
            parts.push(`gomin=${_data.gomin}`)
        }

        if ('gomax' in _data) {
            parts.push(`gomax=${_data.gomax}`)
        }

        if ('from' in _data) {
            parts.push(`from=${_data.from}`)
        }

        if ('currGame' in _data && _data.currGame) {
            parts.push(`currGame=true`)
        }

        if ('bti' in _data) {
            parts.push('bti='+_data.bti.join(','))
        }

        if ('b' in _data) {
            const o     = [];
            const omax  = [];
            const books = [];

            for (let book in _data.b) {
                if (!_data.b[book]) {
                    continue;
                }

                books.push(book);

                if ('o' in _data && book in _data.o) {
                    o.push(book+':'+_data.o[book]);
                }

                if ('omax' in _data && book in _data.omax) {
                    omax.push(book+':'+_data.omax[book]);
                }
            }

            if (books.length > 0) {
                parts.push(`b=${books.join(',')}`);

                if (o.length > 0) {
                    parts.push(`o=${o.join(',')}`);
                }

                if (omax.length > 0) {
                    parts.push(`omax=${omax.join(',')}`);
                }
            }
        }

        return '?format=json&'+parts.join('&');
    }

    componentDidMount() {
        this.updateData();
    }

    updateData() {
        this.loadForksConfig();
    }

    toggleSportGroups(e) {
        e.nativeEvent.preventDefault();
        const sport = e.target.getAttribute('data-sport');
        const {sportGroups} = this.state;

        if (sport in sportGroups) {
            delete sportGroups[sport];
        } else {
            sportGroups[sport] = true;
        }

        this.setState({ sportGroups });
    }

    onChangeSport(e) {
        const filters = this.state.filters;
        filters.s[e.target.value] = e.target.checked;
        this.setState({ filters });
    }

    onChangeMinPercent(e) {
        const filters = this.state.filters;
        const percent = Number(e.target.value);
        filters.p = percent;

        if (filters.pmax < percent) {
            filters.pmax = percent;
        }

        this.setState({ filters });
    }

    onChangeMaxPercent(e) {
        const filters = this.state.filters;
        const percent = Number(e.target.value);
        filters.pmax  = percent;

        if (filters.p > percent) {
            filters.p = percent;
        }

        this.setState({ filters });
    }

    onChangeSecondsFrom(e) {
        const filters = this.state.filters;
        const seconds = Number(e.target.value);
        filters.from  = seconds;

        if (filters.to < seconds) {
            filters.to = seconds;
        }

        this.setState({ filters });
    }

    onChangeSecondsTo(e) {
        const filters = this.state.filters;
        const seconds = Number(e.target.value);
        filters.to    = seconds;

        if (filters.from > seconds) {
            filters.from = seconds;
        }

        this.setState({ filters });
    }

    onChangeOddsMin(e) {
        const filters = this.state.filters;
        const odds    = Number(e.target.value);
        filters.gomin = odds;

        if (filters.gomax < odds) {
            filters.gomax = odds;
        }

        this.setState({ filters });
    }

    onChangeOddsMax(e) {
        const filters = this.state.filters;
        const odds    = Number(e.target.value);
        filters.gomax = odds;

        if (filters.gomin > odds) {
            filters.gomin = odds;
        }

        this.setState({ filters });
    }

    onChangeInitiator(e) {
        const filters = this.state.filters;
        filters.init  = e.target.checked;
        this.setState({ filters });
    }

    onChangeCurrGame(e) {
        const filters = this.state.filters;
        filters.init  = e.target.checked;
        this.setState({ filters });
    }

    onChangeRequiredBookmaker(e) {
        e.nativeEvent.preventDefault();
        const filters = this.state.filters;
        const value   = e.target.value.trim();

        if (value !== '' && filters.r.indexOf(value) === -1) {
            filters.r.push(value);
        }

        this.setState({ filters });
    }

    onClickRemoveRequired(e) {
        e.nativeEvent.preventDefault();
        const book = e.target.getAttribute('data-bookmaker');

        if (book !== filters.required) {
            const fltrs = this.state.filters;
            const index = fltrs.r.indexOf(book);

            if (index !== -1) {
                fltrs.r.splice(index, 1);
                this.setState({ filters: fltrs });
            }
        }
    }

    onChangeReversedForks(e) {
        const filters = this.state.filters;
        filters.reversed = e.target.value.trim();
        this.setState({ filters });
    }

    onChangeSportGroup(e) {
        const input = e.target;
        const value = Number(input.value.trim());

        if (value) {
            const filtrs = this.state.filters;
            const index  = filtrs.bti.indexOf(value);

            if (input.checked) {
                filtrs.bti.push(value);
                this.setState({ filters: filtrs });
            } else if (!input.checked) {
                filtrs.bti.splice(index, 1);
                this.setState({ filters: filtrs });
            }
        }
    }

    groupSelected(gID) {
        return (this.state.filters.bti.indexOf(gID) !== -1);
    }

    sportGroupsTotal(sport) {
        let i = 0;

        this.state.sportGroupNames.map(group => {
            const gID = groupID(group, sport);
            const has = this.groupSelected(gID);

            if (has) {
                i -=- 1;
            }

            return group;
        })

        return i;
    }

    toggleBookmaker(e) {
        const filters = this.state.filters;
        const input   = e.target;
        const book    = input.value.trim();
        const checked = input.checked;
        let changed   = false;

        if (checked && filters.b[book] === false) {
            filters.b[book] = [];
            changed = true;
        } else if (!checked && filters.b[book] !== false) {
            filters.b[book] = false;
            changed = true;
        }

        if (changed) {
            this.setState({ filters });
        }
    }

    onChangeMinOdds(e) {
        const filters = this.state.filters;
        const input = e.target;
        const book  = input.getAttribute('name');
        let value   = input.value;

        if (!/^\d+(?:\.)?(?:\d+)?$/gi.test(value) ){
            value = 0;
        }

        filters.o[book] = value;
        this.setState({ filters });
    }

    onChangeMaxOdds(e) {
        const filters = this.state.filters;
        const input = e.target;
        const book  = input.getAttribute('name');
        let value   = input.value;

        if (!/^\d+(?:\.)?(?:\d+)?$/gi.test(value) ){
            value = 0;
        }

        filters.omax[book] = value;
        this.setState({ filters });
    }

    onClickSaveButton(e) {
        e.nativeEvent.preventDefault();
        backend.put(`/bot/update/${this.props.name}`, { forks: this.state.filters }).then(this.updateData).catch(console.log);
    }

    render() {
        return (
            <div className="x_panel mb-4">
                <div className="x_title">
                    <h2>Forks filters</h2>
                    <div className="clearfix" />
                </div>
                <div className="x_content">
                    {this.state.filtersOpened ? (
                        <>
                            <div className="row">
                                <div className="col-xs-12 col-md-12 col-sm-12">
                                    <div style={{ overflow: 'scroll', whiteSpace: 'nowrap' }}>
                                        <u className="text-info text-clipboard">{this.buildQuery()}</u>
                                    </div>
                                    <hr />
                                </div>
                                <div className="col-xs-12 col-md-3 col-sm-3">
                                    <strong className="mb-2">Sports{(() => {
                                        let total  = 0;
                                        let active = 0;

                                        for (let s in this.state.filters.s) {
                                            if (this.state.filters.s[s]) {
                                                active -=- 1;
                                            }
                                            total -=- 1;
                                        }

                                        return (
                                            <u className="ml-2">{active}/{total}</u>
                                        );
                                    })()}</strong>
                                    <ul className="list-group">
                                        {Object.keys(this.state.filters.s).map(sport => {
                                            const ss = this.state.filters.s[sport];
                                            const ln = this.state.sportGroupNames.length;
                                            const qn = this.sportGroupsTotal(sport);
                                            let clNm = `ml-2`;

                                            if (ss) {
                                                if (qn === 0) {
                                                    clNm += ` text-danger`;
                                                } else if (qn < (ln / 2)) {
                                                    clNm += ` text-warning`;
                                                } else if (qn < ln) {
                                                    clNm += ` text-info`;
                                                } else {
                                                    clNm += ` text-success`;
                                                }
                                            } else {
                                                clNm += ` text-muted`;
                                            }

                                            return (
                                                <li key={sport} className="list-group-item">
                                                    <input type="checkbox" value={sport} defaultChecked={ss} checked={ss} onChange={this.onChangeSport} />
                                                    <span className="ml-2">{sport}</span>
                                                    <small  style={{ cursor: 'pointer' }} className="ml-2 text-info">
                                                        <i data-sport={sport} onClick={this.toggleSportGroups}>
                                                            {sport in this.state.sportGroups ? 'Hide groups' : 'Show groups'}
                                                            <u className={clNm}>({qn}/{ln})</u>
                                                        </i>
                                                    </small>
                                                    {sport in this.state.sportGroups && (
                                                        <>
                                                            <div className="clearfix mt-2"/>
                                                            <ul className="list-group">
                                                                {this.state.sportGroupNames.map(group => {
                                                                    const gID = groupID(group, sport);
                                                                    const has = this.groupSelected(gID);

                                                                    return (
                                                                        <li key={group} className="list-group-item">
                                                                            <span className="mr-2">{group}</span>
                                                                            <input type="checkbox" disabled={!ss} value={gID} checked={has} onChange={this.onChangeSportGroup} />
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        </>
                                                    )}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                                <div className="col-xs-12 col-md-3 col-sm-3">
                                    <strong className="mb-2">Percent</strong>
                                    <div className="input-group input-group-sm mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">From</span>
                                        </div>
                                        <input type="text" className="form-control" name="p" value={this.state.filters.p} onChange={this.onChangeMinPercent} />
                                        <div className="input-group-append" style={{ marginLeft: '-1px', marginRight: '-1px' }}>
                                            <span className="input-group-text">%</span>
                                        </div>
                                        <input type="text" className="form-control" name="pmax" value={this.state.filters.pmax} onChange={this.onChangeMaxPercent} />
                                        <div className="input-group-append">
                                            <span className="input-group-text">To</span>
                                        </div>
                                    </div>

                                    <strong className="mb-2">Time</strong>
                                    <div className="input-group input-group-sm mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">From</span>
                                        </div>
                                        <input type="text" className="form-control" name="from" value={this.state.filters.from} onChange={this.onChangeSecondsFrom} />
                                        <div className="input-group-append" style={{ marginLeft: '-1px', marginRight: '-1px' }}>
                                            <span className="input-group-text">Seconds</span>
                                        </div>
                                        <input type="text" className="form-control" name="to" value={this.state.filters.to} onChange={this.onChangeSecondsTo} />
                                        <div className="input-group-append">
                                            <span className="input-group-text">To</span>
                                        </div>
                                    </div>

                                    <strong className="mb-2">Global Odds</strong>
                                    <div className="input-group input-group-sm mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">Min</span>
                                        </div>
                                        <input type="text" className="form-control" name="gomin" value={this.state.filters.gomin} onChange={this.onChangeOddsMin} />
                                        <div className="input-group-append" style={{ marginLeft: '-1px', marginRight: '-1px' }}>
                                            <span className="input-group-text">Number</span>
                                        </div>
                                        <input type="text" className="form-control" name="gomax" value={this.state.filters.gomax} onChange={this.onChangeOddsMax} />
                                        <div className="input-group-append">
                                            <span className="input-group-text">Max</span>
                                        </div>
                                    </div>

                                    <strong className="mb-2">Miscellaneous</strong>
                                    <ul className="list-group mb-3">
                                        <li className="list-group-item">
                                            <input type="checkbox" defaultChecked={this.state.filters.init} checked={this.state.filters.init} onChange={this.onChangeInitiator} />
                                            <span className="ml-2">Include forks with initiators</span>
                                        </li>
                                        <li className="list-group-item">
                                            <input type="checkbox" defaultChecked={this.state.filters.currGame} checked={this.state.filters.currGame} onChange={this.onChangeCurrGame} />
                                            <span className="ml-2">Current game (only for tennis and table-tennis)</span>
                                        </li>
                                    </ul>

                                    <strong className="mb-2">Reversed forks</strong>
                                    <div className="mb-2">
                                        <select className="form-control form-control-sm" defaultValue={this.state.filters.reversed} onChange={this.onChangeReversedForks}>
                                            <option value="both">All forks</option>
                                            <option value="only">Only reversed</option>
                                            <option value="skip">Skip reversed</option>
                                        </select>
                                    </div>

                                    <strong className="mb-2">Required bookmakers</strong>
                                    <div className="mb-2">
                                        <select className="form-control form-control-sm" defaultValue="" onChange={this.onChangeRequiredBookmaker}>
                                            <option value="">- Select to add bookmaker as required -</option>
                                            {Object.keys(this.state.filters.b).filter(e => (this.state.filters.r.indexOf(e) === -1)).map(book => (
                                                <option value={book} key={book}>{book}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <ul className="list-group">
                                        {this.state.filters.r.map(book => (
                                            <li className="list-group-item">
                                                <span className="mr-2">{book}</span>
                                                {book !== filters.required && (
                                                    <u style={{ cursor: 'pointer' }} data-bookmaker={book} onClick={this.onClickRemoveRequired} className="text-danger">
                                                        <small>Remove</small>
                                                    </u>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="col-xs-12 col-md-6 col-sm-6">
                                    <strong className="mb-2">Bookmakers{(() => {
                                        let total  = 0;
                                        let active = 0;

                                        for (let b in this.state.filters.b) {
                                            if (this.state.filters.b[b] !== false) {
                                                active -=- 1;
                                            }
                                            total -=- 1;
                                        }

                                        return (
                                            <u className="ml-2">{active}/{total}</u>
                                        );
                                    })()}</strong>
                                    <ul className="list-group" style={{ maxHeight: '582px', overflowY: 'scroll' }}>
                                        {Object.keys(this.state.filters.b).map(book => {
                                            const checked = (this.state.filters.b[book] !== false);
                                            const disable = (book === filters.required);
                                            const minOdds = (book in this.state.filters.o) ? Number(this.state.filters.o[book]) : '';
                                            const maxOdds = (book in this.state.filters.omax) ? Number(this.state.filters.omax[book]) : '';
                                            const blocked = !checked;

                                            return (
                                                <li className="list-group-item" key={book}>
                                                    <label>
                                                        <input type="checkbox" value={book} disabled={disable} checked={checked} onChange={this.toggleBookmaker} />
                                                        <strong className="mr-2 ml-2">{book}</strong>
                                                    </label>
                                                    <div className="input-group input-group-sm" style={{ float: 'right', maxWidth: '50%', marginTop: '-5px', marginBottom: '-5px', marginRight: '-12px' }}>
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">Min</span>
                                                        </div>
                                                        <input type="text" className="form-control" name={book} disabled={blocked} value={minOdds} onChange={this.onChangeMinOdds} placeholder="1.00" />
                                                        <div className="input-group-append" style={{ marginLeft: '-1px', marginRight: '-1px' }}>
                                                            <span className="input-group-text">Odds</span>
                                                        </div>
                                                        <input type="text" className="form-control" name={book} disabled={blocked} value={maxOdds} onChange={this.onChangeMaxOdds} placeholder="100.00" />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text">Max</span>
                                                        </div>
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className="clearfix" />
                            <div className="ln_solid"/>
                            <button type="button" className="btn btn-light" onClick={this.onClickToggleFilters}>Hide filters</button>
                            <button type="button" className="btn btn-success" onClick={this.onClickSaveButton}>Save</button>
                        </>
                    ) : (
                        <i className="text-muted">Collapsed (<u onClick={this.onClickToggleFilters} className="text-info" style={{ cursor: 'pointer' }}>Show</u>)</i>
                    )}
                </div>
            </div>
        );
    }
}

export default ForksPage;
