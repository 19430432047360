import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Card, Avatar, Col, Row } from 'antd'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import backend from '../../../chunks/backend'
// import sub from '../../../chunks/subscriber'

// import helpers from "../../../chunks/helpers";
// import routes from "../../../chunks/routes";

const gridStyle = {
  width: '50%',
  // padding: '10px',
  padding: '0px 10px 0px 10px',
  // cursor: 'pointer',
  // textAlign: 'center',
}
const gridStyle2 = {
  width: '50%',
  padding: '0px 0px 0px 10px',
  // cursor: 'pointer'
  // textAlign: 'center',
}

class TemplateEntity extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      event: this.props.data
    }

    this.openGame = this.openGame.bind(this);
    this.openCoupon = this.openCoupon.bind(this);
    this.placeBet = this.placeBet.bind(this);
  }

  // componentDidMount() {
  //   console.log('componentDidMount: ', this.props.data.name)
  //   // this.setState({event: this.props.data})
  // }
  //
  // componentWillUnmount() {
  //   console.log('componentWillUnmount')
  // }

  static getDerivedStateFromProps(props, state) {
    // console.log('--- getDerivedStateFromProps: ', props.data.name)
    return {
      event: props.data
    }
  }


  openGame(bet, event) {
    // console.log('openGam bet:', bet)
    // console.log('openGam event:', event)

    try {
      backend.put(`/bot/open-game-basketball`, {
        bet: bet,
        bot: event,
      })
        .then((res) => {
          if (res.data.success) {
            // this.updateData();
          }
        })
        .catch(console.log);
    }
    catch (err) {
      console.log('Error openGame:', err.message)
    }
  }

  openCoupon(bet, event) {
    // console.log('openCoupon bet:', bet)
    // console.log('openCoupon event:', event)

    try {
      backend.put(`/bot/open-coupon-basketball`, {
        bet: bet,
        bot: event,
      })
        .then((res) => {
          if (res.data.success) {
            // this.updateData();
          }
        })
        .catch(console.log);
    }
    catch (err) {
      console.log('Error openCoupon:', err.message)
    }
  }

  placeBet(bet, event) {
    // console.log('placeBet bet:', bet)
    // console.log('placeBet event:', event)

    try {
      backend.put(`/bot/place-bet-basketball`, {
        bet: bet,
        bot: event,
      })
        .then((res) => {
          if (res.data.success) {
            // this.updateData();
          }
        })
        .catch(console.log);
    }
    catch (err) {
      console.log('Error placeBet:', err.message)
    }
  }
  render() {
    // const event = this.props.data
    // console.log('= TemplateEntity event:', event)
    // console.log(`OLD: ${event.name}, NEW: ${this.state.event.name}`)
    // this.setState({event: event})

    // const url = routes.users.profile.replace(':username', event.username);

    return (
      <Card>
        {/*<Card style={{height: "108px"}}>*/}
        {Object.keys(this.state.event.outcomes).map((key) => {
          const outcome = this.state.event.outcomes[key]

          let betsKey = []
          if(outcome.originalName.toLowerCase().includes("handicap")){
            betsKey = Object.keys(outcome.bets).reverse()
          }
          else {
            betsKey = Object.keys(outcome.bets)
          }

          return <Card.Grid hoverable={false} style={gridStyle}>
            <small><span className=""><strong>{outcome.originalName}</strong></span></small>
            {/*<small><span className="">Translated: <strong>{outcome.name}</strong></span></small>*/}
            {/*<div><small><span className="">Original  : <strong>{outcome.originalName}</strong></span></small></div>*/}
            <Card>
              {betsKey.map((key2) => {
                if (outcome.bets[key2].isLive) {
                  const oddsDecimal = Number(outcome.bets[key2].odds)
                  let oddsAmerican = ''
                  if(oddsDecimal < 2) {
                    oddsAmerican = `${Number(-100 / (oddsDecimal - 1)).toFixed(0)}`
                  } else {
                    oddsAmerican = `+${Number((oddsDecimal - 1) * 100).toFixed(0)}`
                  }

                  return <Card.Grid hoverable={false} style={gridStyle2}>
                    {/*<small><span className=""><strong>{outcome.bets[key2].name}</strong></span></small>*/}
                    <div><small><span className=""><strong>{outcome.bets[key2].originalName}</strong></span></small></div>
                    {/*<small><span className="">Translated: <strong>{outcome.bets[key2].name}</strong></span></small>*/}
                    {/*<div><small><span className="">Original  : <strong>{outcome.bets[key2].originalName}</strong></span></small></div>*/}
                    {/*<div><span className="badge badge-primary badge-font"><strong>{oddsAmerican}</strong></span></div>*/}
                    <div style={{marginTop: '10px'}}>
                      <div className="btn-group-sm btn-group">
                        <div style={{marginRight: "15px"}}><span className={outcome.bets[key2].coeffChange === 'nothing' ? "badge badge-dark badge-font" : (outcome.bets[key2].coeffChange === 'up' ? "badge badge-success badge-font" : (outcome.bets[key2].coeffChange === 'down' ? "badge badge-danger badge-font" : "badge badge-dark badge-font"))}><strong>{oddsAmerican}</strong></span></div>
                        {/*<div style={{marginRight: "30px"}}><span className={outcome.bets[key2].coeffChange === 'nothing' ? "badge badge-dark badge-font" : (outcome.bets[key2].coeffChange === 'up' ? "badge badge-success badge-font" : (outcome.bets[key2].coeffChange === 'down' ? "badge badge-danger badge-font" : "badge badge-dark badge-font"))}><strong>{oddsAmerican}</strong></span></div>*/}
                        <OverlayTrigger
                          // style={{marginRight: "20px"}}
                          placement="bottom"
                          overlay={<Tooltip>Open Game</Tooltip>}
                        >
                          <button
                            type="button"
                            style={{marginRight: '15px'}}
                            onClick={() => this.openGame(outcome.bets[key2], this.state.event)}
                            className="btn btn-sm btn-danger"
                            // data-toggle="modal" data-target=".modal-clone-bot"
                          >
                            <i className="fa fa-right-to-bracket fa-lg"></i>
                          </button>
                        </OverlayTrigger>

                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip>Open Coupon</Tooltip>}
                        >
                          <button
                            type="button"
                            style={{marginRight: '15px'}}
                            onClick={() => this.openCoupon(outcome.bets[key2], this.state.event)}
                            className="btn btn-sm btn-warning"
                            // data-toggle="modal" data-target=".modal-clone-bot"
                          >
                            {/*<i className="fa fa-bullseye-pointer fa-lg"></i>*/}
                            <i className="fa fa-money-bill fa-lg"></i>
                          </button>
                        </OverlayTrigger>

                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip>Place Bet</Tooltip>}
                        >
                          <button
                            type="button"
                            onClick={() => this.placeBet(outcome.bets[key2], this.state.event)}
                            className="btn btn-sm btn-success"
                            // data-toggle="modal" data-target=".modal-clone-bot"
                          >
                            {/*<i className="fa fa-bullseye-pointer fa-lg"></i>*/}
                            <i className="fa fa-arrow-pointer fa-lg"></i>
                          </button>
                        </OverlayTrigger>

                      </div>
                    </div>
                  </Card.Grid>
                }
              })}
            </Card>
          </Card.Grid>
          // return <Card.Grid style={gridStyle}><span className="badge badge-secondary mr-1">Outcome: <u>{event.outcomes[key].name}</u></span></Card.Grid>
        })}
      </Card>
    )
  }
}

export default withRouter(TemplateEntity)
