import React from 'react';
import helpers from "../../../chunks/helpers";

class BalanceHistoryEntity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };

  }

  render() {
    const history = this.props.data;

    const currency = <span style={{fontSize: "8px"}}><strong>{this.props.curency}</strong></span>

    return (
        <>
          <tr key={this.key}>
            <td>
              <div>{ helpers.dt(history.createdAt)}</div>
              {history.updatedAt && (
                  <small><div>Last update: { helpers.dt(history.updatedAt) }</div></small>
              )}
            </td>
            <td>
              {history.balance !== undefined && history.balance !== null ? (
                  <>
                    <strong>{history.balance}</strong> {currency}
                  </>
              ) : (
                  <i className="text-muted">probably not authorized</i>
              )}
            </td>
            <td>{history.name}</td>
            <td>
              {history.username && history.username !== 'null' ? (
                <strong>{history.username}</strong>
              ) : (
                  <i className="text-muted">Unauthorized</i>
              )}
            </td>
          </tr>
        </>
    );
  }
}

export default BalanceHistoryEntity;
