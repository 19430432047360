import React from 'react';

import BotsList from "./src/BotsList";
import title from "../../chunks/title";

class BotsActive extends React.Component {
    componentDidMount() {
        title('Active Bots');
    }

    render() {
        return (
            <>
                <BotsList type="active" permissions={this.props.permissions} user={this.props.user}/>
            </>
        );
    }
}

export default BotsActive;
