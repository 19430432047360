import backend from "./backend";

const unique = () => {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < characters.length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return result;
};
const events = {};

let ws;

// TODO: ВРЕМЕННО ЗАКОМЕНТИТЬ
// (function initSocket() {
//     if (ws instanceof WebSocket) {
//         return;
//     }
//
//     // const { host } = window.location;
//
//     // if (!window.location.protocol.startsWith("https")) {
//     //     window.location.href = `https://${window.location.host}`;
//     //     return;
//     // }
//
//     // if (
//     //     ["billy-lg.com", "eurobet.billy-lg.com", "parimatch.billy-lg.com", "bet365-point.billy-lg.com"].indexOf(
//     //         host
//     //     ) === -1
//     // ) {
//     //     window.location.href = "https://billy-lg.com";
//     //     return;
//     // }
//
//     // const wsUrl = (() => {
//     //     switch (host) {
//     //         case "billy-lg.com":
//     //             return "wss";
//     //         case "eurobet.billy-lg.com":
//     //             return "ewss";
//     //         case "parimatch.billy-lg.com":
//     //             return "pwss";
//     //         case "bet365-point.billy-lg.com":
//     //             return "bet365-point-wss";
//     //         default:
//     //             return "dev";
//     //     }
//     // })();
//
//    // ws = new WebSocket(`wss://bkag.ninjateam.io`);
//    ws = new WebSocket(`wss://wss.ninjateam.io/`);
//     ws.onmessage = (e) => {
//         const msg = JSON.parse(e.data);
//         fire(msg.event, msg);
//     };
//
//     let interval = setInterval(() => {
//         if (ws && ws.readyState === ws.OPEN) {
//             ws.send(JSON.stringify({ action: "ping" }));
//         }
//     }, 10000);
//
//     ws.onclose = () => {
//         ws = null;
//         clearInterval(interval);
//         setTimeout(initSocket, 1000);
//     };
// })();

function token() {
    return backend.makeID(backend.l);
}

function sendSubscribe(event) {
    if (ws && ws.readyState === ws.OPEN) {
        const msg = JSON.stringify({
            action: "subscribe",
            event,
            token: token(),
        });

        ws.send(msg);
    }
}

function sendUnSubscribe(event) {
    if (ws && ws.readyState === ws.OPEN) {
        const msg = JSON.stringify({
            action: "unsubscribe",
            event,
            token: token(),
        });

        ws.send(msg);
    }
}

function subscribe(event, callback) {
    if (typeof event !== "string" || typeof callback !== "function") {
        return void 0;
    }

    if (!(event in events)) {
        events[event] = {};
    }

    const id = unique();
    events[event][id] = callback;
    sendSubscribe(event);

    return id;
}

function unsubscribe(id) {
    for (let event in events) {
        if (id in events[event]) {
            delete events[event][id];

            if (Object.keys(events[event]).length === 0) {
                sendUnSubscribe(event);
                delete events[event];
            }
        }
    }
}

function fire(event, data) {
    if (event in events) {
        for (let key in events[event]) {
            events[event][key](data);
        }
    }
}

const sub = (() => {
    return { subscribe, unsubscribe };
})();

export default sub;
