import React, {Component} from 'react';
import './style.css';

import {withRouter} from "react-router-dom";
import backend from "../../../chunks/backend";
import PaginationComponent from "../../../components/PaginationComponent";
import GamesHistoryEntity from "./GamesHistoryEntity";


class BotDetailedGamesHistory extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      games: [],
      details: {},
      page: 1,
    };

    this.updateData = this.updateData.bind(this);
    this.showPage = this.showPage.bind(this);
    // this.getDetailedGame = this.getDetailedGame.bind(this);

  }

  componentDidMount() {
    this.updateData()
  }

  updateData() {
    backend.get(`/bot/games-history/${this.props.match.params.name}?page=${this.state.page}`).then(({data}) => {
      if (data.success) {
        this.setState({games: data.games});
      }
    }).catch(console.log).finally(() => {
      this.setState({
        loaded: true
      })
    })
  }

  showPage(page) {
    this.setState({ page }, () => {
      this.updateData();
    });
  }

  render() {

    return (

        <div className="x_panel">

          <div className="x_title">
            <h2>Games history</h2>
            <PaginationComponent page={this.state.page} showPage={this.showPage} />
            <div className="clearfix"/>
          </div>

          <div className="x_content">

            <div className="table-responsive">
              <table className="table table-hover bulk_action jambo_table table-striped accordion-table">
                <thead>
                  <tr className="headings">
                    <th className="column-title">Timestamp</th>
                    <th className="column-title">Name</th>
                  </tr>
                </thead>
                <tbody>
                {this.state.games.length === 0 && (
                    <tr>
                      <td colSpan="2">List is empty</td>
                    </tr>
                )}
                </tbody>
              </table>
              <div className="accordion" id="accordion1" role="tablist" aria-multiselectable="true">
                {this.state.loaded && (
                    this.state.games.length > 0 && (
                        this.state.games.map((game, i) => <GamesHistoryEntity key={i} data={game} user={this.props.user}/>)
                    )
                )}
              </div>
            </div>

          </div>

        </div>

    );
  }
}

export default withRouter(BotDetailedGamesHistory);
