import React, {Component} from 'react';
import helpers from "../../../chunks/helpers";

class PlacedBetHistoryEntity extends Component {
  constructor(props) {
    super(props);

    this.state = {};

  }

  render() {
    const history = this.props.data;

    // status: "sent",
    // forkId: forkId,
    // sport: sport,
    // firstPercent: first_percent,
    // firstBookmakerName: first_bookmakerName,
    // firstEventId: first_eventId,
    // firstLeague: first_league,
    // firstEventName: first_eventName,
    // firstEventUrl: first_eventUrl,
    // firstOutcomeName: first_outcomeName,
    // firstBetId: first_betId,
    // firstBetName: first_betName,
    // firstOdds: first_odds,
    // secondMoneyBet: moneyBet,
    // secondBookmakerName: bookName,
    // secondEventUrl: eventUrl,
    // secondEventName: eventName,
    // secondEventId: eventId,
    // secondBetName: betName,
    // secondBetId: betId,
    // secondOdds: odds,
    // secondOddsUpdateDate: odds_update_date,
    // secondIsLive: isLive,
    // secondScore: score,
    // secondGameTime: gameTime,
    // marginPercent: marginPercent,
    // name: this.name,
    // username: this.username,

    return (
        <>
          <tr key={this.key}>
            <td>
              <div>{helpers.dt(history.createdAt)}</div>
              {history.updatedAt && (
                  <small>
                    <div>Last update: {helpers.dt(history.updatedAt)}</div>
                  </small>
              )}
            </td>
            <td>
              <p className={history.status === 'accepted' ? 'green-text' : (history.status === 'closed' ? 'text-warning' : '')}><strong>{history.status.toUpperCase()}</strong></p>
            </td>
            <td>{history.sport}</td>
            <td>{history.firstLeague}</td>
            <td>
              <div><a rel="noreferrer" target="_blank" href={`${history.firstEventUrl}`}>{`${history.firstBookmakerName}`.replace('loot', 'nitrobetting').replace('ladbrokes', 'ladbrokes_be').replace('bwin_it', 'superbet_ro').replace('bwin', 'superbet_pl').replace('1win', 'cloudbet_fifa').replace('tonybet', 'tipsport').toUpperCase()}</a>
              </div>
              <div>{history.firstEventName}</div>
            </td>
            <td>
              <div><a rel="noreferrer" target="_blank"
                      href={`${history.secondEventUrl}`}>{`${history.secondBookmakerName}`.replace('loot', 'nitrobetting').replace('ladbrokes', 'ladbrokes_be').replace('bwin_it', 'superbet_ro').replace('bwin', 'superbet_pl').replace('tonybet', 'tipsport').replace('1win', 'cloudbet_fifa').toUpperCase()}</a></div>
              <div>{history.secondEventName}</div>
            </td>
            <td>{history.firstOutcomeName}</td>
            <td>{history.secondScore}</td>
            <td>
              <div><span className="blue-text"><strong>{history.firstOdds}</strong></span></div>
              <small>
                <div>{history.firstBetName}</div>
                <div>{history.firstOddsUpdateDate ? helpers.dtt(history.firstOddsUpdateDate) : ''}</div>
              </small>
            </td>
            <td>
              <div><span className={history.secondIsLive ? 'blue-text' : 'gray-text'}><strong>{history.secondOdds}</strong></span></div>
              <small>
                <div>{history.secondBetName}</div>
                <div>{history.secondOddsUpdateDate ? helpers.dtt(history.secondOddsUpdateDate) : ''}</div>
              </small>
            </td>
            <td><span className={history.marginPercent >= 0 ? 'green-text' : 'red-text'}><strong>{history.marginPercent}</strong></span> <i
                className="fa fa-percent"/></td>
            <td><i className="fa fa-usd"/> <span><strong>{history.secondMoneyBet}</strong></span></td>
          </tr>
        </>
    );
  }
}

export default PlacedBetHistoryEntity;
