import React, {Component} from 'react';
import './style.css';

import {withRouter} from "react-router-dom";
import backend from "../../../../chunks/backend";
import BotsLine from "./BotsLine";
import PaginationComponent from "../../../../components/PaginationComponent";

import AddBotModal from "../../../Bots/modals/AddBotModal";

class UserBot extends Component {
  constructor(props) {
    super(props);

    this.state = {
      banned: null,
      bots: [],
      ranges: [],
      isActive: true,
      loaded: false,
      username: {value: '', hasError: false},
      password: {value: '', hasError: false},
      rangename: {value: '', hasError: false},
      description: '',
      mirror: 'https://bet365.com',
      workStatus: 'working',
      global_money: '',
      oddsRanges: [{coef: '', money: ''}],
      page: 1
    };

    this.sendFormUpdate = this.sendFormUpdate.bind(this);
    this.changeUsername = this.changeUsername.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.changeGlobalMoney = this.changeGlobalMoney.bind(this);
    this.changeDescription = this.changeDescription.bind(this);
    this.changeMirror = this.changeMirror.bind(this);
    this.updateData = this.updateData.bind(this);
    this.banBot = this.banBot.bind(this);
    this.unBanBot = this.unBanBot.bind(this);
    this.deleteBot = this.deleteBot.bind(this);
    this.trashBot = this.trashBot.bind(this);
    this.restoreBot = this.restoreBot.bind(this);
    this.showActive = this.showActive.bind(this);
    this.showDeleted = this.showDeleted.bind(this);
    this.changeCoef = this.changeCoef.bind(this);
    this.changeMoney = this.changeMoney.bind(this);
    this.addMoneyRange = this.addMoneyRange.bind(this);
    this.clearBotState = this.clearBotState.bind(this);
    this.changeRangeName = this.changeRangeName.bind(this);
    this.toggleBotsStatus = this.toggleBotsStatus.bind(this);

    this.showPage = this.showPage.bind(this);
    this.changeWorkStatus = this.changeWorkStatus.bind(this);
  }

  componentDidMount() {
    this.updateData()

    // setInterval(() => {
    //   this.updateData()
    // }, 5000);
  }

  showPage(page) {
    this.setState({ page }, () => {
      this.updateData();
    });
  }

  updateData() {
    const type = this.state.isActive ? 'active' : 'deleted';

    backend.get(`/bot/${type}/${this.props.match.params.username}?page=${this.state.page}`).then(({data}) => {
      if (data.success) {
        const banned = data.banned;
        const bots   = data.bots;
        this.setState({ bots, banned });
      }
    }).catch(console.log).finally(() => {
      this.setState({
        loaded: true
      })
    })

    backend.get(`/money-ranges`).then(({data}) => {
      if (data.success) {
        console.log()
        const ranges = data.ranges
        this.setState({ranges: ranges});
      }
    }).catch(console.log).finally(() => {
      this.setState({
        loaded: true
      });
    });
  }

  changeUsername(e) {
    this.setState({
      username: {
        value: e.target.value,
        hasError: false
      }
    });
  }

  changePassword(e) {
    this.setState({
      password: {
        value: e.target.value,
        hasError: false
      }
    });
  }

  changeGlobalMoney(e) {
    this.setState({global_money: e.target.value});
  }

  changeDescription(e) {
    this.setState({description: e.target.value});
  }

  changeMirror(e) {
    this.setState({mirror: e.target.value});
  }

  changeWorkStatus(e) {
    this.setState({workStatus: e.target.value});
  }

  changeCoef(e) {
    const ind = `${e.target.id}`.replace('coef-', '')
    const coef = e.target.value
    const oddsRanges = this.state.oddsRanges
    oddsRanges[ind].coef = coef
    this.setState({oddsRanges: oddsRanges});
  }

  changeMoney(e) {
    const ind = `${e.target.id}`.replace('money-', '')
    const money = e.target.value
    const oddsRanges = this.state.oddsRanges
    oddsRanges[ind].money = money
    this.setState({oddsRanges: oddsRanges});
  }

  updateBot(e) {

    this.setState({
      username: {
        value: e.name,
        hasError: false
      },
      password: {
        value: e.password,
        hasError: false
      },
      description: e.description,
      mirror: e.mirror,
      global_money: e.global_money,
      oddsRanges: e.oddsRanges
    });

  }

  deleteBot(e) {
    backend.delete(`/bot/remove/${e.name}`).then(({data}) => {
      if (data.success) {
        console.log('Remove success, Bot: ', e.name)
        this.updateData()
      }
    }).catch(console.log)
  }

  trashBot(e) {
    backend.delete(`/bot/trash/${e.name}`).then(({data}) => {
      if (data.success) {
        console.log('Trash success, Bot: ', e.name)
        this.updateData()
      }
    }).catch(console.log)
  }

  restoreBot(e) {
    backend.put(`/bot/restore/${e.name}`).then(({data}) => {
      if (data.success) {
        console.log('Restore success, Bot: ', e.name)
        this.updateData()
      }
    }).catch(console.log)
  }

  banBot(e) {
    backend.put(`/bot/ban/${e.name}`).then(({data}) => {
      if (data.success) {
        console.log('Ban success, Bot: ', e.name)
        this.updateData()
      }
    }).catch(console.log)
  }

  unBanBot(e) {
    backend.put(`/bot/unban/${e.name}`).then(({data}) => {
      if (data.success) {
        console.log('UnBan success, Bot: ', e.name)
        this.updateData()
      }
    }).catch(console.log)
  }

  showActive() {
    this.setState({
      isActive: true,
      loaded:   false
    }, this.updateData);
  }

  showDeleted() {
    this.setState({
      isActive: false,
      loaded:   false
    }, this.updateData);
  }

  addMoneyRange() {
    const oddsRanges = this.state.oddsRanges;
    oddsRanges.push({coef: '', money: ''})
    this.setState({ oddsRanges });
  }

  clearBotState() {
    this.setState({
      username: {value: '', hasError: false},
      password: {value: '', hasError: false},
      description: '',
      mirror: '',
      global_money: '',
      rangename: {value: '', hasError: false},
      oddsRanges: [{coef: '', money: ''}]
    });
  }

  changeRangeName(e) {
    const name = e.target.value
    this.setState({
      rangename: {
        value: e.target.value,
        hasError: false
      }
    });

    if(name){
      const range = this.state.ranges.find(r => r.name === name);
      if(range){
        this.setState({
          oddsRanges: range.oddsRanges
        });
      }
    }
    else {
      this.setState({
        oddsRanges: [{coef: '', money: ''}]
      });
    }
  }

  sendFormUpdate(e) {
    e.preventDefault();

    const {password} = this.state;
    let formWithErrors = false;


    if (password.value === '') {
      password.hasError = true;
      formWithErrors = true;
    }

    if (formWithErrors) {
      this.setState({
        password
      });

      return;
    }

    const oddsRanges = this.state.oddsRanges.filter((el) => {
      return (`${el.coef}`.trim() !== '' && `${el.money}`.trim() !== '');
    });

    backend.put(`/bot/update/${this.state.username.value}`, {
      password: `${this.state.password.value}`.trim(),
      global_money: `${this.state.global_money}`.trim(),
      description: `${this.state.description}`.trim(),
      mirror: `${this.state.mirror}`.trim(),
      oddsRanges: oddsRanges,
      workStatus: this.state.workStatus
    }).then((res) => {
      if (res.data.success) {
        console.log('Update bot success, Bot: ', this.state.username.value)
        this.updateData()
      }
    }).catch(console.log).finally(() => {
      document.querySelector('#myModalUpdate').querySelector('.close').click()
    })
  }

  toggleBotsStatus(e) {
    backend.put(`/system/goals-status-by-user/${this.props.match.params.username}`).then(({ data }) => {
      if (data.success) {
        this.updateData();
      }
    }).catch(console.log);
  }

  render() {
    return (

        <div id="user_bots_list" className="x_panel">
          <div className="x_title">
            <div className="row">
              <div className="col-md-4 col-xs-4">
                <h2>Bots</h2>
              </div>
              <div className="col-md-4 col-xs-4">
                {this.props.user.hasAccess('bots.deleted') && (
                    <div className="btn-group btn-group-sm form-check pl-0" data-toggle="buttons">
                      <button className="btn btn-secondary active" onClick={this.showActive}>
                        <input type="radio" className="join-btn"/> Active
                      </button>
                      <button className="btn btn-secondary" onClick={this.showDeleted}>
                        <input type="radio" className="join-btn"/> Deleted
                      </button>
                    </div>
                )}
              </div>
              <div className="col-md-4 col-xs-4">
                <PaginationComponent page={this.state.page} showPage={this.showPage} />
              </div>
            </div>
          </div>

          <div className="x_content">
            <div className="table-responsive">
              <table className="table table-hover bulk_action jambo_table table-striped">
                <thead>
                <tr className="headings">
                  <th className="column-title">Name</th>
                  <th className="column-title">Password</th>
                  <th className="column-title">Mirror</th>
                  <th className="column-title">Balance</th>
                  <th className="column-title">Actions</th>
                </tr>
                </thead>
                <tbody>
                {this.state.loaded && (
                    this.state.bots.length > 0 ? (
                        this.state.bots.map((botData) => <BotsLine user={this.props.user} key={botData._id.toString()} data={botData} deleteBot={this.deleteBot} trashBot={this.trashBot} restoreBot={this.restoreBot} banBot={this.banBot} unBanBot={this.unBanBot} />)
                    ) : (
                        <tr><td colSpan="5">List is empty</td></tr>
                    )
                )}
                {!this.state.loaded && (
                    <tr>
                      <td colSpan="5"><i className="text-muted">Loading...</i></td>
                    </tr>
                )}
                </tbody>
              </table>
            </div>

            <div className="ln_solid"/>
            <div className="form-group row">
              <div className="col-md-9 col-sm-9">
                <div className="x_content">
                  <button type="button" onClick={this.clearBotState} className="btn btn-primary" data-toggle="modal" data-target=".bs-example-modal-lg">Add Bot</button>
                  <AddBotModal updateData={this.updateData} username={this.props.match.params.username} user={this.props.user}/>
                  {this.state.banned && (
                      <button type="button" onClick={this.toggleBotsStatus} className="btn btn-success">Start Bots</button>
                  )}
                  {this.state.banned === false && (
                      <button type="button" onClick={this.toggleBotsStatus} className="btn btn-danger">Stop Bots</button>
                  )}
                </div>
              </div>
            </div>

          </div>
        </div>

    );
  }
}

export default withRouter(UserBot);
