import React, {Component} from 'react';
import helpers from "../../../chunks/helpers";

class PlacedBetWaitingForkHistoryEntity extends Component {
  constructor(props) {
    super(props);

    this.state = {};

  }

  render() {
    const history = this.props.data;

    // const secondScore = '15:21 (11:19 4:2) 07:34'

    let firstTotalScore = ''
    let firstGameTime = ''
    let firstScoreQuarter1 = ''
    let firstScoreQuarter2 = ''
    let firstScoreQuarter3 = ''
    let firstScoreQuarter4 = ''
    let firstCountQuarter = 0

    if (history.firstScore) {
      if(history.firstScore.includes("(")) {
        firstTotalScore = `${history.firstScore}`.split(/\s+/g).shift()
        firstGameTime = `${history.firstScore}`.split(/\s+/g).pop()

        const sc1 = `${history.firstScore}`.split(/ \(/g)
        if (sc1) {
          if (sc1[1]) {
            const sc2 = sc1[1].split(/\)/g)
            if (sc2) {
              const sc3 = sc2[0].split(/ /g);
              if (sc3) {
                firstCountQuarter = sc3.length
                for (let i = 0; i < sc3.length; i++) {
                  const sc4 = sc3[i];
                  if (i === 0) {
                    firstScoreQuarter1 = sc4
                  }
                  if (i === 1) {
                    firstScoreQuarter2 = sc4
                  }
                  if (i === 2) {
                    firstScoreQuarter3 = sc4
                  }
                  if (i === 3) {
                    firstScoreQuarter4 = sc4
                  }
                }
              }
            }
          }
        }
      }
    }

    let secondTotalScore = ''
    let secondGameTime = ''
    let secondScoreQuarter1 = ''
    let secondScoreQuarter2 = ''
    let secondScoreQuarter3 = ''
    let secondScoreQuarter4 = ''
    let secondCountQuarter = 0

    if (history.secondScore) {
      if(history.secondScore.includes("(")) {
        secondTotalScore = `${history.secondScore}`.split(/\s+/g).shift()
        secondGameTime = `${history.secondScore}`.split(/\s+/g).pop()

        const sc1 = `${history.secondScore}`.split(/ \(/g)
        if (sc1) {
          if (sc1[1]) {
            const sc2 = sc1[1].split(/\)/g)
            if (sc2) {
              const sc3 = sc2[0].split(/ /g);
              if (sc3) {
                secondCountQuarter = sc3.length
                for (let i = 0; i < sc3.length; i++) {
                  const sc4 = sc3[i];
                  if (i === 0) {
                    secondScoreQuarter1 = sc4
                  }
                  if (i === 1) {
                    secondScoreQuarter2 = sc4
                  }
                  if (i === 2) {
                    secondScoreQuarter3 = sc4
                  }
                  if (i === 3) {
                    secondScoreQuarter4 = sc4
                  }
                }
              }
            }
          }
        }
      }
    }


    return (
        <>
          <tr key={this.key} className={((history.firstStatus === 'accepted' && history.secondStatus === 'accepted') || (history.secondStatus === 'closed')) ? (history.marginMoney >= 0 ? 'accepted-tr' : 'remove-tr') : ((history.firstStatus === 'failed' && history.secondStatus === 'failed') ? 'failed-tr' : '')}>
            {/*<tr key={this.key} className={history.status === 'accepted' ? 'accepted-tr' : (history.isGameClosed ? 'remove-tr' : '')}>*/}
            <td>
              <div>{helpers.dt(history.createdAt)}</div>
              {history.updatedAt && (
                  <small>
                    <div>Last update:</div>
                    <div>{helpers.dt(history.updatedAt)}</div>
                  </small>
              )}
              {/*<small>*/}
              {/*  <div style={{marginTop: '10px'}}>*/}
              {/*    <button*/}
              {/*        type="button"*/}
              {/*        onClick={() => this.props.deletePlaceBet(history)}*/}
              {/*        className="btn btn-sm btn-danger col-md-12 col-xs-12"*/}
              {/*    >*/}
              {/*      Delete*/}
              {/*    </button>*/}
              {/*  </div>*/}
              {/*</small>*/}
            </td>
            <td><p className={history.firstStatus === 'accepted' ? 'green-text' : (history.firstStatus === 'failed' ? 'red-text' : (history.firstStatus === 'closed' ? 'text-warning' : ''))}><strong>{history.firstStatus.toUpperCase()}</strong></p></td>
            <td><p className={history.secondStatus === 'accepted' ? 'green-text' : (history.secondStatus === 'failed' ? 'red-text' : (history.secondStatus === 'closed' ? 'text-warning' : ''))}><strong>{history.secondStatus.toUpperCase()}</strong></p></td>
            {/*<td><strong>{history.sport}</strong></td>*/}
            {/*<td>{history.firstLeague}</td>*/}
            <td>
              <div><strong>{history.sport}</strong></div>
              <div>{history.firstLeague}</div>
            </td>
            <td>
              <div><a rel="noreferrer" target="_blank" href={`${history.firstEventUrl}`}>{`${history.firstBookmakerName}`.replace('stake', 'pinnacle_2').replace('loot', 'nitrobetting').replace('ladbrokes', 'ladbrokes_be').replace('bwin_it', 'superbet_ro').replace('bwin', 'superbet_pl').replace('tonybet', 'tipsport').replace('1win', 'cloudbet_fifa').toUpperCase()}</a>
              </div>
              <div>{history.firstEventName}</div>
            </td>
            <td>
              <div><a rel="noreferrer" target="_blank" href={`${history.secondEventUrl}`}>{`${history.secondBookmakerName}`.replace('stake', 'pinnacle_2').replace('loot', 'nitrobetting').replace('ladbrokes', 'ladbrokes_be').replace('bwin_it', 'superbet_ro').replace('bwin', 'superbet_pl').replace('tonybet', 'tipsport').replace('1win', 'cloudbet_fifa').toUpperCase()}</a></div>
              <div>{history.secondEventName}</div>
            </td>
            <td>
              <div>First:</div>
              <>
                { firstTotalScore ? (
                    <div>
                      <span><strong>{firstTotalScore}</strong></span> ({firstScoreQuarter1 ?
                        <span className={firstCountQuarter === 1 ? 'blue-text' : ''}>{firstScoreQuarter1}</span> : ''}{firstScoreQuarter2 ?
                        <span className={firstCountQuarter === 2 ? 'blue-text' : ''}> {firstScoreQuarter2}</span> : ''}{firstScoreQuarter3 ?
                        <span className={firstCountQuarter === 3 ? 'blue-text' : ''}> {firstScoreQuarter3}</span> : ''}{firstScoreQuarter4 ?
                        <span className={firstCountQuarter === 4 ? 'blue-text' : ''}> {firstScoreQuarter4}</span> : ''}) <span className="red-text">{firstGameTime}</span>
                    </div>
                ) : (
                    <div><strong>{history.firstScore}</strong></div>
                )}
              </>
              <div>Second:</div>
              <>
                { secondTotalScore ? (
                    <div>
                      <span><strong>{secondTotalScore}</strong></span> ({secondScoreQuarter1 ?
                        <span className={secondCountQuarter === 1 ? 'blue-text' : ''}>{secondScoreQuarter1}</span> : ''}{secondScoreQuarter2 ?
                        <span className={secondCountQuarter === 2 ? 'blue-text' : ''}> {secondScoreQuarter2}</span> : ''}{secondScoreQuarter3 ?
                        <span className={secondCountQuarter === 3 ? 'blue-text' : ''}> {secondScoreQuarter3}</span> : ''}{secondScoreQuarter4 ?
                        <span className={secondCountQuarter === 4 ? 'blue-text' : ''}> {secondScoreQuarter4}</span> : ''}) <span className="red-text">{secondGameTime}</span>
                    </div>
                ) : (
                    <div><strong>{history.secondScore}</strong></div>
                )}
              </>
            </td>

            {/*<td>*/}
            {/*  <div>First:</div>*/}
            {/*  <div><strong>{history.firstScore}</strong></div>*/}
            {/*  <div>Second:</div>*/}
            {/*  <div><strong>{history.secondScore}</strong></div>*/}
            {/*</td>*/}
            <td>
              <div><span className={history.betFirstOdds === 0 ? "badge badge-secondary badge-font" : "badge badge-success badge-font"}><strong>{history.betFirstOdds}</strong></span></div>
              <div><span className={history.betFirstOdds === 0 ? "badge badge-secondary badge-font" : "badge badge-success badge-font"}>{history.betFirstTime ? helpers.dtt(history.betFirstTime) : ''}</span></div>
              {/*<div><span className={history.firstIsLive ? 'blue-text' : 'gray-text'}><strong>{history.firstOdds}</strong></span></div>*/}
              <div><span style={{visibility: "hidden"}}>.</span></div>
              <small>
                {/*<div>{history.firstOddsUpdateDate ? helpers.dtt(history.firstOddsUpdateDate) : ''}</div>*/}
                <div style={{visibility: "hidden"}}>.</div>
                <div><strong>{history.firstBetName}</strong></div>
                <div>Outcome:</div>
                <div><strong>{history.firstOutcomeName}</strong></div>
              </small>
              <div style={{marginTop: '10px'}}>
                <div className="form-check">
                  <input
                      className="form-check-input"
                      type="checkbox"
                      id={`${history._id}_check`}
                      onChange={(e) => this.props.sendWinFirstBookmaker(history, e)}
                      checked={history.isWinFirstBookmaker || false}
                      // defaultChecked={history.isWinFirstBookmaker || false}
                      disabled={history.secondStatus !== 'closed'}
                  />
                  <label className="form-check-label" htmlFor={`${history._id}_check`}>Win</label>
                </div>
              </div>
            </td>
            <td>
              <div><span className={history.betSecondOdds === 0 ? "badge badge-secondary badge-font" : "badge badge-success badge-font"}><strong>{history.betSecondOdds}</strong></span> | <span className={history.betMarginPercent === 0 ? "badge badge-secondary badge-font" : ( history.betMarginPercent > 0 ? "badge badge-success badge-font" : "badge badge-danger badge-font")}><strong>{history.betMarginPercent}</strong> <i className="fa fa-percent"/></span></div>
              {/*<div><span className={history.betSecondOdds === 0 ? "badge badge-secondary badge-font" : "badge badge-success badge-font"}><strong>{history.betSecondOdds}</strong></span><span><strong> | <span className={history.betMarginPercent >= 0 ? 'green-text' : 'red-text'}>{history.betMarginPercent}</span></strong> <i className="fa fa-percent"/></span></div>*/}
              <div><span className={history.betSecondOdds === 0 ? "badge badge-secondary badge-font" : "badge badge-success badge-font"}>{history.betSecondTime ? helpers.dtt(history.betSecondTime) : '-'}</span></div>
              {/*<div><span><strong><span className={history.secondIsLive ? 'blue-text' : 'gray-text'}>{history.secondOdds}</span> | <span className={history.marginPercent >= 0 ? 'green-text' : 'red-text'}>{history.marginPercent}</span></strong> <i className="fa fa-percent"/></span></div>*/}
              <div><span><strong><span className={history.secondIsLiveAllTime ? '' : 'gray-text'}>{history.secondOddsAllTime}</span> | <span className={history.secondMarginPercentAllTime >= 0 ? 'green-text' : 'red-text'}>{history.secondMarginPercentAllTime}</span></strong> <i className="fa fa-percent"/></span></div>
              <small>
                <div>{history.secondOddsUpdateDate ? helpers.dtt(history.secondOddsUpdateDate) : ''}</div>
                <div><strong>{history.secondBetName}</strong></div>
                <div>Outcome:</div>
                <div><strong>{history.secondOutcomeName}</strong></div>
              </small>
              <div><span><strong>First: {history.secondOddsFirst} | <span className={history.secondOddsMarginFirst >= 0 ? 'green-text' : 'red-text'}>{history.secondOddsMarginFirst}</span></strong> <i className="fa fa-percent"/></span></div>
              <div><small>{history.secondOddsFirstTime ? helpers.dtt(history.secondOddsFirstTime) : ''}</small></div>
              <div><span><strong>Min: {history.secondOddsMin} | <span className={history.secondOddsMinMargin >= 0 ? 'green-text' : 'red-text'}>{history.secondOddsMinMargin}</span></strong> <i className="fa fa-percent"/></span></div>
              <div><small>{history.secondOddsMinTime ? helpers.dtt(history.secondOddsMinTime) : ''}</small></div>
              <div><span><strong>Max: {history.secondOddsMax} | <span className={history.secondOddsMaxMargin >= 0 ? 'green-text' : 'red-text'}>{history.secondOddsMaxMargin}</span></strong> <i className="fa fa-percent"/></span></div>
              <div><small>{history.secondOddsMaxTime ? helpers.dtt(history.secondOddsMaxTime) : ''}</small></div>
              <div style={{marginTop: '10px'}}>
                <button
                    type="button"
                    onClick={() => this.props.manualPlaceBet(history)}
                    className="btn btn-sm btn-info col-md-12 col-xs-12"
                    disabled={history.firstStatus === 'failed' || history.secondStatus === 'accepted' || history.secondStatus === 'closed'}
                    // disabled={history.firstStatus === 'failed' || history.secondStatus === 'accepted' || history.secondStatus === 'sent' || history.secondStatus === 'closed'}
                >
                  Bet
                </button>
              </div>
            </td>
            {/*<td>*/}
            {/*    <button*/}
            {/*        type="button"*/}
            {/*        onClick={() => this.props.manualPlaceBet(history)}*/}
            {/*        className="btn btn-sm btn-info col-md-12 col-xs-12"*/}
            {/*        disabled={history.status === 'accepted' || history.status === 'sent_second' || history.status === 'closed'}*/}
            {/*    >*/}
            {/*        Bet*/}
            {/*    </button>*/}
            {/*</td>*/}

            {/*<td>{history.status === 'accepted' ? <strong>{history.marginPercent}</strong> : history.marginPercent} <i className="fa fa-percent"/></td>*/}
            {/*<td><span className={history.marginPercent >= 0 ? 'green-text' : 'red-text'}><strong>{history.marginPercent}</strong></span> <i className="fa fa-percent"/></td>*/}
            {/*<td><span className={history.stopLoss >= 0 ? 'green-text' : 'red-text'}><strong>{history.stopLoss}</strong></span> <i className="fa fa-percent"/></td>*/}
            {/*<td><span><strong>{history.maxMarginPercent}</strong> <i className="fa fa-percent"/></span></td>*/}

            <td>
              <div>Stop Loss:</div>
              <div><span className={history.stopLoss >= 0 ? 'green-text' : 'red-text'}><strong>{history.stopLoss}</strong></span> <i className="fa fa-percent"/></div>
              <div>Max %</div>
              <div>(Trailing Stop):</div>
              <div><span><strong>{history.maxMarginPercent}</strong> <i className="fa fa-percent"/></span></div>
            </td>
            <td>
              <div>First: <i className={history.firstCurrency === 'EUR' ? 'fa fa-eur' : (history.firstCurrency === 'GBP' ? 'fa fa-gbp' : (history.firstCurrency === 'RUB' ? 'fa fa-rub' : 'fa fa-usd'))}/> <span className={history.betFirstMoneyBet === 0 ? "badge badge-secondary badge-font" : "badge badge-success badge-font"}><strong>{history.betFirstMoneyBet}</strong></span></div>
              <div>Sec.: <i className={history.secondCurrency === 'EUR' ? 'fa fa-eur' : (history.secondCurrency === 'GBP' ? 'fa fa-gbp' : (history.secondCurrency === 'RUB' ? 'fa fa-rub' : 'fa fa-usd'))}/> <span className={history.betSecondMoneyBet === 0 ? "badge badge-secondary badge-font" : "badge badge-success badge-font"}><strong>{history.betSecondMoneyBet}</strong></span></div>
              <div>Rate: <span><strong>{history.exchangeRate || 1}</strong></span></div>
              <div>First: <i className={history.firstCurrency === 'EUR' ? 'fa fa-eur' : (history.firstCurrency === 'GBP' ? 'fa fa-gbp' : (history.firstCurrency === 'RUB' ? 'fa fa-rub' : 'fa fa-usd'))}/> <span><strong>{history.moneyBetFirst}</strong></span></div>
              <div>Sec.: <i className={history.secondCurrency === 'EUR' ? 'fa fa-eur' : (history.secondCurrency === 'GBP' ? 'fa fa-gbp' : (history.secondCurrency === 'RUB' ? 'fa fa-rub' : 'fa fa-usd'))}/> <span><strong>{history.moneyBetSecond}</strong></span></div>
              <div>Sec No Rate: <i className={history.secondCurrency === 'EUR' ? 'fa fa-eur' : (history.secondCurrency === 'GBP' ? 'fa fa-gbp' : (history.secondCurrency === 'RUB' ? 'fa fa-rub' : 'fa fa-usd'))}/> <span><strong>{history.moneyBetOriginalSecond}</strong></span></div>
              {/*<div>Sec No Rate: <i className={history.firstCurrency === 'EUR' ? 'fa fa-eur' : (history.firstCurrency === 'GBP' ? 'fa fa-gbp' : (history.firstCurrency === 'RUB' ? 'fa fa-rub' : 'fa fa-usd'))}/> <span><strong>{history.moneyBetSecond}</strong></span></div>*/}
            </td>

            {/*<td><i className="fa fa-usd"/> <span><strong>{history.moneyBetFirst}</strong></span></td>*/}
            {/*<td><i className="fa fa-usd"/> <span><strong>{history.moneyBetSecond}</strong></span></td>*/}
            {/*<td><i className="fa fa-usd green-text"/><span>{history.status === 'accepted' ? <strong>{history.marginMoney}</strong> : history.marginMoney}</span></td>*/}
            <td>
              <div><i className={history.firstCurrency === 'EUR' ? 'fa fa-eur' : (history.firstCurrency === 'GBP' ? 'fa fa-gbp' : (history.firstCurrency === 'RUB' ? 'fa fa-rub' : 'fa fa-usd'))}/> <span className={history.betMarginMoney === 0 ? "badge badge-secondary badge-font" : ( history.betMarginMoney > 0 ? "badge badge-success badge-font" : "badge badge-danger badge-font")}><strong>{history.betMarginMoney}</strong></span></div>
              <div><i className={history.firstCurrency === 'EUR' ? 'fa fa-eur' : (history.firstCurrency === 'GBP' ? 'fa fa-gbp' : (history.firstCurrency === 'RUB' ? 'fa fa-rub' : 'fa fa-usd'))}/> <span className={history.marginMoney >= 0 ? 'green-text' : 'red-text'}><strong>{history.marginMoney}</strong></span></div>
            </td>
          </tr>
        </>
    );
  }
}

export default PlacedBetWaitingForkHistoryEntity;
