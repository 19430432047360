import React, {Component} from 'react';
import './style.css';

import {withRouter} from "react-router-dom";
import backend from "../../../chunks/backend";
import PaginationComponent from "../../../components/PaginationComponent";
import AuthHistoryEntity from "./AuthHistoryEntity";


class BotDetailedAuthHistory extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      history: [],
      page:    1,
    };

    this.updateData = this.updateData.bind(this);
    this.showPage = this.showPage.bind(this);
  }


  componentDidMount() {
    this.updateData()
  }

  updateData() {
    backend.get(`/bot/auth-history/${this.props.match.params.name}?page=${this.state.page}`).then(({data}) => {
      if (data.success) {
        this.setState({history: data.history});
      }
    }).catch(console.log).finally(() => {
      this.setState({
        loaded: true
      })
    })
  }

  showPage(page) {
    this.setState({ page }, () => {
      this.updateData();
    });
  }

  render() {

    return (
        <div className="x_panel">
          <div className="x_title">
            <h2>Auth history</h2>
            <PaginationComponent page={this.state.page} showPage={this.showPage} />
            <div className="clearfix"/>
          </div>
          <div className="x_content">

            <div className="table-responsive">
              <table className="table table-hover bulk_action jambo_table table-striped">
                <thead>
                <tr className="headings">
                  <th className="column-title">Timestamp</th>
                  <th className="column-title">IP</th>
                  <th className="column-title">Bot</th>
                  <th className="column-title">Session End</th>
                </tr>
                </thead>
                <tbody>
                {this.state.loaded && (
                    this.state.history.length > 0 ? (
                        this.state.history.map((auth) => <AuthHistoryEntity key={auth._id} data={auth} /> )
                    ) : (
                        <tr>
                          <td colSpan="4">List is empty</td>
                        </tr>
                    )
                )}
                {!this.state.loaded && (
                    <tr>
                      <td colSpan="4"><i className="text-muted">Loading...</i></td>
                    </tr>
                )}
                </tbody>
              </table>
            </div>

          </div>
        </div>



    );
  }
}

export default withRouter(BotDetailedAuthHistory);
