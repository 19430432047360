import React from 'react';

import BotsList from "./src/BotsList";
import title from "../../chunks/title";

class BotsBanned extends React.Component {
    componentDidMount() {
        title('Banned Bots');
    }

    render() {
        return (
            <>
                <BotsList type="banned" permissions={this.props.permissions} user={this.props.user}/>
            </>
        );
    }
}

export default BotsBanned;
