import {Component} from "react";
import './style.css'

class RightSideComponent extends Component {
    render() {
        return (
            <>
                <main className="right_col">{this.props.children}</main>
            </>
        );
    }
}

export default RightSideComponent;
