import axios from "axios";
const FileDownload = require("js-file-download");

const tName = "_token";
const l = 32;

function makeID(length) {
    const token = localStorage.getItem(tName);

    if (token && token.length === length) {
        return token;
    }

    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    localStorage.setItem(tName, result);

    return result;
}

function addToken(config) {
    if (!config) {
        config = {};
    }

    if (!config.headers) {
        config.headers = {};
    }

    config.headers["_token"] = makeID(l);

    return config;
}

function clearToken() {
    localStorage.removeItem(tName);
}

const backend = {
    clearToken,
    makeID,
    l,

    url(path) {
        if (!path.startsWith("/")) {
            path = "/" + path;
        }

        const { _token } = addToken().headers;

        // const aSub = (() => {
        //     switch(window.location.host) {
        //         case 'billy-lg.com': return 'api';
        //         case 'eurobet.billy-lg.com': return 'eapi';
        //         case 'parimatch.billy-lg.com': return 'papi';
        //         case 'bet365-point.billy-lg.com': return 'bet365-point-api';
        //         default: return 'dev';
        //     }
        // })();
        // const url = `http://86.110.208.70:2001/api${path}`;
        const url = `https://apiforks.ninjateam.io/api${path}`;
        // const url = `https://apiag.ninjateam.io/api${path}`;

        if (url.split("?").length === 1) {
            return url + "?_token=" + _token;
        }

        return url + "&_token=" + _token;
    },

    download(url, fileName) {
        this.get(url, { responseType: "blob" }).then((response) => {
            FileDownload(response.data, fileName);
        });
    },

    post(url, data, config) {
        config = addToken(config);
        return axios.post(this.url(url), data, config);
    },

    get(url, config) {
        config = addToken(config);
        return axios.get(this.url(url), config);
    },

    delete(url, config) {
        config = addToken(config);
        return axios.delete(this.url(url), config);
    },

    head(url, config) {
        config = addToken(config);
        return axios.head(this.url(url), config);
    },

    options(url, config) {
        config = addToken(config);
        return axios.options(this.url(url), config);
    },

    put(url, data, config) {
        config = addToken(config);
        return axios.put(this.url(url), data, config);
    },

    patch(url, data, config) {
        config = addToken(config);
        return axios.patch(this.url(url), data, config);
    },
};

export default backend;
