import React from "react";
import backend from "../../../chunks/backend";
import helpers from "../../../chunks/helpers";
import DateTimePicker from 'react-datetime-picker';
import LineChart from "../../DashboardPage/charts/LineChart";
import { Link } from "react-router-dom";

class Calculator extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataset: this.props.dataset,
            serving: {
                calculate: false,
                calcRange:   '',
                calcMoney:   '',
                calcPercent: ''
            },
            acceptor: {
                calculate: true,
                calcRange:   '',
                calcMoney:   '',
                calcPercent: ''
            },
            dataLoading: false,
            dateFrom: new Date(0),
            dateTo: new Date(),
            itfLeague: null,
            betType: 'win_15',
            priority: 'acceptor',
            startingBalance: 10,
            excludedGames: '',
            result: {
                endBalance: 0,
                wasCalculated: false,
                inProgress: false,
                totalServing: 0,
                totalAcceptor: 0,
                totalMoneyWin: 0,
                totalMoneyLoose: 0,
                totalInvested: 0,
                totalCalculatedBets: 0,
                servingDetailed: {},
                acceptorDetailed: {}
            },
            historyA: [],
            labelsA:  [],
            historyS: [],
            labelsS:  [],
            log: [],
        };

        this.onChangeCalculateServing  = this.onChangeCalculateServing.bind(this);
        this.onChangeCalculateAcceptor = this.onChangeCalculateAcceptor.bind(this);
        this.oncChangeServingRanges    = this.oncChangeServingRanges.bind(this);
        this.onChangeAcceptorRanges    = this.onChangeAcceptorRanges.bind(this);
        this.onChangeServingMoney      = this.onChangeServingMoney.bind(this);
        this.onChangeAcceptorMoney     = this.onChangeAcceptorMoney.bind(this);
        this.onClickCalculate          = this.onClickCalculate.bind(this);
        this.onChangeServingPercent    = this.onChangeServingPercent.bind(this);
        this.onChangeAcceptorPercent   = this.onChangeAcceptorPercent.bind(this);
        this.reloadAllData      = this.reloadAllData.bind(this);
        this.onChangeDateFrom   = this.onChangeDateFrom.bind(this);
        this.onChangeDateTo     = this.onChangeDateTo.bind(this);
        this.onChangeITFLeague  = this.onChangeITFLeague.bind(this);
        this.onChangeBetType    = this.onChangeBetType.bind(this);
        this.onChangePriority   = this.onChangePriority.bind(this);
        this.onChangeStartingBalance = this.onChangeStartingBalance.bind(this);
        this.onChangeExcludedGames = this.onChangeExcludedGames.bind(this);
    }

    onChangeStartingBalance(e) {
        this.setState({
            startingBalance: Number(e.target.value)
        });
    }

    onChangeExcludedGames(e) {
        this.setState({
            excludedGames: e.target.value
        });
    }

    onChangeServingPercent(e) {
        const { serving } = this.state;
        const value = Number(e.target.value);
        serving.calcPercent = (typeof value === 'number') ? value : 0;
        this.setState({ serving });
    }

    onChangeAcceptorPercent(e) {
        const { acceptor } = this.state;
        const value = Number(e.target.value);
        acceptor.calcPercent = (typeof value === 'number') ? value : 0;
        this.setState({ acceptor });
    }

    onChangeServingMoney(e) {
        const { serving } = this.state;
        serving.calcMoney = e.target.value;
        this.setState({ serving });
    }

    onChangeAcceptorMoney(e) {
        const { acceptor } = this.state;
        acceptor.calcMoney = e.target.value;
        this.setState({ acceptor });
    }

    onChangeCalculateServing(e) {
        const checked = e.target.checked;
        const serving = this.state.serving;

        serving.calculate = checked;

        this.setState({ serving });
    }

    onChangeCalculateAcceptor(e) {
        const checked  = e.target.checked;
        const acceptor = this.state.acceptor;

        acceptor.calculate = checked;

        this.setState({ acceptor });
    }

    oncChangeServingRanges(e) {
        const { serving } = this.state;
        serving.calcRange = e.target.value;
        this.setState({ serving });
    }

    onChangeAcceptorRanges(e) {
        const { acceptor } = this.state;
        acceptor.calcRange = e.target.value;
        this.setState({ acceptor });
    }

    onClickCalculate(e) {
        e.nativeEvent.preventDefault();

        const { result }  = this.state;
        result.inProgress = true;
        const historyA = [ this.state.startingBalance ];
        const historyS = [ this.state.startingBalance ];
        const labelsA  = [ 'Start balance' ];
        const labelsS  = [ 'Start balance' ];
        let currStep = 1;
        let currBalance = this.state.startingBalance;
        const log = [];

        this.setState({ result }, () => {
            let totalResult = {
                wasCalculated: true,
                inProgress: false,
                totalServing: 0,
                totalAcceptor: 0,
                totalMoneyWin: 0,
                totalMoneyLoose: 0,
                totalInvested: 0,
                totalCalculatedBets: 0,
                servingDetailed: {},
                acceptorDetailed: {}
            };

            const serving  = JSON.parse(JSON.stringify( this.state.serving ));
            const acceptor = JSON.parse(JSON.stringify( this.state.acceptor ));
            const excludeGames = this.state.excludedGames.split(',').map(e => Number(e));

            if (serving.calculate) {
                serving.calcRange = serving.calcRange.split(',').map(e => {
                    const r = {min: 1, max: Infinity, money: Number(serving.calcMoney) || 1, percent: Number(serving.calcPercent) || 0}
                    const p = e.trim().split(':');

                    if (p[1]) {
                        r.money = Number(p[1].trim());
                    }

                    if (p[0]) {
                        const s = p[0].split('-').map(i => Number(i));
                        r.min = s[0];
                        r.max = s[1];
                    }

                    return r;
                });
            }

            if (acceptor.calculate) {
                acceptor.calcRange = acceptor.calcRange.split(',').map(e => {
                    const r = {min: 1, max: Infinity, money: Number(acceptor.calcMoney) || 1, percent: Number(acceptor.calcPercent) || 100}
                    const p = e.trim().split(':');

                    if (p[1]) {
                        r.money = Number(p[1].trim());
                    }

                    if (p[0]) {
                        const s = p[0].split('-').map(i => Number(i));
                        r.min = s[0];
                        r.max = s[1];
                    }

                    return r;
                });
            }

            const { itfLeague } = this.state;

            const startFrom = new Date(this.state.dateFrom);
            const endDate   = new Date(this.state.dateTo);

            // Start calculating
            for (let game of this.state.dataset) {

                if ( (game.createdAt > endDate) || (game.createdAt < startFrom) ) {
                    continue;
                }

                if (itfLeague !== null) {
                    const league = game.slug.split(' :: ')[1];
                    const words  = league.split(' ').map(e => e.trim().toLowerCase());
                    const index  = words.indexOf('itf');

                    if (
                        (index !== -1 && !itfLeague) ||
                        (index === -1 && itfLeague)
                    ) {
                        continue;
                    }
                }

                const goals = game.goals;

                for (let key in goals) {
                    const goalPoints = goals[key];

                    if (goalPoints.length < 2) {
                        continue;
                    }

                    if (excludeGames.indexOf(goalPoints[0].game) !== -1) {
                        continue;
                    }

                    let goalPoint;
                    let goalPercent;

                    if (this.state.betType === 'win_15') {
                        goalPoints.forEach((point, i) => {
                            if ([point.points.home, point.points.away].sort().join(':') === '0:15') {
                                const o = (goalPoints.length > 0 && !('loadAll' in this.props)) ? (i + 1) : 0;
                                const p = goalPoints[o];

                                if (p && p.outcome) {
                                    goalPoint = point;
                                    goalPercent = { home: p.homePrc, away: p.awayPrc };
                                }
                            }
                        });
                    } else {
                        if (!serving.calculate) {
                            continue;
                        }

                        const lastGoal = goalPoints[0] || null;
                        const only15   = (this.state.betType === 'loose_15');
                        const accept   = ['s0:a40', 's15:a40'];

                        if (!only15) {
                            accept.push('s30:a40');
                        }

                        if (lastGoal && lastGoal.outcome) {
                            goalPercent = { home: lastGoal.homePrc, away: lastGoal.awayPrc };
                            let s, a;

                            if (lastGoal.outcome.away.isServe) {
                                s = `s${lastGoal.points.away}`;
                                a = `a${lastGoal.points.home}`;
                            } else {
                                s = `s${lastGoal.points.home}`;
                                a = `a${lastGoal.points.away}`;
                            }

                            const serveOdds = lastGoal.outcome.home.isServe ? lastGoal.outcome.home.odds : lastGoal.outcome.away.odds;
                            const servePercent = lastGoal.outcome.home.isServe ? goalPercent.home : goalPercent.away;
                            const range = (() => {
                                for (let r of serving.calcRange) {
                                    if (r.min <= serveOdds && r.max >= serveOdds) {
                                        if (goalPercent && ( servePercent < r.percent )) {
                                            continue;
                                        }

                                        return r;
                                    }
                                }
                            })();

                            if (!range) {
                                continue;
                            }

                            const _k = (range.min+':'+range.max)+range.percent+'%';

                            if (!totalResult.servingDetailed[_k]) {
                                totalResult.servingDetailed[_k] = {
                                    ranges: range.min+':'+range.max,
                                    times:   0,
                                    earned:  0,
                                    loose:   0,
                                    percent: range.percent+'%'
                                };
                            }

                            totalResult.servingDetailed[_k].times -=- 1;
                            totalResult.totalInvested -=- range.money;
                            totalResult.totalCalculatedBets -=- 1;

                            if (accept.indexOf(`${s}:${a}`) !== -1) {
                                goalPoint = lastGoal;
                                const win = (serveOdds * range.money);
                                totalResult.totalMoneyWin -=- win;
                                totalResult.servingDetailed[_k].earned -=- win;
                            } else {
                                const loose = range.money;
                                totalResult.totalMoneyLoose -=- loose;
                                totalResult.servingDetailed[_k].loose -=- loose;
                            }

                            continue;
                        } else {
                            continue;
                        }
                    }

                    if (!goalPoint || !goalPoint.outcome || (typeof goalPoint.outcome.home !== 'object') || (typeof goalPoint.outcome.away !== 'object')) {
                        continue;
                    }

                    const pointHome  = goalPoint.points.home;
                    const pointAway  = goalPoint.points.away;
                    const homeWin    = (pointHome === null || (pointHome > pointAway));
                    const awayWin    = (pointAway === null || (pointAway > pointHome));
                    const homeSrc    = goalPoint.outcome.home.isServe;
                    const awaySrc    = goalPoint.outcome.away.isServe;
                    const homeOdd    = goalPoint.outcome.home.odds;
                    const awayOdd    = goalPoint.outcome.away.odds;
                    const serveWin   = ((homeWin && homeSrc) || (awayWin && awaySrc));
                    const serveOdds  = (homeSrc) ? homeOdd : awayOdd;
                    const acceptOdds = (homeSrc) ? awayOdd : homeOdd;
                    const serveBet   = (homeSrc) ? goalPoint.outcome.home.name : goalPoint.outcome.away.name;
                    const acceptBet  = (homeSrc) ? goalPoint.outcome.away.name : goalPoint.outcome.home.name;
                    let servePercent;
                    // let acceptPercent;

                    if (goalPercent) {
                        servePercent  = homeSrc ? goalPercent.home : goalPercent.away;
                        // acceptPercent = homeSrc ? goalPercent.away : goalPercent.home;
                    }

                    if (serveWin) {
                        totalResult.totalServing -=- 1;
                    } else {
                        totalResult.totalAcceptor -=- 1;
                    }

                    let acceptorAlreadyAccepted = false;

                    if (acceptor.calculate) {
                        for (let range of acceptor.calcRange) {
                            if (range.min <= acceptOdds && range.max >= acceptOdds) {
                                if (goalPercent && ( servePercent > range.percent )) {
                                    continue;
                                }

                                acceptorAlreadyAccepted = true;
                                const _k = (range.min+':'+range.max)+range.percent+'%';

                                if (!totalResult.acceptorDetailed[_k]) {
                                    totalResult.acceptorDetailed[_k] = {
                                        ranges:  range.min+':'+range.max,
                                        times:   0,
                                        earned:  0,
                                        loose:   0,
                                        percent: range.percent+'%',
                                        winTimes: 0,
                                        looseTimes: 0
                                    };
                                }

                                totalResult.acceptorDetailed[_k].times -=- 1;
                                totalResult.totalInvested -=- range.money;
                                totalResult.totalCalculatedBets -=- 1;

                                if (!serveWin) {
                                    const win = helpers.fm(acceptOdds * range.money);

                                    // Chart data start here
                                    currBalance -=- helpers.fm(win - range.money);
                                    historyA.push(Number(currBalance + 0));
                                    labelsA.push(`Win ${currStep} (${acceptOdds})`);
                                    currStep -=- 1;
                                    // Chart data end here

                                    totalResult.totalMoneyWin -=- win
                                    totalResult.acceptorDetailed[_k].earned -=- win;
                                    totalResult.acceptorDetailed[_k].winTimes -=- 1;

                                    log.push({
                                        name: 'Acceptor',
                                        type: 'win',
                                        odds: acceptOdds,
                                        money: range.money,
                                        win,
                                        servePercent,
                                        game: `${game.home} - ${game.away}`,
                                        slug: game.slug,
                                        bet: acceptBet
                                    });
                                } else {
                                    const loose = range.money;

                                    // Chart data start here
                                    currBalance -= loose;
                                    historyA.push(Number(currBalance + 0));
                                    labelsA.push(`Loose ${currStep} (${acceptOdds})`);
                                    currStep -=- 1;
                                    // Chart data end here

                                    totalResult.totalMoneyLoose -=- loose;
                                    totalResult.acceptorDetailed[_k].loose -=- loose;
                                    totalResult.acceptorDetailed[_k].looseTimes -=- 1;

                                    log.push({
                                        name: 'Acceptor',
                                        type: 'loose',
                                        odds: acceptOdds,
                                        money: range.money,
                                        loose,
                                        servePercent,
                                        game: `${game.home} - ${game.away}`,
                                        slug: game.slug,
                                        bet: acceptBet
                                    });
                                }
                            }
                        }
                    }

                    if (serving.calculate && !acceptorAlreadyAccepted) {
                        for (let range of serving.calcRange) {
                            if (range.min <= serveOdds && range.max >= serveOdds) {
                                if (goalPercent && ( servePercent < range.percent )) {
                                    continue;
                                }

                                const _k = (range.min+':'+range.max)+range.percent+'%';

                                if (!totalResult.servingDetailed[_k]) {
                                    totalResult.servingDetailed[_k] = {
                                        ranges: range.min+':'+range.max,
                                        times:   0,
                                        earned:  0,
                                        loose:   0,
                                        percent: range.percent+'%',
                                        winTimes: 0,
                                        looseTimes: 0
                                    };
                                }

                                totalResult.servingDetailed[_k].times -=- 1;
                                totalResult.totalInvested -=- range.money;
                                totalResult.totalCalculatedBets -=- 1;

                                if (serveWin) {
                                    const win = helpers.fm(serveOdds * range.money);

                                    // Chart data start here
                                    currBalance -=- helpers.fm(win - range.money);
                                    historyS.push(Number(currBalance + 0));
                                    labelsS.push(`Win ${currStep} (${serveOdds})`);
                                    currStep -=- 1;
                                    // Chart data end here

                                    totalResult.totalMoneyWin -=- win;
                                    totalResult.servingDetailed[_k].earned -=- win;
                                    totalResult.servingDetailed[_k].winTimes -=- 1;

                                    log.push({
                                        name: 'Server',
                                        type: 'win',
                                        odds: serveOdds,
                                        money: range.money,
                                        win,
                                        servePercent,
                                        game: `${game.home} - ${game.away}`,
                                        slug: game.slug,
                                        bet: serveBet
                                    });
                                } else {
                                    const loose = range.money;

                                    // Chart data start here
                                    currBalance -= loose;
                                    historyS.push(Number(currBalance + 0));
                                    labelsS.push(`Loose ${currStep} (${serveOdds})`);
                                    currStep -=- 1;
                                    // Chart data end here

                                    totalResult.totalMoneyLoose -=- loose;
                                    totalResult.servingDetailed[_k].loose -=- loose;
                                    totalResult.servingDetailed[_k].looseTimes -=- 1;

                                    log.push({
                                        name: 'Server',
                                        type : 'loose',
                                        odds: serveOdds,
                                        money: range.money,
                                        loose,
                                        servePercent,
                                        game: `${game.home} - ${game.away}`,
                                        slug: game.slug,
                                        bet: serveBet
                                    });
                                }
                            }
                        }
                    }
                }
            }

            this.setState({ result: totalResult, historyA, labelsA, historyS, labelsS, log });
        });
    }

    reloadAllData(e) {
        e.nativeEvent.preventDefault();

        this.setState({ dataLoading: true }, () => {
            backend.get('/points/calculator-dataset').then(({ data }) => {
                if (data.success) {
                    this.setState({
                        dataset: data.data,
                        dataLoading: false
                    });
                }
            }).catch(console.log);
        });
    }

    onChangeDateFrom(dateFrom) {
        this.setState({ dateFrom });
    }

    onChangeDateTo(dateTo) {
        this.setState({ dateTo });
    }

    onChangeITFLeague(e) {
        const value = e.target.value.trim();
        const itfLeague = (value === '') ? null : (value === 'include');
        this.setState({ itfLeague });
    }

    onChangeBetType(e) {
        const { serving, acceptor } = this.state;
        const betType = e.target.value.trim();
        const servCalc = betType.startsWith('loose') || serving.calculate;
        const acptCalc = betType.startsWith('win');
        serving.calculate = servCalc;
        acceptor.calculate = acptCalc;

        this.setState({ betType, serving, acceptor });
    }

    onChangePriority(e) {
        this.setState({
            priority: e.target.value.trim()
        });
    }

    render() {
        return (
            <div className="x_panel">
                <div className="x_content">
                    <div className="row">
                        <div className="col-xs-4 col-md-4 col-sm-4">
                            <div className="row">
                                <div className="col-xs-6 col-md-6 col-sm-6">
                                    <strong>Serving</strong>
                                    <div className="mt-2">
                                        <label htmlFor="calcServing" className="mr-2">Calculate</label>
                                        <input id="calcServing" type="checkbox" checked={this.state.serving.calculate} defaultChecked={this.state.serving.calculate} onChange={this.onChangeCalculateServing}/>
                                    </div>
                                    <div className="mt-2">
                                        <label htmlFor="rangeServing" className="mr-2">Odds range</label>
                                        <input value={this.state.serving.calcRange} disabled={!this.state.serving.calculate} className="form-control form-control-sm" id="rangeServing" type="text" placeholder="Example: 1.10-2.90" onChange={this.oncChangeServingRanges} />
                                    </div>
                                    <div className="mt-2">
                                        <label htmlFor="moneyServing" className="mr-2">Money</label>
                                        <input value={this.state.serving.calcMoney} disabled={!this.state.serving.calculate} className="form-control form-control-sm" id="moneyServing" type="text" placeholder="Example: 50" onChange={this.onChangeServingMoney} />
                                    </div>
                                    <div className="mt-2">
                                        <label htmlFor="percentServing" className="mr-2">Percent over</label>
                                        <input value={this.state.serving.calcPercent} disabled={!this.state.serving.calculate} className="form-control form-control-sm" id="percentServing" type="text" placeholder="Example: 67" onChange={this.onChangeServingPercent} />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-md-6 col-sm-6">
                                    <strong>Acceptor</strong>
                                    <div className="mt-2">
                                        <label htmlFor="calcAcceptor" className="mr-2">Calculate</label>
                                        <input id="calcAcceptor" type="checkbox" checked={this.state.acceptor.calculate} defaultChecked={this.state.acceptor.calculate} onChange={this.onChangeCalculateAcceptor}/>
                                    </div>
                                    <div className="mt-2">
                                        <label htmlFor="rangeAcceptor" className="mr-2">Odds range</label>
                                        <input value={this.state.acceptor.calcRange} disabled={!this.state.acceptor.calculate} className="form-control form-control-sm" id="rangeAcceptor" type="text" placeholder="Example: 1.10-2.90" onChange={this.onChangeAcceptorRanges} />
                                    </div>
                                    <div className="mt-2">
                                        <label htmlFor="moneyAcceptor" className="mr-2">Money</label>
                                        <input value={this.state.acceptor.calcMoney} disabled={!this.state.acceptor.calculate} className="form-control form-control-sm" id="moneyAcceptor" type="text" placeholder="Example: 50" onChange={this.onChangeAcceptorMoney} />
                                    </div>
                                    <div className="mt-2">
                                        <label htmlFor="percentAcceptor" className="mr-2">Serving percent under:</label>
                                        <input value={this.state.acceptor.calcPercent} disabled={!this.state.acceptor.calculate} className="form-control form-control-sm" id="percentAcceptor" type="text" placeholder="Example: 67" onChange={this.onChangeAcceptorPercent} />
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 mt-2">
                                    <hr />
                                    <div className="form-group">
                                        <div className="input-group input-group-sm">
                                            <div className="input-group-prepend">
                                                <label className="input-group-text" htmlFor="starting_balance">Starting balance</label>
                                            </div>
                                            <input id="starting_balance" type="number" value={this.state.startingBalance} onChange={this.onChangeStartingBalance} className="form-control" />
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        {('loadAll' in this.props) ? (
                                            <>
                                                <div className="col-xs-6 col-md-6 col-sm-6">
                                                    <div className="form-group">
                                                        <label htmlFor="date_from" className="mr-2">Date from</label>
                                                        <DateTimePicker
                                                            onChange={this.onChangeDateFrom}
                                                            value={this.state.dateFrom}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-xs-6 col-md-6 col-sm-6">
                                                    <div className="form-group">
                                                        <label htmlFor="date_to" className="mr-2">Date to</label>
                                                        <DateTimePicker
                                                            onChange={this.onChangeDateTo}
                                                            value={this.state.dateTo}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="col-xs-12">
                                                <i className="text-muted">Date range filter not available for single game</i>
                                            </div>
                                        )}
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-xs-4 col-md-4 col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="itf_league" className="mr-2">ITF League</label>
                                                {!('loadAll' in this.props) ? (
                                                    <div>
                                                        <i className="text-muted">Not available for single game</i>
                                                    </div>
                                                ) : (
                                                    <select id="itf_league" className="form-control" onChange={this.onChangeITFLeague}>
                                                        <option value=""> - </option>
                                                        <option value="include">Include</option>
                                                        <option value="exclude">Exclude</option>
                                                    </select>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-xs-4 col-md-4 col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="bet_type" className="mr-2">Bet type</label>
                                                <select id="bet_type" className="form-control" defaultValue={this.state.betType} onChange={this.onChangeBetType}>
                                                    <option value="win_15">Who first to 0:15</option>
                                                    <option value="loose_15">Server to win 0/15</option>
                                                    <option value="loose_30">Server to win 0/15/30</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-xs-4 col-md-4 col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="priority" className="mr-2">Priority</label>
                                                <select disabled={true} id="priority" className="form-control" defaultValue={this.state.priority} onChange={this.onChangePriority}>
                                                    <option value="server">Server</option>
                                                    <option value="acceptor">Acceptor</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="form-group">
                                        <div className="input-group input-group-sm">
                                            <div className="input-group-prepend">
                                                <label className="input-group-text" htmlFor="excluded_games">Excluded games</label>
                                            </div>
                                            <input id="excluded_games" type="text" value={this.state.excludedGames} onChange={this.onChangeExcludedGames} className="form-control" />
                                        </div>
                                    </div>
                                    <hr />
                                    {this.state.dataLoading ? (
                                        <div>
                                            <i className="text-muted">Please wait while loading data...</i>
                                        </div>
                                    ) : (
                                        <>
                                            <button type="button" disabled={this.state.result.inProgress || !this.state.dataset.length} className="btn btn-sm btn-success" onClick={this.onClickCalculate}>Calculate {this.state.dataset.length} {this.state.dataset.length === 1 ? 'game' : 'games'}</button>
                                            {('loadAll' in this.props) && (
                                                <button type="button" disabled={this.state.result.inProgress} className="btn btn-sm btn-info ml-2" onClick={this.reloadAllData}>
                                                    {this.state.dataset.length > 0 ? 'Reload data' : 'Load data'}
                                                </button>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-8 col-md-8 col-sm-8">
                            <h6>Result</h6>
                            {this.state.result.inProgress ? (
                                <div className="text-muted">
                                    <i>Calculating...</i>
                                </div>
                            ) : (
                                <div className="row">
                                    <div className="col-xs-4 col-sm-4 col-md-4">
                                        <strong>General</strong>
                                        <div className="mt-2">
                                            <span className="mr-2">Total serving wins:</span>
                                            <strong>{this.state.result.totalServing}</strong>
                                        </div>
                                        <div className="mt-2">
                                            <span className="mr-2">Total acceptor wins:</span>
                                            <strong>{this.state.result.totalAcceptor}</strong>
                                        </div>
                                        <div className="mt-2">
                                            <span className="mr-2">Total money received:</span>
                                            <strong className="text-success">{helpers.fm(this.state.result.totalMoneyWin)}</strong>
                                        </div>
                                        <div className="mt-2">
                                            <span className="mr-2">Total money loose:</span>
                                            <strong className="text-danger">{helpers.fm(this.state.result.totalMoneyLoose)}</strong>
                                        </div>
                                        <hr />
                                        <div className="mt-2">
                                            <span className="mr-2">Total calculated bets:</span>
                                            <strong>{this.state.result.totalCalculatedBets}</strong>
                                        </div>
                                        {this.state.result.totalInvested ? (
                                            <div className="mt-2">
                                                <span className="mr-2">Total invested:</span>
                                                <strong>{helpers.fm(this.state.result.totalInvested)}</strong>
                                                {this.state.result.totalInvested > this.state.result.totalMoneyWin && (
                                                    <span className="ml-2">
                                                        Loose: <u className="text-danger">{helpers.fm(this.state.result.totalInvested - this.state.result.totalMoneyWin)}</u>
                                                    </span>
                                                )}
                                                {this.state.result.totalInvested < this.state.result.totalMoneyWin && (
                                                    <span className="ml-2">
                                                        Win: <u className="text-success">{helpers.fm(this.state.result.totalMoneyWin - this.state.result.totalInvested)}</u>
                                                    </span>
                                                )}
                                            </div>
                                        ) : ''}
                                    </div>
                                    <div className="col-xs-4 col-sm-4 col-md-4">
                                        <strong>Serving detailed</strong>
                                        {this.state.result.wasCalculated ? (
                                            (Object.keys(this.state.result.servingDetailed).length > 0) ? (
                                                <ul className="list-group">
                                                    {Object.values(this.state.result.servingDetailed).map(data => (
                                                        <li className="list-group-item">
                                                            <strong className="mr-2">Range: <u>{data.ranges}</u></strong>
                                                            <strong className="mr-2">Percent over: <u>{data.percent}</u></strong>
                                                            <br />
                                                            <strong className="mr-2">Returned $: <u className="text-success">{helpers.fm(data.earned)}</u></strong>
                                                            <strong className="mr-2">Lost $: <u className="text-danger">{helpers.fm(data.loose)}</u></strong>
                                                            <br />
                                                            <strong className="mr-2">Total win times: <u className="text-info">{data.winTimes}</u></strong>
                                                            <strong className="mr-2">Total loose times: <u className="text-warning">{data.looseTimes}</u></strong>
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <div>Nothing...</div>
                                            )
                                        ) : (
                                            <div className="text-muted">Not calculated yet</div>
                                        )}
                                    </div>
                                    <div className="col-xs-4 col-sm-4 col-md-4">
                                        <strong>Acceptor detailed</strong>
                                        {this.state.result.wasCalculated ? (
                                            (Object.keys(this.state.result.acceptorDetailed).length > 0) ? (
                                                <ul className="list-group">
                                                    {Object.values(this.state.result.acceptorDetailed).map(data => (
                                                        <li className="list-group-item">
                                                            <strong className="mr-2">Range: <u>{data.ranges}</u></strong>
                                                            <strong className="mr-2">Percent under: <u>{data.percent}</u></strong>
                                                            <br />
                                                            <strong className="mr-2">Returned $: <u className="text-success">{helpers.fm(data.earned)}</u></strong>
                                                            <strong className="mr-2">Lost $: <u className="text-danger">{helpers.fm(data.loose)}</u></strong>
                                                            <br />
                                                            <strong className="mr-2">Total win times: <u className="text-info">{data.winTimes}</u></strong>
                                                            <strong className="mr-2">Total loose times: <u className="text-warning">{data.looseTimes}</u></strong>
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <div>Nothing...</div>
                                            )
                                        ) : (
                                            <div className="text-muted">Not calculated yet</div>
                                        )}
                                    </div>
                                    {this.state.log.length > 0 ? (
                                        <div className="col-xs-12 col-sm-12 col-md-12">
                                            {[...this.state.log].reverse().map((log, i) => (
                                                <div key={i}>
                                                    <strong className={log.type === 'loose' ? 'text-danger' : 'text-success'}>{log.name}</strong>
                                                    <mark className="ml-2">Odds: {log.odds}</mark>
                                                    <code className="ml-2">{log.type === 'loose' ? log.loose : log.win} ({log.servePercent}%)</code>
                                                    <span className="ml-2 mr-2">
                                                        <Link to={`/points/detailed/history/${log.slug}`}>{log.game}</Link>
                                                    </span>
                                                    <small className="text-muted">{log.bet}</small>
                                                </div>
                                            ))}
                                        </div>
                                    ) : ''}
                                </div>
                            )}
                        </div>
                    </div>
                    {this.state.historyA.length > 1 ? (
                        <div className="row" style={{ height: '300px', maxHeight: '300px' }}>
                            <div className="col-xs-12 col-md-12 col-sm-12">
                                <strong>Acceptor</strong>
                                <LineChart data={{
                                    labels: this.state.labelsA,
                                    datasets: [{
                                        borderColor: 'rgb(54, 162, 235)',
                                        label: 'Acceptor',
                                        data:  this.state.historyA
                                    }],
                                }} />
                            </div>
                        </div>
                    ) : ''}
                    {this.state.historyS.length > 1 ? (
                        <div className="row" style={{ height: '300px', maxHeight: '300px' }}>
                            <div className="col-xs-12 col-md-12 col-sm-12">
                                <strong>Server</strong>
                                <LineChart data={{
                                    labels: this.state.labelsS,
                                    datasets: [{
                                        borderColor: 'rgb(235, 54, 162)',
                                        label: 'Server',
                                        data:  this.state.historyS
                                    }],
                                }} />
                            </div>
                        </div>
                    ) : ''}
                </div>
            </div>
        );
    }
}

export default Calculator;
