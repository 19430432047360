import React from 'react';
import TemplateEntity from "./TemplateEntity";
import backend from "../../../chunks/backend";
import PaginationComponent from "../../../components/PaginationComponent";
import MoneyLine from "../../Users/Profile/UserBot/MoneyLine";
import sub from "../../../chunks/subscriber";

class TemplateList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      subIds: [],
      ranges: [],
      users:  [],
      loaded: false,
      name: {value: '', hasError: false},
      oddsRanges: [{coef: '', money: ''}],
      page: 1,
      username: this.props.user.username
    };

    this.sendFormAdd = this.sendFormAdd.bind(this);
    this.sendFormUpdate = this.sendFormUpdate.bind(this);
    this.changeName = this.changeName.bind(this);
    this.updateData = this.updateData.bind(this);
    this.updateRange = this.updateRange.bind(this);
    this.deleteRange = this.deleteRange.bind(this);
    this.changeCoef = this.changeCoef.bind(this);
    this.changeMoney = this.changeMoney.bind(this);
    this.addMoneyRange = this.addMoneyRange.bind(this);
    this.clearRangeState = this.clearRangeState.bind(this);

    this.showPage = this.showPage.bind(this);
    this.loadUsers = this.loadUsers.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.removeMoneyLinePosition = this.removeMoneyLinePosition.bind(this);
  }

  loadUsers() {
    backend.get('/user/active').then(({ data }) => {
      const {users} = data;
      this.setState({ users });
    }).catch(console.log);
  }

  componentDidMount() {
    const ids = [];

    for (let event of ['moneys.create_range', 'moneys.update_range']) {
      const id = sub.subscribe(event, (data) => {
        console.log('MoneysList', data.event);
        this.updateData();
      });

      ids.push(id);
    }

    this.setState({ subIds: ids });
    this.updateData();

    if (
        this.props.user.hasAccess('moneys.assign_user')
    ) {
      this.loadUsers();
    }
  }

  componentWillUnmount() {
    for (let id of this.state.subIds) {
      sub.unsubscribe(id);
    }
  }

  updateData() {
    backend.get(`/money-ranges?page=${this.state.page}`).then(({ data }) => {
      if (data.success) {
        console.log()
        const ranges = data.ranges
        this.setState({ranges: ranges});
      }
    }).catch(console.log).finally(() => {
      this.setState({
        loaded: true
      })
    })
  }

  showPage(page) {
    this.setState({ page, loaded: false }, () => {
      this.updateData();
    });
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  changeName(e) {
    this.setState({
      name: {
        value: e.target.value,
        hasError: false
      }
    });
  }

  changeCoef(e) {
    const ind = `${e.target.id}`.replace('coef-', '')
    const coef = e.target.value
    const oddsRanges = this.state.oddsRanges
    oddsRanges[ind].coef = coef
    this.setState({oddsRanges: oddsRanges});
  }

  changeMoney(e) {
    const ind = `${e.target.id}`.replace('money-', '')
    const money = e.target.value
    const oddsRanges = this.state.oddsRanges
    oddsRanges[ind].money = money
    this.setState({oddsRanges: oddsRanges});
  }

  updateRange(e) {
    this.setState({
      name: {
        value: e.name,
        hasError: false
      },
      username: e.username || '',
      oddsRanges: e.oddsRanges
    });
  }

  deleteRange(e) {
    console.log('deleteRange ---- ', e)
    backend.delete(`/money-range/${e.name}`).then(({data}) => {
      if (data.success) {
        console.log('Remove success, Bot: ', e.name)
        this.updateData()
      }
    }).catch(console.log)
  }

  addMoneyRange() {
    const oddsRanges = this.state.oddsRanges;
    oddsRanges.push({coef: '', money: ''})
    this.setState({ oddsRanges });
  }

  clearRangeState() {
    this.setState({
      username: {value: '', hasError: false},
      oddsRanges: [{coef: '', money: ''}]
    });
  }

  sendFormUpdate(e) {
    e.preventDefault();

    const oddsRanges = this.state.oddsRanges.filter((el) => {
      return (`${el.coef}`.trim() !== '' && `${el.money}`.trim() !== '');
    });

    if (oddsRanges.length === 0) {
      return;
    }

    backend.put(`/money-range/${this.state.name.value.trim()}`, {
      oddsRanges: oddsRanges,
      username: this.state.username
    }).catch(console.log).finally(() => {
      document.querySelector('#modalMoneyUpdate').querySelector('.close').click()
    })
  }

  sendFormAdd(e) {
    e.preventDefault();

    const {name} = this.state;
    let formWithErrors = false;

    if (`${name.value}`.trim() === '') {
      name.hasError = true;
      formWithErrors = true;
    }

    if (formWithErrors) {
      this.setState({
        name
      });

      return;
    }

    const oddsRanges = this.state.oddsRanges.filter((el) => {
      return (`${el.coef}`.trim() !== '' && `${el.money}`.trim() !== '');
    });

    if (oddsRanges.length === 0) {
      return;
    }

    backend.post(`/money-ranges`, {
      name: `${this.state.name.value}`.trim(),
      username: this.state.username,
      oddsRanges: oddsRanges,
    }).catch(console.log).finally(() => {
      document.querySelector('#modalMoneyAdd').querySelector('.close').click()
    })
  }

  removeMoneyLinePosition(e) {
    const {oddsRanges} = this.state;
    const i = e.target.getAttribute('data-index');
    oddsRanges.splice(i, 1);
    this.setState({ oddsRanges });
  }

  render() {
    const nameClass = `form-control${this.state.name.hasError ? ' is-invalid' : ''}`;

    return (
        <>
          <div className="page-title">
            <div className="_title_left">
              <div className="x_content">
                <button type="button" onClick={this.clearRangeState} className="btn btn-primary" data-toggle="modal" data-target=".modal-money-add">Add Money Rangers</button>
                <PaginationComponent showPage={this.showPage} page={this.state.page} />
                <div className="modal fade bs-example-modal-lg modal-money-add" id="modalMoneyAdd" tabIndex="-1" role="dialog" aria-hidden="true">
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title" id="myModalLabel">Add new Money Ranges</h4>
                        <button type="button" className="close" data-dismiss="modal"><span aria-hidden="true">×</span>
                        </button>
                      </div>

                      {/* Form add start here */}
                      <form className="form-label-left input_mask" onSubmit={this.sendFormAdd}>
                        <div className="modal-body">

                          <div className="form-group row has-feedback">
                            <label className="col-form-label col-md-3 col-sm-3 ">Name</label>
                            <div className="col-md-9 col-sm-9 ">
                              {this.state.name.hasError && (
                                  <small className="text-danger">Invalid Name</small>
                              )}
                              <input value={this.state.name.value} onChange={this.changeName} type="text" className={nameClass} required=""/>
                            </div>
                          </div>

                          {this.props.user.hasAccess('moneys.assign_user') && (
                              <div className="form-group row has-feedback">
                                  <label className="col-form-label col-md-3 col-sm-3 ">Assigned user</label>
                                  <div className="col-md-9 col-sm-9 ">
                                    <select onChange={this.onChangeUsername} className="form-control" defaultValue={this.state.username}>
                                      {this.state.users.map((user, i) => (
                                          <option key={i} value={user.username}>{user.username}</option>
                                      ))}
                                    </select>
                                  </div>
                              </div>
                          )}

                          <div className="form-group row">
                            <label className="col-form-label col-md-3 col-sm-3 ">Money Ranges</label>
                            <div className="col-md-9 col-sm-9 ">
                              <button type="button" onClick={this.addMoneyRange} className="btn btn-sm btn-round btn-info">
                                <i className="fa fa-plus"/>
                              </button>
                            </div>
                          </div>

                          {this.state.oddsRanges.map((oddsData, i) =>
                              <MoneyLine
                                  key={i}
                                  ind={i}
                                  data={oddsData}
                                  changeCoef={this.changeCoef}
                                  changeMoney={this.changeMoney}
                                  hasRemoveBtn={true}
                                  removeMoneyLinePosition={this.removeMoneyLinePosition}
                              />)
                          }

                        </div>

                        <div className="modal-footer">
                          <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                          <button type="submit" className="btn btn-success">Save</button>
                        </div>
                      </form>
                      {/* Form add end here */}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix"/>
          <div className="table-responsive">
            <table className="table table-hover bulk_action jambo_table table-striped">
              <thead>
                <tr className="headings">
                  <th className="column-title">Name</th>
                  {this.props.user.hasAccess('moneys.assign_user') && (
                      <th className="column-title">Assigned user</th>
                  )}
                  <th className="column-title">Money Ranges</th>
                  <th className="column-title">Timestamps</th>
                  <th className="column-title">Actions</th>
                </tr>
              </thead>
              <tbody>
              {this.state.loaded && (
                  this.state.ranges.length > 0 ? (
                      this.state.ranges.map((botData) =>
                          <TemplateEntity
                              key={botData._id.toString()}
                              data={botData}
                              updateRange={this.updateRange}
                              deleteRange={this.deleteRange}
                              accessToUser={this.props.user.hasAccess('moneys.assign_user')}
                          />)
                  ) : (
                      <tr>
                        <td colSpan="4">List is empty</td>
                      </tr>
                  )
              )}
              {!this.state.loaded && (
                  <tr>
                    <td colSpan="5"><i className="text-muted">Loading...</i></td>
                  </tr>
              )}
              </tbody>
            </table>
          </div>
          <br />
          <div className="modal fade bs-example-modal-lg modal-profile modal-money-update" id="modalMoneyUpdate" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="myModalLabel">Update Money Ranges</h4>
                  <button type="button" className="close" data-dismiss="modal"><span aria-hidden="true">×</span>
                  </button>
                </div>

                <form className="form-label-left input_mask" onSubmit={this.sendFormUpdate}>

                  <div className="modal-body">
                    <div className="form-group row has-feedback">
                      <label className="col-form-label col-md-3 col-sm-3 ">Name</label>
                      <div className="col-md-9 col-sm-9 ">
                        <input value={this.state.name.value} type="text" className="form-control" required="" disabled="disabled"/>
                      </div>
                    </div>

                    {this.props.user.hasAccess('moneys.assign_user') && (
                        <div className="form-group row has-feedback">
                          <label className="col-form-label col-md-3 col-sm-3 ">Assigned user</label>
                          <div className="col-md-9 col-sm-9 ">
                            <select onChange={this.onChangeUsername} className="form-control" defaultValue={this.state.username}>
                              {this.state.users.map((user, i) => (
                                  <option key={i} value={user.username}>{user.username}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                    )}

                    <div className="form-group row">
                      <label className="col-form-label col-md-3 col-sm-3 ">Money Ranges </label>
                      <div className="col-md-9 col-sm-9 ">
                        <button type="button" onClick={this.addMoneyRange} className="btn btn-round btn-info"><i className="fa fa-plus"/></button>
                      </div>
                    </div>

                    {this.state.oddsRanges.map((oddsData, i) =>
                        <MoneyLine
                            key={i}
                            ind={i}
                            data={oddsData}
                            changeCoef={this.changeCoef}
                            changeMoney={this.changeMoney}
                            hasRemoveBtn={true}
                            removeMoneyLinePosition={this.removeMoneyLinePosition}
                        />)
                    }

                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="submit" className="btn btn-success">Save</button>
                  </div>

                </form>

              </div>
            </div>
          </div>
        </>
    );
  }
}

export default TemplateList;
