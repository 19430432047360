import React from 'react';

import BotsList from "./src/BotsList";
import title from "../../chunks/title";

class BotsAll extends React.Component {
    componentDidMount() {
        title('All Bots');
    }

    render() {
        return (
            <>
                <BotsList type="all" permissions={this.props.permissions} user={this.props.user}/>
            </>
        );
    }
}

export default BotsAll;
