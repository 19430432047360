import React from "react";

import backend from "../../../../chunks/backend";

class CreateGroup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            title: ''
        };

        this.onChangeTitle = this.onChangeTitle.bind(this);
        this.sendCreateForm = this.sendCreateForm.bind(this);
    }

    onChangeTitle(e) {
        this.setState({
            title: e.target.value
        });
    }

    sendCreateForm(e) {
        e.nativeEvent.preventDefault();
        backend.post('/groups', this.state).catch(console.log);
    }

    render() {
        return (
            <div className="modal fade bs-example-modal-lg modal-profile" id="modalCreateGroup" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Create Group</h4>
                            <button type="button" className="close" data-dismiss="modal"><span aria-hidden="true">×</span></button>
                        </div>
                        <form className="form-label-left input_mask" onSubmit={this.sendCreateForm}>
                            <div className="modal-body">
                                <div className="form-group row">
                                    <label for="group_title" className="col-form-label col-md-3 col-sm-3 ">Title</label>
                                    <div className="col-md-9 col-sm-9">
                                        <input id="group_title" value={this.state.title} onChange={this.onChangeTitle} type="text" className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-success">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default CreateGroup;
