// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.margin-top-profile {
    margin-top: 10px;
}

.btn-remove-profile {
    margin: 0 0 !important;
}

.modal-profile{
    display: none;
}

.goal-scored {
    color: #dc3545;
}

@media (max-width: 991.98px) {
    .btn-group, .btn-group-vertical {
        display: inline-grid;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Games/Live/style.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI;QACI,oBAAoB;IACxB;AACJ","sourcesContent":[".margin-top-profile {\n    margin-top: 10px;\n}\n\n.btn-remove-profile {\n    margin: 0 0 !important;\n}\n\n.modal-profile{\n    display: none;\n}\n\n.goal-scored {\n    color: #dc3545;\n}\n\n@media (max-width: 991.98px) {\n    .btn-group, .btn-group-vertical {\n        display: inline-grid;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
