import React from "react";
import backend from "../../chunks/backend";
import routes from "../../chunks/routes";
import title from "../../chunks/title";
import helpers from "../../chunks/helpers";

import PaginationComponent from "../../components/PaginationComponent";

import Calculator from "./src/Calculator";

import { Link, withRouter } from "react-router-dom";

class Points extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            loaded: false,
            page: 1,
            type: this.props.type
        };

        this.updateData = this.updateData.bind(this);
        this.buildGameHref = this.buildGameHref.bind(this);
        this.showPage = this.showPage.bind(this);
    }

    updateData() {
        title(`Points ${this.props.type}`);

        const type = this.props.type;
        const url  = (type === 'live') ? routes.points.live : routes.points.history;

        this.setState({ loaded: false, type }, () => {
            backend.get(url+'?page='+this.state.page).then(({ data }) => {
                if (data.success) {
                    let result = data.data;

                    if (type === 'live') {
                        let over = [];

                        for (let sport in result) {
                            for (let slug in result[sport]) {
                                over.push(
                                    result[sport][slug]
                                );
                            }
                        }

                        result = over;
                    }

                    this.setState({
                        data:   result,
                        loaded: true
                    });
                }
            }).catch(console.log);
        });
    }

    componentDidMount() {
        this.updateData();
    }

    showPage(page) {
        this.setState({ page }, () => {
            this.updateData();
        });
    }

    buildGameHref(entry) {
        const url = routes.points.detailed;
        return url.replace(':type', this.props.type).replace(':slug', entry.slug);
    }

    render() {
        if (this.props.type !== this.state.type) {
            this.updateData();
        }

        return (
            <div>
                <div className="page-title">
                    <div className="title_left">
                        <h3>Points {this.props.type}</h3>
                    </div>
                    {this.props.type === 'history' && (
                        <div className="title_right">
                            <PaginationComponent page={this.state.page} showPage={this.showPage} />
                        </div>
                    )}
                </div>
                <div className="clearfix"/>
                <div className="row margin-top-profile">
                    <div className="col-xs-12 col-sm-12 col-md-12 mb-4">
                        <Calculator dataset={[]} loadAll={true} />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12">
                        <div className="x_panel">
                            <div className="x_content">
                                <table className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th>Create At</th>
                                        <th>Sport</th>
                                        <th>Home</th>
                                        <th>Away</th>
                                        <th>Score</th>
                                        <th>Meta</th>
                                        <th>Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {!this.state.loaded && (
                                        <tr>
                                            <td colSpan="8"><i className="text-muted">Loading...</i></td>
                                        </tr>
                                    )}
                                    {this.state.loaded && (
                                        <>
                                            {this.state.data.length > 0 ? (
                                                this.state.data.map((entry, key) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td>
                                                                <strong>{helpers.dt(entry.createdAt)}</strong>
                                                                {entry.createdAt !== entry.updatedAt && (
                                                                    <div>
                                                                        {entry.deletedAt ? (
                                                                            <small className="text-danger">{helpers.dt(entry.deletedAt)}</small>
                                                                        ) : (
                                                                            <small className="text-muted">{helpers.dt(entry.updatedAt)}</small>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </td>
                                                            <td>{entry.sport}</td>
                                                            <td><strong>{entry.home}</strong></td>
                                                            <td><strong>{entry.away}</strong></td>
                                                            <td><mark>{entry.score}</mark></td>
                                                            <td>
                                                                <code style={{ height: '16px' }}>{entry.currGame} (<i>
                                                                        {(typeof entry.currPoints.home === 'number') ? entry.currPoints.home : 'A'}
                                                                        :
                                                                        {(typeof entry.currPoints.away === 'number') ? entry.currPoints.away : 'A'}
                                                                        )</i>
                                                                </code>
                                                                {entry && entry.outcome && (
                                                                    <>
                                                                        {entry.outcome.home && (
                                                                            <div style={{ height: '16px' }}>
                                                                                <small>
                                                                                    <span className="mr-1">{entry.outcome.home.name}</span>
                                                                                    <u>Odds: {entry.outcome.home.odds}</u>
                                                                                    {entry.outcome.home.isServe && (
                                                                                        <strong className="text-success ml-1">(srv)</strong>
                                                                                    )}
                                                                                </small>
                                                                            </div>
                                                                        )}
                                                                        {entry.outcome.away && (
                                                                            <div style={{ height: '16px' }}>
                                                                                <small>
                                                                                    <span className="mr-1">{entry.outcome.away.name}</span>
                                                                                    <u>Odds: {entry.outcome.away.odds}</u>
                                                                                    {entry.outcome.away.isServe && (
                                                                                        <strong className="text-success ml-1">(srv)</strong>
                                                                                    )}
                                                                                </small>
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </td>
                                                            <td>
                                                                <Link
                                                                    to={this.buildGameHref(entry)}
                                                                    className="btn btn-sm btn-info"
                                                                >Show</Link>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            ) : (
                                                <tr>
                                                    <td colSpan="8">Data list is empty </td>
                                                </tr>
                                            )}
                                        </>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Points);
