import React from 'react';
import './style.css';

import title from "../../chunks/title";
import backend from "../../chunks/backend";

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: {value: '', hasError: false},
            password: {value: '', hasError: false},
            unknownUser: false
        };

        this.sendForm = this.sendForm.bind(this);
        this.changeUsername = this.changeUsername.bind(this);
        this.changePassword = this.changePassword.bind(this);
    }

    changeUsername(e) {
        this.setState({
            username: {
                value:    e.target.value,
                hasError: false
            }
        });
    }

    changePassword(e) {
        this.setState({
            password: {
                value:    e.target.value,
                hasError: false
            }
        });
    }

    componentDidMount() {
        title('Login Page');
        document.body.classList.add('login-form')
    }

    componentWillUnmount() {
        document.body.classList.remove('login-form');
    }

    sendForm(e) {
        e.preventDefault();

        const {username, password} = this.state;
        let formWithErrors = false;

        if (username.value === '') {
            username.hasError = true;
            formWithErrors = true;
        }

        if (password.value === '') {
            password.hasError = true;
            formWithErrors = true;
        }

        if (formWithErrors) {
            this.setState({
                username, password
            });

            return;
        }

        backend.post('/user/login', {
            username: this.state.username.value,
            password: this.state.password.value
        }).then((res) => {
            if (res.data && res.data.success) {
                this.props.onAuthorize(res.data.user);
            } else {
                this.setState({
                    unknownUser: true
                });
            }
        }).catch(console.log);
    }

    render() {
        const usernameClass = `form-control${this.state.username.hasError ? ' is-invalid' : ''}`;
        const passwordClass = `form-control${this.state.username.hasError ? ' is-invalid' : ''}`;

        return (
            <>
                <div className="login_wrapper">
                    <div className="animate form login_form">
                        <section className="login_content">
                            <form className="form" onSubmit={this.sendForm}>
                                <h1>Login Form</h1>
                                <div className="form-group text-left">
                                    {this.state.username.hasError && (
                                        <small className="text-danger">Invalid username</small>
                                    )}
                                    <input value={this.state.username.value} onChange={this.changeUsername} type="text" className={usernameClass} placeholder="Username" required=""/>
                                </div>
                                <div className="form-group text-left">
                                    {this.state.password.hasError && (
                                        <small className="text-danger">Invalid password</small>
                                    )}
                                    <input value={this.state.password.value} onChange={this.changePassword} type="password" className={passwordClass} placeholder="Password" required=""/>
                                </div>
                                <div>
                                    {this.state.unknownUser && (
                                        <div>
                                            <small className="text-danger">Unknown user</small>
                                        </div>
                                    )}
                                    <button type="submit" className="btn btn-default submit">Log in</button>
                                </div>

                                <div className="clearfix"/>
                            </form>
                        </section>
                    </div>
                </div>
            </>
        );
    }
}

export default LoginPage;
