import React, {Component} from 'react';
import './style.css';

import {withRouter} from "react-router-dom";
import backend from "../../../chunks/backend";
import PaginationComponent from "../../../components/PaginationComponent";
import BetHistoryEntity from "./BetHistoryEntity";


class BotDetailedBetHistory extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      history: [],
      page:    1,
    };

    this.updateData = this.updateData.bind(this);
    this.showPage = this.showPage.bind(this);
  }


  componentDidMount() {
    this.updateData()
  }

  updateData() {
    backend.get(`/bot/bets-history/${this.props.match.params.name}?page=${this.state.page}`).then(({data}) => {
      // console.log('bets-history --- ', data)
      if (data.success) {
        this.setState({history: data.history});
      }
    }).catch(console.log).finally(() => {
      this.setState({
        loaded: true
      })
    })
  }

  showPage(page) {
    this.setState({ page }, () => {
      this.updateData();
    });
  }

  render() {

    return (
        <div className="x_panel">
          <div className="x_title">
            <h2>Bets history</h2>
            <PaginationComponent page={this.state.page} showPage={this.showPage} />
            <div className="clearfix"/>
          </div>
          <div className="x_content">

            <div className="table-responsive">
              <table className="table table-hover bulk_action jambo_table table-striped">
                <thead>
                <tr className="headings">
                  <th className="column-title">Timestamp</th>
                  <th className="column-title">Game Name</th>
                  <th className="column-title">Goal From</th>
                  <th className="column-title">Odd</th>
                  <th className="column-title">Stake</th>
                  <th className="column-title">Return</th>
                  <th className="column-title">Status</th>
                </tr>
                </thead>
                <tbody>
                {this.state.loaded && (
                    this.state.history.length > 0 ? (
                        this.state.history.map((bet) => <BetHistoryEntity key={bet._id} data={bet} /> )
                    ) : (
                        <tr>
                          <td colSpan="7">List is empty</td>
                        </tr>
                    )
                )}
                {!this.state.loaded && (
                    <tr>
                      <td colSpan="7"><i className="text-muted">Loading...</i></td>
                    </tr>
                )}
                </tbody>
              </table>
            </div>

          </div>
        </div>



    );
  }
}

export default withRouter(BotDetailedBetHistory);
