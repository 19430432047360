import React from "react";

import helpers from "../../chunks/helpers";
import backend from "../../chunks/backend";
import title from "../../chunks/title";
import Form from "./src/Form";
import {withRouter} from "react-router-dom";
import sub from "../../chunks/subscriber";

class PointStrategy extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            strategies: [],
            subIds: []
        };

        this.onClickDeleteButton = this.onClickDeleteButton.bind(this);
    }

    componentDidMount() {
        title('Strategies');
        this.updateData();

        const ids = [];

        for (let event of ['strategy.create', 'strategy.update', 'strategy.delete']) {
            const id = sub.subscribe(event, (data) => {
                this.updateData();
            });

            ids.push(id);
        }

        this.setState({ subIds: ids });
    }

    componentWillUnmount() {
        for (let id of this.state.subIds) {
            sub.unsubscribe(id);
        }
    }

    updateData() {
        backend.get('/strategies').then(({ data }) => {
            if (data.success) {
                this.setState({ strategies: data.strategies });
            }
        }).catch(console.log);
    }

    onClickDeleteButton(e) {
        const name = e.target.getAttribute('data-name');
        backend.delete(`/strategies/${name}`).catch(console.log);
    }

    render() {
        return (
            <div>
                <Form id="points_strategy_modal" />
                <div className="page-title">
                    <div className="title_left">
                        <h3>Points Strategy</h3>
                    </div>
                    <div className="title_right">
                        <div className="btn-group pull-right">
                            <button className="btn btn-success" data-toggle="modal" data-target="#points_strategy_modal">Create Strategy</button>
                        </div>
                    </div>
                </div>
                <div className="clearfix"/>
                <div className="row margin-top-profile">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                        <table className="table jambo_table">
                            <thead>
                                <tr className="headings">
                                    <th style={{ width: '160px' }}>Timestamps</th>
                                    <th>Strategy name</th>
                                    <th>Server details</th>
                                    <th>Acceptor details</th>
                                    <th>ITF League</th>
                                    <th>Bet type</th>
                                    <th>Priority</th>
                                    <th style={{ width: '150px' }}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                            {this.state.strategies.map((strategy, i) => (
                                <tr key={i}>
                                    <td>
                                        <i className="text-muted">{helpers.dt(strategy.createdAt)}</i>
                                        {strategy.updatedAt ? (
                                            <>
                                                <br />
                                                <i className="text-warning">{helpers.dt(strategy.updatedAt)}</i>
                                            </>
                                        ) : ''}
                                    </td>
                                    <td>
                                        <strong>{strategy.name}</strong>
                                    </td>
                                    <td>
                                        {strategy.server.available ? (
                                            <small>
                                                <div>Odds: <u>{strategy.server.odds || ' - '}</u></div>
                                                <div>Percent: <u>{strategy.server.percent || ' - '}</u></div>
                                                <div>Excluded: <u>{strategy.server.excluded || ' - '}</u></div>
                                            </small>
                                        ) : (
                                            <i className="text-muted">Not available</i>
                                        )}
                                    </td>
                                    <td>
                                        {strategy.acceptor.available ? (
                                            <small>
                                                <div>Odds: <u>{strategy.acceptor.odds || ' - '}</u></div>
                                                <div>Percent: <u>{strategy.acceptor.percent || ' - '}</u></div>
                                                <div>Excluded: <u>{strategy.acceptor.excluded || ' - '}</u></div>
                                            </small>
                                        ) : (
                                            <i className="text-muted">Not available</i>
                                        )}
                                    </td>
                                    <td>{strategy.itfLeague}</td>
                                    <td>{strategy.type}</td>
                                    <td>{strategy.priority}</td>
                                    <td>
                                        <div className="btn-group btn-group-sm">
                                            <button type="button" className="btn btn-warning" data-toggle="modal" data-target={'#update_'+strategy.name.trim()}>Update</button>
                                            <button type="button" className="btn btn-danger" onClick={this.onClickDeleteButton} data-name={strategy.name.trim()}>Delete</button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            {this.state.strategies.length === 0 && (
                                <tr>
                                    <td colSpan="8">
                                        <i className="text-muted">Strategies list is empty</i>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        {this.state.strategies.map((strategy) => (
                            <Form key={strategy._id} id={'update_'+strategy.name.trim()} data={strategy} />
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(PointStrategy);
