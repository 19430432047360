import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Card } from 'antd'
import backend from '../../../chunks/backend'

// import helpers from "../../../chunks/helpers";
// import routes from "../../../chunks/routes";

const gridStyle1 = {
  width: '15%',
  // textAlign: 'center',
  padding: '10px',
  backgroundColor: '#dfdfe1'
}

const gridStyle2 = {
  width: '15%',
  // textAlign: 'center',
  padding: '10px',
  backgroundColor: '#fcd7d7'
}

const gridStyle3 = {
  width: '15%',
  // textAlign: 'center',
  padding: '10px',
  backgroundColor: '#d3f3d3'
}

const formGroupStyle = {
  marginBottom: '0px'
}

class BotEntity extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      moneySimbetRegularFork: this.props.data.moneySimbetRegularFork || '',
      isSendBetSimbet: this.props.data.isSendBetSimbet || false,
    }

    this.changeMoneySimbetRegularFork = this.changeMoneySimbetRegularFork.bind(this);
    this.sendForm = this.sendForm.bind(this);
    this.stopSenddBot = this.stopSenddBot.bind(this);
  }

  // componentDidMount() {
  //   console.log('componentDidMount: ', this.props.data.name)
  //   // this.setState({event: this.props.data})
  // }

  // static getDerivedStateFromProps(props, state) {
  //   return {
  //     moneySimbetRegularFork: props.data.moneySimbetRegularFork || '',
  //     isSendBetSimbet: props.data.isSendBetSimbet || false,
  //   }
  // }

  stopSenddBot(e) {
    e.preventDefault();

    backend.put(`/bot/update/${this.props.data.name}`, {
      isSendBetSimbet: !this.state.isSendBetSimbet,
    })
      .then((res) => {
        if (res.data.success) {
          // this.updateData();
          this.setState({
            isSendBetSimbet: !this.state.isSendBetSimbet,
          });
        }
      })
      .catch(console.log);

    // this.setState({
    //   isSendBetSimbet: !this.state.isSendBetSimbet,
    // });
  }

  changeMoneySimbetRegularFork(e) {
    // console.log('changeMoneySimbetRegularFork: ', e.target.value)
    this.setState({
      moneySimbetRegularFork: e.target.value
    });
  }

  sendForm(e) {
    e.preventDefault();

    const {moneySimbetRegularFork} = this.state;
    // console.log('sendForm: ', moneySimbetRegularFork)
    // console.log('name: ', this.props.data.name)

    backend.put(`/bot/update/${this.props.data.name}`, {
      moneySimbetRegularFork: moneySimbetRegularFork,
    })
      .then((res) => {
        if (res.data.success) {
          // this.updateData();

          // messageApi.open({
          //   type: 'success',
          //   content: 'Saved!',
          // });
        }
      })
      .catch(console.log);
  }

  render() {
    const bot = this.props.data
    // console.log('bot', bot)
    // const url = routes.users.profile.replace(':username', bot.username);

    return (
      <Card.Grid key={`${bot._id}_${bot.name}`} hoverable={false} style={bot.isBetBasketballAccepted === '1' ? gridStyle1 : (bot.isBetBasketballAccepted === '2' ? gridStyle2 : (bot.isBetBasketballAccepted === '3' ? gridStyle3 : (bot.isBetBasketballAccepted ? gridStyle1 : gridStyle1)))}>
        <small><span className=""><strong>{bot.name}</strong></span></small>
        <div>
          <form className="form-label-left input_mask" onSubmit={this.sendForm}>
            <div className="form-row">
              <div className="form-group col-md-8 col-sm-8" style={formGroupStyle}>
                {/*<label>Сумма вывода с <strong>{book1}</strong></label>*/}
                <input
                  value={this.state.moneySimbetRegularFork}
                  onChange={this.changeMoneySimbetRegularFork}
                  type="text"
                  className="form-control form-control-sm"
                  required=""
                />
              </div>
              <div className="form-group col-md-2 col-sm-2" style={formGroupStyle}>
                <button type="submit" className="btn btn-primary btn-sm"><i className="fa fa-check" aria-hidden="true"></i></button>
              </div>
              <div className="form-group col-md-2 col-sm-2" style={formGroupStyle}>
                <button onClick={this.stopSenddBot} className={this.state.isSendBetSimbet ? "btn btn-success btn-sm" : "btn btn-danger btn-sm"}><i className="fa fa-ban" aria-hidden="true"></i></button>
              </div>
            </div>
          </form>
        </div>
        {/*/!*<small><span className="">Translated: <strong>{outcome.bets[key2].name}</strong></span></small>*!/*/}
        {/*/!*<div><small><span className="">Original  : <strong>{outcome.bets[key2].originalName}</strong></span></small></div>*!/*/}
        {/*<div><span className="badge badge-primary badge-font"><strong>{outcome.bets[key2].odds}</strong></span></div>*/}
      </Card.Grid>
    )
  }
}

export default withRouter(BotEntity)
