// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-profile{
    display: none;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #26B99A !important;
    border-color: #169F85;
}

#user_bots_list .pagination {
    margin-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Users/Profile/UserBot/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;;IAEI,WAAW;IACX,oCAAoC;IACpC,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".modal-profile{\n    display: none;\n}\n\n.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,\n.show > .btn-secondary.dropdown-toggle {\n    color: #fff;\n    background-color: #26B99A !important;\n    border-color: #169F85;\n}\n\n#user_bots_list .pagination {\n    margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
